<template>
  <div class="trade-page">
    <div class="panel-header">
      <div class="row between">
        <div class="row-item">
          <BaseBtn naked>
            <IconChevronDown left />
            <!-- <img src="@/assets/icons/chevron-left.svg" alt="" /> -->
          </BaseBtn>
          <div class="breadcrumb">
            <span><h3>AI매매신호 / E-mini NASDAQ /</h3></span><h3>Early Trend Cater</h3>
          </div>
        </div>
        <div class="row-item">
          <BaseBtn naked>
            <img src="@/assets/icons/add.svg" alt="" />
          </BaseBtn>
        </div>
      </div>
    </div>
    <div class="table-header">
      <h3 class="table-subtitle">나의 신호 / 가용 신호 (4/5)</h3>
      <BtnSub text="업그레이드" />
    </div>
    <div class="alert-item-info">
      <h2 class="bold">상품 · NQ | TYPE · Trend</h2>
      <img class="chart-sample" src="@/assets/images/chart-sample2.png" alt="" />
      <div class="table-container">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-delkoit"
          alwaysShowHorizontalScroll="true"
          :columnDefs="grid.columnDefs"
          :rowData="grid.rowData"
          :headerHeight="30"
          :rowHeight="30"
        ></ag-grid-vue>
      </div>
      <div class="desc">
        <h3 class="bold">신호선택</h3>
        <h5>
          본 신호는 추세추종 데이트레이딩 전략으로 추세의 방향을 맞췄을 때 수익이 발생한다. 긴 추세를 확보하기 까지 작은 손실을 견디는 전형적인
          추세전략으로 추세추종 데이트레이딩 전략으로 추세의 방향을 맞췄을 때 수익이 발생한다. 긴 추세를 확보하기 까지 작은 손실을 견디는 전형적인
          추세전략으로 ….
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
import BtnSub from '@/components/BtnSub.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import { AgGridVue } from "ag-grid-vue" // Vue Grid Logic

export default {
  name: 'TradePageSub',
  components: { BtnSub, BaseBtn, IconChevronDown, AgGridVue },
  data() {
    return {
      grid: {
        columnDefs: [
          { 
            headerName: "구분", 
            field: "구분",
            minWidth: 89,
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "테스트(기대)",
            field: "테스트(기대)",
            minWidth: 89,
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "1M 실거래",
            field: "1M 실거래",
            minWidth: 89,
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          
        ],
        rowData: [
          { 
            구분: "수익($)",
            '테스트(기대)': 2548,
            '1M 실거래': 1254,
          },
          { 
            구분: "거래횟수",
            '테스트(기대)': 23,
            '1M 실거래': 20,
          },
          { 
            구분: "승률",
            '테스트(기대)': '44.50%',
            '1M 실거래': '50%',
          },
          { 
            구분: "평균수익",
            '테스트(기대)': 568,
            '1M 실거래': 425,
          },
          { 
            구분: "평균손실",
            '테스트(기대)': -245,
            '1M 실거래': -235,
          },
          { 
            구분: "수익/손실",
            '테스트(기대)': 2.14,
            '1M 실거래': 1.85,
          },
          { 
            구분: "MDD",
            '테스트(기대)': -1234,
            '1M 실거래': -1234,
          },
        ],        
      }
    }
  },
  mounted() {},
  methods: {},
  
};
</script>

<style lang="scss" scoped>
.trade-page {
  padding: 10px 20px;
}
.panel-header {
  margin-bottom: 10px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 5px;
  span {
    opacity: 0.8;
    font-weight: 400;
  }
}

.table-subtitle {
  opacity: 0.6;
}
.table-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 9px;
}

.alert-item-info {
  padding-top: 10px;
  border-top: 1px solid var(--border-color-dom);
  .chart-sample {
    margin-top: 10px;
  }
  .table-container {
    margin-top: 20px;
    height: 243px;
  }
  .desc {
    margin-top: 20px;
    h5 {
      margin-top: 10px;
      line-height: 1.4em;
      opacity: 0.8;
    }
  }
}

@import '@/assets/scss/agGridStyles.scss';
</style>
