<template>
	<div class="avatar-container" :style="{ width: width + 'px', height: height + 'px', backgroundColor: color }" @click="handleClick(color)">
		<img class="profile-image" src="@/assets/images/profile-sample.jpg" alt="" v-if="profile">
		<div class="avatar-name" :style="{fontSize: fontSize + 'px', fontWeight: fontWeight}" v-if="!profile">{{name}}</div>
	</div>
</template>

<script>
export default {
	name: 'AvatarComp',
	props: {
      width: [String, Number],
      height: [String, Number],
      fontSize: [String, Number],
      fontWeight: String,
      profile: Boolean,
      name: String,
      color: String,
	},
	data() {
      return {
      }
	},

	mounted() {
		
	},

	methods: {
      handleClick(color) {
        this.$emit('click', color)
      }		
	},
};
</script>

<style lang="scss" scoped>
.avatar-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50vh;
	background-color: #459DFF;
	color: white;
	overflow: hidden;
	cursor: pointer;
	.profile-image {
		width: 100%;
		object-position: center;
	}
	.avatar-name {
		font-size: 16px;
		/* padding-bottom: 2px; */
	}
}
</style>