<template>
	<div class="faq-item-container">
		<p>{{ faq }}</p>
		<IconChevronDown right />
	</div>
</template>

<script>
import IconChevronDown from '@/components/icons/IconChevronDown.vue';

export default {
    name: 'FaqItem',
    props: {
        faq: String
    },
    data() {
        return {};
    },
    mounted() {
    },
    methods: {},
    components: { IconChevronDown }
};
</script>

<style lang="scss" scoped>
.faq-item-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 10px 10px 20px;
	border-bottom: 1px solid rgba(255,255,255,0.1);
	
	&:hover {
		background-color: rgba(255,255,255,0.05);
		cursor: pointer;
	}
	p {
		max-width: 280px;
		line-height: 1.4em;
	}
	@media (max-width: $mobile) {
		padding: 16px 10px 16px 20px;
	}
}
</style>