<template>
    <div class="login-page">
      <div class="left">
        <div class="hero-title">
          <h1 class="title">Plan&Timing</h1>
          <h2 class="subtitle">The Art of Trading</h2>
        </div>
      </div>
      <div class="right">
        <div class="header">
          <div></div>
          <h4 class="header-title">
            <b>TAP</b>
            <span>TradingAsPlan</span>
          </h4>
          <BaseBtn naked @click="close">
            <IconClose width="24" height="24" />
          </BaseBtn>       
        </div>
        <div class="body">
          <div class="body-inner">
            <h3 class="body-title">
              {{ form.loginId }} 님
              <br />
              비밀번호를 
              <br />
              변경 해 주십시오.
            </h3>
            <p class="desc">
              발송된 링크의 유효기간은 30분입니다. <br>
              유효기간 안에 비밀번호 변경을 완료 해 주시기 바랍니다.
            </p>

            <div class="email-login-container">
              <validation-observer ref="validator">
                <validation-provider rules="required|password" name="비밀번호" v-slot="{errors}">
                    <div class="password-container">
                    <input :type="fieldType" placeholder="비밀번호" class="input" :class="[errors[0] ? 'error': '']" :tabIndex="1" v-model="form.passwd" />
                    <BaseBtn naked class="show-pass" @click="showPassword">
                        <img src="@/assets/icons/eye.svg" alt="" v-if="!passwordEye">
                        <img src="@/assets/icons/eye-closed.svg" alt="" v-if="passwordEye">
                    </BaseBtn>
                    </div>
                    <p class="login-error-message">{{errors[0]}}</p>
                </validation-provider>

                <validation-provider rules="required|password" name="비밀번호 확인" v-slot="{errors}">
                    <div class="password-container">
                    <input :type="confFieldType" placeholder="비밀번호 확인" class="input" :class="[errors[0] ? 'error': '']" :tabIndex="2" @keyup.enter="reset" v-model="form.confPasswd" />
                    <BaseBtn naked class="show-pass" @click="showConfPassword">
                        <img src="@/assets/icons/eye.svg" alt="" v-if="!confPasswordEye">
                        <img src="@/assets/icons/eye-closed.svg" alt="" v-if="confPasswordEye">
                    </BaseBtn>
                    </div>
                    <p class="login-error-message">{{errors[0]}}</p>
                </validation-provider> 
              </validation-observer>               
            
              <button class="login-btn" @click="reset">확인</button>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import IconClose from '@/components/icons/IconClose.vue'
  import IconChevronDown from '@/components/icons/IconChevronDown.vue'
  import BaseBtn from '@/components/BaseBtn.vue'
  import Const from '@/const'

  export default {
    components: {
      IconClose,
      IconChevronDown,
      BaseBtn,
    },
  
    name: 'ResetPasswdPage',
  
    data() {
      return {
        form : {
          loginId: '',
          key: '',
          passwd: '', 
          confPasswd: '',
        },
        fieldType: 'password',
        passwordEye: '',
        confFieldType: 'password',
        confPasswordEye: '',
      }
    },
  
    created() {
        this.form.loginId = this.$route.params.loginId
        this.form.key = this.$route.params.key
    },
  
    methods: {
      close () {
        this.$router.push('/')
      },         
      showPassword() {
        this.fieldType = this.fieldType === 'password' ? 'text' : 'password'
        this.passwordEye = !this.passwordEye
      },
      showConfPassword() {
        this.confFieldType = this.confFieldType === 'password' ? 'text' : 'password'
        this.confPasswordEye = !this.confPasswordEye
      },      
      reset () {
        const { validator } = this.$refs

        validator.validate().then((result) => {
          if (result) {

            if ( this.form.passwd !== this.form.confPasswd ) {
              this.$alert.alert('비밀번호와 비밀번호 확인이 일치하지 않습니다.', 'Warnning')  
              return
            }

            this.$alert.confirm('비밀번호를 변경 하시겠습니까?', '확인')
              .then((response) => {
                if (response) {      

                  this.$lhttp
                    .post('/auth/passwd/reset',
                        this.form)
                    .then((response) => {
                      // 성공
                      if ( response.data.code == Const.LOGIN_RESPONSE_TYPE.SUCCESS ) {
                        this.$alert.alert('비밀번호 변경에 성공하였습니다. 변경된 비밀번호로 로그인 하십시오.', '안내')
                        .then(() => {
                          this.$router.push('/login-email')
                        })
                      } else {
                        this.$alert.alert(response.data.message, 'Warnning')
                      }

                    })

                }
              })

          }
        })        
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .body-title {
    margin-bottom: 20px;
  }
  .desc {
    font-size: 14px;
    line-height: 1.4em;
    text-align: center;
    opacity: 1 !important;
    &.notice {
      font-size: 12px;
      text-align: left;
      width: 100%;
      opacity: 0.6 !important;
    }
    .title {
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      margin-right: 5px;
    }
    .result {
      opacity: 0.8;
    }
  }
  .input {
    width: 100%;
    padding: 20px 10px;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    &.error {
      border-color: var(--main-red);
    }
  }

  .password-container {
    position: relative;
    .show-pass {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }
  }
  .checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    align-self: flex-start;
  }
  .agree-link {
    color: var(--main-orange);
  }
  .email-login-container {
    width: 100%;
    margin: 38px 0 42px 0;
  }  
  .login-error-message {
    font-size: 12px;
    color: var(--main-red);
    margin-bottom: 20px;
  }
</style>
  