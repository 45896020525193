<template>
	<div class="checkbox-container" :class="{active : checked}" :value="value" @click="handleClick">
		<img class="checkbox-off" src="@/assets/icons/checkbox-off.svg" alt="">
		<img class="checkbox-on" src="@/assets/icons/checkbox-on.svg" alt="">
		<h3>{{text}}</h3>
	</div>
</template>

<script>
/*
v-bind:value="slStockVal2"
@childEvent="sellSlStock"
*/
//
//
export default {
	name: 'CheckboxBtn',
	props: {
		text: String,
		value: {
			type: Boolean,
			default: () => {
				return false;
			},
		}
	},
	watch: {
		value: {
		handler(newFlag) {
			this.checked = newFlag;
		},
		deep: true,
		},
	},
	data() {
		return {
			checked: false	
		};
	},
	

	mounted() {
		
	},

	methods: {
		handleClick() {
			this.$emit("childEvent"); //부모페이지 호출 <CheckboxBtn .... @childEvent='메소드명' />
		}
	},
};
</script>

<style lang="scss" scoped>
.checkbox-container {
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	&:hover {
		opacity: 0.7;
	}
	&.active {
		.checkbox-on {
			display: block;
		}
		.checkbox-off {
			display: none;
		}
	}
	.checkbox-on {
		display: none;
	}
}
</style>