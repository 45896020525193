<template>
  <div class="login-page">
    <div class="left">
      <div class="hero-title">
        <h1 class="title">Plan&Timing</h1>
        <h2 class="subtitle">The Art of Trading</h2>
      </div>
    </div>
    <div class="right">
      <div class="header">
        <BaseBtn naked @click="goBack">
          <IconChevronDown left width="24" height="24" />
        </BaseBtn>
        <h4 class="header-title">
          <b>TAP</b>
          <span>TradingAsPlan</span>
        </h4>
        <BaseBtn naked @click="close">
          <IconClose width="24" height="24" />
        </BaseBtn>
      </div>
      <div class="body">
        <div class="body-inner">
          <h3 class="body-title">
            귀하의 이메일을
            <br />
            인증하시기 바랍니다.
          </h3>
          <p class="desc">
            인증 링크가 전송되었습니다. 받은 편지함에 <br>
            인증 링크가 없으면 스팸/정크함을 확인하세요.
          </p>

          <button class="send-again" @click="sendSignUp">활성화 링크 다시 보내기</button>

          <div class="login-division">
            <span></span>
            <p>또는</p>
            <span></span>
          </div>
          <p class="desc">다음 계정으로 가입할 수 있습니다.</p>
          <div class="sns-login-container">
            <a href="">
              <img src="@/assets/images/google-logo.png" alt="" />
              google
            </a>
            <a href="">
              <img src="@/assets/images/kakao-logo.png" alt="" />
              kakao
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import BaseBtn from '@/components/BaseBtn.vue'

export default {
  components: {
    IconClose,
    IconChevronDown,
    BaseBtn,
  },

  name: 'VerifyEmailPage',

  data() {
    return {
      loginId: ''
    }
  },

  created() {
    this.loginId = this.$route.params.loginId
  },

  methods: {
    goBack () {
      this.$router.go(-1)
    },
    close () {
      this.$router.push('/')
    },    
    sendSignUp () {

      this.$alert.confirm('활성화 링크를 다시 보내시겠습니까?', '확인')
        .then((response) => {
          if (response) {     
            this.$lhttp
              .post('/auth/signup/email/resend',
                { loginId : this.loginId })
              .then(() => {
                this.$alert.alert('인증 이메일이 전송되었습니다. 이메일을 확인 해 주십시오.', '안내')
              })   
          }
        })

    }
  },
};
</script>

<style lang="scss" scoped>
.body-title {
  margin-bottom: 20px;
}
.desc {
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
}
.send-again {
  color: var(--main-orange);
  font-size: 14px;
  margin-top: 11px;
}
.login-division {
  margin: 30px 0 !important;
}
.sns-login-container {
  margin-top: 30px;
}
</style>
