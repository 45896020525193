<template>
  <div>
    <h3 class="balance-text" :class="balance < 0 ? 'minus' : 'plus'">{{ balance | thousand }}</h3>
  </div>
</template>

<script>
// import H3Text from './H3Text.vue';

export default {
  name: 'BalanceText',
  props: {
    balance: String,
  },
  data() {
    return {};
  },
  filters: {
    thousand(value) {
      return new Intl.NumberFormat('en-US').format(value);
    },
  },
  mounted() {},
  methods: {},
  // components: { H3Text }
};
</script>

<style lang="scss" scoped>
.balance-text {
  color: white;
  &.minus {
    color: var(--main-blue);
  }
  &.plus {
    color: var(--main-red);
  }
}
</style>
