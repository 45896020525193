<template>
	<svg id="add" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16">
  <path id="Union" d="M3.85,9h1.3V5.15H9V3.85H5.15V0H3.85V3.85H0v1.3H3.85Z" transform="translate(3.5 3.5)" :style="{ fill: fill }" />
</svg>

</template>

<script>
export default {
	name: 'IconAdd',
	props: {
		width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
		fill: String
	},
	data() {
		return {
			
		};
	},

	mounted() {
		
	},

	methods: {
		
	},
};
</script>

<style lang="scss" scoped>
svg {
	fill: white;
}
</style>