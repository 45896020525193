<template>
    <div>
      <p>처리 중입니다...</p>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'LoginGooglePage',
  
    data() {
      return {
        form: {
          code: '',
          redirectUri: '',
        }  
      }
    }, 
  
    created() {
      this.form.code        = this.$route.query.code
      this.form.redirectUri = window.location.protocol +'//'+ window.location.host + window.location.pathname
  
      window.opener.postMessage({type: 'LOGIN_KAKAO', payload: this.form}, process.env.VUE_APP_WEB_BASE_URL)
      
      window.close()
    },  
  
  }
  </script>