<template>
  <div class="slide">
    <input type="checkbox" :id="id" :value="value" v-model="slideChecked" :disabled="disabled" @change="handleChecked" />
    <label :for="id"></label>
  </div>
</template>

<script>
export default {
  name: 'BtnSlide',
  props: {
    id: String,
    value: {
      type: Boolean,
      default: false      
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      slideChecked: false,
    };
  },

  mounted() {
    this.slideChecked = this.value
  },

  methods: { 
    handleChecked() {
      this.$emit('input', this.slideChecked)
      this.$emit('event:checkSlide', this.slideChecked)
    },
  },
};
</script>

<style lang="scss" scoped>
.slide {
  width: 32px;
  height: 16px;

  input[type='checkbox'] {
    display: none;
    & + label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      background-color: #595e6c;
      border-radius: 50vh;
      width: 100%;
      height: 100%;
      @media (hover: hover) {
        &:hover {
          opacity: 0.7;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50vh;
        background-color: var(--main-white);
        transition: left 0.2s;
      }
    }
    &:checked {
      & + label {
        background-color: var(--main-orange);
        &::before {
          left: 17px;
        }
      }
    }
  }
}
</style>
