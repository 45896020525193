const state = {
  symbolList: [],
  curSymbol: {}
}

const getters = {
  getSymbolList (state) {
    return state.symbolList
  },
  getCurSymbol (state) {
    return state.curSymbol
  }
}

const mutations = {
  setSymbolList (state, { symbolList }) {
    state.symbolList = symbolList
  },
  setCurSymbol (state, { curSymbol }) {
    state.curSymbol = curSymbol
  }
}

const actions = {
  load ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      window.$http
        .get('chart/datafeed/symbols')
        .then(response => {
          //console.log(response.data)
          if (response.data) {
            commit('setSymbolList', {
              symbolList: response.data
            })

            dispatch('setCurSymbol', response.data[0])
          }
          resolve()
        })
        .catch(e => reject(e))
    })    
  },
  setCurSymbol({ commit, state }, symbolName) {
    // 초기화
    commit('setCurSymbol', {curSymbol: {}})

    if ( state.symbolList != null && state.symbolList.length > 0 ) {
      const findSymbol = state.symbolList.find(symbol =>  symbol.name === symbolName)
      if(findSymbol){
        commit('setCurSymbol', {curMenu: findSymbol})
      }
    }   
  }
}    

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}