<template>
  <div class="notification-page">
    <div class="panel-header">
      <div class="row between">
        <div class="row-item">
          <h3 class="bold">알림</h3>
        </div>
        <div class="row-item">
          <GnbDropDown right width="120">
            <template #gnb-title>
              <div class="panel-menu-container">
                <img src="@/assets/icons/ellipsis-menu.svg" alt="" />
                <div class="tool-tip down"><h3>알림설정</h3></div>
              </div>                 
            </template>
            <template #dropdown-list>
              <h4 class="dropdown-subtitle">알림설정</h4>

              <div class="dropdown-list-item" v-for="msgTp in msgTpList" v-bind:key="msgTp.cd">
                <div class="col">
                  <BtnCheckbox :id="`chk_msgTp_${msgTp.cd}`" :text="msgTp.cdNm" v-model="msgTp.isCheck" @click="setMsgTp(msgTp.cd, $event)" />
                </div>
              </div>

            </template>
          </GnbDropDown>
        </div>
      </div>
    </div>

    <div class="notification-body">
      <template v-for="(msgCont, index) in msgList">

        <PanelBoxList v-bind:key="`notification-panel-${index}`" :title="msgCont.msgDay" :style="[index > 0 ? {'margin-top': '15px'}: {}]">

          <li v-for="msg in msgCont.msgContList" v-bind:key="`notification-item-${msg.seq}`">
            <NotificationItem :msg="msg" />
          </li>

        </PanelBoxList>

      </template>
    </div>

  </div>
</template>

<script>
import GnbDropDown from '@/components/GnbDropDown.vue'
import NotificationItem from '@/components/NotificationItem.vue'
import BtnCheckbox from '@/components/BtnCheckbox.vue'
import PanelBoxList from '@/components/PanelBoxList.vue'
import EventBus from '@/modules/event-bus'
import Utils from '@/modules/utils'

export default {
  name: 'NotificationPage',
  data() {
    return {
      isMouted: false,
      msgTpList: null,
      params:{
        msgTpCds: []
      },
      msgList: null,
    }
  },
  created() {
    // 
    EventBus.$on('Alarm:event:refresh', () => {
      this.refresh()
    })

    // 
    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.selectMsgTpList()
    })    
  },    
  mounted() {
    this.isMouted = true
    this.selectMsgTpList()
  },
  destroyed() {
    this.isMouted = false
  },
  methods: {
    selectMsgTpList() {
      const msgTpCds = window.$store.getters['Layout/getMsgTpCds']

      this.$http
      .get('/common/com-code/list', {params : {grpCd : 'MSGTP'}})
      .then((result) => {
        this.msgTpList = result.data
        this.params.msgTpCds = []

        for (let msgTp of this.msgTpList) {
          if ( msgTpCds !== undefined && msgTpCds.findIndex((msgTpCd) => msgTpCd === msgTp.cd) > -1 ) {
            msgTp.isCheck = true
            this.params.msgTpCds.push(msgTp.cd)
          } else {
            msgTp.isCheck = false
          }
        }
        
        this.search()
      })
    },
    async search() {
      const response = await this.$http.get('/api/msg/list', { params: this.params })
      this.msgList = new Array()

      if ( response && response.data ) {
        let msgDay = null
        let index = -1

        for (let item of response.data) {
          if ( item.msgDay !== msgDay ) {
            index += 1
            msgDay = item.msgDay
            this.msgList[index] = new Object()
            this.msgList[index].msgDay = msgDay
            this.msgList[index].msgContList = new Array()
          }

          this.msgList[index].msgContList.push(item) 
        }
      }

      // 알림 조회 완료
      EventBus.$emit('Alarm:event:search', {})
    },
    refresh() {
      if ( this.isMouted ) {
        this.search()
      }
    },
    setMsgTp(msgTpCd, value) {
      if (value) {
        Utils.addValueInArray(this.params.msgTpCds, msgTpCd)
      } else {
        Utils.delValueInArray(this.params.msgTpCds, msgTpCd)
      }

      window.$store.dispatch('Layout/saveCustom', { type: 'MSG', custom: this.params.msgTpCds })
      this.search()
    }
  },
  components: {
    NotificationItem,
    GnbDropDown,
    BtnCheckbox,
    PanelBoxList,
  },
};
</script>

<style lang="scss" scoped>
.notification-page {
  padding: 10px 20px;
  height: calc(100% - 50px);
  .panel-header {
    margin-bottom: 10px;
  }
  .notification-body {
    padding: 0px 0px 0px 0px;
    height: 100%;
    overflow-y: auto;
  }  
}
</style>
