<template>
  <svg id="close" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16">
    <path
      d="M0,.919.919,0,4,3.076,7.071,0,7.99.919,4.914,4,7.99,7.071l-.919.919L4,4.914.919,7.99,0,7.071,3.076,4Z"
      transform="translate(4.005 4.005)" :style="{ fill: fill }"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconClose',
  props: {
    width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
}
</style>
