<template>
  <div class="favorite-page">
    <div class="panel">
      <div class="row between">
        <div class="row-item">
          <GnbDropDown chevron>
            <template #gnb-title>
              <h2>관심종목<span v-if="grid.custom.seq > 0">_{{ grid.custom.itrstNm }}</span></h2>
            </template>
            <template #dropdown-list>
              <div class="dropdown-list-item" @click="changeFavoriteNm">
                <div class="col">
                  <h3>이름변경하기</h3>
                </div>
              </div>
              <div class="dropdown-list-item" @click="createNewFavoriteList">
                <div class="col">
                  <h3>새리스트 만들기</h3>
                </div>
              </div>
              <div class="dropdown-list-item" @click="createCopyFavoriteList">
                <div class="col">
                  <h3>복사본 만들기</h3>
                </div>
              </div>
              <div class="dropdown-list-item" @click="loadFavoriteList">
                <div class="col">
                  <h3>리스트 불러오기</h3>
                </div>
              </div>
            </template>
          </GnbDropDown>
        </div>
        <div class="row-item">
          <div class="panel-menu-container">
            <BaseBtn naked @click="addToFavorite"><IconAdd /></BaseBtn>
            <div class="tool-tip down"><h4>심볼넣기</h4></div>
          </div>          
          <GnbDropDown width="120" right>
            <template #gnb-title>
              <div class="panel-menu-container">
                <img src="@/assets/icons/ellipsis-menu.svg" alt="" />
                <div class="tool-tip down"><h4>열 설정</h4></div>
              </div>              
            </template>
            <template #dropdown-list>
              <h4 class="dropdown-subtitle">열 설정</h4>
              <!-- <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="symbol" text="심볼" v-model="grid.custom.shtNm" @click="setCustom" />
                </div>
              </div> -->
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="currentPrice" text="현재가" v-model="grid.custom.curPr" @click="setCustom" />
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="moveAmount" text="등락" v-model="grid.custom.ydiffPr" @click="setCustom" />
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="moveRate" text="등락율" v-model="grid.custom.chGrate" @click="setCustom" />
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="volume" text="거래량" v-model="grid.custom.totQ" @click="setCustom" />
                </div>
              </div>
            </template>
          </GnbDropDown>
        </div>
      </div>
      <div class="favorite-table">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-delkoit"
          alwaysShowHorizontalScroll="true"
          :columnDefs="grid.columnDefs"
          :autoSizeStrategy="grid.autoSizeStrategy"
          :alwaysShowVerticalScroll="true"
          :rowData="grid.rowData"
          @grid-ready="onGridReady"
          @row-double-clicked="setSymbol">
        </ag-grid-vue>
      </div>
    </div>

    <ChatPanel />

    <addFavoritePop @event:saveFavorite="loadFavorite" />
    <favoriteCreatePop @event:saveFavorite="loadFavorite" />
    <favoriteLoadPop @event:saveFavorite="loadFavorite" />
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
import ChatPanel from '@/pages/community/ChatPanel.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import GnbDropDown from '@/components/GnbDropDown.vue'
import BtnCheckbox from '@/components/BtnCheckbox.vue'
import IconAdd from '@/components/icons/IconAdd.vue'

import AddFavoritePop from '@/pages/main/AddFavoritePop.vue'
import FavoriteCreatePop from '@/pages/main/FavoriteCreatePop.vue'
import FavoriteLoadPop from '@/pages/main/FavoriteLoadPop.vue'

import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
import DownComp from '@/components/DownComp.vue'
import UpComp from '@/components/UpComp.vue'
//import FavoriteCustomHeader from '@/components/grid/FavoriteCustomHeader.vue'

import EventBus from '@/modules/event-bus'
import gridUtils from '@/modules/grid-utils'
import { stringCount } from '@/modules/helper'

// eslint-disable-next-line no-unused-vars
const cellClassRulesMinus = {
	red: params => params.data.ydiffSign == "2",
	blue: params => params.data.ydiffSign == "5",
}

export default {
  name: 'FavoritePage',
  props: {
    hideTable: Boolean,
    active: Boolean,
  },
  computed: {
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData']
      return ovcData
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList']
      return ovcData
    },
  },
  watch: {
    //소켓시세
    changeOvcData(newPrice){
        this.ovcMap.set(newPrice.symbol, newPrice);
        this.symbolData();
    },
    //폴링시세
    changeOvcDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          this.ovcMap.set(data.symbol, data);
        })
        this.symbolData();
      }
    },
  },
  components: {
    ChatPanel,
    // eslint-disable-next-line vue/no-unused-components
    BaseBtn,
    // eslint-disable-next-line vue/no-unused-components
    IconChevronDown,
    // eslint-disable-next-line vue/no-unused-components
    GnbDropDown,
    // eslint-disable-next-line vue/no-unused-components
    BtnCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    IconAdd,
    AddFavoritePop,
    FavoriteCreatePop,
    FavoriteLoadPop,
    AgGridVue, // Add AG Grid Vue3 component
		// eslint-disable-next-line vue/no-unused-components
		DownComp,
		// eslint-disable-next-line vue/no-unused-components
		UpComp,
    //agColumnHeader: FavoriteCustomHeader,    
  },

  data() {
    return {
      ovcMap: new Map(),
      grid: {
        columnDefs: [
          { 
            headerName: "심볼", 
            field: "shtNm", 
            flex: 0.7,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            headerComponentParams: {
              enableMenu: true,
            },            
          },
          { 
            headerName: "현재가",
            field: "curPr",
            flex: 0.8,
            headerClass: 'ag-right-aligned-header',
            //headerClass: 'right-aligned-header',
            cellStyle: {
              justifyContent: 'flex-end',
            },            
            type: 'rightAligned',
          },
          { 
            headerName: "등락",
            field: "ydiffPr",
            flex: 0.8,
            headerClass: 'ag-right-aligned-header',
            //headerClass: 'right-aligned-header',
            cellStyle: {
              justifyContent: 'flex-end',
            },
            cellRendererSelector: (p) => {
              if (p.data.ydiffSign == "5") {
                return {component: 'DownComp'};
              }
              if (p.data.ydiffSign == "2") {
                return {component: 'UpComp'};
              }
            },
            cellClassRules: cellClassRulesMinus,
            // cellClass: (params) => {
            //   return params.data.ydiffSign == "2" ? 'upValue' : 'downValue'
            // }
          },
          { 
            headerName: "등락율",
            field: "chGrate",
            flex: 0.8,
            headerClass: 'ag-right-aligned-header',
            //headerClass: 'right-aligned-header',
            cellStyle: {
              justifyContent: 'flex-end',
            },
            cellClassRules: cellClassRulesMinus
          },
          { 
            headerName: "거래량",
            field: "totQ",
            flex: 1,
            headerClass: 'ag-right-aligned-header',
            //headerClass: 'right-aligned-header',
            cellStyle: {
              justifyContent: 'flex-end',
            },
            cellRenderer : gridUtils.numberComma,
            //valueFormatter: volumnFormatter 
          },
        ],
        rowData: [
          //{ shtNm: "ES", curPr: "4530.25", ydiffPr: "10.14", chGrate: "-0.15", totQ: 1152352 },
        ],
        autoSizeStrategy: null,  
        custom : {
          itrstNm: '관심종목',
          shtNm: true,
          curPr: true,
          ydiffPr: true,
          chGrate: true,
          totQ: true,
          seq:0,
          socketSymbol:[],
        },                      
      },
      gridApi: null,
      gridColumnApi: null,
      isGridReady: false,
      setTimeId:null,
      layoutSeq: 0,
    }
  },

  created() {
    // 
    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom()
    })
  },  

  mounted() {
    this.loadCustom()
    this.setStockInfo()
  },
  destroyed(){
    clearTimeout(this.setTimeId);
    this.gridApi = null;
  },
  methods: {
    async setStockInfo() {
      const rStock = await window.$http.get('chart/datafeed/stockdata', { params: {  } } )
      if ( rStock != null ) {          
        //시세 db값으로 초기 설정
        rStock.data.forEach(item => {
          this.ovcMap.set(item.symbol, item);
        });
        this.symbolData();
      }
    },
    symbolData(){
      this.grid.rowData.forEach(rowData =>{
        let symbol;
        let symbolList = window.$store.getters['Symbol/getSymbolList'];

        symbolList.forEach(item => {
          if (item.name == rowData.symCd){
            symbol = item
            return
          }
        })

        if (this.ovcMap.get(rowData.symCd) != undefined){
          const ovc = this.ovcMap.get(rowData.symCd)
          rowData.curPr = ovc.curPr.toFixed(this.tikDotSz(symbol))
          rowData.chGrate = ovc.chGrate
          rowData.ydiffPr = ovc.ydiffPr.toFixed(this.tikDotSz(symbol))
          rowData.totQ = ovc.totQ
          rowData.ydiffSign = ovc.ydiffSign
        }
      })

      if (this.gridApi){
        this.gridApi.refreshCells();
      }
      


      this.setTimeId = setTimeout(() => {
        this.symbolData()
      }, 100)
    },
    tikDotSz(symbol){
      if (symbol == null){
        return 0;
      }
      return stringCount(symbol.pricescale);
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      this.isGridReady = true

      this.setGridCustomVisible()
    },
    setGridCustomVisible() {
      const grid  = this.grid
      const gridColumnApi = this.gridColumnApi

      Object.keys(grid.custom).forEach(function(key){
        gridColumnApi.setColumnVisible(key, grid.custom[key])
      })              
    },        
    loadCustom() {
      const custom = window.$store.getters['Layout/getFavoriteGridCustom']

      if ( custom !== undefined && custom !== null ) {
        this.grid.custom = custom
      }
      
      this.layoutSeq = window.$store.getters['Layout/getLaySeq']
      if ( this.isGridReady ) {
        this.setGridCustomVisible()
      }

      this.loadFavorite()
    },   
    setCustom() {
      window.$store.dispatch('Layout/saveCustom', { type: 'FV', custom: this.grid.custom })
      this.loadCustom()
    },      
    addToFavorite() {
      if (this.layoutSeq == 0){
        this.$alert.alert('선택한 화면이 없습니다.', '알림');
        return;
      }
      if (this.grid.custom.seq == 0){
        this.$alert.alert('선택한 관심종목이 없습니다.', '알림')
        return;
      }
      let params = {custom:this.grid.custom}
      this.$modal.show('addFavoritePop', params)
    },
    changeFavoriteNm(){
      if (this.grid.custom.seq == 0){
        this.$alert.alert('선택한 관심종목이 없습니다.', '알림')
        return;
      }
      let params = {custom:this.grid.custom, copyYn:'N', chgNmYn:'Y'}
      this.$modal.show('favoriteCreatePop', params)
    },
    createNewFavoriteList() {
      if (this.layoutSeq == 0){
        this.$alert.alert('선택한 화면이 없습니다.', '알림');
        return;
      }
      let params = {custom:this.grid.custom, copyYn:'N', chgNmYn:'N'}
      this.$modal.show('favoriteCreatePop', params)
    },
    createCopyFavoriteList(){
      if (this.grid.custom.seq == 0){
        this.$alert.alert('선택한 관심종목이 없습니다.', '알림')
        return;
      }
      let params = {custom:this.grid.custom, copyYn:'Y', chgNmYn:'N'}
      this.$modal.show('favoriteCreatePop', params)
    },
    loadFavoriteList() {
      if (this.layoutSeq == 0){
        this.$alert.alert('선택한 화면이 없습니다.', '알림');
        return;
      }
      let params = {custom:this.grid.custom}
      this.$modal.show('favoriteLoadPop', params)
      //bus.$emit('closeList');
    },
    async loadFavorite() {
      if (this.grid.custom.seq > 0){
        const response = await window.$http.get('api/symbol/favorite/dtlList', { params: {seq: this.grid.custom.seq} })

        if ( response.data && response.data.length > 0 ) {
          
          this.grid.custom.socketSymbol = []
          response.data.forEach(data => {
            this.grid.custom.socketSymbol.push(data.symNo)
          })
          
          
          this.grid.rowData = response.data
          this.symbolData()
          
        }else{
          //this.grid.custom.seq = 0;
          this.grid.rowData = [];
        }
      }else{
        //this.grid.custom.seq = 0;
        this.grid.rowData = [];
      }
    },
    setSymbol(symbol){
      EventBus.$emit('Favorite:event:setSymbol', symbol.data.symCd)
    },
  },
};
</script>

<style lang="scss" scoped>
.favorite-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  overflow-y: hidden;
  &.hideTable {
    gap: 10px;
    .favorite-table {
      display: none;
    }
  }
}

.panel {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  /* padding-bottom: 20px; */
}
.icon {
  margin-left: 5px;
  transition: transform 0.3s;
  &.active {
    transform: rotate(180deg);
  }
}
.favorite-table {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  border: 1px solid var(--border-color-dom);
}

@import '@/assets/scss/agGridStyles.scss';

.ag-theme-delkoit {
  &::v-deep {
    .ag-cell {
      display: flex;
      align-items: center;
      /* justify-content: center; */
    }
  }
}
</style>
