<template>
  <div>
    <p>처리 중입니다...</p>
  </div>
</template>

<script>

export default {
  name: 'LoginGooglePage',

  data() {
    return {
      form: {
        code: '',
        redirectUri: '',
        scope: '',
        authuser: '',
        prompt: '',
      }  
    }
  }, 

  created() {
    this.form.code        = this.$route.query.code
    this.form.redirectUri = window.location.protocol +'//'+ window.location.host + window.location.pathname
    this.form.scope       = this.$route.query.scope
    this.form.authuser    = this.$route.query.authuser
    this.form.prompt      = this.$route.query.prompt

    window.opener.postMessage({type: 'LOGIN_GOOGLE', payload: this.form}, process.env.VUE_APP_WEB_BASE_URL)
    
    window.close()
  },  

}
</script>