<template>
  <div class="order-standard" :class="{sell:sell, buy:buy}">
    <div class="row">
      <h3 class="label">종목명</h3>
      <h3 class="value">{{ symbol.description }} ({{ symbol.exchange }})</h3>
    </div> 
    <div class="row">
      <h3 class="label">유형</h3>
      <div class="order-type">
        <BtnRadio :id="id[0]" :name="name" text="지정가" @click="ordTpCd='1'" :checked="true" />
        <BtnRadio :id="id[1]" :name="name" text="시장가" @click="ordTpCd='2'"  />
      </div>
    </div>

    <div class="row">
      <h3 class="label">수량</h3>
      <!-- <CounterComp full /> -->
      <CounterComp full :maxCnt="999" :maxLength=3 @childEvent="setClickQty" v-bind:value="ordQty" />
    </div>

		<div class="row">
      <h3 class="label">가격</h3>
      <OrderInput :symbol="symbol" :nowPrice="nowPrice" @updateOrdPrc="updateOrdPrc" />
    </div>

    <div class="row end">
			<!-- <div></div> -->
      <BtnLine width="70" blue text="매도" class="order-btn" v-if="sell" @click="sellStock" />
			<BtnLine width="70" red text="매수" class="order-btn" v-if="buy" @click="buyStock" />
    </div>
  </div>
</template>

<script>
import BtnRadio from '@/components/BtnRadio.vue';
import BtnLine from '@/components/BtnLine.vue';
import CounterComp from './CounterComp.vue';
import OrderInput from './OrderInput.vue';

export default {
  name: 'OrderStandard',
  components: {
    BtnRadio,
    BtnLine,
		CounterComp,
		OrderInput,
  },
	props: {
		sell: Boolean,
		buy: Boolean,
    name: String,
    id: Array,
    symbol: Object,
    nowPrice: Number,
	},
  data() {
    return {
      ordQty: 1, //수량
      ordPrice: 0, //주문가격
      ordSdCd: '2', //주문방향
      ordTpCd: '1', //지정가, 시장가 구분
    }
  },

  mounted() {},

  methods: {
    setClickQty(newVal){      
      this.ordQty = Number(newVal);
    },
    sellStock(){
      let data = {ordSdCd:'2', ordQty:this.ordQty, ordPrc:this.ordPrice, ordTpCd: this.ordTpCd};
      this.$emit("click", data);
    },
    buyStock(){
      let data = {ordSdCd:'1', ordQty:this.ordQty, ordPrc:this.ordPrice, ordTpCd: this.ordTpCd};
      this.$emit("click", data);
    },
    updateOrdPrc(newPrice){
      this.ordPrice = newPrice;
    }
  },
};
</script>

<style lang="scss" scoped>
.order-standard {
	display: flex;
	flex-direction: column;
	//gap: 10px;
  gap: 3px;
	padding: 8px 20px 10px 20px;
	border: 1px solid rgba(255,255,255,0.1);
	flex: 1;
	&.buy {
		background-color: rgba(255,67,82, 0.2);
	}
	&.sell {
		background-color: rgba(69, 157, 255, 0.2);
	}
  .row {
    height: 30px;
    gap: 44px;
		width: 100%;
		&.end {
			justify-content: flex-end;
		}
		.label {
			flex: 0 40px;
			opacity: 0.6;
		}
  }
  .order-type {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .order-btn {
    align-self: flex-end;
  }
}
</style>
