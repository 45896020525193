<template>
  <div class="notice-page">
    <div class="panel-header">
      <div class="row">
        <div class="row-item">
          <BaseBtn naked @click="goBack">
            <IconChevronDown left />
            <!-- <img src="@/assets/icons/chevron-left.svg" alt="" /> -->
          </BaseBtn>
          <p class="breadcrumb">
            <span v-if="brdCd == 'FAQ'">
              FAQ
              <template v-if="postDivNm != ''">/</template>
            </span>
            <span v-else-if="brdCd == 'HELP'">
              헬프센터
              <template v-if="postDivNm != ''">/</template>
            </span>
            <span v-else>
              공지사항 
              <template v-if="postDivNm != ''">/</template>
            </span>

            <template v-if="postDivNm != ''">
              {{ postDivNm }}
            </template>

            <template v-if="keyword != ''">
              ( {{ keyword }} )
            </template>            
          </p>
        </div>
      </div>
    </div>
    <div class="page-body">
      <h1 class="title bold">{{ post.postSj }}</h1>
      <h5 class="date">{{ post.regDt }}</h5>
      <div class="content" v-html="post.cont" />
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'

export default {
  name: 'BoardPost',
  components: { BaseBtn, IconChevronDown },  
  data() {
    return {
      brdCd : 'NOTICE',
      postNo : 0,
      postDivNm : '',
      keyword : '',
      post : {}
    }
  },
  mounted() {
    this.brdCd     = this.$route.query.brdCd
    this.postNo    = this.$route.query.postNo
    this.postDivNm = (this.$route.query.postDivNm == undefined) ? '' : this.$route.query.postDivNm
    this.keyword   = (this.$route.query.keyword == undefined) ? '' : this.$route.query.keyword

    this.select(this.postNo)
  },
  methods: {
    async select(postNo) {

      // 조회 조건
      const params = {
        brdCd : this.brdCd,
        postNo : postNo
      }

      const response = await this.$lhttp.get('/api/board/post', { params: params })

      if ( response && response.data ) {
        this.post = response.data
      }         
    },    
    goBack() {
      if ( window.history.length > 2 ) {
        this.$router.go(-1) 
      } else {
        if ( this.brdCd == 'FAQ' ) {
          this.$router.push('/main/help')
        } else if ( this.brdCd == 'HELP' ) {
          this.$router.push('/main/help/sub')
        } else {
          this.$router.push('/main/notice')
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.notice-page {
  padding: 10px 20px;
}

.panel-header {
  border-bottom: 1px solid var(--border-color-dom);
  height: 40px;
  margin-bottom: 10px;
}
.title {
  line-height: 1.4em;
}
.date {
  opacity: 0.6;
  margin-top: 12px;
}
img {
  margin: 20px 0;
}
.context {
  line-height: 1.4em;
  opacity: 0.8;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.page-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 130px);
  @media (max-width: $mobile) {
    height: unset;
    overflow: hidden;
  }
}

.breadcrumb {
  font-weight: 600;
	span {
		opacity: 0.8;
    font-weight: 400;
	}
}

.content {
  margin-bottom: 20px;
  line-height: 1.4em;
  opacity: 0.8;
  overflow: hidden;
}

::v-deep .content img {
  max-width: 100%;
  object-fit:cover;
}
</style>
