import { render, staticRenderFns } from "./PaginationComp.vue?vue&type=template&id=84509694&scoped=true"
import script from "./PaginationComp.vue?vue&type=script&lang=js"
export * from "./PaginationComp.vue?vue&type=script&lang=js"
import style0 from "./PaginationComp.vue?vue&type=style&index=0&id=84509694&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84509694",
  null
  
)

export default component.exports