<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20">
    <path
      d="M121.833-776.441v-.967l1.414-1.414v2.381a.672.672 0 0 1-.207.5.7.7 0 0 1-.506.2.675.675 0 0 1-.5-.2.683.683 0 0 1-.201-.5zm3.646 0v-4.54l1.414-1.414v5.953a.672.672 0 0 1-.207.5.7.7 0 0 1-.506.2.675.675 0 0 1-.5-.2.683.683 0 0 1-.2-.499zm3.647 0v-5.953l1.414 1.436v4.517a.672.672 0 0 1-.207.5.7.7 0 0 1-.506.2.675.675 0 0 1-.5-.2.683.683 0 0 1-.201-.5zm3.647 0v-4.517l1.414-1.414v5.931a.672.672 0 0 1-.207.5.7.7 0 0 1-.506.2.675.675 0 0 1-.5-.2.683.683 0 0 1-.201-.5zm3.646 0v-8.112l1.414-1.414v9.526a.672.672 0 0 1-.207.5.7.7 0 0 1-.506.2.675.675 0 0 1-.5-.2.683.683 0 0 1-.201-.5zm-14.378-6.608 5.012-4.99a1.356 1.356 0 0 1 .989-.4 1.36 1.36 0 0 1 .99.4l2.582 2.582 5.012-5a.668.668 0 0 1 .5-.208.687.687 0 0 1 .495.216.722.722 0 0 1 .2.495.646.646 0 0 1-.2.487l-5.02 5.012a1.307 1.307 0 0 1-1 .413 1.382 1.382 0 0 1-1-.413l-2.567-2.568-4.99 4.982a.677.677 0 0 1-.512.2.723.723 0 0 1-.5-.223.722.722 0 0 1-.2-.495.636.636 0 0 1 .209-.49z"
      transform="translate(-119.833 793.67)"
      :style="{ fill: fill }"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTrade',
  props: {
    width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
}
</style>
