<template>
  <div class="landing-body" ref="landingBody">
    <LandingHeader :isLoggedIn="false" />
    <div class="landing-hero">
      <div class="landing-inner">
        <div class="landing-title">
          <span>T</span>rading
          <br />
          <span>A</span>s
          <span>P</span>lan
        </div>
        <div class="landing-subtitle">
          당신이 계획한 최고의
          <br />
          투자 타이밍을 놓치지 마세요.
        </div>
        <div class="chrome">TAP은 크롬 브라우저 및 1980x1080 해상도에 최적화되어 있습니다.</div>
      </div>
    </div>

    <div class="landing-page-container">
      <div class="landing-page-section">
        <h2 class="bold center">
          Any Time
          <span>|</span>
          Any Where
          <span>|</span>
          Any Device
        </h2>
        <h4 class="center subpara">당신의 투자 타이밍을 지켜주는 최적의 투자솔루션</h4>
        <div class="landing-round-btn-container">
          <router-link to="/landing2" class="landing-round-btn">주요특징</router-link>
        </div>
        <img src="@/assets/images/product-sample.png" alt="" class="product-image variant" />
      </div>

      <div class="landing-page-section">
        <div class="landing-page-grid">
          <div class="ranking-table">
            <div class="ranking-table-header">
              <div class="ranking-table-title">
                <h3 class="bold">Ranking</h3>
                <IconChevronDown right :width="30" :height="30" class="chevron" />
              </div>
              <DateSort />
            </div>
            <div class="ranking-table-body">
              <table class="rank-table">
                <thead>
                  <tr>
                    <th>유저네임</th>
                    <th>수익율</th>
                    <th>거래횟수</th>
                    <th>승률</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><AvatarComp class="avatar" /></td>
                    <td>+124.58%</td>
                    <td>10</td>
                    <td>45.25%</td>
                  </tr>
                  <tr>
                    <td><AvatarComp class="avatar" /></td>
                    <td>+124.58%</td>
                    <td>10</td>
                    <td>45.25%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="notice-table">
            <div class="notice-table-header">
              <div class="notice-table-title">
                <h3 class="bold">Notice</h3>
                <IconChevronDown right :width="30" :height="30" class="chevron" />
              </div>
            </div>
            <div class="notice-table-body">
              <ul class="notice-list">
                <li>
                  <div class="notice-title">3%TV 배 모의투자 신청</div>
                  <div class="notice-time">14:24</div>
                </li>
                <li>
                  <div class="notice-title">3%TV 배 모의투자 신청</div>
                  <div class="notice-time">14:24</div>
                </li>
                <li>
                  <div class="notice-title">3%TV 배 모의투자 신청</div>
                  <div class="notice-time">14:24</div>
                </li>
              </ul>
            </div>
          </div>

          <div class="market-selection">
            <div class="market-selection-header">
              <div class="market-selection-title">
                <h3 class="bold">Market</h3>
                <IconChevronDown right :width="30" :height="30" class="chevron" />
              </div>
            </div>
            <div class="market-selection-body">
              <ul class="market-selection-list">
                <li class="active">
                  <div class="market-name">Nasdaq</div>
                  <div class="market-info">
                    <div class="price">17159.50</div>
                    <div class="percentage">+0.24%</div>
                  </div>
                </li>
                <li>
                  <div class="market-name">S&P500</div>
                  <div class="market-info">
                    <div class="price">17159.50</div>
                    <div class="percentage">+0.24%</div>
                  </div>
                </li>
                <li>
                  <div class="market-name">Crude Oil</div>
                  <div class="market-info">
                    <div class="price">17159.50</div>
                    <div class="percentage">+0.24%</div>
                  </div>
                </li>
                <li>
                  <div class="market-name">Gold</div>
                  <div class="market-info">
                    <div class="price">17159.50</div>
                    <div class="percentage">+0.24%</div>
                  </div>
                </li>
              </ul>
              <div class="market-chart-container">
                <img src="@/assets/images/landing-chart-sample.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="landing-page-section">
        <h2 class="bold center">당신이 TAP을 써야 하는 이유</h2>
        <ul class="tap-feature">
          <li>
            <img src="@/assets/icons/landing-thunder.svg" alt="" />
            <h3 class="bold">Fast</h3>
            <h4>국내 최초 Web Click 주문</h4>
          </li>
          <li>
            <img src="@/assets/icons/landing-globe.svg" alt="" />
            <h3 class="bold">No Install</h3>
            <h4>설치 없이 Web Browser 환경 어디서나</h4>
          </li>
          <li>
            <img src="@/assets/icons/landing-trend.svg" alt="" />
            <h3 class="bold">AI Signal</h3>
            <h4>AI 매매타이밍 신호 제공</h4>
          </li>
        </ul>
      </div>
    </div>
    <LandingFooter />
  </div>
</template>

<script>
import BtnSlide from '@/components/BtnSlide.vue';
import FeatureBar from '@/components/FeatureBar.vue';
import LandingHeader from '@/components/LandingHeader.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import DateSort from '@/components/DateSort.vue';
import AvatarComp from '@/components/AvatarComp.vue';
import LandingFooter from '@/components/LandingFooter.vue';

export default {
  name: 'ProfilePage',
  components: {
    BtnSlide,
    FeatureBar,
    LandingHeader,
    IconChevronDown,
    DateSort,
    AvatarComp,
    LandingFooter,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
