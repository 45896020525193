<template>
  <button @click="handleClick" :disabled="disabled" :class="{naked:naked, disabledBtn:disable}">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseBtn',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
		naked: Boolean
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    handleClick(e) {
      this.$emit('click',e);
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  min-width: 30px;
  height: 30px;
	background-color: transparent;
	border: none;
	gap: 5px;
	color: white;
	cursor: pointer;
  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
  &.disabledBtn {
    opacity: 0.4;
    cursor: default;
  }
  &.naked {
		padding: 0;
		height: unset;
    width: unset;
    min-width: unset;
	}
  @media (max-width: $mobile) {
    height: 40px;
  }
}
</style>
