<template>
  <div class="help-page">
    <div class="panel-header">
      <div class="row">
        <div class="row-item">
          <h3 class="bold">헬프 센터</h3>
        </div>
      </div>
    </div>
    <div class="search-ui">
      <InputCompSearch v-model="form.keyword"  @event:enter="search" />
      <BtnPrimary text="조회" icon="search" :width="70" @click="search" />
    </div>
		<div class="help-category-container">
      <h3 class="subtitle">신호 카테고리</h3>
      <ul class="help-category-list">

        <li v-for="help in helpCategoryList" v-bind:key="help.postDivCd"> 
          <router-link :to="{path: '/main/help/sub', query: {postDivCd: help.postDivCd, postDivNm: help.postDivNm, keyword: form.keyword}}">
            <CategoryItem 
							:title="help.postDivNm"
							:amount="help.postCnt +' 문서'"
							:icon="getHelpIcon(help.postDivCd)"
						>
            </CategoryItem>
          </router-link>
        </li>

      </ul>
    </div>
		<h3 class="subtitle">FAQ</h3>
		<div class="faq-container">

      <template v-for="faq in faqList">
        <router-link v-bind:key="faq.postNo" :to="{path: '/main/board/post', query: {brdCd: 'FAQ', postNo: faq.postNo, keyword: form.keyword}}">
          <FaqItem :faq="faq.postSj" />
        </router-link>
      </template>

      <PaginationComp :paging="paging" @onPage="searchFaq" />

		</div>
  </div>
</template>

<script>
import InputCompSearch from '@/components/InputCompSearch.vue'
import CategoryItem from '@/components/CategoryItem.vue'
import FaqItem from '@/components/FaqItem.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import PaginationComp from '@/components/grid/PaginationComp.vue'

export default {
  name: 'HelpPage',
  components: {
    InputCompSearch,
    CategoryItem,
    FaqItem,
    BtnPrimary,
    PaginationComp
},

  data() {
    return {
      form: {
        keyword: ''
      },
      paging : {
        page: 1,
        size: 5,
        total: 0,
        pageSize: 5,        
      },     
      helpCategoryList: [],
      faqList: [] 
    }
  },

  mounted() {
    this.search()
  },

  methods: {
    search() {
      this.searchHelpCategory()
      this.searchFaq()
    },
    async searchHelpCategory() {
      // 조회 조건
      const params = {
        brdCd : 'HELP',
        keyword : this.form.keyword
      }

      const response = await this.$lhttp.get('/api/board/cate/list', { params: params })

      if ( response && response.data ) {
        this.helpCategoryList = response.data
      }               
    },
    async searchFaq(page) {
      if (page) {
        this.paging.page = page
      }

      // 조회 조건
      const params = {
        brdCd : 'FAQ',
        page : this.paging.page,
        size : this.paging.size,
        keyword : this.form.keyword
      }

      const response = await this.$lhttp.get('/api/board/list/page', { params: params })

      if ( response && response.data ) {
        this.paging.total = response.data.total
        this.faqList = response.data.content
      }         
    },
    getHelpIcon(postDivCd) {
      if ( postDivCd === 'HELP02' ) {
        return 'browser'
      } else if ( postDivCd === 'HELP03' ) {        
        return 'smart-toy'
      } else if ( postDivCd === 'HELP04' ) {        
        return 'web-traffic'
      } else if ( postDivCd === 'HELP05' ) {        
        return 'settings'
      } else if ( postDivCd === 'HELP06' ) {        
        return 'person-edit'                        
      } else {
        return 'receipt'
      }
    }     
  },
};
</script>

<style lang="scss" scoped>
.help-page {
  padding: 10px 20px;
  @media (max-width: $mobile) {
    padding: 0 20px;
  }
}
.panel-header {
  margin-bottom: 10px;
  @media (max-width: $mobile) {
    display: none;
  }
}
.help-category-container {
	margin-top: 22px;
  margin-bottom: 20px;
}
.help-category-list {
	display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 9px;
}
.subtitle {
  opacity: 0.6;
}
.faq-container {
  border-top: 1px solid rgba(255,255,255,0.1);
  margin-top: 7px;
  @media (max-width: $mobile) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.search-ui {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>

