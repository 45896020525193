<template>
  <div class="report-modal-container">
    <BtnRadio v-for="code in codeList" v-bind:key="'radio_' + code.cd" :id="'radio_' + grpCd +'_'+ code.cd" :name="'radio_' + grpCd" :text="code.cdNm" :value="code.cd" @click="onClick" />
  </div>          
</template>

<script>
import BtnRadio from '@/components/BtnRadio.vue'

export default {
  name: 'codeRadio',
  components: {
    BtnRadio,
  },  
  props: {
    grpCd: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    }
  },  
  data () { 
    return {
      codeList: []
    }
  },    
  mounted () {
    let params = {
      grpCd : this.grpCd
    }

    this.$http
      .get('/common/com-code/list', {params : params})
      .then((result) => {
        this.codeList = result.data
      })
  },
  methods: {
    onClick(value) {
      this.$emit('input', value)
    }
  }  
}
</script>