<template>
  <div class="panel" :class="{ hideOrders: hideOrders }">
    <div class="overlay">
      <div class="overlay-title">
        당신이 꿈꿔 왔던
        <br />
        최고의 웹 클릭주문을
        <br />
        <span>지금 바로 경험</span>
        하세요.
      </div>
      <router-link to="/signup" class="signup-link"><h1>무료회원가입</h1></router-link>
      <router-link to="/login" class="signup-link"><h1>로그인</h1></router-link>
    </div>

    <div class="row between">
      <div class="row-item">
        <TabNav :tabs="tabList" :selected="selectedTab" @selectedTab="setSelectedTab" :width="280"></TabNav>

      </div>
      <div class="row-item">
        <div class="panel-menu-container">
        <BaseBtn naked @click="orderConfig">
          <IconEllipsis />
        </BaseBtn>
          <div class="tool-tip"><h3>주문환경설정</h3></div>
        </div>
      </div>
    </div>

    <div class="table-container">
      <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-delkoit"
          alwaysShowHorizontalScroll="false"
          :defaultColDef="grid.defaultColDef"
          :columnDefs="grid.columnDefs"
          :overlayNoRowsTemplate="grid.overlayNoRowsTemplate"
          :headerHeight="30"
          :rowHeight="30"
          :enableBrowserTooltips="true"
          :rowData="grid.rowData"
          @grid-ready="onGridReady"
          >
        </ag-grid-vue>
    </div>
    <div class="row between">
      <div class="row-item">
        <div class="row-item-content">
          <DropDown
            id="symbolDropdown"
            :options="symbolList"
            :defaultOption="defaultOption"
            width="170"
            height="24"
            @symbolDropdown="symbolDropdown"
          />
          <BtnIconLine white width="24" height="24" @click="searchSymbol" />
        </div>
      </div>
      <div class="row-item">
        <BtnLine width="70" height="24" white text="중앙정렬" @click="domGridClickCenterAlign" />
      </div>
      <div class="row-item">
        <CheckboxBtn text="손절" v-bind:value="slStockVal2" @childEvent="sellSlStock" />
        <CounterComp width="46" height="24" :disabled="slStockVal2" :maxCnt="200" :maxLength=3 @childEvent="setSellSlTick" :value="sellSlTick" />
        <CheckboxBtn text="익절" v-bind:value="slStockVal1" @childEvent="buySlStock" />
        <CounterComp width="46" height="24" :disabled="slStockVal1" :maxCnt="200" :maxLength=3 @childEvent="setBuySlTick" :value="buySlTick" />
      </div>
    </div>
    <DomGridClick v-if="selectedTab === 'clickOrder'" 
        :symbol="symbol"
        :gridrow="gridrow"
        :rStock="rStock"

        :setSlStockVal="setSlStockVal"

        :slStockVal1="slStockVal1"
        :slStockVal2="slStockVal2"
        :sellSlTick="sellSlTick"
        :buySlTick="buySlTick"
        :selectOrderData="selectOrderData"
        :sendOrderData="sendOrderData"
        :sellAbleQty="sellAbleQty"
        :buyAbleQty="buyAbleQty"
        :clickGridCenter="clickGridCenter"
        :selectLoadCustom="selectLoadCustom"
        :account="account"
        :avgPrice="avgPrice"
        @completeOrder="completeOrder"
        @updateSlStock="updateSlStock"
        @PanelComp5SelectOrder="PanelComp5SelectOrder"
    />
    <DomGridStandard v-if="selectedTab === 'standardOrder'"
        :symbol="symbol"
        :rStock="rStock"

        :setSlStockVal="setSlStockVal"
        
        :slStockVal1="slStockVal1"
        :slStockVal2="slStockVal2"
        :sellSlTick="sellSlTick"
        :buySlTick="buySlTick"
        :sellAbleQty="sellAbleQty"
        :buyAbleQty="buyAbleQty"
        :sendOrderData="sendOrderData"
        :account="account"
        @completeOrder="completeOrder"
        @updateSlStock="updateSlStock"
    />

    <searchSymbolPop v-on:change-symbol-complete="changeSymbol"  />
    <orderSettingsPop v-on:order-setting-complete="completeOrderSetting" />
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
//import BtnCheckbox from '@/components/BtnCheckbox.vue'
import CheckboxBtn from '@/components/CheckboxBtn.vue'
import CounterComp from '@/components/CounterComp.vue'
import BtnIconLine from '@/components/BtnIconLine.vue'
import IconEllipsis from '@/components/icons/IconEllipsis.vue'
import DropDown from '@/components/DropDown.vue'
import BtnLine from '@/components/BtnLine.vue';
import DomGridStandard from '@/components/DomGridStandard.vue'
import DomGridClick from '@/components/DomGridClick.vue'
//import TabComp from '@/components/TabComp.vue'
import TabNav from '@/components/TabNav.vue';

import { AgGridVue, } from "ag-grid-vue"; // Vue Grid Logic
import {buySellType, cellClassRulesMinus} from '@/modules/grid-utils.js'

import SearchSymbolPop from '@/pages/main/SearchSymbolPop.vue'
import OrderSettingsPop from '@/pages/main/OrderSettingsPop.vue'
//import { bus } from '../main';

import EventBus from '@/modules/event-bus'
import gridUtils from '@/modules/grid-utils'
import { stringCount } from '@/modules/helper'
import Utils from '@/modules/utils'


export default {
  name: 'PanelComp5',
  props: {
    symbolList : {
      type: Array
    },
    sendOrder: {
      type: Object,
    },
    currencyInfo: {
      type: Object
    },
    selectChgComp5 : {
      type: Number
    },
    account: {
      type: Object
    },
    chgSymbol: {
      type: Object
    }
  },
  watch: {
    symbolList: {
      handler(newSymbolList){
        if (newSymbolList != null) {
          if (newSymbolList.length > 0 ) {        
            this.symbolDropdown(newSymbolList[0]);
            this.defaultOption = newSymbolList[0].name; //drop down 디폴트 이름설정

            if ( this.$sessionStorage.get('xDkToken') !== null ) {
              this.search(newSymbolList[0].name); //포지션 목록 조회
            }
          }
        }
      }
    },
    sendOrder: {
      handler(newData){
        if (newData != null){
          this.sendOrderData = null;
          this.sendOrderData = newData;
        }
      }
    },
    account: {
      handler(newAcc){
        if (newAcc != null && newAcc.accId > 0){
          this.grid.rowData = [];
          this.slStockVal1 = false;
          this.slStockVal2 = false;
          this.sellSlTick = 1; //db저장된 디폴트 값을 셋팅
          this.buySlTick = 1; //db저장된 디폴트 값을 셋팅
          this.sellAbleQty = 0;
          this.buyAbleQty = 0;
          this.selectOrder(this.symbol.name);

          if (newAcc.accPass && newAcc.passYn != undefined && newAcc.passYn == "Y"){
            this.search(this.symbol.name);
            this.searchOrdAbleQty();
          }
        }
      },
      deep:true
    },
    selectChgComp5:{
      handler(){
        this.searchOrdAbleQty();  
      },
      deep: true,
    },
    currencyInfo: {
      handler(){
        if (this.grid.rowData.length > 0){
          this.setValProLoss(this.grid.rowData[0]); //평가손익 계산
          this.gridApi.refreshCells();
        }
      },
      deep: true,
    },
    chgSymbol: {
      handler(newVal){
        this.defaultOption = newVal.name; //drop down 디폴트 이름설정
        this.symbolDropdown(newVal);
      },
      deep:true
    },
    resultOms(res){
      if (res.result == "S"){
        this.completeOrder(res);
      }else{
        this.procFailMessage(res);
        //실패시 리스트목록 주문 목록 재조회
        if (res.body.code != "OMSFL007" && res.body.code != "OMSFL011" && res.body.code != "OMSFL002" && res.body.code != "OMSFL012"
          && res.body.code != "OMSFL014" && res.body.code != "OMSFL016" && res.body.code != "OMSFL017"){
          this.selectOrder(this.symbol.name);
        }
      }
    },
    getAbleQty:{
      handler(res){
        this.sellAbleQty = res.ordAbleSellQty;
        this.buyAbleQty = res.ordAbleBuyQty;
      },
      deep:true
    },
    //소켓시세
    changeOvcData(newPrice){
      if (newPrice.symbol == this.symbol.name) {
        //console.log("curPr ", newPrice)
        this.curPr = newPrice.curPr;
        this.setValProLoss(this.grid.rowData[0]); //평가손익 계산
        this.gridApi.refreshCells();
      }
    },
    //폴링시세
    changeOvcDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (data.symbol == this.symbol.name){
            this.curPr = data.curPr;
            this.setValProLoss(this.grid.rowData[0]); //평가손익 계산
            this.gridApi.refreshCells();
          }
        })
      }
    },
  },
	computed: {
    tikDotSz(){
      return stringCount(this.symbol.pricescale)
    },
    resultOms: function(){
      return window.$store.getters['SocketOms/getCompleteOrder']
    },
    getAbleQty: function(){
      return window.$store.getters['SocketOms/getAbleQty']
    },
    getUsrAccPass(){
      const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))
      if (accPassObj == undefined && !this.account.accPass){
        //저장된 계좌비번이 없으면
        return "";
      }else if (accPassObj != undefined && accPassObj[this.account.accNo] && !this.account.accPass){
        //저장된 계좌비번
        return accPassObj[this.account.accNo];
      }else if (this.account.accPass){
        //비밀번호를 직접입력
        return this.account.accPass;
      }else{
        return "";
      }
    },
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData']
      return ovcData
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList']
      return ovcData
    },
	},
  components: {
    BaseBtn,
    //BtnCheckbox,
    CheckboxBtn,
    CounterComp,
    BtnIconLine,
    IconEllipsis,
    DropDown,
    //TabComp,
    TabNav,
    BtnLine,
    // eslint-disable-next-line vue/no-unused-components
    DomGridStandard,
    // eslint-disable-next-line vue/no-unused-components
    DomGridClick,
    AgGridVue,
    SearchSymbolPop,
    OrderSettingsPop
  },
  data() {
    return {
      tabList: [{name:"클릭주문", id:"clickOrder"},{name:"일반주문", id:"standardOrder"}],
      hideOrders: '',
      orderClick: true,
      orderStandard: '',
      grid: {
        columnDefs: [
          { 
            headerName: "포지션", 
            field: "ordSdNm", 
            resizable: false,
            flex: 0.7,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass: "center-align-header",
            cellClassRules: buySellType,
          },
          { 
            headerName: "수량",
            field: "balQty",
            flex: 0.5,
            resizable: false,
            type: 'rightAligned',
            cellRenderer : gridUtils.numberComma
          },
          { 
            headerName: "평균체결가",
            field: "execPrc",
            resizable: false,
            flex: 1,
            type: 'rightAligned',
            valueFormatter: (d) =>{
              if (d.data.symNo == 2){ //항셍
                let val = d.value;
                if (!Number.isInteger(val)){
                  val = val.toFixed(1);
                }
                return val;
              }else{
                return d.value.toFixed(this.tikDotSz);
              }
            },
          },
          { 
            headerName: "평가손익",
            field: "valPl",
            resizable: false,
            flex: 1.1,
            type: 'rightAligned',
            cellClassRules: cellClassRulesMinus,
            cellRenderer : gridUtils.numberComma,
          },
          { 
            headerName: "청산손익",
            field: "ordPlMn",
            resizable: false,
            flex: 1.1,
            type: 'rightAligned',
            cellClassRules: cellClassRulesMinus,
            cellRenderer : gridUtils.numberComma,
          },
          { 
            headerName: "총손익",
            field: "plMn",
            resizable: false,
            flex: 1,
            type: 'rightAligned',
            cellClassRules: cellClassRulesMinus,
            cellRenderer : gridUtils.numberComma,
          },
          
        ],
        rowData: [],
        overlayNoRowsTemplate: '<span style="margin-top: 30px;">조회된 실시간 보유가 없습니다.</span>',
        autoSizeStrategy: null,
      },
      symbol: {},
      oldSymbol: {},
      defaultOption : '',
      gridrow : 14,
      slStockVal1:false,
      slStockVal2:false,
      sellSlTick:1,
      buySlTick:1,
      curPr:0,
      setSlStockVal:{}, //익절, 손절 체크 관련 정보

      // channelToPrice: new Map(),  
      // socketOnePrice : null,
      // isConnectPrice: false,
      selectedTab: "clickOrder",
      rStock : null,
      stockPrice : null,
      selectOrderData: null,
      sendOrderData: null, //상단 일괄 취소, 정정 목록
      sellAbleQty: 0,
      buyAbleQty: 0,
      clickGridCenter : 0,
      orderSetting: null,
      selectLoadCustom: 0,
      socketOneInterval: null,
      connectOneTimeout: null,
      avgPrice: null,
    }
  },

  mounted() {
    this.loadCustom();

    // const chartPanel = document.querySelector('.panel5');

    // //클릭 그리드 사이즈 자동인식
    // const observer = new ResizeObserver(entries => {
    //   for (let entry of entries) {
    //     const {width, height} = entry.contentRect;
    //     if (Number(height) <= 640){
    //       //클릭 그리드 영역이 650이하면 14개 고정
    //       this.gridrow = 14;
    //     }else if (Number(height) > 640 && height < 700){
    //       //클릭 그리드 영역이 700이하면 16개 고정
    //       this.gridrow = 16;
    //     }else{
    //       //클릭 그리드 700이상일경우 계산으로 row개수를 구함
    //       const count = Math.round((Number(height) - 270 ) / 26);
    //       if ((count%2) != 0){
    //         this.gridrow = count-1;
    //       }else{
    //         this.gridrow = count;
    //       }
          
    //     }
    //   }
    // });

    // //자동으로 인식할 부분 추가
    // observer.observe(chartPanel);
  },
  created() {
/*
    bus.$on('domChange', () => {
      // eslint-disable-next-line vue/no-mutating-props
      this.orderClick = !this.orderClick;
      // eslint-disable-next-line vue/no-mutating-props
      this.orderStandard = !this.orderStandard;
    });
*/
    //this.connectPrice();

    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom()
    })
  },
  destroyed() {
    //이벤트 해제
    //EventBus.$off();
    this.disconnect();
  },
  methods: {
    setGridCustomVisible() {
      const grid  = this.grid
      const gridColumnApi = this.gridColumnApi

      Object.keys(grid.custom).forEach(function(key){
        gridColumnApi.setColumnVisible(key, grid.custom[key])
      })
    },
    loadCustom() {
      this.orderSetting = window.$store.getters['Layout/getOrderSetting']
      //this.orderSetting = Object.assign({}, window.$store.getters['Layout/getOrderSetting']);

      if (this.orderSetting != null){
        this.selectLoadCustom = this.selectLoadCustom +1;

        //손절
        if (this.slStockVal2 == false){
          this.setSellSlTick(this.orderSetting.slTikVal1.tikVal)
        }

        //익절
        if (this.slStockVal1 == false){
          this.setBuySlTick(this.orderSetting.slTikVal2.tikVal);
        }
      }

    },    
    orderConfig() {
      //this.$modal.show('orderSettingsPop', Object.assign({}, this.orderSetting))
      this.$modal.show('orderSettingsPop', this.orderSetting)
    },
    completeOrderSetting(){
      this.loadCustom();
    },
    searchSymbol() {
      this.$modal.show('searchSymbolPop', this.symbolList)
    },
    setSelectedTab(tab) {
      if (this.selectedTab != tab.id){
        this.selectedTab = tab.id;
        this.setStockInfo(this.symbol, null);
        if (tab.id == "clickOrder"){ //tab 이동시 주문정보 다시 로딩
          this.selectOrder(this.symbol.name);
          this.search(this.symbol.name);
        }else{
          this.selectOrderData = null;
        }
      }
    },
    updateSlStock(data){
      if (data.chkType == "2"){ //손절
        this.slStockVal2 = data.chkVal;
      }else if (data.chkType == "1"){ //익절
        this.slStockVal1 = data.chkVal;
      }      
    },
    completeOrder(res){
      if (res != undefined && res.notify != undefined){
        this.notifyAlert(res.notify)
      }
      const apiCode = res.apiCode;
      const body = res.body;

      if ( apiCode == 'OMS0007') {
        if ( body.chkVal == '1' && body.accId == this.account.accId) {
            //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
            this.updateSlStock({chkType:'1', chkVal:!this.slStockVal1});
        } else if ( body.chkVal == '2' && body.accId == this.account.accId) {
            //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
            this.updateSlStock({chkType:'2', chkVal:!this.slStockVal2});
        }
      // STOP LOSS 취소 
      } else if ( apiCode == 'OMS0008') {
        if ( body.chkVal == '1' && body.accId == this.account.accId) {
          if ( body.execYn == 'Y' ) {
            this.updateSlStock({chkType:'1', chkVal:!this.slStockVal1});
          }
        } else if ( body.chkVal == '2' && body.accId == this.account.accId) {
          if ( body.execYn == 'Y' ) {
            this.updateSlStock({chkType:'2', chkVal:!this.slStockVal2});
          }
        }
       // 전/현 종목 청산
      } else if ( apiCode == 'OMS0013') {
        if (body.delList != undefined){
          body.delList.forEach(del => {
              //같은 종목만 삭제 처리
              if (del.symCd == this.symbol.name){
                if ( del.chkVal == '1' ) {
                  if ( del.execYn == 'Y' ) {
                    this.updateSlStock({chkType:'1', chkVal:false});
                  }
                } else if ( del.chkVal == '2' ) {
                  if ( del.execYn == 'Y' ) {        
                    this.updateSlStock({chkType:'2', chkVal:false});
                  }
                }
              }           
            });
        }
      //RMS 청산
      }else if (apiCode == 'OMS0017'){
        this.updateSlStock({chkType:'1', chkVal:false});
        this.updateSlStock({chkType:'2', chkVal:false});
      //배치 청산
      } else if ( apiCode == 'OMS9995'){ 
        if (body.execYn == "Y"){
          this.updateSlStock({chkType:'1', chkVal:false});
          this.updateSlStock({chkType:'2', chkVal:false});
        }
      }
      

      //포지션 조회
      if (this.getUsrAccPass){
        this.search(this.symbol.name);
        this.searchOrdAbleQty();
        this.$emit("PanelComp4Select", this.symbol.name);
      }
    },
    procFailMessage(res) {
      let body = res.body;

      //기존 알림창 닫기
      this.$notify({ group: 'notification', clean: true })

      this.$notify({
        group: 'notification',
        title: 'FAIL',
        text: body.message
      })
    },
    //주문가능수량 조회
    searchOrdAbleQty() {
      window.$store.dispatch('SocketOms/selectAbleQty', {accId:this.account.accId, symcd:this.symbol.name});
    },
    tikVal(symbol){
      return (symbol.minmov / symbol.pricescale);
    },


    /* 소켓 데이터 관련 이동 */
    async setStockInfo(newSymbol, oldSymbol) {
      if ( newSymbol != null ) {

        const rStock = await window.$http.get('chart/datafeed/stockdata/symbol', { params: { symbol: newSymbol.name } } )
        if ( rStock != null ) {
          if (this.curPr == null || this.curPr == 0 && rStock.data && rStock.data.curPr){
            this.curPr = rStock.data.curPr;
            this.$emit("updateCurPr", this.curPr);
          }
          this.rStock = rStock;
        }   
        
        // 구독
        //this.subscribeOnPrice(this.symbol.name)
      }

      if ( oldSymbol != undefined && oldSymbol.name !== undefined ) {
        // 구독 취소
        //this.unSubscribeOnPrice(oldSymbol.name)
      }
    },
 
    // 시세 socket 연결
    // connectPrice() {
    //   const websocketPath = process.env.VUE_APP_QUOTE_SOCKET_BASE_URL

    //   if(this.socketOnePrice && this.socketOnePrice.readyState == WebSocket.OPEN){       
    //     this.socketOnePrice.close()
    //   }
      
    //   this.socketOnePrice = new WebSocket(websocketPath)

    //   this.socketOnePrice.onopen = () => {
    //     this.isConnectPrice = true

    //     this.socketOneInterval = setInterval(() => {
    //       this.sendPriceHeartBeat()
    //     }, 50000)
    //   }

    //   this.socketOnePrice.onmessage = (e) => {
    //     const returnData = JSON.parse(e.data)
    //     const data = returnData.body

    //     this.stockPrice = data;

    //     if ( data.apiType == 'OVC' ) {         
          
    //       this.curPr = data.curPr;

    //       if (this.grid.rowData.length > 0){
    //         //this.setValProLoss(this.grid.rowData[0].ordSdCd, this.grid.rowData[0].execPrc, this.grid.rowData[0].trdUnt, this.grid.rowData[0].balQty); //평가손익 계산
    //         this.setValProLoss(this.grid.rowData[0]); //평가손익 계산
    //         this.gridApi.refreshCells();
    //       }
    //       this.$emit("updateOvcData", this.stockPrice);
    //     }
    //   }

    //   this.socketOnePrice.onerror = (e) => {
    //     this.isConnectPrice = false
    //     console.error("[Error] socket Error")
    //   }

    //   this.socketOnePrice.onclose = (e) => {
    //     this.isConnectPrice = false
    //     console.error("[OnClose] socket close")
    //     this.connectOneTimeout = setTimeout(() => {
    //       this.connectPrice()
    //     }, 1000)
    //   }
    // },
    // sendPriceHeartBeat() {
    //   const reqData = {
    //     header: {
    //     apiCode : 'DSA0000',
    //     token : this.$sessionStorage.get('xDkToken'),
    //     },
    //     body : {
    //     }
    //   }
    //   this.socketOnePrice.send(JSON.stringify(reqData))
    // },
    // getOVCReqData(apiCode, symbol) {
    //   let apiTypeData = ['OVC'];
    //   let apiType = 'OVC';

    //   const reqData = {
    //     header: {
    //       apiCode : apiCode,
    //       token : this.$sessionStorage.get('xDkToken'),
    //     },
    //     body : {
    //       apiType : apiType,
    //       symbol : symbol,
    //       apiTypeData: apiTypeData,
    //       symbolData : [symbol]
          
    //     }
    //   }
    //   return reqData

    // },    
    // getOVHReqData(apiCode, symbol) {
    //   let apiTypeData = ['OVH'];
    //   let apiType = 'OVH';
      
    //   const reqData = {
    //     header: {
    //       apiCode : apiCode,
    //       token : this.$sessionStorage.get('xDkToken'),
    //     },
    //     body : {
    //       apiType : apiType,
    //       symbol : symbol,
    //       apiTypeData: apiTypeData,
    //       symbolData : [symbol]
          
    //     }
    //   }
    //   return reqData

    // },    
    // sendPriceMessage(apiCode, symbol) {  
    //   this.socketOnePrice.send(JSON.stringify(this.getOVHReqData(apiCode, symbol)))
    //   this.socketOnePrice.send(JSON.stringify(this.getOVCReqData(apiCode, symbol)))
    // },    
    // subscribeOnPrice(symbol) {
    //   // 소켓 구독
    //   const subscribeItem = {
    //     symbol,
    //     subscriptionUID: this.subscriptionUID,
    //     data: new Array()
    //   }

    //   this.channelToPrice.set(symbol, subscribeItem)
    //   if(this.isConnectPrice){
    //     this.sendPriceMessage('DSA0003', subscribeItem.symbol)
    //   }
      
    // },
    // unSubscribeOnPrice(symbol){
    //   // 소켓 구독 종료
    //   const data = this.channelToPrice.get(symbol)
    //   if(data != null){
    //     if(this.isConnectPrice){
    //       this.sendPriceMessage('DSA0004', symbol)
    //     }
    //     this.channelToPrice.delete(symbol)
    //   }
      
    // },
    disconnect() {
      // if (this.isConnectPrice) {
      //   this.socketOnePrice.onclose = function () {}
      //   this.socketOnePrice.close()
      //   this.isConnectPrice = false 
      // }
      //clearInterval(this.socketOneInterval)
      //clearTimeout(this.connectTimeout)
    },




    onGridReady(params) {
      this.gridApi = params.api
    },
    //그리드 평가손익 계산
    setValProLoss(data){
      // 실시간 시세 , 수량, 평균체결가 TRD_UNT, 종목테이블에 있음
      // trd_unt * 평균가 * 수량 = 값1 , trd_unt * 시세 * 수량 = 값2
      // 값1 - 값2 = 평가손익
      // if 매도 일때 (값1 - 값2) = -마이너스값이면 손해 > 그대로
      // if 매수 일때 (값1 - 값2) = -마이너스값이면 손익 > +로 표시
      //, , , balQty
      if (data == undefined) return;
      const ordSdCd = data.ordSdCd;
      const execPrc = data.execPrc;
      const trdUnt = data.trdUnt;
      const balQty = data.balQty;
      const trdUsd = data.trdUsd;


      let val1 = 0;
      let val2 = 0;
      let valProLoss = 0;

      if (this.curPr == 0 || ordSdCd == 0){
        
        valProLoss = 0;
      }else{

        if (ordSdCd == "1"){ //매도
          val1 = execPrc *  balQty;
          val2 = this.curPr * balQty;
        }else if (ordSdCd == "2"){ //매수
          val1 = this.curPr * balQty;
          val2 = execPrc * balQty;
        }

        //달러로 계산
        if (data.crcCd == "2"){ //홍콩달러
          valProLoss = ((val1 - val2) * trdUnt * this.currencyInfo.usdExHkd);
        }else{
          valProLoss = ((val1 - val2) / this.tikVal(this.symbol)) * trdUnt; //달러로 계산
        }
      }


      //원화로 표시
      if (this.currencyInfo.currency == "WON"){
        //달러 > 원화로 변경
        valProLoss = Math.ceil(valProLoss * this.currencyInfo.usdExR);

        this.grid.rowData[0].valPl = valProLoss
        this.grid.rowData[0].plMn = Math.ceil(this.grid.rowData[0].orgPlMn * this.currencyInfo.usdExR);
        this.grid.rowData[0].ordPlMn = Math.ceil(this.grid.rowData[0].orgOrdPlMn * this.currencyInfo.usdExR);
      }else{ //달러
        this.grid.rowData[0].valPl = (valProLoss).toFixed(2);
        this.grid.rowData[0].plMn = (this.grid.rowData[0].orgPlMn).toFixed(2);
        this.grid.rowData[0].ordPlMn = (this.grid.rowData[0].orgOrdPlMn).toFixed(2);
      }
    },
    sellSlStock() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.sellSlTick,
        chkVal : '2', // '1' 익절 '2' 손절
        accPass : this.getUsrAccPass
      }
      let apiCode = "OMS0007"
      if (this.slStockVal2){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008"
      }
      this.sendOmsMessage(apiCode, order)
    },
    buySlStock() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.buySlTick,
        chkVal : '1', // '1' 익절 '2' 손절 
        accPass : this.getUsrAccPass
      }

      let apiCode = "OMS0007"
      if (this.slStockVal1){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008"
      }
      this.sendOmsMessage(apiCode, order)
    },
    sendOmsMessage(apiCode, body) {
      if ( !window.$store.getters['SocketOms/getIsConnectOms'] ) {
        window.$alert.alert('OMS 서버 연결 종료.')
        return
      }

      //비밀번호 추가
      body.accPass = this.getUsrAccPass;

      window.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body})
    },
    //손절 틱설정값 저장
    setSellSlTick(tickVal){
      this.sellSlTick = Number(tickVal);
    },
    //익절 틱설정값 저장
    setBuySlTick(tickVal){
      this.buySlTick = Number(tickVal);
    },
    //종목셀렉트 선택
    symbolDropdown(value) {
      this.curPr = 0;
      let oldValue = Object.assign({}, this.symbol);
      this.symbol = value;
      this.oldSymbol = value;

      window.$store.dispatch('SocketPrice/setSelectSymbol', value)
      
      this.setStockInfo(value, oldValue);

      if (this.account.accId != undefined && this.account.accId >0 && this.account.passYn != undefined && this.account.passYn == "Y"){
        this.search(this.symbol.name);
        this.selectOrder(this.symbol.name);
        this.searchOrdAbleQty();
      }
    },
    //포지션 평가손익, 정산등 조회
    async search(symbol) {
      if (this.grid.rowData.length == 0){
        //this.gridApi.showLoadingOverlay()
      }

      const response = await this.$http.get('/api/order/rt-pos/list', { params: {accId: this.account.accId, symCd: symbol} })

      if ( response && response.data ) {
        this.grid.rowData = response.data
        if (this.grid.rowData.length > 0){
            //this.setValProLoss(this.grid.rowData[0].ordSdCd, this.grid.rowData[0].execPrc, this.grid.rowData[0].trdUnt, this.grid.rowData[0].balQty); //평가손익 계산
            this.setValProLoss(this.grid.rowData[0]); //평가손익 계산
            this.gridApi.refreshCells();

            this.avgPrice = {avgPrice: this.grid.rowData[0].execPrc, ordSdCd: this.grid.rowData[0].ordSdCd, balQty: this.grid.rowData[0].balQty}
        }

      }
    },
    async selectOrder(symbol) {
      const response = await window.$http.get('api/order/list', { params: { accId: this.account.accId, symCd: symbol } } )

      //STOP LOSS 초기화
      this.slStockVal1 = false;
      this.slStockVal2 = false;
      this.sellSlTick = 1; //db저장된 디폴트 값을 셋팅
      this.buySlTick = 1; //db저장된 디폴트 값을 셋팅


      if (response.data) {
        this.selectOrderData = {selectOrderList: response.data};

        for (let order of response.data) {
          if (order.ordType == "SL" && order.symCd == this.symbol.name){
            if (order.ordSdCd == "1"){ //CHK_VAL 값 //1:익절 , 2:손절
              //ordPrc -> TICK_VAL 설정값
              if (order.ordPrc != null && order.ordPrc > 0){
                if (order.ordTpCd == 0){ // ORD_STAT_CD = 0 이면 체크
                  this.slStockVal1 = true;
                  this.buySlTick = Number(order.ordPrc);
                }
              }
            }else if (order.ordSdCd == "2"){
              if (order.ordPrc != null && order.ordPrc > 0){
                if (order.ordTpCd == 0){ // ORD_STAT_CD = 0 이면 체크
                  this.slStockVal2 = true;
                  this.sellSlTick = Number(order.ordPrc);
                }
              }
            }
          }
        }

        //손절, 익절 포지션 설정값이 없으면 셋팅설정값으로 설정

        //손절
        if (this.slStockVal2 == false){
          this.setSellSlTick(this.orderSetting.slTikVal1.tikVal)
        }

        //익절
        if (this.slStockVal1 == false){
          this.setBuySlTick(this.orderSetting.slTikVal2.tikVal);
        }

        // for (let order of response.data) {
          // if ( order.ordSdCd == '1' ) {
          //   this.setSellData(order)
          // } else if ( order.ordSdCd == '2' ) {
          //   this.setBuyData(order)
          // }
        // }

        // this.resetOrderData(this.symbol.name)
      }else{
        this.selectOrderData = {selectOrderList: []};
      }
    },
    PanelComp5SelectOrder(){ //주문 목록 재조회
      this.selectOrder(this.symbol.name);
    },
    domGridClickCenterAlign(){
      this.clickGridCenter = this.clickGridCenter+1;
    },
    changeSymbol(val){
      EventBus.$emit('Favorite:event:setSymbol', val);
    },
    notifyAlert(msgInfo){ //주문 알림 메시지
      //console.log("msgInfo", msgInfo)
      
      if (msgInfo != null){
        if (this.orderSetting != null && this.orderSetting.messageAlert){
          
           // 사운드 실행
          if (msgInfo.apiCode == 'OMS0001'){ //주문완료
            if (msgInfo.body.ordSdCd == '1'){
              this.soundOn('/SELL_COMP.WAV')
            }else{
              this.soundOn('/BUY_COMP.WAV')
            }
          }else if (msgInfo.apiCode == 'OMS0002'){  //취소주문
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0003'){  //정정주문  
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0005'){  //ST취소완료
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0006'){  //ST정정완료
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0010'){ //종목선택취소 
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0011'){ //ST종목선택취소
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0012'){ //일괄취소
            this.soundOn('/CANCEL_COMP.WAV')
          }

          this.$notify({ group: msgInfo.group, clean: true })

          this.$notify(msgInfo)
        }        
      }
    },
    soundOn(file){
      if (this.orderSetting != null && this.orderSetting.soundAlert){
        new Audio("/wav"+file).play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  position: relative;
}
.radio-group {
  .row {
    height: 24px;
    gap: 6px;
  }
}
.table-container {
  width: 100%;
  height: 78px;
}

.landing {
  .overlay {
    display: flex;
  }
}

.overlay {
  display: none;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background-color: rgba(8,15,31,0.9);
  z-index: 3;
  .overlay-title {
    text-align: center;
    font-size: 36px;
    line-height: 1.4em;
    letter-spacing: -2px;
    font-weight: 300;
    span {
      font-weight: bold;
      color: var(--main-orange);
    }
  }
  .signup-link {
    border-radius: 50vh;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 13px 33px 15px;
    margin-top: 60px;
  }
}

@import '@/assets/scss/agGridStyles.scss';
</style>
