<template>
  <modal name="depositWithdrawPop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="입출금내역" modalName="depositWithdrawPop">
      <template #body>
        <div class="row">
          <div class="row-item">
            <h3>계좌번호</h3>
            <div class="row-item-content"> 
              <AccountDropDown id="accountDropdown" popup v-model="gridSearch.accId" @event:selectAccount="selectAccount" />
              <InputComp readonly width="120" v-model="account.accNo" />
            </div>
          </div>
          <div class="row-item">
            <h3>비밀번호</h3>
            <InputComp type="password" :width="65" v-model="account.accPass" />
          </div>
        </div>
        <div class="row">
          <div class="row-item">
            <h3>조회일자</h3>
            <div class="row-item-content">
              <DatePicker width="117" v-model="gridSearch.fromDate" />
              ~
              <DatePicker width="117" v-model="gridSearch.toDate" />
            </div>
          </div>
          <div class="row-item">
            <BtnPrimary text="조회" icon="search" :width="70" @click="search(1)" />
          </div>
        </div>
        <div class="table-container">
          <ag-grid-vue
            style="width: 100%; height: 100%"
            alwaysShowHorizontalScroll="true"
            class="ag-theme-delkoit"
            :columnDefs="grid.columnDefs"
            :rowData="grid.rowData"
            :headerHeight="30"
            :rowHeight="30"
          ></ag-grid-vue>
          <PaginationComp :paging="grid.paging" @onPage="search" />
        </div>        
      </template>
    </ModalContent>
  </modal>
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import AccountDropDown from '@/components/AccountDropDown.vue'
import InputComp from '@/components/InputComp.vue'
import BtnRadio from '@/components/BtnRadio.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import DatePicker from '@/components/DatePicker.vue'

import { AgGridVue } from "ag-grid-vue" // Vue Grid Logic

import gridUtils from '@/modules/grid-utils'
import Utils from '@/modules/utils'

import PaginationComp from '@/components/grid/PaginationComp.vue'

export default {
	name: 'ModalDepositWithdraw',
	components: {
    ModalContent,
    InputComp,
    BtnPrimary,
    BtnRadio,
		DatePicker,
    AccountDropDown,
    AgGridVue,
    PaginationComp,    
},
	data() {
		return {
      account: {
        accNo: '',
        accPass: ''
      },      
      gridSearch: {
        accId: 0,
        accPass: '',
        fromDate: Utils.getDatePrevMonth(1), 
        toDate: Utils.getToday(), 
      },              
			grid: {
        columnDefs: [
          { 
            headerName: "거래일자", 
            field: "trdDt", 
            minWidth: 89,
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "신청시간",
            field: "dpsWdReqTm",
            minWidth: 89,
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "구분",
            field: "dpsWdTpNm",
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "금액",
            field: "amount",
            minWidth: 89,
            flex: 1,
            type: 'rightAligned',
            headerClass: "center-align-header",
            cellRenderer : gridUtils.numberComma,         
            suppressMovable: true,
          },
          { 
            headerName: "처리시간",
            field: "dpsWdProcTm",
            minWidth: 89,
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "상태",
            field: "dpsWdProcNm",
            minWidth: 89,
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "비고",
            field: "cmt",
            minWidth: 110,
            flex: 1.3,
            cellStyle: {
              textAlign: 'left'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
        ],
        rowData: [],   
        paging: {
          page: 1,
          size: 20,
          total: 0,
          pageSize : 10
        }                 
      }
		};
	},

	mounted() {
		
	},

	methods: {
    beforeOpen(e) {  
      this.grid.rowData = []
      this.grid.paging.page = 1      
    },    
    opened() {
    },    		
    selectAccount(account) {
      this.account = account
    },     
    async search(page) {
      if (page) {
        this.grid.paging.page = page
      }

      // 조회 조건
      this.gridSearch.accId   = this.account.accId
      this.gridSearch.accPass = this.account.accPass
      this.gridSearch.page    = this.grid.paging.page
      this.gridSearch.size    = this.grid.paging.size

      const response = await this.$lhttp.get('/api/account/dpswd/list/page', { params: this.gridSearch })

      if ( response && response.data ) {
        this.grid.paging.total = response.data.total
        this.grid.rowData = response.data.content

        if ( response.data.content.length <= 0 ) {
          this.$alert.alert('조회된 입출금내역이 없습니다.', 'Warnning')
        }
      }         
    }       
	},
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 10px;
}
.table-container {
  width: 100%;
	height: 393px;
	border-color: #565B66;
  margin-top: 10px;
}

@import '@/assets/scss/agGridStyles.scss';
</style>