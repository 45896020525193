<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16">
    <path
      d="M101.238-808.367a1.194 1.194 0 0 1-.878-.359 1.194 1.194 0 0 1-.36-.874v-9.158a1.194 1.194 0 0 1 .359-.878 1.194 1.194 0 0 1 .878-.359h10.526a1.194 1.194 0 0 1 .878.359 1.194 1.194 0 0 1 .359.878v9.158a1.194 1.194 0 0 1-.359.878 1.194 1.194 0 0 1-.878.359zm0-1.026h10.526a.2.2 0 0 0 .145-.066.2.2 0 0 0 .066-.145v-9.158a.2.2 0 0 0-.066-.145.2.2 0 0 0-.145-.066h-10.526a.2.2 0 0 0-.145.066.2.2 0 0 0-.066.145v9.158a.2.2 0 0 0 .066.145.2.2 0 0 0 .145.065zm1.421-1.632h7.684v-1.026h-7.684zm0-2.645h2.526v-3.671h-2.526zm4.026 0h3.658v-1.03h-3.658zm0-2.645h3.658v-1.026h-3.658zm-5.658 6.921z"
      transform="translate(-98.501 822.183)"
      :style="{ fill: fill }"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconNotice',
  props: {
    width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
}
</style>
