export default {
  RESPONSE_TYPE: {
    SUCCESS: '200', // 성공
    NOT_LOGIN: '801', // 로그인 오류
  },

  LINK_TYPE: {
    URL: 'LINKTP01',    // URL
    POP: 'LINKTP02',    // 팝업
    LAYER: 'LINKTP03',  // 레이어
    NONE: 'LINKTP04',   // 없음
  },

  MSG_API_TYPE: {
    NOTICE: 'MSG0001',			  // 공지사항
    BANK: 'MSG0002',			    // 입출금
    LOGOUT: 'MSG0101',			  // 로그아웃
    SUBSCRIBE: 'MSG9999',		  // 구독
    UNSUBSCRIBE: 'MSG9998'		// 구독취소
  },
  
  LOGIN_RESPONSE_TYPE: {
    SUCCESS: '200',
    FAIL: '501',
    MAX_FAIL: '502',
    EXPIRED: '503',
    SLEEP: '504',
    TERMINATION: '505',
    VALID_FAIL_AUTH_KEY: '506',
    NOT_LOGIN: '511',
  },

  SIGN_UP_RESPONSE_TYPE: {
    SUCCESS: '200',
    FAIL: '501',
    NOT_POLICY_AGREE: '502',
    DUP_NICK: '503',
    DUP_EMAIL: '504',
    VALID_FAIL_AUTH_KEY: '506',
  },  

  PROFILE_RESPONSE_TYPE: {
    SUCCESS: '200',
    FAIL: '501',
    DUP_NICK: '503',
    NICK_UPD_CNT: '504',
    CONF_PASSWD_FIAL: '511',
    PASSWD_FIAL: '512',
  },  
  
  PLAN_TYPE: {
    NORMAL: 'P01',
    ESSENTIAL: 'P02',
    PLUS: 'P03',
    PREMIUM: 'P04',
  },    

  ACC_DIV_TYPE: {
    OVERSEAS_FUTURES: 'ACCDIV01',		    // 국내선물
    DOMESTIC_FUTURES: 'ACCDIV02',	    	// 해외선물
    EVENT: 'ACCDIV03'         					// 이벤트
  },

  MSG_TP_TYPE: {
    ORDER_RECEIPT:       'MSGTP00',			// 주문접수
    ORDER_EXECUTION:     'MSGTP01',			// 주문체결
    ORDER_CANCEL:        'MSGTP02',			// 주문취소
    ORDER_ERROR:         'MSGTP03',			// 주문오류
    SIGNAL:              'MSGTP04',			// 매매신호
    DEPOSIT_WITHDRAW:    'MSGTP05',			// 입출금
    ORDER_REJECT:        'MSGTP06',			// 주문거부
    ORDER_CORRECTION:    'MSGTP07',			// 주문정정
    CURRENT_CLEAR:       'MSGTP08',			// 현종목청산
    ALL_CLEAR:           'MSGTP09',			// 전종목청산
    ORDER_EXEC:          'MSGTP10',		  // 주문가동
    ORDER_CLEAR:         'MSGTP11',	    // 주문해제
    RMS_CLEAR:           'MSGTP12',     // RMS발동
    ETC:                 'MSGTP99',			// 기타
  },

  MSG_DIV_TYPE: {
    NORMAL:             'MSGDIV01',			// 일반
    SELL:               'MSGDIV02',			// 매도
    BUY:                'MSGDIV03',			// 매수
    PROFIT:             'MSGDIV04',			// 익절
    STOP_LOSS:          'MSGDIV05',		  // 손절
    ORDER_REJECT:       'MSGDIV06',		  // 주문거부
    ORDER_CLEAR:        'MSGDIV07',		  // 청산
    MO_STOPLOSS:        'MSGDIV08',		  // 모바일 로그인 (스탑로스 자동취소)
    RMS_CLEAR:          'MSGDIV09',     // RMS
  },
}