<template>
  <div class="chatdate-container">
    <h4>{{ chatDate }}</h4>
  </div>
</template>

<script>

export default {
    name: 'ChatDate',
    props: {
        chatDate: String,
    },
    data() {
        return {};
    },
    mounted() { },
    methods: {},
  
};
</script>

<style lang="scss" scoped>
.chatdate-container {
	display: inline-flex;
	align-items: center;
	justify-content: center;
  height: 30px;
	padding: 10px 0;
	border: 1px solid var(--border-color-dark);
	border-radius: 50vh;
	padding: 0 14px;
	margin: 0 auto;
}
</style>
