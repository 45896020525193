<template>
  <div class="feature-bar">
    <div class="bar" :style="{ width: width }"></div>
  </div>
</template>

<script>
export default {
  name: 'FeatureBar',
	props: {
		width: [String, Number]
	},
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.feature-bar {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 50vh;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  margin-top: 15px;
  .bar {
    border-radius: 50vh;
    position: absolute;
    height: 100%;
    width: 10%;
    background-color: var(--main-orange);
  }
}
</style>
