<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16">
    <path
      data-name="notification"
      d="M5.5 14a2.506 2.506 0 0 1-2.45-2H.5a.5.5 0 0 1-.448-.276A.491.491 0 0 1 .1 11.2L1 10V5.5a4.491 4.491 0 0 1 4-4.472V0h1v1.028A4.492 4.492 0 0 1 10 5.5V10l.9 1.2a.491.491 0 0 1 .047.524.5.5 0 0 1-.447.276H7.951A2.507 2.507 0 0 1 5.5 14zm-1.414-2a1.5 1.5 0 0 0 2.83 0zM5.5 2A3.5 3.5 0 0 0 2 5.5v4.833L1.5 11h8l-.5-.667V5.5A3.5 3.5 0 0 0 5.5 2z"
      transform="translate(2.499 1)"
      :style="{ fill: fill }"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconNotification',
  props: {
    width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
}
</style>
