<template>
  <div class="landing-body">
    <LandingHeader />
    <div class="landing-hero" v-if="!isLogin">
      <div class="hero-inner">
        <div>
          <h2 class="hero-title">준비된 트레이더가 될 때까지 무료</h2>
          <h3 class="hero-subtitle">
            모의투자로 트레이딩에 먼저 익숙해 지세요.
            <br />
            그런 다음 고급 트레이딩 도구로 업데이트해 실제 매매에 활용하세요.
          </h3>
        </div>
        <div class="cta-container">
          <div class="cta-text">
            <span>￦</span>
            <h1 class="hero-title">0</h1>
            <p class="desc">평생</p>
            <span class="credit">(신용 카드 필요 없음)</span>
          </div>
          <a href="javascript:;" class="signup-link" @click="goSignUp">회원가입</a>
        </div>
      </div>
    </div>

    <div class="page-container">
      <h1 class="page-title">
        유료 플랜을 사용해 보세요.
        <br />
        30일 무료입니다.
      </h1>
      <div class="desc">한해 최대 16% 할인을 받아, 2달 무료가 됩니다.</div>
      <div class="payment-frequency">
        <p class="monthly" :class="{ active: monthActive }">달달</p>
        <BtnSlide id="payment" @slideChecked="handleChange" />
        <p class="yearly" :class="{ active: yearActive }">해마다</p>
      </div>

      <div class="price-option-grid">
        <div class="price-option">
          <div class="price-title">Essential</div>
          <div class="price-container">
            <div class="price">
              ￦
              <span>0</span>
            </div>
            <div class="dc-price">
              <s>9,000</s>
              <span>/월</span>
            </div>
          </div>
          <div class="per-year">￦108,800 / 연</div>
          <div class="dc-info">
            <span>여러분은 연간 $24.00를 절약할 수 있습니다.</span>
            <img src="@/assets/icons/info-circle-filled.svg" alt="" />
          </div>
          <a href="javascript:;" class="plan-link" @click="appEssential" v-if="isLogin">30일 동안 무료 사용</a>
          <a href="javascript:;" class="plan-link" @click="goSignUp" v-else>30일 동안 무료 사용</a>
          <div class="notice">
            또는 트라이얼 스킵하고
            <span>지금 결제하기</span>
          </div>
          <ul class="plan-features">
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>3개</b>
                  모의투자 계좌
                </p>
                <FeatureBar width="25%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>0.5억원</b>
                  계좌별 투자금액한도
                </p>
                <FeatureBar width="16%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>월4회</b>
                  투자금액 충전 신청
                </p>
                <FeatureBar width="26%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>5개</b>
                  관심종목 리스트
                </p>
                <FeatureBar width="16%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>5개</b>
                  레이아웃 리스트
                </p>
                <FeatureBar width="16%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>2개</b>
                  AI매매신호
                </p>
                <FeatureBar width="10%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">무광고</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">이벤트 계좌 무제한</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">손절 자동주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">익절 자동주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">일반주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">클릭주문</p>
              </div>
            </li>
            <li class="disable">
              <div>
                <img src="@/assets/icons/close.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">차트주문</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="price-option">
          <div class="price-title">Plus</div>
          <div class="price-container">
            <div class="price">
              ￦
              <span>0</span>
            </div>
            <div class="dc-price">
              <s>29,000</s>
              <span>/월</span>
            </div>
          </div>
          <div class="per-year">￦348,000 / 연</div>
          <div class="dc-info">
            <span>여러분은 연간 $60.00를 절약할 수 있습니다.</span>
            <img src="@/assets/icons/info-circle-filled.svg" alt="" />
          </div>
          <a href="javascript:;" class="plan-link" @click="appPlus" v-if="isLogin">30일 동안 무료 사용</a>
          <a href="javascript:;" class="plan-link" @click="goSignUp" v-else>30일 동안 무료 사용</a>          
          <div class="notice">
            또는 트라이얼 스킵하고
            <span>지금 결제하기</span>
          </div>
          <ul class="plan-features">
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>5개</b>
                  모의투자 계좌
                </p>
                <FeatureBar width="41%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>1억원</b>
                  계좌별 투자금액한도
                </p>
                <FeatureBar width="33%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>월6회</b>
                  투자금액 충전 신청
                </p>
                <FeatureBar width="40%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>10개</b>
                  관심종목 리스트
                </p>
                <FeatureBar width="33%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>10개</b>
                  레이아웃 리스트
                </p>
                <FeatureBar width="33%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>6개</b>
                  AI매매신호
                </p>
                <FeatureBar width="30%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">무광고</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">이벤트 계좌 무제한</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">손절 자동주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">익절 자동주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">일반주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">클릭주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">차트주문</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="price-option">
          <div class="price-title">Premium</div>
          <div class="price-container">
            <div class="price">
              ￦
              <span>0</span>
            </div>
            <div class="dc-price">
              <s>49,000</s>
              <span>/월</span>
            </div>
          </div>
          <div class="per-year">￦588,000 / 연</div>
          <div class="dc-info">
            <span>여러분은 연간 $120.00를 절약할 수 있습니다.</span>
            <img src="@/assets/icons/info-circle-filled.svg" alt="" />
          </div>
          <a href="javascript:;" class="plan-link" @click="appPremium" v-if="isLogin">30일 동안 무료 사용</a>
          <a href="javascript:;" class="plan-link" @click="goSignUp" v-else>30일 동안 무료 사용</a>                 
          <div class="notice">
            또는 트라이얼 스킵하고
            <span>지금 결제하기</span>
          </div>
          <ul class="plan-features">
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>7개</b>
                  모의투자 계좌
                </p>
                <FeatureBar width="58%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>2억원</b>
                  계좌별 투자금액한도
                </p>
                <FeatureBar width="66%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>월10회</b>
                  투자금액 충전 신청
                </p>
                <FeatureBar width="66%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>20개</b>
                  관심종목 리스트
                </p>
                <FeatureBar width="66%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>20개</b>
                  레이아웃 리스트
                </p>
                <FeatureBar width="66%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">
                  <b>10개</b>
                  AI매매신호
                </p>
                <FeatureBar width="50%" />
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">무광고</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">이벤트 계좌 무제한</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">손절 자동주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">익절 자동주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">일반주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">클릭주문</p>
              </div>
            </li>
            <li>
              <div>
                <img src="@/assets/icons/checkmark.svg" alt="" />
              </div>
              <div class="col">
                <p class="feature-title">차트주문</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BtnSlide from '@/components/BtnSlide.vue'
import FeatureBar from '@/components/FeatureBar.vue'
import LandingHeader from '@/components/LandingHeader.vue'
import Const from '@/const'

export default {
  name: 'ProfilePage',
  components: {
    BtnSlide,
    FeatureBar,
    LandingHeader,
  },

  data() {
    return {
      monthActive: true,
      yearActive: false,
    };
  },

  mounted() {},

  computed: {
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin']
    },   
  },  

  methods: {
    handleChange(value) {
      this.monthActive = !value;
      this.yearActive = value;
    },
    goSignUp() {
      this.$router.push('/signup')
    },
    appEssential() {
      this.upgrade(Const.PLAN_TYPE.ESSENTIAL)
    },
    appPlus() {
      this.upgrade(Const.PLAN_TYPE.PLUS)
    },
    appPremium() {
      this.upgrade(Const.PLAN_TYPE.PREMIUM)
    },   
    upgrade(planType) {
      const msg = '결제 기능이 준비 중입니다.<br/>'
                + '현재는 별도의 과금없이 자유롭게 사용할 수 있으나 결제 기능 적용 후'
                + '플랜 업그레이드 정보는 <span style="color:red;">초기화</span>됩니다.<br/>'
                + '플랜 업그레이드 하시겠습니까?'

      this.$alert.confirm(msg, '확인')
        .then((response) => {
          if (response) {  
            this.$lhttp
              .post('/api/plan/upgrade',
                {planType : planType})
              .then((response) => {

                this.$sessionStorage.set('xDkToken', response.xDkToken)

                window.$store.dispatch('Login/refreshInfo')
                  .then(() => {
                    this.$alert.alert('플랜 업그레이드가 완료되었습니다.', '안내').then(() => {
                      this.$router.push('/profile/level')
                    })
                  })
              })
          }
        })
    } 
  },
};
</script>

<style lang="scss" scoped>
.landing-body {
  /* overflow: hidden; */
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
}

.landing-hero {
  background-image: url('@/assets/images/landing-hero-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  .hero-inner {
    max-width: 1048px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    .hero-title {
      font-size: 40px;
      font-weight: bold;
      letter-spacing: -0.05em;
    }
    .hero-subtitle {
      font-size: 20px;
      line-height: 1.4em;
      margin-top: 22px;
      letter-spacing: -0.05em;
    }
    .cta-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* justify-content: center; */
      padding: 23px 40px 36px;
      border-radius: 20px;
      gap: 20px;
      background-color: rgba(0, 0, 0, 0.3);

      .cta-text {
        display: flex;
        align-items: baseline;
        .hero-title {
          margin-right: 8px;
        }
        .desc {
          font-size: 14px;
          margin-right: 14px;
        }
        .credit {
          opacity: 0.6;
          font-size: 12px;
        }
      }
    }
    .signup-link {
      display: block;
      background-color: var(--main-white);
      color: #9757ff;
      font-size: 16px;
      font-weight: bold;
      width: 220px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50vh;
    }
  }
  @media (max-width: $mobile) {
    display: none;
  }
}

.page-container {
  max-width: 1040px;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 131px;
  margin-bottom: 100px;
  .page-title {
    font-size: 60px;
    font-weight: bold;
    letter-spacing: -0.04em;
    text-align: center;
    line-height: 1.3em;
  }
  .desc {
    font-size: 14px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
    padding: 5px 38px 9px;
    border-radius: 50vh;
    background-color: rgba(255, 255, 255, 0.1);
    margin-top: 37px;
    text-align: center;
  }
  .payment-frequency {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-top: 22px;
    p {
      padding-bottom: 2px;
      opacity: 0.4;
      &.active {
        opacity: 1;
      }
    }
  }
  .price-option-grid {
    display: grid;
    grid-template-columns: 332px 332px 332px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 80px;
    .price-option {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 58px;
      padding-bottom: 40px;
      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      &:nth-child(2) {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .price-title {
        font-size: 24px;
        font-weight: bold;
        color: var(--main-white);
      }
      .price-container {
        display: flex;
        align-items: baseline;
        margin-top: 30px;
        .price {
          color: var(--main-red);
          font-size: 30px;
          font-weight: bold;
          margin-right: 12px;
          span {
            font-size: 60px;
          }
        }
        .dc-price {
          color: var(--main-white);
          s {
            font-size: 30px;
            font-weight: bold;
          }
          span {
            font-size: 14px;
            opacity: 0.6;
            margin-left: 10px;
          }
        }
      }
      .per-year {
        opacity: 0.6;
        font-size: 14px;
        margin-top: 15px;
      }
      .dc-info {
        display: flex;
        align-items: center;
        font-size: 11px;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50vh;
        padding: 10px 14px 10px 28px;
        margin-top: 30px;
        span {
          padding-bottom: 2px;
          margin-right: 20px;
        }
      }
      .plan-link {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main-orange);
        color: var(--main-white);
        padding: 18px 70px 20px;
        border-radius: 50vh;
        font-size: 16px;
        font-weight: bold;
        margin-top: 30px;
      }
      .notice {
        font-size: 13px;
        margin-top: 12px;
        span {
          color: var(--main-orange);
        }
      }
      .plan-features {
        display: flex;
        flex-direction: column;
        gap: 25px;
        max-width: 266px;
        width: 100%;
        margin-top: 60px;

        li {
          display: flex;
          align-items: flex-start;
          width: 100%;
          gap: 10px;
          .col {
            flex: 1;
          }
          .feature-title {
            font-size: 14px;
            b {
              font-weight: bold;
              margin-right: 5px;
            }
          }
          &.disable {
            opacity: 0.4;
          }
        }
      }
    }
    @media (max-width: $mobile) {
      overflow-x: auto;
      margin-top: 40px;
      /* width: 100vw; */
    }
  }
  @media (max-width: $mobile) {
    margin-top: 40px;
    .page-title {
      font-size: 30px;
    }
    .desc {
      font-size: 13px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
</style>
