<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20">
    <g data-name="icon / 20 / trophy">
        <path d="M143.947-804v-1.412h3.353v-3.225a4.694 4.694 0 0 1-2.118-1.008 4.193 4.193 0 0 1-1.307-1.913 4.376 4.376 0 0 1-2.749-1.383 3.978 3.978 0 0 1-1.12-2.823v-.941a1.358 1.358 0 0 1 .417-.995 1.358 1.358 0 0 1 .995-.417h2.19V-820h8.8v1.882h2.19a1.358 1.358 0 0 1 .995.417 1.358 1.358 0 0 1 .417.995v.941a3.978 3.978 0 0 1-1.12 2.823 4.376 4.376 0 0 1-2.749 1.383 4.193 4.193 0 0 1-1.307 1.913 4.694 4.694 0 0 1-2.118 1.008v3.225h3.348V-804zm-.344-9.075v-3.625h-2.19v.941a2.63 2.63 0 0 0 .617 1.729 2.781 2.781 0 0 0 1.57.956zm4.4 3.084a2.864 2.864 0 0 0 2.1-.864 2.864 2.864 0 0 0 .864-2.1v-5.629h-5.937v5.629a2.864 2.864 0 0 0 .864 2.1 2.864 2.864 0 0 0 2.106.865zm4.4-3.084a2.781 2.781 0 0 0 1.573-.96 2.63 2.63 0 0 0 .617-1.729v-.941H152.4zM148-814.289z" transform="translate(-138.001 821.999)" :style="{ fill: fill }"/>
    </g>
</svg>

</template>

<script>
export default {
  name: 'IconTrophy',
  props: {
    width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
}
</style>
