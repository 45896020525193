<template>
	<div class="container">
		<h3 :class="[params.data.일손익 > 0 ? 'red' : 'blue']">{{ params.data.일손익 | thousand }}</h3>
		<AddDeleteBtn class="add-btn" />
	</div>
</template>

<script>
import AddDeleteBtn from '@/components/AddDeleteBtn.vue'
export default {
	name: 'AiSignalCustomRow2',
	components: {AddDeleteBtn},
	data() {
		return {
			
		};
	},

	mounted() {
		
	},
	filters: {
    thousand(value) {
      return new Intl.NumberFormat('us-EN').format(value);
    },
  },

	methods: {
		
	},
};
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
	.add-btn {
		margin-left: 10px;
	}
}
</style>