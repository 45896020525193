<template>
	<div class="container">
		<h3 class="bold">{{ params.data.전략.title }}</h3>
		<h3>{{ params.data.전략.type }}</h3>
		<h3>{{ params.data.전략.desc }}</h3>
	</div>
</template>

<script>
export default {
	name: 'AiSignalCustomRow',

	data() {
		return {
			
		};
	},

	mounted() {
		
	},

	methods: {
		
	},
};
</script>

<style lang="scss" scoped>
.container {
	text-align: left;
	line-height: 1.4em;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	padding-left: 5px;
}
</style>