<template>
  <div class="container">
    <p v-if="params.col === 'trdDt'">
      {{ params.data.trdDt }}
      <br />
      {{ params.data.ordTm }}
    </p>
    <p v-else-if="params.col === 'ordId'">
      {{ params.data.ordId }}
      <br />
      {{ params.data.orgHstOrdNo }}
    </p>    
    <p v-else-if="params.col === 'symCd'">
      {{ params.data.symCd }}
      <br />
      {{ params.data.symEngNm }}
    </p>       
    <p v-else-if="params.col === 'ordSdCd'"> 
      <span :class="{sell:sell, buy:buy}">
        {{ params.data.ordSdNm }}
      </span>
      <br />
      {{ params.data.plcTpNm }}
    </p>     
    <p v-else-if="params.col === 'ordTpCd'">
      <span v-if="params.data.ordTpCd === '1'">
        {{ params.data.ordTpNm }}
      </span>
      <span v-else-if="params.data.ordTpCd !== '1' && params.data.ordHndTpCd === '1'">
        시장가
      </span>      
      <span v-else>
        {{ params.data.ordHndTpNm }}
      </span>         
      <br />
      {{ params.data.ordQty }}
    </p>       
    <p v-else-if="params.col === 'execQty'">
      {{ params.data.execQty }}
      <br />
      {{ params.data.ordPrc | thousand }}
    </p>    
    <p v-else-if="params.col === 'bfAvgPrc'">
      {{ params.data.bfAvgPrc | thousand }}
      <br />
      {{ params.data.afAvgPrc | thousand }}
    </p>    
    <p v-else-if="params.col === 'execPrc'">
      {{ params.data.execPrc | thousand }}
      <br />
      <span :class="{minus:minus, plus:plus}">{{ params.data.ordPlMn | thousand  }}</span>
    </p>    
    <p v-else-if="params.col === 'cms'">
      {{ params.data.cms | thousand }}
      <br />
      <!--{{ params.data.제세금 }} -->
    </p>    
    <p v-else-if="params.col === 'usdExR'">
      {{ params.data.usdExR | thousand }}
      <br />
      <!-- {{ params.data.제세금 }} -->
    </p>    
    <!-- <p>
      {{ params.data.거래번호 }}
      <br />
      {{ params.data.원거래번호 }}
    </p> -->
  </div>
</template>

<script>
export default {
  name: 'AgTransactionRow',
  props: {},
  data() {
    return {
      buy: '',
      sell: '',
      minus: '',
      plus: ''      
    };
  },
  filters: {
    thousand(value) {
      if ( value === null || value === '' ) {
        return ''
      } else {
        return value.toLocaleString('en-US', { minimumFractionDigits: 2 });
      }
    },
  },
  beforeMount() {
    if (this.params.data.ordSdCd === '1') {
      this.sell = true
    }
    
    if (this.params.data.ordSdCd === '2') {
      this.buy = true
    }    

    if (this.params.data.ordPlMn < 0) {
      this.minus = true
    } else {
      this.plus = true
    }    
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  .buy {
    color: var(--main-red);
  }
  .sell {
    color: var(--main-blue);
  }
  .minus {
    color: var(--main-blue);
  }
  .plus {
    color: var(--main-red);
  }  
}
</style>
