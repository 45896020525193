<template>
	<div class="clean-header" :class="{scrolled: isScrolled}">
      <router-link to="/">
        <div class="logo">
          <!-- TAP -->
          <img src="@/assets/images/tab_bi_w.png" alt="" />
        </div>
      </router-link>
      <router-link to="/main">
        <div class="start-now-link">Trader 이동</div>
      </router-link>
			<div class="start-now-container" v-if="!isLogin">
				<img src="@/assets/icons/user.svg" alt="">
				<router-link to="/login" class="start-now-link">지금 시작</router-link>
			</div>
      <AvatarComp class="avatar" v-if="isLogin" :name="profileNick" :color="profilebgColor" @click="goProfile" />
      <BaseBtn naked class="close-btn">
        <IconClose :width="24" :height="24" />
      </BaseBtn>
    </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue';
import BaseBtn from '@/components/BaseBtn.vue';
import AvatarComp from '@/components/AvatarComp.vue';

export default {
	name: 'LandingHeader',
	components: {
		IconClose,
		BaseBtn,
		AvatarComp
	},
	props: {
		isLoggedIn: Boolean
	},
	data() {
		return {
			isScrolled: '',
			scrollPosition: null
		};
	},

	mounted() {
		this.$parent.$el.addEventListener('scroll', this.handleScroll);
		// console.log(this.$parent.$el)
	},

  computed: {
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin']
    },    
    profileNick () {
      const usrNick = window.$store.getters['Login/getUsrNick']

      if ( usrNick !== null ) {
        return usrNick.substring(0, 1)
      } else {
        return ''
      }
    },
    profilebgColor () {
      const prfBgCol = window.$store.getters['Login/getPrfBgCol']

      if ( prfBgCol !== null ) {
        return prfBgCol
      } else {
        return ''
      }      
    }
  },  

	methods: {
		handleScroll() {
			if(this.$parent.$el.scrollTop > 200) {
				this.isScrolled = true;
			} else {
				this.isScrolled = false;
			}
			// console.log(this.$parent.$el.scrollTop);
		},
    goProfile() {
      this.$router.push('/profile')
    }
	},
};
</script>

<style lang="scss" scoped>
.clean-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  /* background-color: var(--main-black); */
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #596174; */
  padding: 0 20px;
	transition: background-color 0.3s;
	&.scrolled {
		background-color: var(--main-black);
	}
	.start-now-container {
		display: flex;
		gap: 20px;
	}
	.start-now-link {
		color: white;
		background-color: var(--main-orange);
		font-size: 14px;
		font-weight:  bold;
		padding: 6px 20px 9px;
		border-radius: 50vh;
	}
  .logo {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    width: 104px;
    img {
      width: 100%;
    }
  }
  .header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mobile-header-title {
    display: none;
  }
  .close-btn {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
  @media (max-width: $mobile) {
    /* justify-content: center; */
    height: 60px;
    .logo {
      /* display: none; */
    }
    .header-title {
      display: none;
    }
    .avatar {
      /* display: none; */
    }
    .mobile-header-title {
      /* display: block; */
    }
    .close-btn {
      /* display: block; */
    }
  }
}
</style>