<template>
  <div class="base-dropdown" :id="id" :style="{ width: width + 'px', height: height + 'px' }" :class="{ full: full }" ref="dropdown">
    <div class="dropdown-title" @click="handleDropdown" :class="{ active: isDropdownVisible }" :style="{ height: height + 'px' }">
      <h3>
        {{ selectedName }}
      </h3>
      <img class="triangle" :class="{ open: isDropdownVisible }" src="@/assets/icons/triangle-down.svg" alt="" />
    </div>
    <transition name="fade">
      <DropDownList v-if="isDropdownVisible" v-bind:width="width" :popup="popup">
        <div
          v-if="allYn == 'Y'"
          class="dropdown-list-item" @click="selectSymbol({symCd:'', engNm: '전체'})">
          <div class="col">
            <h3>전체</h3>
          </div>          
        </div>         
        <div
          class="dropdown-list-item"
          v-for="(symbol, index) in symbolList"
          :key="index"
          @click="selectSymbol(symbol)" >
          <div class="col">
            <h3>{{ symbol.shtNm }}</h3>
          </div>
        </div>  
      </DropDownList>
    </transition>
  </div>
</template>

<script>
import DropDownList from '@/components/DropDownList.vue'

export default {
  name: 'SymbolDropDown',
  props: {
    id: String,
    allYn: {
      type: String,
      default: 'Y'
    },       
    value: {
      type: String,
      default: '',
    },    
    width: [String, Number],
    height: [String, Number],
    full: Boolean,
    popup: Boolean,
  },
  components: {
    DropDownList,
  },  
  data() {
    return {
      isDropdownVisible: false,
      symbolList: []
    };
  },

  computed: {
    selectedName() {
      let rtn = '전체'  

      if ( this.allYn !== 'Y' ) {
        if (this.symbolList.length > 0 ) {
          rtn = this.symbolList[0].shtNm
        }
      } else {
        for (let i in this.symbolList) {
          if ( this.value == this.symbolList[i].symCd ) {
            rtn = this.symbolList[i].shtNm
            break
          }  
        }
      }

      return rtn
    }
  },     

  mounted() {

    this.$http
      .get('/api/symbol/list')
      .then((result) => {
        if ( result && result.data !== null ) {
          this.symbolList = result.data

          if ( this.allYn !== 'Y' && this.value == 0 && this.symbolList.length > 0 ) {
            this.$emit('input', this.symbolList[0].symCd)
            this.$emit('event:selectSymbol', this.symbolList[0])
          } else {
            this.$emit('event:selectSymbol', {symCd:'', engNm: '전체'})
          }          
        }
      })

  },

  methods: {
    handleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    selectSymbol(symbol) {
      this.isDropdownVisible = false
      this.$emit('input', symbol.symCd)
      this.$emit('event:selectSymbol', symbol)
    },    
  },
};
</script>

<style lang="scss" scoped>
.base-dropdown {
  position: relative;
  white-space: nowrap;
  height: 30px;
  cursor: pointer;
  &.full {
    flex: 1;
    width: 100%;
    .dropdown-list {
      width: 100%;
    }
  }
  .dropdown-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    height: 100%;
    /* height: 30px; */
    border: 1px solid var(--border-color);
    &:hover {
      border-color: var(--main-orange);
    }
    &.active {
      border-color: var(--main-orange);
    }
  }
  h3 {
    font-size: 12px;
    // font-weight: 400;
    padding-bottom: 2px;
  }
  .triangle {
    margin-left: 5px;
    transition: transform 0.2s;
    &.open {
      transform: rotate(180deg);
    }
  }
  @media (max-width: $mobile) {
    height: 40px;
  }
}
</style>
