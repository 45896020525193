<template>
    <div class="login-page">
      <div class="left">
        <div class="hero-title">
          <h1 class="title">Plan&Timing</h1>
          <h2 class="subtitle">The Art of Trading</h2>
        </div>
      </div>
      <div class="right">
        <div class="header">
          <BaseBtn naked @click="goBack">
            <IconChevronDown left width="24" height="24" />
          </BaseBtn>
          <h4 class="header-title">
            <b>TAP</b>
            <span>TradingAsPlan</span>
          </h4>
          <BaseBtn naked @click="close">
            <IconClose width="24" height="24" />
          </BaseBtn>
        </div>
        <div class="body">
          <div class="body-inner">
            <h3 class="body-title">
              비밀번호
              <br />
              변경 요청 페이지 입니다.
            </h3>
            <p class="desc">
              확인 버튼 클릭시 인증 링크가 전송됩니다. <br>
              받은 편지함에 인증 링크가 없으면 <br> 
              스팸/정크함을 확인하세요.
            </p>

            <div class="email-login-container">
              <validation-observer ref="validator">
                <validation-provider rules="required|email" name="이메일" v-slot="{errors}">
                  <input type="text" placeholder="이메일" class="input" :class="[errors[0] ? 'error': '']" v-model="form.loginId">
                  <p class="login-error-message">{{errors[0]}}</p>
                </validation-provider>
              </validation-observer>
            
              <button class="login-btn" @click="reset">확인</button>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import IconClose from '@/components/icons/IconClose.vue'
  import IconChevronDown from '@/components/icons/IconChevronDown.vue'
  import BtnCheckbox from '@/components/BtnCheckbox.vue'
  import BaseBtn from '@/components/BaseBtn.vue'
  
  export default {
    components: {
      IconClose,
      IconChevronDown,
      BtnCheckbox,
      BaseBtn,
    },
  
    name: 'AppResetPasswdPage',
  
    data() {
      return {
        form : {
          loginId: ''
        }
      }
    },
  
    created() {

    },
  
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      close () {
        this.$router.push('/')
      },  
      reset () {
        const { validator } = this.$refs

        validator.validate().then((result) => {
          if (result) {

            this.$alert.confirm('비밀번호를 변경 요청 하시겠습니까?', '확인')
              .then((response) => {
                if (response) {      

                  this.$lhttp
                    .post('/auth/passwd/reset/app',
                        this.form)
                    .then((response) => {
                      this.$alert.alert('인증 이메일이 전송되었습니다. 이메일을 확인 해 주십시오.', 'Warnning')
                    })

                }
              })

          }
        })        
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .body-title {
    margin-bottom: 20px;
  }
  .desc {
    font-size: 14px;
    line-height: 1.4em;
    text-align: center;
    opacity: 1 !important;
    &.notice {
      font-size: 12px;
      text-align: left;
      width: 100%;
      opacity: 0.6 !important;
    }
    .title {
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      margin-right: 5px;
    }
    .result {
      opacity: 0.8;
    }
  }
  .input {
    width: 100%;
    padding: 20px 10px;
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 10px;
    &.error {
      border-color: var(--main-red);
    }
  }
  .checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    align-self: flex-start;
  }
  .agree-link {
    color: var(--main-orange);
  }
  .email-login-container {
    width: 100%;
    margin: 38px 0 42px 0;
  }  
  .login-error-message {
    font-size: 12px;
    color: var(--main-red);
    margin-bottom: 20px;
  }
</style>
  