<template>
  <div class="login-page">
    <div class="left">
      <div class="hero-title">
        <h1 class="title">Plan&Timing</h1>
        <h2 class="subtitle">The Art of Trading</h2>
      </div>
    </div>
    <div class="right">
      <div class="header">
        <BaseBtn naked @click="goBack">
          <IconChevronDown left width="24" height="24" />
        </BaseBtn>        
        <h4 class="header-title">
          <b>TAP</b>
          <span>TradingAsPlan</span>
        </h4>
        <BaseBtn naked @click="close">
          <IconClose width="24" height="24" />
        </BaseBtn>
      </div>
      <div class="body">
        <div class="body-inner">
          <h3 class="body-title">이메일로 회원가입</h3>
          <p class="desc">
            어카운트 만들기 버튼 클릭시 인증 링크가 전송됩니다. <br>
            받은 편지함에 인증 링크가 없으면 <br> 
            스팸/정크함을 확인하세요.
          </p>          


          <div class="email-login-container">
            <validation-observer ref="validator">
              <validation-provider rules="required|email" name="이메일" v-slot="{errors}">
                <input type="text" placeholder="이메일" class="input" :class="[errors[0] ? 'error': '']" :tabIndex="1" v-model="form.loginId" />
                <p class="login-error-message">{{errors[0]}}</p>
              </validation-provider>
              <validation-provider rules="required|password" name="비밀번호" v-slot="{errors}">
                <div class="password-container">
                  <input :type="fieldType" placeholder="비밀번호" class="input" :class="[errors[0] ? 'error': '']" :tabIndex="2" @keyup.enter="signup" v-model="form.passwd" />
                  <BaseBtn naked class="show-pass" @click="showPassword">
                    <img src="@/assets/icons/eye.svg" alt="" v-if="!passwordEye">
                    <img src="@/assets/icons/eye-closed.svg" alt="" v-if="passwordEye">
                  </BaseBtn>
                </div>
                <p class="login-error-message">{{errors[0]}}</p>
              </validation-provider>
            </validation-observer>

            <button class="login-btn" @click="signup">어카운트 만들기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import BaseBtn from '@/components/BaseBtn.vue'

export default {
  components: {
    IconClose,
    IconChevronDown,
    BaseBtn
  },

  name: 'SignupEmailPage',

  data() {
    return {
      password: '',
      fieldType: 'password',
      passwordEye: '',
      form: {
        loginId: '',
        passwd: ''
      }      
    };
  },

  mounted() {},

  methods: {
    showPassword() {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password';
      this.passwordEye = !this.passwordEye;
    },
    goBack () {
      this.$router.go(-1)
    },
    close () {
      this.$router.push('/')
    },      
    signup () {
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {
          this.$alert.confirm('입력하신 정보로 회원가입 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {     
                this.$lhttp
                  .post('/auth/signup/email',
                    this.form)
                  .then((response) => {

                    this.$alert.alert('인증 이메일이 전송되었습니다. 이메일을 확인 해 주십시오.', '안내')
                      .then(() => {
                        this.$router.push('/verify-email/'+ this.form.loginId)
                      })

                  })    
              }
            })
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.email-login-container {
  width: 100%;
  margin: 38px 0 42px 0;
}
input[type='password']:not(:placeholder-shown) {
  font-family: caption;
  font-size: 24px;
}
.input {
  width: 100%;
  padding: 20px 10px;
  font-size: 14px;
  margin-bottom: 10px;
  &.error {
    border-color: var(--main-red);
  }
}
.password-container {
  position: relative;
  .show-pass {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    right: 10px;
  }
}

.desc {
    font-size: 14px;
    line-height: 1.4em;
    text-align: center;
    opacity: 1 !important;
    &.notice {
      font-size: 12px;
      text-align: left;
      width: 100%;
      opacity: 0.6 !important;
    }
    .title {
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      margin-right: 5px;
    }
    .result {
      opacity: 0.8;
    }
  }
.login-error-message {
  font-size: 12px;
  color: var(--main-red);
  margin-bottom: 20px;
}
</style>
