<template>
  <aside class="sidemenu">
    <router-link to="/main/favorite" @click.native="handleSidemenu()" v-if="isLogin">
      <SidemenuItem>
        <IconFavorite width="20" height="20" />
        <div class="tool-tip"><h3>관심종목</h3></div>
      </SidemenuItem>
    </router-link>
    <router-link to="/main/notification" @click.native="handleSidemenu()" v-if="isLogin">
      <SidemenuItem :hasAlert="hasAlert">
        <IconNotification width="20" height="20" />
        <div class="tool-tip"><h3>알림</h3></div>
      </SidemenuItem>
    </router-link>
    <router-link to="/main/demo" @click.native="handleSidemenu()" v-if="isLogin">
      <SidemenuItem>
        <IconTrophy :width="20" :height="20" />
        <div class="tool-tip"><h4>모의투자</h4></div>
      </SidemenuItem>
    </router-link>    
    <router-link to="/main/trade" @click.native="handleSidemenu()" v-if="isLogin">
      <SidemenuItem>
        <IconTrade width="20" height="20" />
        <div class="tool-tip"><h3>AI매매신호</h3></div>
      </SidemenuItem>
    </router-link>
    <router-link to="/main/notice" @click.native="handleSidemenu()">
      <SidemenuItem>
        <IconNotice width="20" height="20" />
        <div class="tool-tip"><h3>공지사항</h3></div>
      </SidemenuItem>
    </router-link>
    <router-link to="/main/help" @click.native="handleSidemenu()">
      <SidemenuItem>
        <IconHelpCenter width="20" height="20" />
        <div class="tool-tip"><h3>헬프센터</h3></div>
      </SidemenuItem>
    </router-link>
    <router-link :to="{name:'tradeExecList'}" @click.native="handleSidemenu()" ref="exec" v-if="isLogin">
      <SidemenuItem>
        <TradeExecList width="20" height="20" />
        <div class="tool-tip"><h3>체결리스트</h3></div>
      </SidemenuItem>
    </router-link>
  </aside>
</template>
<!-- /main/tradeExecList -->
<script>
import IconFavorite from '@/components/icons/IconFavorite.vue'
import IconNotification from '@/components/icons/IconNotification.vue'
import IconTrade from '@/components/icons/IconTrade.vue'
import IconTrophy from '@/components//icons/IconTrophy.vue'
import IconNotice from '@/components/icons/IconNotice.vue'
import IconHelpCenter from '@/components/icons/IconHelpCenter.vue'
import SidemenuItem from '@/components/SidemenuItem.vue'
import TradeExecList from '@/components/icons/IconTrade.vue'
import EventBus from '@/modules/event-bus'

export default {
  name: 'SideMenu',
  components: {
    IconFavorite,
    IconNotification,
    IconTrade,
    IconTrophy,
    IconNotice,
    IconHelpCenter,
    SidemenuItem,
    TradeExecList,
  },
  props: {
    selectNotice : {
      type: Number
    },
  },
  data() {
    return {
      passRoute: '',
      currentRoute: '',
      hasAlert: false,
      ovcData: {ovcData:null, cnt:0}
    };
  },

  created() {
    // 
    EventBus.$on('Alarm:event:refresh', () => {
      this.hasAlert = true
    })

    EventBus.$on('Alarm:event:search', () => {
      this.hasAlert = false
    })    
  },   

  mounted() {
    this.currentRoute = this.$route.name
    if (this.currentRoute == "tradeExecList"){
      this.$router.push({name:'MainPage'})
    }
    
  },

  computed: {
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin']
    },    
  },

  methods: {
    handleSidemenu() {
      this.passRoute = this.currentRoute
      this.currentRoute = this.$route.name

      if (this.currentRoute == this.passRoute ) {
        this.$emit('event:hideSideMenu', { isCurrent: true })
      } else {
        window.$store.commit('Layout/setPath', { path: this.$route.path })
        
        this.$emit('event:hideSideMenu', { isCurrent: false })
      }


        
    },
  },
  watch:{
    selectNotice:{
      handler(){
        this.passRoute ='notice'
        this.currentRoute = this.$route.name
      if (this.currentRoute == this.passRoute ) {
        this.$router.go(this.currentRoute)
      } else {
        window.$store.commit('Layout/setPath', {path: '/main/notice'})
        this.$emit('event:hideSideMenu', { isCurrent: false })
      }
      },
      deep: true,
    },
  }
};
</script>

<style lang="scss" scoped>
.sidemenu {
  /* position: fixed; */
  /* top: 50px; */
  /* bottom: 0; */
  /* right: 0; */
  width: 50px;
  height: 100%;
  /* height: calc(100vh - 50px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  border-left: 1px solid var(--border-color);
  padding: 15px 0;
  z-index: 2;
  background-color: var(--main-black);
  /* @media (max-width: $mobile) {
    display: none;
  } */
  @media (max-width: $mobile) {
    display: none;
  }
}
.router-link-active {
  svg {
    fill: var(--main-orange);
  }
}
</style>

