<template>
  <div class="notice-item-container">
    <h5 class="date">{{ date }}</h5>
    <h2 class="title bold" :class="{accent: accent}">{{ title }}</h2>
    <div class="summary" v-html="summary"></div>
  </div>
</template>

<script>
export default {
  name: 'NoticeItem',
  props: {
    date: String,
    title: String,
    summary: String,
		accent: Boolean,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.notice-item-container {
  margin-top: 13px;
  &:hover {
      .title {
        color: var(--main-orange);
      }
    }
  .date {
    opacity: 0.6;
  }
  .title {
    margin: 6px 0 9px;
    
  }
  .summary {
    margin-bottom: 20px;
    line-height: 1.4em;
    opacity: 0.8;
    overflow: hidden;
  }
  ::v-deep .summary img {
    max-width: 100%;
    object-fit:cover;
  }

}
</style>
