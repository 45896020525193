<template>
  <validation-observer ref="validator" class="form">
    <h3 class="required-label">필수 입력 항목입니다</h3>

    <div class="form-row">
      <div class="form-row-label require">
        <h3>유저네임</h3>
      </div>
      <div class="form-input">
        <validation-provider rules="required|limit:15" name="유저 네임" v-slot="{errors}">
          <InputComp full placeholder="유저 네임을 입력 해 주십시오." :error="errors[0] ? true: false" :readonly="nickUpdCnt <= 0 ? true: false" v-model="form.usrNick" />
          <p class="profile-error-message">{{errors[0]}}</p>
          <h3 class="warning" v-if="nickUpdCnt > 0">앞으로 {{ nickUpdCnt }}번 더 이름을 바꿀 수 있으니 신중히 하세요.</h3>
          <h3 class="warning" v-else>더 이상 유저 네임을 바꿀 수 없습니다.</h3>
        </validation-provider>
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-label">
        <h3>아바타</h3>
      </div>
      <div class="form-input">
        <div class="upload">
          <AvatarComp width="60" height="60" fontSize="30" fontWeight="600" :name="profileNick" :color="form.prfBgCol" />
          <div>
            <h3>프로필 배경색을 선택하십시오.</h3>
            <div class="avatar">
              <div class="avatar-container" v-for="avatarColor in avatarColors" v-bind:key="avatarColor">
                <AvatarComp width="30" height="30" fontSize="15" fontWeight="300" :color="avatarColor" @click="setAvatar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
    <div class="form-row">
      <div class="form-row-label">
        <h3>내소개</h3>
      </div>
      <div class="form-input">
        <validation-provider rules="limit:1000" name="내소개" v-slot="{errors}">
          <InputCompTextarea full placeholder="내소개를 입력하세요." :error="errors[0] ? true: false" v-model="form.itdTxt" />
          <p class="profile-error-message">{{errors[0]}}</p>
        </validation-provider>
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-label">
        <h3>이메일</h3>
      </div>
      <div class="form-input">
        <div class="inner">
          <h3 style="padding-top:12px;">{{ form.mail }}</h3>
        </div>
      </div>
    </div>
    <div class="form-row" v-if="isPasswd">
      <div class="form-row-label">
        <h3>비밀번호 변경</h3>
      </div>
      <div class="form-input">
        <div class="inner">
          <BtnPrimary text="변경" @click="popResetPasswd" />
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-label">
        <h3>소셜 미디어 링크가 표시됩니다.</h3>
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-label">
        <h3>트위터 유저네임</h3>
      </div>
      <div class="form-input">
        <InputComp full placeholder="유저네임" v-model="form.twtUsrNm" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-label">
        <h3>유튜브 유저네임</h3>
      </div>
      <div class="form-input">
        <InputComp full placeholder="유저네임" v-model="form.ytbUsrNm" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-label">
        <h3>유튜브 채널링크</h3>
      </div>
      <div class="form-input">
        <InputComp full placeholder="https://www.youtube.com" v-model="form.ytbChUrl" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-label">
        <h3>Facebook 유저네임</h3>
      </div>
      <div class="form-input">
        <InputComp full placeholder="유저네임" v-model="form.fbUsrNm" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-label">
        <h3>Instagram 유저네임</h3>
      </div>
      <div class="form-input">
        <InputComp full placeholder="유저네임" v-model="form.istgrUsrNm" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-row-label">
        <h3>웹사이트</h3>
      </div>
      <div class="form-input">
        <InputComp full placeholder="http://" v-model="form.hmUrl" />
      </div>
    </div>

    <div class="form-footer">
      <BtnPrimary text="바뀐 내용 저장" class="form-submit-btn" @click="save" />
    </div>

    <resetPasswdPop />
  </validation-observer>
</template>

<script>
import InputComp from '@/components/InputComp.vue'
import InputCompTextarea from '@/components/InputCompTextarea.vue'
import AvatarComp from '@/components/AvatarComp.vue'
// import BtnSub from '@/components/BtnSub.vue';
import BtnPrimary from '@/components/BtnPrimary.vue'
import Const from '@/const'
import ResetPasswdPop from './ResetPasswdPop.vue'

export default {
  name: 'ProfileForm',
  components: {
    InputComp,
    InputCompTextarea,
    AvatarComp,
    // BtnSub,
    BtnPrimary,
    ResetPasswdPop
  },
  data() {
    return {
      avatarColors: ['#459DFF', '#9757FF', '#6FA751', '#6464FF', '#A27548', '#67B1B4', '#CE63A1', '#527ED1', '#C77A62', '#8B9D00'],
      isPasswd: false,
      form: {
        usrNick: '',
        prfBgCol: '#459DFF',
        itdTxt: '',
        mail: '',
        twtUsrNm: '',
        ytbUsrNm: '',
        ytbChUrl: '',
        fbUsrNm: '',
        istgrUsrNm: '',
        hmUrl: '',      
        usrNickUpdCnt: 0  
      }
    }
  },

  mounted() {

    if ( window.$store.getters['Login/isLogin'] ) {
      this.select()
    } else {
      window.$store.watch(() => window.$store.getters['Login/isLogin'], isLogin => {
        this.select()
      })
    }

  },

  computed: {
    profileNick () {
      const usrNick = this.form.usrNick

      if ( usrNick !== null ) {
        return usrNick.substring(0, 1)
      } else {
        return ''
      }
    },  
    nickUpdCnt () {
      return 2 - this.form.usrNickUpdCnt
    }
    
  },

  methods: {
    setAvatar(color) {
      this.form.prfBgCol = color
    },
    popResetPasswd() {
      this.$modal.show('resetPasswdPop')
    },
    select() {

      this.$lhttp
        .get('/auth/profile')
        .then((response) => {
          if (response.data) {
            this.form.usrNick       = response.data.usrNick
            this.form.prfBgCol      = response.data.prfBgCol
            this.form.itdTxt        = response.data.itdTxt
            this.form.mail          = response.data.mail
            this.form.twtUsrNm      = response.data.twtUsrNm
            this.form.ytbUsrNm      = response.data.ytbUsrNm
            this.form.ytbChUrl      = response.data.ytbChUrl
            this.form.fbUsrNm       = response.data.fbUsrNm
            this.form.istgrUsrNm    = response.data.istgrUsrNm
            this.form.hmUrl         = response.data.hmUrl
            this.form.usrNickUpdCnt = response.data.usrNickUpdCnt
            this.isPasswd           = response.data.passwd
          }
        })    

    },
    save() {

      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {

          this.$alert.confirm('프로필을 수정 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {
                
                this.$lhttp
                  .post('/auth/profile/update',
                    this.form)
                  .then((response) => {
                    // 성공
                    if ( response.data.code == Const.PROFILE_RESPONSE_TYPE.SUCCESS ) {

                      this.$alert.alert('프로필 수정이 완료되었습니다.', '안내').then(() => {
                        window.$store.dispatch('Login/refreshInfo')
                        this.select()
                      })

                    } else {
                      this.$alert.alert(response.data.message, 'Warnning')
                    }
                  })                

              }
            })

        } else {
          this.$alert.alert('먼저 입력 항목을 확인 해 주십시오.', 'Warnning')
        }
      })

    }
  },
};
</script>

<style lang="scss" scoped>
.required-label {
  position: relative;
  padding-left: 10px;
  color: var(--main-orange);
  margin-left: auto;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 5px;
    left: 0;
    width: 4px;
    height: 4px;
    border-radius: 50vh;
    background-color: var(--main-orange);
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: $mobile) {
    padding: 0 20px;
    margin-top: 10px;
  }
}
.form-row {
  display: flex;
  align-items: flex-start;
  @media (max-width: $mobile) {
    display: block;
  }
  .form-row-label {
    margin-top: 10px;
    width: 200px;
    @media (max-width: $mobile) {
      margin-bottom: 10px;
    }
    h3 {
      color: rgba(255,255,255,0.6);
    }
    &.require {
      position: relative;
      h3 {
        width: fit-content;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin-left: 5px;
          width: 4px;
          height: 4px;
          border-radius: 50vh;
          background-color: var(--main-orange);
          opacity: 1;
        }
      }
    }
  }
  .form-input {
    flex: 1;
    .inner {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      .input-container {
        flex: 1;
      }
    }
    .avatar {
      padding-top: 8px;
      .avatar-container {
        display: inline-block;
        padding-right:5px;
      }      
    }      
  }
  .upload {
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    .form-link {
      margin-top: 11px;
      &:not(:last-child) {
        margin-right: 28px;
      }
    }
  }
  .warning {
    margin-bottom: 10px;
  }
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
}
.form-submit-btn {
  padding: 0 20px;
  width: 150px;
  @media (max-width: $mobile) {
    flex: 1;
    width: 100%;
  }
}
.profile-error-message {
  font-size: 12px;
  color: var(--main-red);
  padding-top: 5px;
}
</style>
