<template>
  <div class="sidemenu-item">
    <slot></slot>
    <div class="notification-alert" :class="{hasAlert:hasAlert}"></div>
  </div>
</template>

<script>
export default {
  name: 'SidemenuItem',
  props: {
    hasAlert: Boolean,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.sidemenu-item {
  position: relative;
  @media (hover: hover) {
    &:hover {
      svg {
        opacity: 0.7;
      }
      .tool-tip {
        display: block;
      }
    }
  }
  .notification-alert {
    display: none;
    position: absolute;
    top: 2px;
    right: 1px;
    width: 8px;
    height: 8px;
    border-radius: 50vh;
    background-color: var(--main-orange);
    &.hasAlert {
      display: block;
    }
  }
}
</style>
