<template>
  <div class="container">
    <!-- <div v-if="params.enableMenu" ref="menuButton" class="customHeaderMenuButton" @click="onMenuClicked($event)">
     <BtnCheckbox id="agCheckboxHeader" class="ag-custom-checkbox" text="" /> 
    </div> -->
    <p class="customHeaderLabel">{{ params.name }}</p>
    <p class="customHeaderLabel">{{ params.name2 }}</p>
  </div>
</template>

<script>
// import BtnCheckbox from './BtnCheckbox.vue';
export default {
  // components: { BtnCheckbox },
  name: 'CustomHeader2Rows',

  data() {
    return {
      
    };
  },

  mounted() {
    //console.log('2nd custom header')
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .customHeaderLabel {
    &:first-child {
      margin-bottom: 10px;
    }
  }
}
</style>
