<template>
  <modal name="requestDepositPop" class="custom-modal" adaptive :minWidth="800" :minHeight="428" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="입출금신청" modalName="requestDepositPop">
      <template #body>
        <div class="row">
          <div class="row-item"> 
            <h3>계좌번호</h3>
            <div class="row-item-content">
              <AccountDropDown id="accountDropdown" popup width="120" v-model="accId" @event:selectAccount="selectAccount" store />
              <InputComp readonly width="120" v-model="account.accNo" />
            </div>
          </div>
          <div class="row-item">
            <h3>비밀번호</h3>
            <InputComp type="password" :width="65" v-model="account.accPass" />
          </div>

          <div class="row-item" v-if="showSearchBalMn">
            <BtnPrimary text="출금가능금액조회" icon="search" :width="150" @click="searchBalMn" />
          </div>          
        </div>  
        <div class="request-deposit-container">
          <TabNav :tabs="tabList" :selected="selectedTab" @selectedTab="setSelectedTab" class="deposit-tab" />

          <div v-if="selectedTab === 'requestDeposit'">
            <div class="desosit-withdraw-container">
              <div class="table-wrapper">
                <div class="request-deposit-table">
                  <div class="table-row">
                    <div class="label"><h3>은행명</h3></div>
                    <div class="content"><h3>{{ bankCpn.bnkNm }}</h3></div>
                  </div>
                  <div class="table-row">
                    <div class="label"><h3>계좌번호</h3></div>
                    <div class="content"><h3>{{ bankCpn.bnkAccNo }}</h3></div>
                  </div>
                  <div class="table-row">
                    <div class="label"><h3>예금주</h3></div>
                    <div class="content"><h3>{{ bankCpn.bnkAccHd }}</h3></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper no-bg">
                <div class="request-deposit-table">
                  <validation-observer ref="validatorDeposit">
                    <div class="table-row">
                      <div class="label large"><h3>입금신청금액</h3></div>
                      <div class="content">
                        <InputNumberComp alignRight :width="155" type="number" :max="5000000000" placeholder="입금신청금액" disableDeleteBtn v-model="depositForm.dpsReqMn" /><span class="unit">원</span>
                      </div>
                    </div>
                    <div class="table-row">
                      <div class="label large"><h3>입금자명</h3></div>
                      <div class="content">
                        <validation-provider rules="required|limit:15" name="입금자명" v-slot="{errors}">
                          <InputComp type="text" :width="155" placeholder="입금자명" :error="errors[0] ? true: false" v-model="depositForm.dpsHd" />
                          <div class="error-message"><h3>{{errors[0]}}</h3></div>
                        </validation-provider>
                      </div>
                    </div>
                  </validation-observer>

                  <div class="table-row button-row" style="padding-top: 35px;padding-bottom: 15px;">
                    <BtnLine class="amount-btn" text="+100만" white @click="sumDpsReqMn(100_0000)" />
                    <BtnLine class="amount-btn" text="+50만" white @click="sumDpsReqMn(50_0000)" />
                    <BtnLine class="amount-btn" text="+10만" white @click="sumDpsReqMn(10_0000)" />
                    <BtnLine class="amount-btn" text="+1만" white @click="sumDpsReqMn(1_0000)" />
                    <BtnLine class="amount-btn" text="초기화" white @click="sumDpsReqMn(0)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <BtnSub text="취소" @click="close" />
              <BtnPrimary text="입금신청" @click="requestDeposit" />
            </div>
          </div>

          <div v-else-if="selectedTab === 'requestWithdrawal'">
            <div class="desosit-withdraw-container">
              <div class="table-wrapper">
                <div class="request-deposit-table">
                  <div class="table-row">
                    <div class="label"><h3>은행명</h3></div>
                    <div class="content between">
                      <h3>{{ bankWd.wdBnkNm }}</h3>
                      <BtnLine text="변경" white round @click="popWdBank" />
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="label"><h3>계좌번호</h3></div>
                    <div class="content"><h3>{{ bankWd.wdBnkAccNo }}</h3></div>
                  </div>
                  <div class="table-row">
                    <div class="label"><h3>예금주</h3></div>
                    <div class="content"><h3>{{ bankWd.wdBnkHd }}</h3></div>
                  </div>
                </div>
              </div>
              <div class="table-wrapper no-bg">
                <div class="request-deposit-table">
                  <div class="table-row">
                    <div class="label large"><h3>출금가능금액</h3></div>
                    <div class="content"><InputNumberComp alignRight readonly :value="balMn" /><span class="unit">원</span></div>
                  </div>
                  <div class="table-row">
                    <div class="label large"><h3>출금신청금액</h3></div>
                    <div class="content">
                      <InputNumberComp alignRight :max="balMn" placeholder="금액입력" disableDeleteBtn v-model="withdrawForm.wdReqMn" /><span class="unit">원</span>
                    </div>
                  </div>
                  <div class="table-row button-row" style="padding-top: 35px;padding-bottom: 15px;">
                    <BtnLine class="amount-btn" text="+100만" white @click="sumWdReqMn(100_0000)" />
                    <BtnLine class="amount-btn" text="+50만" white @click="sumWdReqMn(50_0000)" />
                    <BtnLine class="amount-btn" text="+10만" white @click="sumWdReqMn(10_0000)" />
                    <BtnLine class="amount-btn" text="+1만" white @click="sumWdReqMn(1_0000)" />
                    <BtnLine class="amount-btn" text="초기화" white @click="sumWdReqMn(0)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <BtnSub text="취소" @click="close" />
              <BtnPrimary text="출금신청" @click="requestWithdraw" />
            </div>
          </div>
        </div>
      </template>
    </ModalContent>

    <wdBankPop @event:saveWdBank="searchBankWd" />
  </modal>
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import TabNav from '@/components/TabNav.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import InputComp from '@/components/InputComp.vue'
import InputNumberComp from '@/components/InputNumberComp.vue'
import BtnLine from '@/components/BtnLine.vue'
import AccountDropDown from '@/components/AccountDropDown.vue'

import WdBankPop from './WdBankPop'

import Const from '@/const'

export default {
  name: 'RequestDepositPop',
  components: {
    ModalContent,
    TabNav,
    BtnSub,
    BtnPrimary,
    InputComp,
    InputNumberComp,
    BtnLine,
    AccountDropDown,
    WdBankPop
  },
  data() {
    return {
      selectedTab: 'requestDeposit',
      tabList: [{name:"입금신청", id:"requestDeposit"},{name:"출금신청", id:"requestWithdrawal"}],
      showSearchBalMn: false,
      account: {
        accNo: '',
        accPass: ''
      },
      bankCpn: {},
      bankWd: {},
      balMn: 0,
      depositForm: {
        accId: 0,
        accPass: '',
        dpsReqMn: '',
        dpsHd: ''
      },
      withdrawForm: {
        accId: 0,
        accPass: '',
        wdReqMn: '', 
        wdBnkNm: '',
        wdBnkAccNo: '',
        wdBnkHd: '',
      },      
      accId: 0,      
    }
  },

  mounted() {
  },

  methods: {
    beforeOpen(e) {  
      this.searchBankCpn()
      this.searchBankWd()     
      
      this.balMn = 0
      this.depositForm.accId = 0
      this.depositForm.accPass = ''
      this.depositForm.dpsReqMn = ''
      this.depositForm.dpsHd = ''
      this.withdrawForm.accId = 0
      this.withdrawForm.accPass = ''
      this.withdrawForm.wdReqMn = '' 
      this.withdrawForm.wdBnkNm = ''
      this.withdrawForm.wdBnkAccNo = ''
      this.withdrawForm.wdBnkHd = ''
    },    
    opened() {
    },    
    selectAccount(account) {
      this.account = account
      this.balMn = 0
    },        
    setSelectedTab(tab) {
      this.selectedTab = tab.id

      if ( tab.id === 'requestWithdrawal' ) {
        this.showSearchBalMn = true
      } else {
        this.showSearchBalMn = false
      }
    },
    close() {
      this.$modal.hide('requestDepositPop')
    },
    sumDpsReqMn(sumValue) {
      if ( sumValue == 0 ) {
        this.depositForm.dpsReqMn = ''
      } else {
        if ( this.depositForm.dpsReqMn === '' ) {
          this.depositForm.dpsReqMn = sumValue
        } else {
          this.depositForm.dpsReqMn = parseInt(this.depositForm.dpsReqMn) + sumValue
        }
      }
    },
    sumWdReqMn(sumValue) {
      if ( sumValue == 0 ) {
        this.withdrawForm.wdReqMn = ''
      } else {
        if ( this.withdrawForm.wdReqMn === '' ) {
          this.withdrawForm.wdReqMn = sumValue
        } else {
          this.withdrawForm.wdReqMn = parseInt(this.withdrawForm.wdReqMn) + sumValue
        }        
      }
    },
    async searchBankCpn() {
      const response = await this.$lhttp.get('/api/account/bank/cpn')

      if ( response && response.data != null ) {
        this.bankCpn = response.data
      }        
    },
    async searchBankWd() {
      const response = await this.$lhttp.get('/api/account/bank/wd')

      if ( response && response.data != null ) {
        this.bankWd = response.data
      }        
    },
    async searchBalMn() {
      const response = await this.$lhttp.get('/api/account/balmn', {params:{accId: this.accId, accPass: this.account.accPass}})

      if ( response && response.data != null ) {
        this.balMn = response.data
      }        
    },
    requestDeposit() {
      const { validatorDeposit } = this.$refs

      validatorDeposit.validate().then((result) => {
        if (result) {

          if ( this.account.accDivCd === Const.ACC_DIV_TYPE.EVENT ) {
            this.$alert.alert('입금 신청 할 수 없는 계좌입니다.', '확인')
            return
          }

          this.$alert.confirm('입금 신청 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {
                
                this.depositForm.accId   = this.account.accId
                this.depositForm.accPass = this.account.accPass

                this.$lhttp
                  .post('/api/account/request/deposit',
                      this.depositForm)
                  .then(() => {
                    this.$alert.alert('입금 신청이 완료되었습니다.', '안내').then(() => {
                      this.close()
                    })
                  })

              }
            })

        } else {
          this.$alert.alert('먼저 입력 항목을 확인 하십시오.', 'Warnning')            
        }
      })
    },
    requestWithdraw() {
      if ( this.withdrawForm.wdReqMn <= 0 ) {
        this.$alert.alert('먼저 출금신청금액을 확인 하십시오.', 'Warnning')
        return
      }

      if ( this.bankWd.wdBnkAccNo === undefined || this.bankWd.wdBnkAccNo === null || this.bankWd.wdBnkAccNo === '' ) {
        this.$alert.alert('먼저 출금계좌 정보를 등록 하십시오.', 'Warnning')
        return
      }      

      if ( this.account.accDivCd === Const.ACC_DIV_TYPE.EVENT ) {
        this.$alert.alert('출금 신청 할 수 없는 계좌입니다.', '확인')
        return
      }      

      this.$alert.confirm('출금 신청 하시겠습니까?', '확인')
        .then((response) => {
          if (response) {      

            this.withdrawForm.accId      = this.account.accId
            this.withdrawForm.accPass    = this.account.accPass
            this.withdrawForm.wdBnkNm    = this.bankWd.wdBnkNm
            this.withdrawForm.wdBnkAccNo = this.bankWd.wdBnkAccNo
            this.withdrawForm.wdBnkHd    = this.bankWd.wdBnkHd

            this.$lhttp
              .post('/api/account/request/withdraw',
                  this.withdrawForm)
              .then(() => {
                this.$alert.alert('출금 신청이 완료되었습니다.', '안내').then(() => {
                  this.close()
                })
              })

          }
        })

    },
    popWdBank() {
      this.$modal.show('wdBankPop')
    }           
  },
};
</script>

<style lang="scss" scoped>
.request-deposit-container {
	@media (max-width: $mobile) {
		padding: 0 20px;
	}
}
.deposit-tab {
  margin-top: 20px;
  margin-bottom: 10px;
	width: 280px;
	@media (max-width: $mobile) {
		width: 100%;
		margin-top: 20px; 
		margin-bottom: 20px;
	}
}
.desosit-withdraw-container {
  display: flex;
  gap: 10px;
	@media (max-width: $mobile) {
		flex-direction: column;
	}
  .table-wrapper {
    flex: 1;
		@media (max-width: $mobile) {
			padding: 10px;
		}
    &.no-bg {
      background-color: transparent;
    }
  }
  .request-deposit-table {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    .table-row {
      display: flex;
      align-items: center;
      height: 40px;
			gap: 10px;
			@media (max-width: $mobile) {
				flex-wrap: wrap;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				&.button-row {
					height: unset;
				}
			}
      .amount-btn {
        /* margin-right: 5px; */
        width: 60px;
				@media (max-width: $mobile) {
					width: 65px;
				}
      }
      h3 {
        margin-right: 15px;
				@media (max-width: $mobile) {
					margin-right: 13px;
				}
        &.large {
          margin-right: 39px;
        }
      }
      .label {
        color: rgba(255, 255, 255, 0.6);
        width: 68px;
				&.large {
					width: 90px;
					color: rgba(255,255,255,1);
				}
      }
      .content {
        display: flex;
        align-items: center;
        font-weight: 600;
        flex: 1;
				&.between {
					justify-content: space-between;
				}
				.unit {
					margin-left: 10px;
				}
      }
    }
  }
}

.modal-footer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 40px;
	@media (max-width: $mobile) {
		margin-top: 60px;
		button {
			flex: 1;
			justify-content: center;
		}
	}
}
</style>
