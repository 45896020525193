<template>
  <div class="container">
    <div class="inner">
      <div class="star-checkbox">
        <input type="checkbox" :id="`layout_star_${params.data.seq}`"  v-model="checked" disabled="disabled" />
        <label :for="`layout_star_${params.data.seq}`"></label>
      </div>
      <div>
        <p>
          {{ params.data.itrstNm }} 
        </p>
        <p>
          {{ params.data.crtTm }}
        </p>
      </div>
    </div>
    <AddDeleteBtn @addDelete="deleteBtn(params)" />
  </div>
</template>

<script>
import AddDeleteBtn from '@/components/AddDeleteBtn.vue'

export default {
  name: 'AgStarCheckbox',
  components: {
    AddDeleteBtn,
  },

  data() {
    return {
      checked: false
    }
  },

  mounted() {
    if ( this.params.data.loadData === 'Y' ) {
      this.checked = true
    }
  },

  methods: {
    deleteBtn(params){
      params.clicked(params.data.seq);  
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 5px; */
  padding: 5px 15px 5px 15px;
  .inner {
    display: flex;
    align-items: center;
    gap: 16px;
    p {
      display: block;
      line-height: 1.8em;
      /* margin-left: 26px; */
      /* margin-top: -5px; */
    }
  }
}
.star-checkbox {
  display: flex;
  align-items: center;
  width: 16px;
  input[type='checkbox'] {
    display: none;
    & + label {
      position: relative;
      /* cursor: pointer; */
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-image: url('@/assets/icons/star.svg');
        background-size: 16px 16px;
        width: 16px;
        height: 16px;
      }
    }
    &:checked {
      & + label {
        &::before {
          background-image: url('@/assets/icons/star-filled.svg');
        }
      }
    }
  }
}
</style>
