<template>
  <base-btn @click="handleButton" naked>
    <img class="nav-item-icon" src="@/assets/icons/fullscreen-exit.svg" alt="" v-if="!clicked" />
    <img class="nav-item-icon" src="@/assets/icons/fullscreen.svg" alt="" v-if="clicked" />
    <h2>
      {{ text }}
    </h2>
  </base-btn>
</template>

<script>
import BaseBtn from './BaseBtn.vue'
 
export default {
  name: 'GnbMenu',
	props: {
    text: {
      type: String,
      default: 'name me',
    },
    eventName: String,
  },
	components: {
		BaseBtn
	},
  data() {
    return {
      clicked: true
    }
  },
  mounted() {},
  methods: {
    handleButton(e) {
      this.clicked = !this.clicked
      this.$emit('click', { eventName: this.eventName, clicked : this.clicked } )
    },
    setClicked(clicked) {
      this.clicked = clicked
    }
  },
}
</script>

<style lang="scss" scoped></style>
