<template>
	<div class="date-sort">
		<button>1D</button>
		<span>|</span>
		<button>20D</button>
		<span>|</span>
		<button>60D</button>
	</div>
</template>

<script>
export default {
	name: 'DateSort',

	data() {
		return {
			active: '',
		};
	},

	mounted() {
		
	},

	methods: {
		
	},
};
</script>

<style lang="scss" scoped>
	.date-sort {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 180px;
		height: 40px;
		border: 1px solid rgba(255,255,255,0.3);
		border-radius: 50vh;
		padding: 0 22px;
		@media (max-width: $mobile) {
			width: 150px;
			height: 30px;
			padding: 0 16px;
		}
		span {
			opacity: 0.3;
		}
		button {
			font-size: 16px;
			opacity: 0.6;
			padding: 0;
			margin: 0;
			&.active {
				font-weight: bold;
				opacity: 1;
			}
			@media (max-width: $mobile) {
				font-size: 14px;
			}
		}
	}
</style>