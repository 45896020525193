<template>
  <div class="base-dropdown" :style="{ width: width + 'px', height: height + 'px' }" :class="{ full: full }" ref="dropdown">
    <div class="dropdown-title" @click="handleDropdown" :class="{ active: isDropdownVisible }" :style="{ height: height + 'px' }">
      <h3>
        {{ selectedName }}
      </h3>
      <img class="triangle" :class="{ open: isDropdownVisible }" src="@/assets/icons/triangle-down.svg" alt="" />
    </div>
    <transition name="fade">
      <DropDownList v-if="isDropdownVisible" v-bind:width="width" :popup="popup">
        <div
          class="dropdown-list-item"
          v-for="year in yearList"
          :key="year"
          @click="onSelect(year)">
            <div class="col">
              <h3>{{ year }}</h3>
            </div>
        </div>
      </DropDownList>
    </transition>
  </div>
</template>

<script>
import DropDownList from '@/components/DropDownList.vue'
import Utils from '@/modules/utils'

export default {
  name: 'YearDropDown',
  components: {
    DropDownList,
  },
  props: {
    width: [String, Number],
    height: [String, Number],
    full: Boolean,
    popup: Boolean,
    allYn: {
      type: String,
      default: 'N'
    },     
    value: {
      type: String,
      default: Utils.getThisYear(),
    },
  },    

  data() {
    return {
      yearList: [],
      isDropdownVisible: false,
    }
  },

  computed: {
    selectedName() {
      let rtn = ''  

      if ( this.allYn != 'Y' && this.value === null ) {
        if (this.yearList.length > 0 ) {
          rtn = this.yearList[0]
        }
      } else {
        for (let i in this.yearList) {
          if ( this.value === this.yearList[i] ) {
            rtn = this.yearList[i]
            break
          }  
        }
      }

      return rtn 
    }
  },  

  mounted() {
    for ( let i = Utils.getThisYear(); i >= 2024; i-- ) {
      this.yearList.push((i).toString())
    }
  },

  methods: {
    handleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    onSelect(year) {
      this.$emit('input', year)
      this.$emit('event:selectYear', year)

      this.isDropdownVisible = false
    }
  },
};
</script>

<style lang="scss" scoped>
.base-dropdown {
  position: relative;
  white-space: nowrap;
  height: 30px;
  cursor: pointer;
  &.full {
    flex: 1;
    width: 100%;
    .dropdown-list {
    width: 100%;
    }
  }
  .dropdown-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    height: 100%;
    /* height: 30px; */
    border: 1px solid var(--border-color);
    &:hover {
      border-color: var(--main-orange);
    }
    &.active {
      border-color: var(--main-orange);
    }
  }
  h3 {
    font-size: 12px;
    // font-weight: 400;
    padding-bottom: 2px;
  }
  .triangle {
    margin-left: 5px;
    transition: transform 0.2s;
    &.open {
      transform: rotate(180deg);
    }
  }
  @media (max-width: $mobile) {
    height: 40px;
  }
}
</style>