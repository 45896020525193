import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  digits,
  numeric,
  email,
  confirmed,
} from 'vee-validate/dist/rules'

extend('limit-range', (value, params) => {
  const [min, max] = params;
  if ((value && value.length < min) || value.length > max) {
    return '{_field_} 항목은 '+ min +'자 ~ '+ max +'자 사이로 입력 해 주십시오.'
  }
  return true;
})

extend('limit', (value, params) => {
  const [max] = params
  if (value && value.length > max) {
    return '{_field_} 항목은 '+ max +'자를 초과 입력 할 수 없습니다.'
  }
  return true;
})
   
extend('required', {
  ...required,
  message: '{_field_} 항목은 반드시 입력해야 합니다.',
})
   
extend('required-select', {
  ...required,
  message: '{_field_} 항목은 반드시 선택해야 합니다.',
})
   
extend('numeric', {
  ...numeric,
  message: '{_field_} 항목은 숫자로만 구성되어야 합니다.',
})

extend('digits', {
  ...digits,
  message: '{_field_} 필드는 11자리 여야 합니다.',
})
  
extend('email', {
  ...email,
  message: '잘못 입력된 이메일 주소 입니다.',
})
   
// provider 에 :rules="{ confirmed: 'provider name 명을 적어주면 된다'}"
extend('confirmed', {
  ...confirmed,
  message: '비밀번호와 비밀번호확인이 일치하지 않습니다.',
})

extend('password', (value) => {
  if ( /^.*(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*#?&]).*$/.test(value) !== true ) {
    return '{_field_} 항목은 영문자,숫자,특수기호를 포함한 8자 이상이어야 합니다.'
  }
  return true;
})

extend('phonenumber', (value) => {
  if ( /^\d{3}-\d{3,4}-\d{4}$/.test(value) !== true ) {
    return '{_field_} 항목의 형식이 다릅니다. ex) 010-1111-2222'
  }
  return true;
})

extend('account', (value) => {
  if ( /^(\d{1,})(-(\d{1,})){1,}/.test(value) !== true ) {
    return '{_field_} 항목이 계좌번호 형식이 아닙니다.'
  }
  return true;
})
   
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)