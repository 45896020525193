<template>
  <div class="main-container">
    <LandingHeader />
    <div class="page-scroll">
      <div class="page-container">
        <div class="tab-container">
          <router-link to="/profile/" class="tab">
            <h3>프로파일</h3>
          </router-link>
          <router-link to="/profile/level" class="tab">
            <h3>구독레벨</h3>
          </router-link>
        </div>
        <div>
          <router-view />
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import LandingHeader from '@/components/LandingHeader.vue'
export default {
  name: 'ProfilePage',
  components: {
    LandingHeader
  },
  data() {
    return {}
  },

  mounted() {
    const xDkToken = this.$sessionStorage.get('xDkToken')

    if ( xDkToken === null || xDkToken === '' ) {
      this.$alert.alert('로그인 정보가 없습니다.', 'Warnning')
        .then(() => {
          this.$router.push('/')
        })
    }
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.main-container {
  height: 100vh;
  width: 100vw;
}
.router-link-exact-active {
  background-color: var(--main-orange);
  border-color: var(--main-orange);
}
.page-scroll {
  overflow: auto;  
  width: 100%;
  height: calc(100% - 50px);  
}
.page-container {
  max-width: 800px;
  width: 100%;
  height: 100%;  
  margin: 0 auto;
  padding-top: 40px;
}
.tab-container {
  width: 280px;
  margin-bottom: 30px;
  .tab {
    flex: 1;
  }
}
.clean-header {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #596174;
  padding: 0 20px;
  .logo {
    width: 84px;
    img {
      width: 100%;
    }
  }
  .header-title {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
