<template>
  <div class="container">
    <BtnCheckbox :id="params.node.data.checkBoxId" :value="params.node.data.checked == true ? true : false" class="ag-custom-checkbox" text="" @click="cellClicked($event, params)" />
  </div>
</template>

<script>
import BtnCheckbox from '@/components/BtnCheckbox.vue'

export default {
  name: 'AgCheckbox',
  props: {
  },
  components: {
    BtnCheckbox,
  },
  data() {
    return {};
  },

  mounted() {

  },

  methods: {
    cellClicked(val, params){
      params.node.data.checked = val;
      params.clicked(val, params);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
	width: 100%;
  /* width: 16px; */
	height: 16px;
}
.ag-custom-checkbox {
	width: 16px;
  height: 16px;
  padding-top: 10px;
  label {
    padding-left: 0 !important;
    &::before {
      /* top: 60% !important; */
      /* left: 3px !important; */
    }
  }
}
</style>
