<template>
  <div class="modal-content">
    <div class="modal-header">
      <h1>{{ text }}</h1>
      <div class="header-ui">
        <img src="@/assets/icons/error-circle.svg" alt="" v-if="info" />
        <BaseBtn @click="closeModal" naked>
          <img src="@/assets/icons/close-large.svg" alt="" />
        </BaseBtn>
      </div>
    </div>
    <div class="modal-body">
      <slot name="body"></slot>
    </div>
    <div class="modal-footer" v-if="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
// import BtnPrimary from './BtnPrimary.vue';
// import BtnSub from './BtnSub.vue';

export default {
  name: 'ModalContent',
  components: {
    BaseBtn,
    // BtnPrimary,
    // BtnSub,
  },
  props: {
    text: String,
    modalName: String,
    info: Boolean,
    footer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$modal.hide(this.modalName)
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modal-header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  .header-ui {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.modal-body {
  flex: 1 1 auto;
  
}

.modal-footer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  height: 70px;
  button {
    width: 80px;
  }
}
</style>
