<template>
  <div class="notice-page">
    <div class="panel-header">
      <div class="row">
        <div class="row-item">
          <h3 class="bold">공지사항</h3>
        </div>
      </div>
    </div>

    <ul class="notice-list">

      <li v-for="notice in noticeList" v-bind:key="notice.postNo" >
        <router-link :to="{path: '/main/board/post', query: {brdCd: 'NOTICE', postNo: notice.postNo}}">
          <NoticeItem
            :date="notice.regDt"
            :title="notice.postSj"
            :summary="notice.cont"
          />
        </router-link>
      </li>

      <li>
        <PaginationComp :paging="paging" @onPage="search" />        
      </li>
    </ul>
  </div>
</template>

<script>
import NoticeItem from '@/components/NoticeItem.vue'
import PaginationComp from '@/components/grid/PaginationComp.vue'

export default {
  name: 'NoticePage',
  components: {
    PaginationComp,
    NoticeItem
  },
  props: {
    selectNotice : {
      type: Number
    },
  },
  data() {
    return {
      paging : {
        page: 1,
        size: 5,
        total: 0,
        pageSize: 5,        
      },
      noticeList: []
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    async search(page) {

      if (page) {
        this.paging.page = page
      }

      // 조회 조건
      const params = {
        brdCd : 'NOTICE',
        page : this.paging.page,
        size : this.paging.size
      }

      const response = await this.$lhttp.get('/api/board/list/page', { params: params })

      if ( response && response.data ) {
        this.paging.total = response.data.total
        this.noticeList = response.data.content
      }         
    }        
  },
  watch:{
    selectNotice:{
      handler(){
        this.search(1)
        this.$router.go()
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.notice-page {
  padding: 10px 20px;
  @media (max-width: $mobile) {
    padding: 0 20px;
  }
}
.panel-header {
  border-bottom: 1px solid var(--border-color-dom);
  height: 40px;
  @media (max-width: $mobile) {
    display: none;
  }
}
.notice-list {
  display: flex;
  flex-direction: column;
  gap: 42px;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 130px);
  padding-bottom: 10px;
  @media (max-width: $mobile) {
    overflow-y: unset;
    height: unset;
  }
}
</style>
