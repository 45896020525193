const openWinPop = function( uri, name, width, height ) {

  let left = (screen.width) ? (screen.width - width) / 2 : 0
  let top = (screen.height) ? (screen.height - height) / 2 : 0

  let attr = 'top=' + top + ', left=' + left  + ', width=' + width + ', height=' + height + ', resizable=no,status=no'

  // 윈도우 팝업 띄우기 
  const popup = window.open(uri, name, attr)
  popup.focus()

  return popup
}

export default { 
    openWinPop,
}