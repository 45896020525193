<template>
  <div class="login-page">
    <div class="left">
      <div class="hero-title">
        <h1 class="title">Plan&Timing</h1>
        <h2 class="subtitle">The Art of Trading</h2>
      </div>
    </div>
    <div class="right">
      <div class="header">
        <BaseBtn naked @click="goBack">
          <IconChevronDown left width="24" height="24" />
        </BaseBtn>
        <h4 class="header-title">
          <b>TAP</b>
          <span>TradingAsPlan</span>
        </h4>
        <BaseBtn naked @click="close">
          <IconClose width="24" height="24" />
        </BaseBtn>
      </div>
      <div class="body">
        <div class="body-inner">
          <h3 class="body-title">이메일로 로그인</h3>
          <div class="email-login-container">
            <validation-observer ref="validator">
              <validation-provider rules="required" name="이메일" v-slot="{errors}">
                <input type="text" placeholder="이메일" class="input" :class="[errors[0] ? 'error': '']" :tabIndex="1" v-model="form.loginId" />
                <p class="login-error-message">{{errors[0]}}</p>
              </validation-provider>

              <validation-provider rules="required" name="비밀번호" v-slot="{errors}">
                <div class="password-container">
                  <input :type="fieldType" placeholder="비밀번호" class="input" :class="[errors[0] ? 'error': '']" :tabIndex="2" v-model="form.passwd" @keyup.enter="login" />
                  <BaseBtn naked class="show-pass" @click="showPassword">
                    <img src="@/assets/icons/eye.svg" alt="" v-if="!passwordEye">
                    <img src="@/assets/icons/eye-closed.svg" alt="" v-if="passwordEye">
                  </BaseBtn>
                </div>
                <p class="login-error-message">{{errors[0]}}</p>
              </validation-provider>
              

            </validation-observer>

            <div>
              <BtnCheckbox id="saveId" text="내아이디 저장" v-model="idSave" />
            </div>
            <button class="login-btn" @click="login">로그인</button>
          </div>
          <div class="signup-container">
            어카운트가 없나요?
            <router-link to="/signup" class="signup-link">Sign Up</router-link>
          </div>
          <div class="signup-container" style="padding-top: 5px;">
            비밀번호가 기억나지 않으시나요?
            <router-link to="/app-reset-passwd" class="signup-link">비밀번호 변경</router-link>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import BtnCheckbox from '@/components/BtnCheckbox.vue'
import BaseBtn from '@/components/BaseBtn.vue'
import Const from '@/const'

export default {
  name: 'LoginEmailPage',

  components: {
    IconClose,
    IconChevronDown,
    BtnCheckbox,
    BaseBtn
  },

  data() {
    return {
      fieldType: 'password',
      passwordEye: '',
      idSave: false, 
      form: {
        loginId: this.$cookies.get("tapLoginId"),
        passwd: ''
      }
    }
  },

  mounted() {},

  methods: {
    showPassword() {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password'
      this.passwordEye = !this.passwordEye
    },
    goBack () {
      this.$router.go(-1)
    },
    close () {
      this.$router.push('/')
    },    
    login() {
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {
          window.$store
            .dispatch('Login/login', this.form)
            .then((response) => {

              // 로그인 성공
              if ( response.data.code == Const.LOGIN_RESPONSE_TYPE.SUCCESS ) {

                if ( this.idSave ) {
                  this.$cookies.set("tapLoginId", this.form.loginId)
                }                

                this.$router.push('/main')

              // 로그인 실패  
              } else { 

                this.$alert.alert(response.data.message, 'Warnning')
                
              }

            })          
        }
      })
      
    }
  },
};
</script>

<style lang="scss" scoped>
.email-login-container {
  width: 100%;
  margin: 38px 0 42px 0;
}
input[type='password']:not(:placeholder-shown) {
  font-family: caption;
  font-size: 24px;
}
.input {
  width: 100%;
  padding: 20px 10px;
  font-size: 14px;
  margin-bottom: 10px;
  &.error {
    border-color: var(--main-red);
  }
}
.password-container {
  position: relative;
  .show-pass {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    right: 10px;
  }
}
.login-error-message {
  font-size: 12px;
  color: var(--main-red);
  margin-bottom: 20px;
}
</style>
