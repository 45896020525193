<template>
  <modal name="favoriteLoadPop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">
    <ModalContent text="리스트 불러오기" modalName="favoriteLoadPop">
      <template #body>
        <div class="container">
          <!-- <InputCompSearch /> -->
          <div class="table-container">
            <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-delkoit"
              alwaysShowHorizontalScroll="true"
              :columnDefs="grid.columnDefs"
              :headerHeight="30"
              :rowData="grid.rowData" 
              @row-double-clicked="selectFavorite"
            ></ag-grid-vue>
          </div>
        </div>        
      </template>
    </ModalContent>
  </modal>
</template>  

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputCompSearch from '@/components/InputCompSearch.vue'
import { AgGridVue } from 'ag-grid-vue' // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import AgStarCheckbox from '@/components/grid/AgStarCheckbox.vue'

export default {
  name: 'FavoriteLoadPop',
  components: {
    ModalContent,
    AgGridVue, // Add AG Grid Vue3 component
    AgStarCheckbox,
  },
  data() {
    return {
      grid: {
        columnDefs: [
          {
            headerName: '관심명',
            field: 'itrstNm',
            cellRendererParams: {
              enableMenu: true,
              clicked: (seq) => { // AgStarCheckbox.vue 에서 전달하는 리턴 클릭
                if (seq != null && seq > 0 ){
                  this.deleteFavorite(seq)
                }
              }
            },
            resizable: false,
            flex: 1,
            autoHeight: true,
            cellRenderer: 'AgStarCheckbox',
          },
        ],
        rowData: [],
      },
      custom : {
        itrstNm: '관심종목',
        shtNm: true,
        curPr: true,
        ydiffPr: true,
        chGrate: true,
        totQ: true,
        seq:0,
        socketSymbol: [],
      },
      form: {
        seq: 0,
      }
    }
  },

  mounted() {},

  methods: {
    beforeOpen(e) {
      this.custom = e.params.custom;
      this.grid.rowData = [];
      this.form.seq = 0;
    },    
    opened() {
      this.searchFavorite();
    },
    async searchFavorite(){
      const response = await window.$http.get('api/symbol/favorite/list', { params: {} })

      if ( response.data && response.data.length > 0 ) {
        this.grid.rowData = response.data
        this.grid.rowData.forEach(row => {
          if (row.seq == this.custom.seq){
            row.loadData = "Y"
            return
          }
        })
      }
    },
    selectFavorite(val){
      this.custom.seq = val.data.seq;
      this.custom.itrstNm = val.data.itrstNm;
      window.$store.dispatch('Layout/saveCustom', { type: 'FV', custom: this.custom});
      this.$emit('event:saveFavorite')
      this.close()
    },
    deleteFavorite(seq){
      this.$alert.confirm('관심종목을 삭제 하시겠습니까?', '확인')
      .then((response) => {
        if (response) {          
          this.form.seq = seq;

          this.$http
            .post('api/symbol/favorite/delete',
                this.form)
            .then((res) => {
              this.grid.rowData.forEach((item, index) => {
                if (item.seq == seq){
                  this.grid.rowData.splice(index,1)
                  return;
                }
              });

              //삭제 후 리스트 1번의 seq를 설정
              if (this.grid.rowData.length > 0){
                this.custom.seq = this.grid.rowData[0].seq;
              }else{
                this.custom.seq = 0;
                this.custom.socketSymbol = [];
              }
              window.$store.dispatch('Layout/saveCustom', { type: 'FV', custom: this.custom});

              this.$alert.alert('관심종목 삭제가 완료되었습니다.', '안내').then(() => {
                this.$emit('event:saveFavorite')
                this.close()
              })
            })
        }
      })   
    },
    close(){
      this.$modal.hide('favoriteLoadPop');
    },
  },
}
</script>    

<style lang="scss" scoped>
.container {
  height: calc(100% - 55px);
}
.table-container {
  margin-top: 10px;
  height: 100%;
}

@import '@/assets/scss/agGridStyles.scss';
</style>