<template>
  <modal name="layoutLoadPop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">
    <ModalContent text="레이아웃 불러오기" modalName="layoutLoadPop">
      <template #body>
        <div class="container">
          <div class="search-ui">
            <InputCompSearch v-model="gridSearch.keyword" @event:enter="search" />
            <BtnPrimary text="조회" icon="search" :width="70" @click="search" />
          </div>

          <div class="table-container">
            <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-delkoit"
              alwaysShowHorizontalScroll="true"
              :columnDefs="grid.columnDefs"
              :headerHeight="30"
              :rowData="grid.rowData" 
              @row-double-clicked="selectLayout">
            </ag-grid-vue>
          </div>
        </div>        
      </template>
    </ModalContent>
  </modal>
</template>  

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputCompSearch from '@/components/InputCompSearch.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import { AgGridVue } from 'ag-grid-vue' // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import AgLayoutStarCheckbox from '@/components/grid/AgLayoutStarCheckbox.vue'
import CustomHeaderLoadLayout from '@/components/grid/CustomHeaderLoadLayout.vue'

export default {
  name: 'LayoutLoadPop',
  components: {
    ModalContent,
    InputCompSearch,
    BtnPrimary,    
    AgGridVue, // Add AG Grid Vue3 component    
    // eslint-disable-next-line vue/no-unused-components
    AgLayoutStarCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    agColumnHeader: CustomHeaderLoadLayout        
  },
  data() {
    return {
      gridSearch: {
        keyword: '',
        order: 'DD'
      },
      grid: {
        columnDefs: [
          {
            headerName: '레이아웃네임',
            field: '레이아웃네임',
            headerComponentParams: {
              //enableMenu: true,
              order: this.order
            },
            resizable: false,
            flex: 1,
            cellRenderer: 'AgLayoutStarCheckbox', 
            cellRendererParams: { addImportant: this.addImportant, deleteLayout: this.deleteLayout },
            autoHeight: true,
            suppressMovable: true,
          },
        ],
        rowData: [], 
      }
    }
  },

  mounted() {},

  methods: {
    beforeOpen(e) {  
    },    
    opened() {
      this.search()
    },    
    async search() {
      const response = await this.$lhttp.get('/common/layout/list', { params: this.gridSearch })

      if ( response  ) {
        this.grid.rowData = response.data
      }        
    }, 
    order(order) {
      this.gridSearch.order = order

      this.search()
    },
    selectLayout(params) {
      this.$emit('event:selectLayout', params.data.laySeq)
      this.$modal.hide('layoutLoadPop')
    },
    addImportant(params, checked) {
      let imptLayYn = (checked) ? 'Y' : 'N'

      this.$lhttp
        .post('/common/layout/important',
          { laySeq: params.data.laySeq, layNm: params.data.layNm, imptLayYn : imptLayYn })
    }, 
    deleteLayout(params) {
      this.$alert.confirm('레이아웃을 삭제 하시겠습니까?', '확인')
        .then((response) => {
          if (response) {      
            this.$lhttp
              .get('/common/layout/delete',
                {params : { laySeq: params.data.laySeq }})
              .then(() => {
                this.$alert.alert('레이아웃 삭제가 완료되었습니다.', '안내').then(() => {
                  this.search()
                })
              }) 
          }
        })                          
    },     
  },
}
</script>    

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
.container {
  height: calc(100% - 55px);
}
.table-container {
  margin-top: 10px;
  height: 100%;
}
.search-ui {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ag-theme-delkoit {
  &::v-deep {
    .ag-header {
      overflow: visible;
      /* z-index: 1; */
    }
    .ag-header-viewport {
      overflow: visible;
    }
    .ag-header-row:not(.ag-header-row-column-group) {
      overflow: visible;
      z-index: 1;
    }
    .ag-header-cell {
      overflow: visible;
    }
    .ag-header-cell-comp-wrapper {
      overflow: visible;
    }
  }
}
</style>