<template>
  <span>{{ params.value }}</span>
</template>

<script>
export default {
  name: 'DownComp',

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
span {
	position: relative;
  color: var(--main-blue);
  &::before {
    content: '\25bc';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 110%;
    font-size: 6px;
  }
}
</style>
