<template>
  <div class="gnb-menu" ref="gnbMenu">
    <div class="gnb-header" @click="handleDropdown">
      <slot name="gnb-title"></slot>
      <IconChevronDown class="chevron" :class="{ open: isDropdownVisible }" v-if="chevron" />
    </div>
    <transition name="fade">
      <DropDownList v-if="isDropdownVisible" v-bind:width="width" :class="{right:right, loadLayout:loadLayout}">
        <slot name="dropdown-list" v-if="isDropdownVisible"></slot>
      </DropDownList>
    </transition>
  </div>
</template>

<script>
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import DropDownList from '@/components/DropDownList.vue'

export default {
  name: 'GnbDropDown',
  props: {
    chevron: Boolean,
    width: [String, Number],
    right: {
      type: Boolean,
      default: false
    },
    loadLayout: Boolean
  },
  components: {
    IconChevronDown,
    DropDownList,
  },
  data() {
    return {
      isDropdownVisible: false,
    };
  },
  created() {
  },
  mounted() {
    window.addEventListener('click', this.closeDropdown)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeDropdown)
  },
  methods: {
    handleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    closeDropdown(element) {
      if (!this.$refs.gnbMenu.contains(element.target)) {
        this.isDropdownVisible = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gnb-menu {
  position: relative;
  width: fit-content;
  .gnb-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }
  cursor: pointer;
  .chevron {
    /* margin-left: 5px; */
    transition: transform 0.2s;
    &.open {
      transform: rotate(180deg);
    }
  }
  .dropdown-list {
    top: calc(100% + 11px);
    &.loadLayout {
      top: calc(100% + 7px);
      background-color: #212736;
    }
  }
}
</style>
