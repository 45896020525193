import Vue from 'vue'
import VueRouter from 'vue-router'

import LandingPage from '@/pages/LandingPage.vue'
import LandingPage2 from '@/pages/LandingPage2.vue'
import LandingPage3 from '@/pages/LandingPage3.vue'

import LoginPage from '@/pages/login/LoginPage.vue'
import SignupPage from '@/pages/login/SignupPage.vue'
import LoginEmailPage from '@/pages/login/LoginEmailPage.vue'
import LoginGooglePage from '@/pages/login/LoginGooglePage.vue'
import LoginKakaoPage from '@/pages/login/LoginKakaoPage.vue'
import SignupEmailPage from '@/pages/login/SignupEmailPage.vue'
import VerifyEmailPage from '@/pages/login/VerifyEmailPage.vue'
import SignupFinalPage from '@/pages/login/SignupFinalPage.vue'
import AppResetPasswdPage from '@/pages/login/AppResetPasswdPage.vue'
import ResetPasswdPage from '@/pages/login/ResetPasswdPage.vue'

import PlanViewPage from '@/pages/PlanViewPage.vue'

import ProfilePage from '@/pages/profile/ProfilePage.vue'
import UserLevelPage from '@/pages/profile/UserLevelPage.vue'
import ProfileFormPage from '@/pages/profile/ProfileFormPage.vue'

import MainPage from '@/pages/main/MainPage.vue'
import FavoritePage from '@/pages/main/FavoritePage.vue'
import NotificationPage from '@/pages/main/NotificationPage.vue'
import DemoPage from '@/pages/main/DemoPage.vue'
import TradePage from '@/pages/main/TradePage.vue'
import NoticePage from '@/pages/main/NoticePage.vue'
import HelpPage from '@/pages/main/HelpPage.vue'
import TradePageSub1 from '@/pages/main/TradePageSub1.vue'
import TradePageSub2 from '@/pages/main/TradePageSub2.vue'
import BoardPostPage from '@/pages/main/BoardPost.vue'
import HelpPageSub1 from '@/pages/main/HelpPageSub1.vue'
import HelpPageSub2 from '@/pages/main/HelpPageSub2.vue'
import TradeExecList from '@/pages/main/TradeExecList.vue'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  
  routes: [
		{
			path: "/",
			name: "LandingPage",
			component: LandingPage,
			meta: { authRequired: false }
		},
		{
			path: "/landing2",
			name: "LandingPage2",
			component: LandingPage2
		},
		{
			path: "/landing3",
			name: "LandingPage3",
			component: LandingPage3
		},		
		{
			path: "/login",
			name: "LoginPage",
			component: LoginPage,
			meta: { authRequired: false }
		},
		{
			path: "/login-email",
			name: "LoginEmailPage",
			component: LoginEmailPage,
			meta: { authRequired: false }
		},
		{
			path: "/login-google",
			name: "LoginGooglePage",
			component: LoginGooglePage,
			meta: { authRequired: false }
		},		
		{
			path: "/login-kakao",
			name: "LoginKakaoPage",
			component: LoginKakaoPage,
			meta: { authRequired: false }
		},				
		{
			path: "/signup",
			name: "SignupPage",
			component: SignupPage,
			meta: { authRequired: false }
		},
		{
			path: "/signup-email",
			name: "SignupEmailPage",
			component: SignupEmailPage,
			meta: { authRequired: false }
		},
		{
			path: "/verify-email/:loginId",
			name: "VerifyEmailPage",
			component: VerifyEmailPage,
			meta: { authRequired: false }
		},
		{
			path: "/signup-final/:loginId/:key",
			name: "SignupFinalPage",
			component: SignupFinalPage,
			meta: { authRequired: false }
		},
		{
			path: "/app-reset-passwd",
			name: "AppResetPasswdPage",
			component: AppResetPasswdPage,
			meta: { authRequired: false }
		},
		{
			path: "/reset-passwd/:loginId/:key",
			name: "ResetPasswdPage",
			component: ResetPasswdPage,
			meta: { authRequired: false }
		},
		{
			path: "/plan",
			name: "PlanViewPage",
			component: PlanViewPage,
			meta: { authRequired: false }
		},		
		{
			path: '/profile',
			name: 'ProfilePage',
			component: ProfilePage,
			meta: { authRequired: true },
			children: [
				{
					path: '/profile/',
					component: ProfileFormPage,
					meta: { authRequired: true }
				},
				{
					path: '/profile/level',
					component: UserLevelPage,
					meta: { authRequired: true }
				}
			]
		},
		{
			path: "/main",
			name: "MainPage",
			component: MainPage,
			meta: { authRequired: false },
			children: [
				{
					path: '/main/favorite',
					name: 'favorite',
					component: FavoritePage,
					meta: { authRequired: true },
				},
				{
					path: '/main/notification',
					name: 'notification',
					component: NotificationPage,
					meta: { authRequired: true },
				},
				{
					path: '/main/demo',
					name: 'demo',
					component: DemoPage,
					meta: { authRequired: true },
				},				
				{
					path: '/main/trade',
					name: 'trade',
					component: TradePage,
					meta: { authRequired: false },
				},
				{
					path: '/main/trade/sub',
					component: TradePageSub1,
					meta: { authRequired: false },
				},
				{
					path: '/main/trade/sub2',
					component: TradePageSub2,
					meta: { authRequired: false },
				},
				{
					path: '/main/notice',
					name: 'notice',
					component: NoticePage,
					meta: { authRequired: false },
				},
				{
					path: '/main/board/post',
					component: BoardPostPage,
					meta: { authRequired: false },
				},
				{
					path: '/main/help',
					name: 'help',
					component: HelpPage,
					meta: { authRequired: false },
				},
				{
					path: '/main/help/sub',
					component: HelpPageSub1,
					meta: { authRequired: false },
				},
				{
					path: '/main/help/sub2',
					component: HelpPageSub2,
					meta: { authRequired: false },
				},
				{
					path: '/main/tradeExecList',
					name: 'tradeExecList',
					component: TradeExecList,
					meta: { authRequired: false },
					props: true
				},
			],
			props: true,
		}
  ]
})

//
/*
router.beforeEach(async (to, from, next) => {
  if ( to.meta.authRequired )  {
    // 로그인 체크
    await window.$store.dispatch('Login/isLogin')
         .then((result) => {
            if (result) {
              return next() // 페이지 이동
            } else {
              next('/')     // 메인 화면 이동
            }
          })
  }

  return next()
})
*/

export default router
