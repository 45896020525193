<template>
  <div class="container" :class="{ me: me }">
    <AvatarComp v-if="!me" :profile="profile" :name="usrNick.substring(0, 1)" :color="prfBgCol" />
    <div>
      <div class="id" v-if="!me">
        {{usrNick}}
        <BaseBtn naked class="report-btn" @click="report" v-if="repoCo < 5">
          <img src="@/assets/icons/emergency.svg" alt="">
          <h4>신고하기</h4>
        </BaseBtn>
      </div>
      <div class="chat-bubble">
        <p v-if="repoCo >= 5" style="color: #747474;">
          신고에 의해 블라인드된 게시물입니다.
        </p>
        <p v-else>
          {{ chat }}
        </p>
      </div>
    </div>
    <h4 class="chat-time">{{ time }}</h4>
  </div>
</template>

<script>
import AvatarComp from '@/components/AvatarComp.vue'
import BaseBtn from '@/components/BaseBtn.vue'

export default {
  name: 'ChatBubble',
  props: {
    me: Boolean,
    chat: String,
    time: String,
    regrId: String,
    usrNick: String,
    profile: Boolean,
    prfBgCol: String,
    chatNo: {
      type: Number,
      default: 0
    },
    repoCo: {
      type: Number,
      default: 0      
    }
  },
  components: {
    AvatarComp,
    BaseBtn,
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    report() {
      this.$emit('event:repoty', this.chatNo)
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.container {
  display: flex;
  /* align-items: flex-end; */
  gap: 5px;
  &:hover {
    cursor: default;
    .report-btn {
      visibility: visible;
      opacity: 1;
    }
  }
  &.me {
    flex-direction: row-reverse;
    .chat-bubble {
      border-top-right-radius: 0;
      border-top-left-radius: 30px;
      background-color: #459dff;
    }
  }
}
.id {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin: 2px 0 6px 0;
  color: #459dff;
}

.chat-bubble {
  position: relative;
  max-width: 252px;
  padding: 10px;
  background-color: #212736;
  border-radius: 30px;
  border-top-left-radius: 0;
  
  p {
    border-radius: 30px;
    padding: 0 10px 5px 10px;
    font-size: 12px;
  }
  @media (max-width: $mobile) {
    max-width: 220px;
  }
}
.chat-time {
  font-size: 11px;
  white-space: nowrap;
  align-self: flex-end;
  opacity: 0.6;
}
.avatar-container {
  margin-right: 5px;
}
.report-btn {
  visibility: hidden;
  opacity: 0;
  margin-left: 10px;
  h4 {
    padding-bottom: 0;
    font-weight: 400;
    margin-left: -4px;
  }
}
</style>
