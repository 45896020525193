<template>
  <modal name="searchSymbolPop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="종목 검색" modalName="searchSymbolPop">
      <template #body>
        <div class="container">
          <InputCompSearch v-model="keyword" @input="searchSymbol" />
          <div class="table-container">
            <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-delkoit"
              alwaysShowHorizontalScroll="true"
              :columnDefs="grid.columnDefs"
              :rowHeight="30"
              :rowData="grid.rowData"
              :headerHeight="0"
              @grid-ready="onGridReady"
              @row-double-clicked="setSymbol"
            ></ag-grid-vue>
          </div>
        </div>
      </template>
    </ModalContent>    
  </modal>  
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputCompSearch from '@/components/InputCompSearch.vue'
import { AgGridVue } from 'ag-grid-vue' // Vue Grid Logic

export default {
  name: 'ModalSearchSymbol',
  components: { 
    ModalContent, 
    InputCompSearch, 
    AgGridVue, // Add AG Grid Vue3 component
  },
  data() {
    return {
      grid: {
        columnDefs: [
          {
            headerName: '종목',
            field: 'full_name',
            resizable: false,
            flex: 1,
            cellStyle: {
              paddingLeft: '30px'
            },
          },
          {
            headerName: '상품명',
            field: 'description',
            resizable: false,
            flex: 1,
            cellStyle: {
              textAlign: 'center',
            }
          },
          {
            headerName: '종목코드',
            field: 'name',
            resizable: false,
            flex: 1,
            cellStyle: {
              textAlign: 'right',
              paddingRight: '30px'
            },
            headerClass:'right-aligned-header-type2'
          },
          {
            headerName: '거래소',
            field: 'exchange',
            resizable: false,
            flex: 1,
            cellStyle: {
              textAlign: 'right',
              paddingRight: '30px'
            },
            headerClass:'right-aligned-header-type2'
          },
        ],
        rowData: null,
      },
      gridApi: null,
      keyword: '',
    }
  },

  mounted() {},

  methods: {
    beforeOpen(e) {  
      if (e.params != null){
        this.grid.rowData = e.params;
      }
    },
    opened(e) {
    },
    onGridReady(params) {
      this.gridApi = params.api
    },
    setSymbol(selData){
      this.$emit('change-symbol-complete', selData.data.name);
      this.close();
    },
    searchSymbol(){
      this.gridApi.setQuickFilter(this.keyword);
    },
    close(){
      this.$modal.hide('searchSymbolPop');
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(100% - 55px);
}
.table-container {
  margin-top: 10px;
  height: 100%;
}

@import '@/assets/scss/agGridStyles.scss';
</style>
