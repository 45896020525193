<template>
  <div class="dropdown-list" :class="{ right: right, popup:popup }" :style="{ minWidth: width + 'px' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DropDownList',
  props: {
    width: String,
    right: Boolean,
    popup: Boolean
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--main-black);
  padding: 5px 0;
  border: 1px solid var(--border-color);
  /* width: fit-content; */
  min-width: 100%;
  white-space: nowrap;
  z-index: 10;
  &.right {
    left: unset;
    right: 0;
  }
  &.popup {
    background-color: #212736;
  }
  .dropdown-subtitle {
    font-size: 11px;
    padding-bottom: 3px;

    color: white;
    opacity: 0.6;
    padding: 6px 10px 7px 10px;
  }
  .dropdown-list-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    &.no-hover {
      @media (hover: hover) {
      &:hover {
        background-color: transparent;
      }
    }  
    }
    @media (hover: hover) {
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        .dropdown-sub {
          display: block;
        }
      }
    }
    .col {
      display: flex;
      align-items: center;
      height: 30px;
      gap: 5px;
      &.btn-group {
        gap: 5px;
        // display: flex;
        // align-items: center;
        margin: 10px 0;
      }
      @media (max-width: $mobile) {
        height: 40px;
      }
    }
  }
  .dropdown-list-button-container {
    padding: 10px;
  }
}
</style>

