<template>
    <div class="input-container" :class="{ full: full }" :style="{ width: width + 'px', height: height + 'px' }">
      <input
        :placeholder="placeholder"
        type="text"
        :disabled="disabled"
        :readonly="readonly"
        :class="{ error: error, readonly: readonly }"
        :style="{ textAlign: alignRight ? 'right' : '' }"
        :tabindex="tabIndex"
        v-model="inputValue"
        @input="onInput"
        @keyup.enter="handleEnter"
      />
      <div class="input-icon-right" v-if="inputValue && !readonly && !disabled && !disableDeleteBtn">
        <BtnDelete @deleteClicked="clearInput" />
      </div>
    </div>
  </template>
  
  <script>
  import BtnDelete from '@/components/BtnDelete.vue'
  export default {
    name: 'InputComp',
    components: {
      BtnDelete,
    },
    props: {
      full: Boolean,
      value: [String, Number],
      width: [String, Number],
      alignRight: Boolean,
      placeholder: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      disableDeleteBtn: Boolean,
      height: Number,
      max: Number,
      tabIndex: Number,
    },
  
    data() {
      return { 
        inputValue: '',
      };
    },
    watch: {
      value (newValue, oldValue) {
        this.inputValue = this.addComma(newValue)

        if (newValue > this.max) {
          this.inputValue = this.addComma(this.max)
          this.$emit('input', this.max)
        }      
      }
    },  
  
    mounted() {
      this.inputValue = this.addComma(this.value)
    },
  
    methods: {
      onInput: function (e) {
        let value = Number(this.removeComma(e.target.value))
        this.inputValue = this.addComma(value)

        if (value > this.max) {
          value = this.max
          this.inputValue = this.addComma(this.max)
        }

        this.$emit('input', value)
      },
      addComma(value) {
        const val = String(value).replace(/[^0-9]/g, "")
        return val.replace(/\d(?=(?:\d{3})+$)/g, '$&,')
      },
      removeComma(value) {
        return String(value).replace(/[^\d]+/g, '')
      },      
      clearInput: function () {
        this.inputValue = ''
        this.$emit('input', '')
      },
      handleEnter(e) {
        this.$emit('event:enter',e)
      }    
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .input-container {
    position: relative;
    height: 30px;
    @media (max-width: $mobile) {
      height: 40px;
    }
    &.search {
      width: 100%;
      input[type='text'] {
        padding-left: 31px;
        width: 100%;
      }
    }
    &.full {
      display: flex;
      width: 100%;
      input {
        flex: 1;
        width: unset;
      }
    }
    .input-icon-left {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }
    .input-icon-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
    input {
      background-color: transparent;
      width: 100%;
      height: 100%;
      border-radius: 0;
      &:focus {
        border-color: var(--main-orange);
      }
      &::placeholder {
        color: var(--border-color);
        text-align: left;
      }      
    }
    /* .error-message {
      position: absolute;
      top: 100%;
      width: fit-content;
      color: var(--main-red);
      background-color: #212736;
      padding: 4px;
      white-space: nowrap;
    } */
    
  }
  </style>
  