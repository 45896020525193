<template>
  <div class="checkbox" :class="{gap10: gap}">
    <input type="checkbox" :id="id" v-model="checkBoxValue" :disabled="disabled" @change="handleChecked" />
    <label :for="id">
      <h3 :class="{ bold: bold }" v-html="text" :style="{ fontSize: fontSize + 'px' }"></h3>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BtnCheckbox',
  props: {
    bold: Boolean,
    text: {
      type: String,
      default: 'name me',
    },
    value: {
      type: Boolean,
      default: false
    },
    id: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    fontSize: [String, Number],
    gap: Boolean,
  },
  data() {
    return {
      checkBoxValue: false
    }
  },
  mounted() {
    this.checkBoxValue = this.value
  },
  methods: {
    handleChecked() {
      this.$emit('input', this.checkBoxValue)
      this.$emit('click', this.checkBoxValue)
    },
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: center;
  &.gap10 {
    input[type='checkbox'] {
      & + label {
        padding-left: 26px;
      }
    }  
  }
  input[type='checkbox'] {
    display: none;
    & + label {
      display: inline-block;
      position: relative;
      padding-left: 21px;
      cursor: pointer;
      height: 16px;
      line-height: 1.24;
      
      @media (hover: hover) {
        &:hover {
          opacity: 0.7;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 51%;
        transform: translateY(-50%);
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/icons/checkbox-off.svg');
        background-size: 16px 16px;
      }
    }
    &:checked {
      & + label {
        &::before {
          background-image: url('@/assets/icons/checkbox-on.svg');
        }
      }
    }
  }
  
}
</style>
