<template>
  <div class="trade-page">
    <div class="panel-header">
      <div class="row between">
        <div class="row-item">
          <h3 class="bold">AI 매매신호</h3>
        </div>
        <div class="row-item">
          <h3>My 신호</h3>
        </div>
      </div>
    </div>
    <InputCompSearch />
    <div class="alert-category-container">
      <h3 class="subtitle">신호 카테고리</h3>
      <ul class="alert-category-list">
        <li>
          <router-link to="/main/trade/sub">
            <CategoryItem 
							title="E-mini NASDAQ"
							amount="9 신호"
							icon="candlestick"
						>
            </CategoryItem>
          </router-link>
        </li>
        <li>
          <router-link to="/main/trade/sub">
            <CategoryItem 
							title="E-mini S&P"
							amount="9 신호"
							icon="dollar"
						>
            </CategoryItem>
          </router-link>
        </li>
        <li>
          <router-link to="/main/trade/sub">
            <CategoryItem 
							title="Crude Oil"
							amount="9 신호"
							icon="oil"
						>
            </CategoryItem>
          </router-link>
        </li>
        <li>
          <router-link to="/main/trade/sub">
            <CategoryItem 
							title="Hang Seng"
							amount="9 신호"
							icon="trend"
						>
            </CategoryItem>
          </router-link>
        </li>
      </ul>
    </div>
    <PanelBoxList title="Monthly Hot 3">
      <li>
        <AlertItem text="Early Trend Catcher" symbol="NQ" price="12254" />
      </li>
      <li>
        <AlertItem text="Second Wave" symbol="CL" price="12254" />
      </li>
      <li>
        <AlertItem text="Ten Beggar" symbol="ES" price="12254" />
      </li>
    </PanelBoxList>
  </div>
</template>

<script>
import InputCompSearch from '@/components/InputCompSearch.vue';
import AlertItem from '@/components/AlertItem.vue';
import CategoryItem from '@/components/CategoryItem.vue';
import PanelBoxList from '@/components/PanelBoxList.vue';
// import H3Text from '@/components/H3Text.vue';

export default {
  name: 'TradePage',
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  components: {
    InputCompSearch,
    PanelBoxList,
    AlertItem,
    CategoryItem,
  },
};
</script>

<style lang="scss" scoped>
.trade-page {
  padding: 10px 20px;
}
.panel-header {
  margin-bottom: 10px;
}

.alert-category-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 9px;
}
.subtitle {
  opacity: 0.6;
}
.alert-category-container {
  margin-top: 22px;
  margin-bottom: 20px;
}
</style>
