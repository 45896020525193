<template>
  <modal name="wdBankPop" class="custom-modal" adaptive :minWidth="400" :minHeight="253" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="출금 계좌 변경" modalName="wdBankPop" footer>
        <template #body>
          <validation-observer ref="validator">
            <div class="row-container">
                <div class="row" style="height: 45px;">
                <div class="row-label"><h3>은행명</h3></div>
                <validation-provider rules="required|limit:15" name="은행명" v-slot="{errors}" style="width:100%;">
                  <InputComp placeholder="새 은형명을 입력해 주세요" :error="errors[0] ? true: false" v-model="form.wdBnkNm" full />
                  <div class="error-message"><h3>{{errors[0]}}</h3></div>
                </validation-provider>
                </div>
                <div class="row" style="height: 45px;">
                <div class="row-label"><h3>계좌번호</h3></div>
                <validation-provider rules="required|account|limit:50" name="계좌번호" v-slot="{errors}" style="width:100%;">
                  <InputComp placeholder="새 계좌번호를 입력해 주세요" full :error="errors[0] ? true: false" v-model="form.wdBnkAccNo" />
                  <div class="error-message"><h3>{{errors[0]}}</h3></div>
                </validation-provider>                
                </div>
                <div class="row" style="height: 45px;">
                <div class="row-label"><h3>예금주</h3></div>
                <validation-provider rules="required|limit:15" name="은행명" v-slot="{errors}" style="width:100%;">
                  <InputComp placeholder="새 예금주를 입력해 주세요" full :error="errors[0] ? true: false" v-model="form.wdBnkHd" />
                  <div class="error-message"><h3>{{errors[0]}}</h3></div>
                </validation-provider>
                </div>
            </div>
          </validation-observer>
        </template>
        <template #footer>
          <BtnSub text="취소" @click="close" />
          <BtnPrimary text="저장" @click="save" />
        </template>
      </ModalContent>
  </modal>
</template>  

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputComp from '@/components/InputComp.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import Utils from '@/modules/utils'

export default {
  name: 'WdBankPop',
  components: {
    ModalContent,
    InputComp,
    BtnSub,
    BtnPrimary,
  },
  data() {
    return {
      form: {
        wdBnkNm : '',
        wdBnkAccNo : '',
        wdBnkHd : '',
      }
    }
  },
  methods: {
    beforeOpen(e) {  
        Utils.initObject(this.form, false)
    },    
    opened() {
    },    
    close() {
      this.$modal.hide('wdBankPop')
    },    
    save() {
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {

          this.$alert.confirm('새 출금 계좌번호를 저장 하시겠습니까?', '확인')
          .then((response) => {
            if (response) {        

              this.$lhttp
                .post('/api/account/bank/wd/update', this.form)
                .then(() => {
                  this.$alert.alert('새 출금 계좌번호 저장이 완료되었습니다.', '안내').then(() => {
                    this.$emit('event:saveWdBank')
                    this.close()
                  })
                })

            }
          })

        }
      })  
    }
  }    
}
</script>