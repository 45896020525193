<template>
  <div class="panel-container">
    <div class="table-panel" :class="{ hideAccount: hideAccount }">
      <div class="row between">
        <div class="row-item">
          <div class="tab-container">
            <div class="tab active" @click="orderClear"><h3>청산</h3></div>
            <!-- <div class="tab"><h3>일반주문</h3></div> -->
          </div>
        </div>
        <div class="row-item">
          <GnbDropDown right>
          <template #gnb-title>
            <div class="panel-menu-container">
              <img src="@/assets/icons/ellipsis-menu.svg" alt="" />
              <div class="tool-tip down"><h3>열 설정</h3></div>
            </div>
          </template>
          <template #dropdown-list>

            <h4 class="dropdown-subtitle">열 설정</h4>
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="symCd" text="종목코드" v-model="grid.custom.symCd" @click="setCustom" />
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="ordSdNm" text="구분" v-model="grid.custom.ordSdNm" @click="setCustom" />
                </div>
              </div>              
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="balQty" text="잔고" v-model="grid.custom.balQty" @click="setCustom" />
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="execPrc" text="체결가" v-model="grid.custom.execPrc" @click="setCustom" />
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="nowPrice" text="현재가" v-model="grid.custom.nowPrice" @click="setCustom" />
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="valPl" text="평가손익" v-model="grid.custom.valPl" @click="setCustom" />
                </div>
              </div>
              <!-- <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="crcNm" text="통화" v-model="grid.custom.crcNm" @click="setCustom" />
                </div>
              </div> -->
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="shtNm" text="상품" v-model="grid.custom.shtNm" @click="setCustom" />
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <BtnCheckbox id="expDt" text="만기일" v-model="grid.custom.expDt" @click="setCustom" />
                </div>
              </div>
          </template>
        </GnbDropDown>
        </div>
      </div>
      <div class="table-container">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-delkoit"
          alwaysShowHorizontalScroll="true"
          :defaultColDef="grid.defaultColDef"
          :columnDefs="grid.columnDefs"
          :headerHeight="30"
          :rowHeight="30"
          :rowData="grid.rowData"
          :overlayNoRowsTemplate="grid.overlayNoRowsTemplate"
          :enableBrowserTooltips="true"
          @grid-ready="onGridReady">
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import GnbDropDown from '@/components/GnbDropDown.vue'
import BtnCheckbox from '@/components/BtnCheckbox.vue'
import AgCheckbox from '@/components/grid/AgCheckbox.vue'
import CustomHeader from '@/components/grid/CustomHeader.vue'
import { AgGridVue } from 'ag-grid-vue'
import {buySellType, cellClassRulesMinus} from '@/modules/grid-utils.js'

import EventBus from '@/modules/event-bus'
import gridUtils from '@/modules/grid-utils'
import { stringCount } from '@/modules/helper'

export default {
  name: 'PanelComp2',
  props: {
    symbolList : {
      type: Array
    },
    ovcData:{
      type: Object,
    },
    selectChgComp2: {
      type: Number
    },
    currencyInfo: {
      type: Object
    },
    account: {
      type: Object
    }
  },
  computed: {
    getUsrAccPass(){
      const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))
      if (accPassObj == undefined && !this.account.accPass){
        //저장된 계좌비번이 없으면
        return "";
      }else if (accPassObj != undefined && accPassObj[this.account.accNo] && !this.account.accPass){
        //저장된 계좌비번
        return accPassObj[this.account.accNo];
      }else if (this.account.accPass){
        //비밀번호를 직접입력
        return this.account.accPass;
      }else{
        return "";
      }
    },
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData']
      return ovcData
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList']
      return ovcData
    },
  },
  watch: {
    symbolList:{
      handler(newSymbolList) {
      }
    },
    ovcData(newData){
      //시세 데이터 받아 현재가 표시, 평가손익 계산에 사용
      this.ovcMap = newData.ovcData;
      this.setGridPrice(); //현재가, 평가손익 새로고침
    },
    selectChgComp2:{
      handler(){
        this.search();
        this.setGridPrice(); //현재가, 평가손익 새로고침
        this.orderFlag = false; // 청산버튼 활성화
      }
    },
    currencyInfo: {
      handler(){
        this.setGridPrice(); //현재가, 평가손익 새로고침
      },
      deep: true,
    },
    account: {
      handler(val){
        this.grid.rowData = [];
        if (val.accPass && val.passYn != undefined && val.passYn == "Y"){
          this.search();
        }
      },
      deep: true,
    },
    //소켓시세
    changeOvcData(newPrice){
        this.ovcMap.set(newPrice.symbol, newPrice);
        this.setGridPrice(); //현재가, 평가손익 새로고침
    },
    //폴링시세
    changeOvcDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          this.ovcMap.set(data.symbol, data);
          this.setGridPrice(); //현재가, 평가손익 새로고침
        })
      }
    },
  },
  components: {
    GnbDropDown,
    BtnCheckbox,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgCheckbox,
    // eslint-disable-next-line vue/no-unused-components
     agColumnHeader: CustomHeader,    
  },
  data() {
    return {
      hideAccount: '',
      grid : {
        columnDefs: [
          {
            headerName: '',
            headerComponentParams: {
              enableMenu: true,
              checkBoxId:'PannelComp2Header', // checkbox 이름이 안겹치도록 다르게 설정
              clicked: (chkVal) => { // customHeader.vue 에서 전달하는 리턴 클릭, 리턴값 (true, false)
                if (chkVal){
                  this.grid.rowData.forEach(data => {
                    data.checked = true;
                    this.selectList.push(data);
                  });
                }else{
                  this.selectList = [];
                  this.grid.rowData.forEach(data => {
                    data.checked = false;
                  });
                }
                this.gridApi.redrawRows();
              },
            },
            field: 'symCdCheckBox',
            resizable: false,
            flex: 0.45,
            cellRenderer: 'AgCheckbox',
            cellRendererParams: {
              clicked: (chkVal, params) => {
                document.getElementById("PannelComp2Header").checked = false; //그리드 헤더 전체 체크 해제
                if (chkVal){
                  this.selectList.push(params.node.data);
                }else{
                  this.selectList.forEach((node, index) => {
                    if (node.symCd == params.node.data.symCd){
                      this.selectList.splice(index, 1);
                      return;
                    }
                  })
                }
              },
            },
            cellStyle: {
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',              
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '종목코드',
            field: 'symCd',
            resizable: false,
            flex: 1,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '구분',
            field: 'ordSdNm',
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
            resizable: false,
            flex: 0.6,
            cellClassRules: buySellType,
          },
          {
            headerName: '잔고',
            field: 'balQty',
            resizable: false,
            flex: 0.6,
            headerClass: 'right-aligned-header',
            cellStyle: {
              textAlign: 'right',
            },
            cellRenderer : gridUtils.numberComma,
          },
          {
            headerName: '체결가',
            field: 'execPrc',
            resizable: false,
            flex: 1,
            headerClass: 'right-aligned-header',
            cellStyle: {
              textAlign: 'right',
            },
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });

                if (d.data.symNo == 2){ //항셍
                  let val = d.value;
                  if (!Number.isInteger(val)){
                    val = val.toFixed(1);
                  }
                  return val;
                }else{
                  return d.value.toFixed(this.tikDotSz(symbol));
                }
              }
            }
          },
          {
            headerName: '현재가',
            field: 'nowPrice',
            resizable: false,
            flex: 1.1,
            headerClass: 'right-aligned-header',
            cellStyle: {
              textAlign: 'right',
            },
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          {
            headerName: '평가손익',
            field: 'valPl',
            resizable: false,
            flex: 1.1,
            headerClass: 'right-aligned-header',
            cellStyle: {
              textAlign: 'right',
            },
            cellRenderer : gridUtils.numberComma,
            cellClassRules: cellClassRulesMinus,
          },
          // {
          //   headerName: '통화',
          //   field: 'crcNm',
          //   resizable: false,
          //   flex: 0.8,
          //   cellStyle: {
          //     textAlign: 'center',
          //   },
          //   headerClass: 'center-align-header',
          // },
          {
            headerName: '상품',
            field: 'shtNm',
            resizable: false,
            flex: 0.8,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '만기일',
            field: 'expDt',
            resizable: false,
            flex: 1.15,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
            valueFormatter: (d) =>{
              if (d.value){
                return d.value.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
              }else{
                return '';
              }
              
            },
          },
        ],
        rowData: [],
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 잔고 목록이 없습니다.</span>',
        autoSizeStrategy: null,
        custom : {
          symCd: true,
          ordSdNm: true,
          balQty: true,
          execPrc: true,
          nowPrice: true,
          valPl: true,
          crcNm: true,
          shtNm: true,
          expDt: true,
        },          
      },
      gridApi: null,
      gridColumnApi: null,
      isGridReady: false,
      selectList:[],
      ovcMap: new Map(),
      orderFlag: false,
    }
  },

  created() {
    // 
    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom()
    })
  },

  mounted() {
    this.loadCustom()
  },

  methods: {
    onGridReady(params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      this.isGridReady = true

      this.setGridCustomVisible();
    },
    setGridCustomVisible() {
      const grid  = this.grid
      const gridColumnApi = this.gridColumnApi

      Object.keys(grid.custom).forEach(function(key){
        gridColumnApi.setColumnVisible(key, grid.custom[key])
      })              
    },
    loadCustom() {
      const custom = window.$store.getters['Layout/getRtPosGridCustom']

      if ( custom !== undefined && custom !== null ) {
        this.grid.custom = custom
      }      

      if ( this.isGridReady ) {
        this.setGridCustomVisible()
      }      
    },
    setCustom() {
      window.$store.dispatch('Layout/saveCustom', { type: 'PS', custom: this.grid.custom })
      this.loadCustom()
    },
    orderClear() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }

      if (this.selectList.length == 0){
        this.$alert.alert("선택한 주문이 없습니다.", '알림');
        return;
      }

      if (this.orderFlag){
        return;
      }

      this.$alert.confirm('선택한 보유잔고를 청산하시겠습니까?', '청산확인')
        .then((response) => {
          if (response) { 
            this.orderFlag = true;
            const dataParam = {selectList: this.selectList, apiCode:"OMS0014"};
            //this.$emit("sendOrder", dataParam);

            const order = {
              accId : this.account.accId,
              trdDt : '',
              accPass : this.getUsrAccPass,
              symbolList : this.selectList,
            }
            this.sendOmsMessage('OMS0014', order)
          }
        })

      //1.5초후 버튼 누를 수 있게 설정
      if (this.orderFlag){
        setTimeout(() => {
          this.orderFlag = false
        }, 1500)
      }
    },
    sendOmsMessage(apiCode, body) {
      if ( !window.$store.getters['SocketOms/getIsConnectOms'] ) {
        window.$alert.alert('OMS 서버 연결 종료.')
        return
      }

      //비밀번호 추가
      body.accPass = this.getUsrAccPass;

      window.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body})
    },  
    //종목별 잔고 조회
    async search() {
      if (this.grid.rowData.length == 0){
        //this.gridApi.showLoadingOverlay()
        document.getElementById("PannelComp2Header").checked = false; //그리드 헤더 전체 체크 해제
      }     

      const response = await this.$http.get('/api/order/rt-pos/list', { params: {accId: this.account.accId} })
      this.grid.rowData = [];
      this.selectList = [];
      if ( response && response.data ) {
        response.data.forEach((data) => {
          if (data.balQty > 0){
            data.checkBoxId = "comp2_"+ data.symCd;
            this.grid.rowData.push(data);
          }
        });

        // if (this.grid.rowData.length > 0){
        //   let chkSymCnt = 0;
        //   response.data.forEach(item => {
        //     chkSymCnt=0;
        //     this.grid.rowData.forEach((data, index) => {
        //       if (data.symCd == item.symCd){
        //         chkSymCnt++;
        //         if (item.balQty == 0){
        //           this.grid.rowData.splice(index,1);
        //           this.selectList.forEach((selData, selIndex) => {
        //             if (selData.symCd == data.symCd){
        //               this.selectList.splice(selIndex, 1);
        //             }
        //           });                  
        //         }else{
        //           data.balQty = item.balQty;
        //           data.ordSdNm = item.ordSdNm;
        //           data.execPrc = item.execPrc;
        //           data.expDt = item.expDt;
        //         }
        //         return;
        //       }
        //     });

        //     //없는 종목 추가
        //     if (chkSymCnt == 0 && item.balQty > 0){
        //       this.grid.rowData.push(item);
        //     }
        //   });

        //   if (this.selectList.length == 0){
        //     document.getElementById("PannelComp2Header").checked = false; //그리드 헤더 전체 체크 해제
        //   }
        // }else{
        //   let list = [];
        //   response.data.forEach(item => {
        //     if (item.balQty > 0){
        //       list.push(item);
        //     }
        //   });
        //   this.grid.rowData = list;
        // }
        this.gridApi.refreshCells(); //현재가 보이도록 셀 새로고침
        this.setGridPrice();
        
        this.$emit("updateSubscribeOnPrice", this.grid.rowData);
      }else{
        //잔고 목록이 없으면 평가손익 초기화
        this.setGridPrice();
        this.selectList = [];
      }
    },
    tikVal(symbol){
      if (symbol == null){
        return 0;
      }
      return (symbol.minmov / symbol.pricescale);
    },
    tikDotSz(symbol){
      if (symbol == null){
        return 0;
      }
      return stringCount(symbol.pricescale);
    },
    setGridPrice(){
      if (this.grid.rowData != null && this.grid.rowData.length > 0){
        let totalValPl = 0;
        const usdExr = this.currencyInfo.usdExR;      
        const hkdExr = this.currencyInfo.hkdExR;
        const usdExHkd = this.currencyInfo.usdExHkd;
        this.grid.rowData.forEach(data => {
          const ovcData = this.ovcMap.get(data.symCd);
          let symbol = null;
            this.symbolList.forEach(item => {
              if (item.name == data.symCd){
                symbol = item;
                return;
              }
            });

          if (ovcData != undefined){
            //현재가
            data.nowPrice = Number(ovcData.curPr).toFixed(this.tikDotSz(symbol));


            //평가손익 계산
            let val1 = 0;
            let val2 = 0;
            let valProLoss = 0;
            if (ovcData.curPr == 0 || data.ordSdCd == 0){
              valProLoss = 0;
            }else{
              //홍콩달러의 경우 달러로 변경 후 합산이 필요

              if (data.ordSdCd == "1"){ //매도
                val1 = data.execPrc *  data.balQty;
                val2 = ovcData.curPr * data.balQty;
              }else if (data.ordSdCd == "2"){ //매수
                val1 = ovcData.curPr * data.balQty;
                val2 = data.execPrc * data.balQty;
              }

              //달러로 계산
              if (data.crcCd == "2"){ //홍콩달러
                valProLoss = ((val1 - val2) * data.trdUnt * this.currencyInfo.usdExHkd);
              //   valProLoss = ((val1 - val2).toFixed(1) / this.tikVal(symbol)) * data.trdUnt; //홍콩달러 계산
              //   valProLoss = (valProLoss * usdExHkd);                
              //   //valProLoss = Math.ceil(valProLoss * hkdExr) / usdExr; //홍콩달러 달러로 변환
              // }else if (data.crcCd =="10"){ //코스피??, 설정 추가되면 수정필요
              //   valProLoss = (val1 - val2).toFixed(this.tikDotSz(symbol)) / this.currencyInfo.usdExR; //원화를 달러계산
              }else{
                valProLoss = ((val1 - val2) / this.tikVal(symbol)) * data.trdUnt; //달러로 계산
              }
              
              if (isNaN(valProLoss)) valProLoss = 0;

              //원화로 표시
              if (this.currencyInfo.currency == "WON"){
                //원화는 통화를 원화로 변경
                //data.crcNm = "WON";

                // if (data.crcCd == "2"){ //홍콩달러
                //   let val = ((val1 - val2).toFixed(1) / this.tikVal(symbol)) * data.trdUnt; //홍콩달러 계산
                //   data.valPl = Math.ceil(val * hkdExr); //원화로 변경
                //   valProLoss = data.valPl;
                // }else if (data.crcCd =="10"){ //코스피??, 설정 추가되면 수정필요
                //   let val = ((val1 - val2));
                //   data.valPl = Math.ceil(val);
                //   valProLoss = val;
                // }else{
                  //달러 > 원화로 변경
                  valProLoss = Math.ceil(valProLoss * usdExr);
                  data.valPl = valProLoss;
                // }
                
              }else{ //달러
                data.valPl = valProLoss.toFixed(2);
                data.crcNm = data.orgCrcNm;                
              }

              totalValPl += valProLoss;
            }
            return;
          }
        });
        this.gridApi.refreshCells(); //현재가 보이도록 셀 새로고침
        this.$emit("updateTotalValPl", totalValPl);
      }else{
        this.$emit("updateTotalValPl", 0);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-container {
  padding: 0 20px;
}
.table-container {
  width: 100%;
  height: 150px;
  margin-top: 10px;
}

@import '@/assets/scss/agGridStyles.scss';
</style>
