<template>
  <modal name="reportPop" class="custom-modal" adaptive :minWidth="400" :minHeight="216" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">
    <ModalContent text="신고" modalName="reportPop" footer>
      <template #body>
        <div class="container">
          <h3>신고 사유를 선택 해 주십시오.</h3>
          <codeRadio grpCd="RREASON" v-model="form.repoRsnCd" />
        </div>          
      </template>
      <template #footer>
        <BtnSub text="취소" @click="close" />
        <BtnPrimary text="신고" @click="save" />
      </template>
    </ModalContent>
  </modal>
</template>  

<script>
import ModalContent from '@/components/ModalContent.vue'
import CodeRadio from '@/components/CodeRadio.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'

export default {
  name: 'reportPop',
  components: {
    ModalContent,
    CodeRadio,
    BtnSub,
    BtnPrimary,
  },

  data() {
    return {
      form: {
        chatNo: 0,
        repoRsnCd: ''
      }
    }
  },

  mounted() {},

  methods: {
    beforeOpen(e) {  
      this.form.chatNo    = e.params.chatNo
      this.form.repoRsnCd = ''
    },    
    opened(e) {
    },  
    close() {
      this.$modal.hide('reportPop')
    },
    save() {
      if ( this.form.repoRsnCd === '' ) {
        this.$alert.alert('먼저 신고사유를 선택 하십시오.', 'Warnning')
        return
      }

      this.$alert.confirm('신고 하시겠습니까?', '확인')
        .then((response) => {
          if (response) {
            this.$http
              .post('/api/chat/report/insert',
                  this.form)
              .then(() => {
                  this.$alert.alert('신고가 완료되었습니다.', '안내').then(() => {
                    this.$emit('event:insertReport')
                    this.close()
                  })
              })
          }
        })      

    },      
  },
};
</script>    

<style lang="scss" scoped>
.container {
  /* height: 100%; */
  margin-bottom: 20px;
}
h3 {
  opacity: 0.6;
  margin-bottom: 10px;
}
</style>