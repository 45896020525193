<template>
  <modal name="resetPasswdPop" class="custom-modal" adaptive :minWidth="550" :minHeight="350" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">
    <ModalContent text="비밀번호 변경" modalName="resetPasswdPop" footer>
    <template #body>
      <div class="container">
        <validation-observer ref="validator" class="form">
          <h3 class="required-label">필수 입력 항목입니다</h3>
          <div class="form-row">
            <div class="form-row-label require">
              <h3>비밀번호</h3>
            </div>
            <div class="form-input">
              <validation-provider rules="required" name="비밀번호" v-slot="{errors}">
                <InputComp type="password" placeholder="비밀번호" full :error="errors[0] ? true: false" :tabIndex="1" v-model="form.passwd" />
                <p class="profile-error-message">{{errors[0]}}</p>
                <!-- <InputCompPassword full /> -->
                <!-- <BtnSub text="바꾸기" /> -->                    
              </validation-provider>
            </div>
          </div>
          <div class="form-row">
            <div class="form-row-label require">
              <h3>새비밀번호</h3>
            </div>
            <div class="form-input">
              <validation-provider rules="required|password" name="새비밀번호" v-slot="{errors}">
                <InputComp type="password" placeholder="새비밀번호" full :error="errors[0] ? true: false" :tabIndex="2" v-model="form.changePasswd" />
                <p class="profile-error-message">{{errors[0]}}</p>
                <!-- <InputCompPassword full /> -->
                <!-- <BtnSub text="바꾸기" /> -->
              </validation-provider>  
            </div>
          </div>
          <div class="form-row">
            <div class="form-row-label require">
              <h3>새비밀번호 확인</h3>
            </div>
            <div class="form-input">
              <validation-provider rules="required|password" name="새비밀번호 확인" v-slot="{errors}">
                <InputComp type="password" placeholder="새비밀번호 확인" full :error="errors[0] ? true: false" :tabIndex="3" v-model="form.confPasswd" @event:enter="save" />
                <p class="profile-error-message">{{errors[0]}}</p>
                <!-- <InputCompPassword full /> -->
                <!-- <BtnSub text="바꾸기" /> -->
              </validation-provider>
            </div>
          </div>
        </validation-observer>          
      </div>  
    </template>
    <template #footer>
        <BtnSub text="취소" @click="close" />
        <BtnPrimary text="저장" @click="save" />
    </template>
    </ModalContent>
</modal>
</template>  

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputComp from '@/components/InputComp.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import Const from '@/const'

export default {
  name: 'ResetPasswdPop',
  components: {
    ModalContent,
    InputComp,
    BtnSub,
    BtnPrimary,    
  },
  data() {
    return {
      form: {
        passwd: '',
        changePasswd: '',
        confPasswd: '',
      }
    }
  },

  mounted() {},

  methods: {
    beforeOpen(e) {  
    },    
    opened() {
    },  
    close() {
      this.$modal.hide('resetPasswdPop')
    },
    save() {
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {

          if ( this.form.changePasswd !== this.form.confPasswd ) {
            this.$alert.alert('새비밀번호와 새비밀번호 확인이 일치하지 않습니다.', 'Warnning')  
            return
          }

          this.$alert.confirm('비밀번호를 변경 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {      

                this.$lhttp
                  .post('/auth/profile/passwd/reset',
                    this.form)
                  .then((response) => {
                    // 성공
                    if ( response.data.code == Const.PROFILE_RESPONSE_TYPE.SUCCESS ) {
                      this.$alert.alert('비밀번호 변경에 성공하였습니다.', '안내')
                      .then(() => {
                        this.close()
                      })
                    } else {
                      this.$alert.alert(response.data.message, 'Warnning')
                    }

                  })

              }
            })

        }
      })

    }      
  },
}
</script>    

<style lang="scss" scoped>
.container {
  /* height: 100%; */
  margin-bottom: 20px;
}
h3 {
  opacity: 0.6;
  margin-bottom: 10px;
}
.required-label {
  position: relative;
  padding-left: 10px;
  color: var(--main-orange);
  margin-left: auto;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 5px;
    left: 0;
    width: 4px;
    height: 4px;
    border-radius: 50vh;
    background-color: var(--main-orange);
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: $mobile) {
    padding: 0 20px;
    margin-top: 10px;
  }
}
.form-row {
  display: flex;
  align-items: flex-start;
  @media (max-width: $mobile) {
    display: block;
  }
  .form-row-label {
    margin-top: 10px;
    width: 120px;
    @media (max-width: $mobile) {
      margin-bottom: 10px;
    }
    h3 {
      color: rgba(255,255,255,0.6);
    }
    &.require {
      position: relative;
      h3 {
        width: fit-content;
        &::after {
          content: '';
          position: absolute;
          top: 30%;
          transform: translateY(-50%);
          margin-left: 5px;
          width: 4px;
          height: 4px;
          border-radius: 50vh;
          background-color: var(--main-orange);
          opacity: 1;
        }
      }
    }
  }
  .form-input {
    flex: 1;
    .inner {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      .input-container {
        flex: 1;
      }
    }
  }
}
.profile-error-message {
  font-size: 12px;
  color: var(--main-red);
  padding-top: 5px;
}
</style>