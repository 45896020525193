<template>
  <div class="dom-grid-container" >
    <div class="dom-grid" >
      <!-- header -->
      <div class="item header">건수</div>
      <div class="item header">잔량</div>
      <div class="item header"></div>
      <div class="item header">잔량</div>
      <div class="item header">건수</div>

      <div class="mini-chart" ref="miniChart" style="width: 253px;height:118px;"></div>

      <!-- row -->
      <div class="item sell level2">{{ Number(ovhData.offerNo5).toLocaleString()}}</div>
      <div class="item sell level2">{{ Number(ovhData.offerRem5).toLocaleString() }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.offerHo5, buyColor: nowPrice != ovhData.offerHo5}">{{ ovhData.offerHo5.toFixed(tikDotSz)}}</div>
      <!-- <div class="item buy none"></div>
          <div class="item buy none"></div> -->

      <div class="item sell level2">{{ Number(ovhData.offerNo4).toLocaleString() }}</div>
      <div class="item sell level2">{{ Number(ovhData.offerRem4).toLocaleString() }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.offerHo4, buyColor: nowPrice != ovhData.offerHo4}">{{ ovhData.offerHo4.toFixed(tikDotSz)}}</div>
      <!-- <div class="item buy none"></div>
          <div class="item buy none"></div> -->

      <div class="item sell level2">{{ Number(ovhData.offerNo3).toLocaleString() }}</div>
      <div class="item sell level2">{{ Number(ovhData.offerRem3).toLocaleString() }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.offerHo3, buyColor: nowPrice != ovhData.offerHo3}">{{ ovhData.offerHo3.toFixed(tikDotSz)}}</div>
      <!-- <div class="item buy none"></div>
          <div class="item buy none"></div> -->

      <div class="item sell level2">{{ Number(ovhData.offerNo2).toLocaleString() }}</div>
      <div class="item sell level2">{{ Number(ovhData.offerRem2).toLocaleString() }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.offerHo2, buyColor: nowPrice != ovhData.offerHo2}">{{ ovhData.offerHo2.toFixed(tikDotSz)}}</div>
      <!-- <div class="item buy none"></div>
          <div class="item buy none"></div> -->

      <div class="item sell level2">{{ Number(ovhData.offerNo1).toLocaleString() }}</div>
      <div class="item sell level2">{{ Number(ovhData.offerRem1).toLocaleString() }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.offerHo1, buyColor: nowPrice != ovhData.offerHo1}">{{ ovhData.offerHo1.toFixed(tikDotSz)}}</div>
      <!-- <div class="item buy none"></div>
          <div class="item buy none"></div> -->

      <!-- 매수 -->
      <div class="item dom-info"><span class="yesPrice">시</span></div>
      <div class="item dom-info-price">{{ yesterdayClosingPrice.toFixed(tikDotSz)  }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.bidHo1, sellColor: nowPrice != ovhData.bidHo1}">{{ ovhData.bidHo1.toFixed(tikDotSz)}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidRem1).toLocaleString()}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidNo1).toLocaleString()}}</div>

      <div class="item dom-info"><span class="highPrice">고</span></div>
      <div class="item dom-info-price">{{ highPrice.toFixed(tikDotSz) }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.bidHo2, sellColor: nowPrice != ovhData.bidHo2}">{{ ovhData.bidHo2.toFixed(tikDotSz)}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidRem2).toLocaleString()}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidNo2).toLocaleString()}}</div>

      <div class="item dom-info"><span class="lowPrice">저</span></div>
      <div class="item dom-info-price">{{ lowPrice.toFixed(tikDotSz) }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.bidHo3, sellColor: nowPrice != ovhData.bidHo3}">{{ ovhData.bidHo3.toFixed(tikDotSz)}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidRem3).toLocaleString()}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidNo3).toLocaleString()}}</div>

      <div class="item dom-info">체결시간</div>
      <div class="item dom-info-price">{{ execTime }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.bidHo4, sellColor: nowPrice != ovhData.bidHo4}">{{ ovhData.bidHo4.toFixed(tikDotSz)}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidRem4).toLocaleString()}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidNo4).toLocaleString()}}</div>

      <div class="item dom-info last">체결량</div>
      <div class="item dom-info-price last">{{ Number(execCnt).toLocaleString() }}</div>
      <div class="item" :class="{currentPrice: nowPrice == ovhData.bidHo5, sellColor: nowPrice != ovhData.bidHo5}">{{ ovhData.bidHo5.toFixed(tikDotSz)}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidRem5).toLocaleString()}}</div>
      <div class="item buy level2">{{ Number(ovhData.bidNo5).toLocaleString()}}</div>

      <!-- footer -->

      <div class="item footer">{{ Number(ovhData.offerNo5 + ovhData.offerNo4 + ovhData.offerNo3 + ovhData.offerNo2 + ovhData.offerNo1).toLocaleString()}}</div>
      <div class="item footer">{{ Number(ovhData.offerRem5 + ovhData.offerRem4 + ovhData.offerRem3 + ovhData.offerRem2 + ovhData.offerRem1).toLocaleString()}}</div>
      <div class="item footer total" :class="{sellColor: Number((ovhData.offerRem5 + ovhData.offerRem4 + ovhData.offerRem3 + ovhData.offerRem2 + ovhData.offerRem1) 
      - (ovhData.bidRem5 + ovhData.bidRem4 + ovhData.bidRem3 + ovhData.bidRem2 + ovhData.bidRem1)
      ) < 0, buyColor: Number((ovhData.offerRem5 + ovhData.offerRem4 + ovhData.offerRem3 + ovhData.offerRem2 + ovhData.offerRem1) 
      - (ovhData.bidRem5 + ovhData.bidRem4 + ovhData.bidRem3 + ovhData.bidRem2 + ovhData.bidRem1)
      ) > 0, zeroColor: Number((ovhData.offerRem5 + ovhData.offerRem4 + ovhData.offerRem3 + ovhData.offerRem2 + ovhData.offerRem1) 
      - (ovhData.bidRem5 + ovhData.bidRem4 + ovhData.bidRem3 + ovhData.bidRem2 + ovhData.bidRem1)
      ) == 0}">{{ 
      Number((ovhData.offerRem5 + ovhData.offerRem4 + ovhData.offerRem3 + ovhData.offerRem2 + ovhData.offerRem1) 
      - (ovhData.bidRem5 + ovhData.bidRem4 + ovhData.bidRem3 + ovhData.bidRem2 + ovhData.bidRem1)
      ).toLocaleString()}}
      </div>
      <div class="item footer">{{ Number(ovhData.bidRem5 + ovhData.bidRem4 + ovhData.bidRem3 + ovhData.bidRem2 + ovhData.bidRem1).toLocaleString()}}</div>
      <div class="item footer">{{ Number(ovhData.bidNo5 + ovhData.bidNo4 + ovhData.bidNo3 + ovhData.bidNo2 + ovhData.bidNo1).toLocaleString()}}</div>
    </div>
    <div>
      <div class="order-standard-container">
      <OrderStandard sell :nowPrice="nowPrice" :symbol="symbol" name="sellType" :id="['ordPrc1', 'market1']" @click="orderChk" />
      <OrderStandard buy :nowPrice="nowPrice" :symbol="symbol"  name="buyType" :id="['ordPrc2', 'market2']" @click="orderChk" />
    </div>
    <div class="dom-order-option">
      <div class="row gap10">
        <div class="row-item">
          <ul class="balance-table">
            <li>
              <div class="label"><h3>매도가능</h3></div>
              <div class="balance blue"><h3>{{ sellAbleQty }}</h3></div>
            </li>
            <li>
              <div class="label"><h3>매수가능</h3></div>
              <div class="balance red"><h3>{{ buyAbleQty }}</h3></div>
            </li>
          </ul>
        </div>
        <!-- <div class="row-item">
          <h3>주문수량</h3>
          <div class="row-item-content">
            <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickOrd" v-bind:value="ordClick" />
            <BtnLine :text="clickSetList[0]" white :value="clickSetList[0]" @click="setOrdClick" />
            <BtnLine :text="clickSetList[1]" white :value="clickSetList[1]" @click="setOrdClick" />
            <BtnLine :text="clickSetList[2]" white :value="clickSetList[2]" @click="setOrdClick" />
            <BtnLine :text="clickSetList[3]" white :value="clickSetList[3]" @click="setOrdClick" />
            <BtnLine :text="clickSetList[4]" white :value="clickSetList[4]" @click="setOrdClick" />
          </div>
        </div> -->
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import OrderStandard from './OrderStandard.vue';
import BtnLine from './BtnLine.vue';
import CounterComp from '@/components/CounterComp.vue'

import Utils from '@/modules/utils'
import { stringCount } from '@/modules/helper'
import * as echarts from 'echarts';
import EventBus from '@/modules/event-bus'

export default {
  name: 'DomGridStandard',
  props: {
    symbol: {
      type: Object,
      default: () => {
        return { name: '', minmov : 0.1, pricescale : 100 }
      },
    },
    rStock: {
      type: Object
    },
    stockPrice: {
      type: Object
    },
    slStockVal1: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    slStockVal2: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    sellSlTick: {
      type : Number,
      default: () => {
        return 1;
      }
    },
    buySlTick: {
      type : Number,
      default: () => {
        return 1;
      }
    },
    // setSlStockVal: {
    //   type: Object,
    // },
    sendOrderData: {
      type: Object
    },
    sellAbleQty: {
      type: Number
    },
    buyAbleQty: {
      type: Number
    },
    account: {
      type: Object
    },

  },
  watch: {
    symbol:{
      handler(newSymbol) {
        this.symbolTik = newSymbol.minmov/newSymbol.pricescale,
        this.reSetData();
        this.getServerTime();
      }
    },
    account: {
      handler(){

      }
    },
    rStock: {
      handler(newRStock) {
        //초기 시세? 정보 설정
        if ( newRStock != null ) {
          if (newRStock.data.curPr != undefined)
            this.nowPrice  = newRStock.data.curPr;
          if (newRStock.data.high != undefined)
            this.highPrice = newRStock.data.high
          if (newRStock.data.low != undefined)
            this.lowPrice  = newRStock.data.low
          if (newRStock.data.open != undefined)
            this.yesterdayClosingPrice = newRStock.data.open

          this.ovhData = newRStock.data;
          //현재가에서 위아래 5개씩 시세 생성
          // if (this.lowPrice && this.highPrice && this.nowPrice){
          //   this.ovhData.offerHo5 = this.lowPrice + (this.tikVal * 4);
          //   this.ovhData.offerHo4 = this.lowPrice + (this.tikVal * 3);
          //   this.ovhData.offerHo3 = this.lowPrice + (this.tikVal * 2);
          //   this.ovhData.offerHo2 = this.lowPrice + (this.tikVal * 1);
          //   this.ovhData.offerHo1 = this.lowPrice;
          //   this.ovhData.bidHo5 = this.lowPrice - (this.tikVal * 1);
          //   this.ovhData.bidHo4 = this.lowPrice - (this.tikVal * 2);
          //   this.ovhData.bidHo3 = this.lowPrice - (this.tikVal * 3);
          //   this.ovhData.bidHo2 = this.lowPrice - (this.tikVal * 4);
          //   this.ovhData.bidHo1 = this.lowPrice - (this.tikVal * 5);
          // }

          //차트 색상설정
          if (newRStock.chGrate > 0.0){
            this.chartOption.color = "#ff0000";
          }else{
            this.chartOption.color = "#00b7ff";
          }
        }
      }
    },
    resultOms(res){
      if (res.result == "S"){
        this.completeOrder(res);
      }else{
        this.procFailMessage(res);
      }
      
      // 알림 재조회
      EventBus.$emit('Alarm:event:refresh', {})
    },
    //소켓시세
    changeOvcData(newPrice){
      //console.log("newPrice", newPrice)
      if (newPrice.symbol == this.symbol.name) {
          //시세
          this.nowPrice  = Number(newPrice.curPr)
          this.highPrice = newPrice.high
          this.lowPrice  = newPrice.low
          this.yesterdayClosingPrice  = newPrice.open

          //체결시간
          if (newPrice.korTm != null){
            let korTm = newPrice.korTm.replace(/\B(?=(\d{2})+(?!\d))/g, ":");
            this.execTime = korTm;
          }

          //체결량 trDq
          if (newPrice.trDq != null){
            this.execCnt = newPrice.trDq;
          }

          const prevTime = this.chartTimeList[this.chartTimeList.length-1];
          const curTime = newPrice.korTm.substring(0, 4);
          if (Number(curTime) > Number(prevTime)){ //1분이 지났을경우
            //0번 데이터 삭제
            this.chartList.shift();
            this.chartTimeList.shift();

            //마지막 데이터 추가
            this.chartList.push(Number(newPrice.curPr).toFixed(this.tikDotSz));
            this.chartTimeList.push(newPrice.korTm.substring(0,4));
          }else{
            //동일시간이면 30번 배열데이터 갱신
            this.chartList[this.chartList.length-1] = Number(newPrice.curPr).toFixed(this.tikDotSz);
            this.chartTimeList[this.chartTimeList.length-1] = newPrice.korTm.substring(0,4);
          }

          //등락율에 따른 색상 변경
          if (newPrice.chGrate > 0.0){
            this.chartOption.color = "#ff0000";
          }else{
            this.chartOption.color = "#00b7ff";
          }

          let min = 0;
          let max = 0;
          for (let i=0; i<this.chartList.length; i++){
            if (i == 0){
              min = this.chartList[i];
              max = this.chartList[i];
            }else{
              if (min > this.chartList[i]){
                min = this.chartList[i];
              }
              if (max < this.chartList[i]){
                max = this.chartList[i];
              }
            }
          }
          this.chartOption.yAxis.min = min;
          this.chartOption.yAxis.max = max;

          //미니 차트 갱신
          this.chart.setOption(this.chartOption);
          this.chart.setOption({
            series: [
              {
                data: this.chartList
              }
            ],
            xAxis: {
              data: this.chartTimeList
            }
          });
        }
    },
    //소켓호가
    changeOvhData(newPrice){
      if (newPrice.symbol == this.symbol.name){
        this.ovhData = newPrice;
      }
    },
    //폴링시세
    changeOvcDataList(newPrice){
      
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (data.symbol == this.symbol.name){
            if (data.symbol == this.symbol.name) {
              //시세
              this.nowPrice  = data.curPr
              this.highPrice = data.high
              this.lowPrice  = data.low
              this.yesterdayClosingPrice  = data.open

              //체결시간
              if (data.korTm){
                let korTm = data.korTm.replace(/\B(?=(\d{2})+(?!\d))/g, ":");
                this.execTime = korTm;
              }

              //체결량 trDq
              if (data.trDq){
                this.execCnt = newPrice.trDq;
              }

              if (data.korTm){
                const prevTime = this.chartTimeList[this.chartTimeList.length-1];
                const curTime = data.korTm.substring(0, 4);
                if (Number(curTime) > Number(prevTime)){ //1분이 지났을경우
                  //0번 데이터 삭제
                  this.chartList.shift();
                  this.chartTimeList.shift();

                  //마지막 데이터 추가
                  this.chartList.push(Number(data.curPr).toFixed(this.tikDotSz));
                  this.chartTimeList.push(data.korTm.substring(0,4));
                }else{
                  //동일시간이면 30번 배열데이터 갱신
                  this.chartList[this.chartList.length-1] = Number(data.curPr).toFixed(this.tikDotSz);
                  this.chartTimeList[this.chartTimeList.length-1] = data.korTm.substring(0,4);
                }
              }

              //등락율에 따른 색상 변경
              if (data.chGrate){
                if (data.chGrate > 0.0){
                  this.chartOption.color = "#ff0000";
                }else{
                  this.chartOption.color = "#00b7ff";
                }
              }

              let min = 0;
              let max = 0;
              for (let i=0; i<this.chartList.length; i++){
                if (i == 0){
                  min = this.chartList[i];
                  max = this.chartList[i];
                }else{
                  if (min > this.chartList[i]){
                    min = this.chartList[i];
                  }
                  if (max < this.chartList[i]){
                    max = this.chartList[i];
                  }
                }
              }
              this.chartOption.yAxis.min = min;
              this.chartOption.yAxis.max = max;

              //미니 차트 갱신
              this.chart.setOption(this.chartOption);
              this.chart.setOption({
                series: [
                  {
                    data: this.chartList
                  }
                ],
                xAxis: {
                  data: this.chartTimeList
                }
              });
            }
          }
        })
      }
    },
    //폴링호가
    changeOvhDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (data.symbol == this.symbol.name){
            this.ovhData = data;
          }
        })
      }
    },
  },
  components: {
    OrderStandard,
    BtnLine,
    CounterComp
  },
  data() {
    return {
      nowPrice : 0,
      highPrice : 0,
      lowPrice : 0,
      yesterdayClosingPrice : 0,
      ovhData : {
        offerHo5:0,
        offerHo4:0,
        offerHo3:0,
        offerHo2:0,
        offerHo1:0,
        offerRem5:0,
        offerRem4:0,
        offerRem3:0,
        offerRem2:0,
        offerRem1:0,
        offerNo5:0,
        offerNo4:0,
        offerNo3:0,
        offerNo2:0,
        offerNo1:0,
        bidHo5:0,
        bidHo4:0,
        bidHo3:0,
        bidHo2:0,
        bidHo1:0,
        bidRem5:0,
        bidRem4:0,
        bidRem3:0,
        bidRem2:0,
        bidRem1:0,
        bidNo5:0,
        bidNo4:0,
        bidNo3:0,
        bidNo2:0,
        bidNo1:0
      },
      execTime : "00:00:00",
      execCnt : 0,
      clickSetList: ['1','5','10','20','50'],
      ordClick: 1,
      symbolTik: this.symbol.minmov/this.symbol.pricescale,
      btnSellFlag: true,
      btnBuyFlag: true,
      btnSellMarketFlag: true,
      btnBuyMarketFlag: true,
      chartOption: {
        title: {
          text : ""
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            params = params[0];
            return (""+params.value);
          },
          axisPointer: {
            animation: false
          }
        },
        grid: {
          top: 10,
          bottom: 20
        },
        color: "#ffffff",
        xAxis: {
          type: 'category',
          data: this.chartTimeList,
          splitLine: {
            show: false
          },
          show:false,
        },
        yAxis: {
          type: 'value',
          min: 0,
          boundaryGap: [0, '100%'],
          splitLine: {
            show: false
          },
          show:false,
        },
        series: [
          {
            name: 'ovc',
            type: 'line',
            showSymbol: false,
            data: [],
          }
        ]
      },
      chart: null,
      chartList: [],
      chartTimeList: [],
      orderSetting: null,
    }
  },
	computed: {
    tikDotSz(){
      return stringCount(this.symbol.pricescale)
    },
    tikVal(){
      return (this.symbol.minmov / this.symbol.pricescale);
    },
    getUsrAccPass(){
      const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))
      if (accPassObj == undefined && !this.account.accPass){
        //저장된 계좌비번이 없으면
        return "";
      }else if (accPassObj != undefined && accPassObj[this.account.accNo] && !this.account.accPass){
        //저장된 계좌비번
        return accPassObj[this.account.accNo];
      }else if (this.account.accPass){
        //비밀번호를 직접입력
        return this.account.accPass;
      }else{
        return "";
      }
    },
    resultOms: function(){
      return window.$store.getters['SocketOms/getCompleteOrder']
    },
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData']
      return ovcData
    },
    //소켓호가
    changeOvhData: function(){
      let ovhData = window.$store.getters['SocketPrice/getOvhData']
      return ovhData
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList']
      return ovcData
    },
    //폴링호가
    changeOvhDataList: function(){
      let ovhData = window.$store.getters['SocketPrice/getOvhDataList']
      return ovhData
    },
	},
  created(){
    this.subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( this.subscriptionUID == null ) {
      this.subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', this.subscriptionUID)
    }

    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom()
    })
  },
  destroyed(){

  },
  mounted() {
    //253, 118
    this.chart = echarts.init(this.$refs.miniChart);
    this.getServerTime();
    this.loadCustom();
  },
  methods: {
    loadCustom(){
      this.orderSetting = window.$store.getters['Layout/getOrderSetting'];
      if (this.orderSetting != null){
        //주문수량 설정
        this.clickSetList[0] = this.orderSetting.clickVal1.val.toString();
        this.clickSetList[1] = this.orderSetting.clickVal2.val.toString();
        this.clickSetList[2] = this.orderSetting.clickVal3.val.toString();
        this.clickSetList[3] = this.orderSetting.clickVal4.val.toString();
        this.clickSetList[4] = this.orderSetting.clickVal5.val.toString();

        //원클릭, 더블클릭 설정
        if (this.orderSetting.oneClick == true){
          this.itemColumnDoubleClickTime = 0;
        }else if (this.orderSetting.dbClick == true){
          this.itemColumnDoubleClickTime = 200;
        }
      }
    },
    async getServerTime(){
      const response = await window.$http.get('chart/datafeed/time')

      if ( response && response.data ) {
        let nowTime = response.data;
        let prevTime = response.data - 30 * 60 * 1000;

        this.getChartData(prevTime, nowTime);
      }
    },
    async getChartData(from, to){
      this.chartTimeList = [];
      this.chartList = [];
      
      const params = {
			symbol: this.symbol.name,
      resolution : 1,
			from: from,					
			to: to,      
			limit: 30,
      };

      const response = await window.$http.get('/chart/datafeed/history', {params:params});
      
      const barData = response.data;

      if ( response && response.data ) {
        let min = 0;
        let max = 0;
        for (let i=0; i<barData.length; i++){
          let lastDate = new Date(barData[i].time * 1000);
          this.chartTimeList.push(lastDate.getHours()+""+lastDate.getMinutes());
          this.chartList.push(barData[i].close.toFixed(this.tikDotSz));
          if (i == 0){
            min = barData[i].close;
            max = barData[i].close;
          }else{
            if (min > barData[i].close){
              min = barData[i].close;
            }
            if (max < barData[i].close){
              max = barData[i].close;
            }
          }
        }
        this.chartOption.yAxis.min = min;
        this.chartOption.yAxis.max = max;


        this.chart.setOption(this.chartOption);
        this.chart.setOption({
          series: [
            {
              data: this.chartList
            }
          ]
        });
      }
    },
    orderChk(data){
      if ((data.ordTpCd == "1" && data.ordPrc == null || data.ordPrc == 0)){
        this.$alert.alert('지정가 주문 가격을 확인 해주세요.', '알림')
        return;
      }else if (data.ordQty < 1){
        this.$alert.alert('주문 수량을 확인 해주세요.', '알림')
        return;
      }

      if (data.ordSdCd == "2"){ // 매도
        if (data.ordTpCd == "1"){ //지정가
          this.sellStock(data.ordPrc, data.ordQty);
        }else if (data.ordTpCd == "2"){ //시장가
          this.sellMarket(data.ordQty)
        }
      }else if (data.ordSdCd == "1"){ //매수
        if (data.ordTpCd == "1"){ //지정가
          this.buyStock(data.ordPrc, data.ordQty);
        }else if (data.ordTpCd == "2"){ //시장가
          this.buyMarket(data.ordQty);
        }
      }
    },
    reSetData(){
      this.lowPrice = 0;
      this.highPrice = 0;
      this.lowPrice = 0;
      this.yesterdayClosingPrice = 0;
      this.ovhData = {
        offerHo5:0,
        offerHo4:0,
        offerHo3:0,
        offerHo2:0,
        offerHo1:0,
        offerRem5:0,
        offerRem4:0,
        offerRem3:0,
        offerRem2:0,
        offerRem1:0,
        offerNo5:0,
        offerNo4:0,
        offerNo3:0,
        offerNo2:0,
        offerNo1:0,
        bidHo5:0,
        bidHo4:0,
        bidHo3:0,
        bidHo2:0,
        bidHo1:0,
        bidRem5:0,
        bidRem4:0,
        bidRem3:0,
        bidRem2:0,
        bidRem1:0,
        bidNo5:0,
        bidNo4:0,
        bidNo3:0,
        bidNo2:0,
        bidNo1:0
      };
      this.execTime = "00:00:00";
      this.execCnt = 0;
    },
    setClickOrd(val){
      this.ordClick = Number(val);
    },
    setOrdClick(e){
      this.ordClick = Number(e.target.innerText);
    },
    /* 주문 */
    sellStock(price, count) {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }

      if (this.sellAbleQty == 0){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 주문가능 수량이 부족합니다.'
        })

        return
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnSellFlag){
        return;
      }

      this.btnSellFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        accPass : this.getUsrAccPass,
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매도(지정가)' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + count + '</br>' +
          '<b style="font-weight:600;">가격</b> : ' + price + '</br></br>' +
          '<b style="font-weight:600;">매도 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnSellFlag = true;
            return;
          }else{
            this.sendOmsMessage('OMS0001', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnSellFlag){
        setTimeout(() => {
          this.btnSellFlag = true
        }, 300)
      }
    },
    buyStock(price, count) {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }
      
      if (this.buyAbleQty == 0){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 주문가능 수량이 부족합니다.'
        })

        return
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnBuyFlag){
        return;
      }

      this.btnBuyFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '1',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        accPass : this.getUsrAccPass,
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매수(지정가)' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + count + '</br>' +
          '<b style="font-weight:600;">가격</b> : ' + price + '</br></br>' +
          '<b style="font-weight:600;">매수 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnBuyFlag = true;
            return;
          }else{
            this.sendOmsMessage('OMS0001', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnBuyFlag){
        setTimeout(() => {
          this.btnBuyFlag = true
        }, 300)
      }
    },
    sellMarket(count){
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }

      if (this.sellAbleQty == 0){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 주문가능 수량이 부족합니다.'
        })

        return
      }
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnSellMarketFlag){
        return;
      }

      this.btnSellMarketFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        accPass : this.getUsrAccPass,
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매도(시장가)' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + count + '</br>' +
          '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
          '<b style="font-weight:600;">매도 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnSellMarketFlag = true
            return;
          }else{
            this.sendOmsMessage('OMS0001', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0001', order)
      }
      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnSellMarketFlag){
        setTimeout(() => {
          this.btnSellMarketFlag = true
        }, 300)
      }
    },
    buyMarket(count){
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }
      
      if (this.buyAbleQty == 0){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 주문가능 수량이 부족합니다.'
        })

        return
      }
      
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnBuyMarketFlag){
        return;
      } 

      this.btnBuyMarketFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        accPass : this.getUsrAccPass,
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매수(시장가)' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + count + '</br>' +
          '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
          '<b style="font-weight:600;">매수 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnBuyMarketFlag = true
            return;
          }else{
            this.sendOmsMessage('OMS0001', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0001', order)
      }
      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnBuyMarketFlag){
        setTimeout(() => {
          this.btnBuyMarketFlag = true
        }, 300)
      }
    },
    sellSlStock() {
      // apiType : 주문타입     //SLNEW (신규), SLCANCEL (취소)
      // stkCd : 종목코드        
      // ordPrc : 주문가격       
      // uId : 사용자ID
      // accNo : 계좌번호 
      // ordNo : 주문번호 (null) 
      // tickVal : 틱값
      // chkVal : 체크값     // '1' 익절 '2' 손절 

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.sellSlTick,
        chkVal : '2', // '1' 익절 '2' 손절
        accPass : this.getUsrAccPass,
      }
      let apiCode = "OMS0007"
      if (this.slStockVal2){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008"
      }
      this.sendOmsMessage(apiCode, order)
    },
    buySlStock() {
      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.buySlTick,
        chkVal : '1', // '1' 익절 '2' 손절
        accPass : this.getUsrAccPass,
      }

      let apiCode = "OMS0007"
      if (this.slStockVal1){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008"
      }
      this.sendOmsMessage(apiCode, order)
    },
    sendOmsMessage(apiCode, body) {
      if ( !window.$store.getters['SocketOms/getIsConnectOms'] ) {
        window.$alert.alert('OMS 서버 연결 종료.')
        return
      }

      //비밀번호 추가
      body.accPass = this.getUsrAccPass;

      window.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body})
    },  
    completeOrder(apiCode, body) {

      // 주문
      if ( apiCode == 'OMS0001' ) {

        if ( body.ordSdCd == '1' ) {
          if ( body.execYn == 'Y' ) {
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[주문완료] 주문이 처리되었습니다.' })
          } else {
            if (body.ordTpCd == "2"){
              this.btnSellMarketFlag = true;
            }
          }

        } else if ( body.ordSdCd == '2' ) {
          if ( body.execYn == 'Y' ) {        
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[주문완료] 주문이 처리되었습니다.' })
          } else {
            if (body.ordTpCd == "2"){
              this.btnBuyMarketFlag = true;
            } 
          }
        }
        // 취소  
      } else if ( apiCode == 'OMS0002' ) {
        if ( body.execYn == 'Y' ) {
          if (body.delList != null){
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 주문이 처리되었습니다.' })
          }
        }
      // 정정
      } else if ( apiCode == 'OMS0003' ) {
        if ( body.execYn == 'Y' ) {
          if (body.delList != null){
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[정정완료] 주문이 처리되었습니다.' })
          }
        }
      // STOP 주문
      } else if (apiCode == 'OMS0004') {
        if ( body.ordSdCd == '1' ) {
          if ( body.execYn == 'Y' ) {
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST주문완료] 주문이 처리되었습니다.' })
          }
        } else if ( body.ordSdCd == '2' ) {
          if ( body.execYn == 'Y' ) {
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST주문완료] 주문이 처리되었습니다.' })
          }
        }
      // STOP 취소
      }  else if ( apiCode == 'OMS0005') {
        if ( body.ordSdCd == '1' ) {
          if ( body.execYn == 'Y' ) {
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 주문이 처리되었습니다.' })
          }

        } else if ( body.ordSdCd == '2' ) {
          if ( body.execYn == 'Y' ) {        
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 주문이 처리되었습니다.' })
          }
        }
      // STOP 정정
      } else if ( apiCode == 'OMS0006') {
        if ( body.ordSdCd == '1' ) {
          if ( body.execYn == 'Y' ) {
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST정정완료] 주문이 처리되었습니다.' })
          }

        } else if ( body.ordSdCd == '2' ) {
          if ( body.execYn == 'Y' ) {        
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST정정완료] 주문이 처리되었습니다.' })
          }
        }
      // STOP LOSS 주문
      } else if ( apiCode == 'OMS0007') {
        if ( body.chkVal == '1' ) {
            //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
        } else if ( body.chkVal == '2' ) {
            //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
        }
      // STOP LOSS 취소 
      } else if ( apiCode == 'OMS0008') {
        if ( body.chkVal == '1' ) {
          if ( body.execYn == 'Y' ) {
            //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
          }
        } else if ( body.chkVal == '2' ) {
          if ( body.execYn == 'Y' ) {        
            //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
          }
        }
      // 종목선택 취소
      } else if ( apiCode == 'OMS0010') {
        if ( body.execYn == 'Y' ) { 
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 주문이 처리되었습니다.' })
        }
        // STOP 종목선택 취소
      } else if ( apiCode == 'OMS0011') {
        if ( body.execYn == 'Y' ) {
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 주문이 처리되었습니다.' })
        }
      // 상단 일괄 취소 버튼
      } else if ( apiCode == 'OMS0012') {
        if ( body.execYn == 'Y' ) {
          //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 주문이 처리되었습니다.' })
        }
      } else if ( apiCode == 'OMS0013') { //전/현 종목 청산
        if (body.delList != undefined){
          body.delList.forEach(del => {
            //같은 종목만 삭제 처리
            if (del.symCd == this.symbol.name){
              if ( del.chkVal == '1' ) {
                if ( del.execYn == 'Y' ) {
                  //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
                }
              } else if ( del.chkVal == '2' ) {
                if ( del.execYn == 'Y' ) {        
                  //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
                }
              }
            }           
          });
        }

        if ( body.execYn == 'Y' ) {
          //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[청산완료] 주문이 처리되었습니다.' })
        }

      // PanelComp2 청산버튼 - 선택 종목의 보유포지션 청산
      } else if ( apiCode == 'OMS0014') {
        if ( body.execYn == 'Y' ) {
          //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[청산완료] 주문이 처리되었습니다.' })
        }
      } else if ( apiCode == 'OMS0015'){
          //ordApiType 정정, 취소 구분
        if (body.ordApiType == 'OMS0003'){ //정정
          if ( body.ordSdCd == '1' ) {
            if ( body.execYn == 'Y' ) {
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 원주문이 이미 처리되었습니다.' })
            }

          } else if ( body.ordSdCd == '2' ) {
            if ( body.execYn == 'Y' ) {             
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 원주문이 이미 처리되었습니다.' })
            }
          }
        }
      //배치 청산
      } else if ( apiCode == 'OMS9995'){ 
          
          //console.log("bat clearing >>> ", body)
          if (body.execYn == "Y"){
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[장마감] 장마감 청산이 완료되었습니다.' , body : body , apiCode:apiCode});
            
            // this.$emit('updateSlStock', {chkType:'1', chkVal:false});

            // this.$emit('updateSlStock', {chkType:'2', chkVal:false});
          }
        } else if ( apiCode == 'OMS9997'){ //서버 상태 조회
            //console.log("OMS9997 >>>> ", body);
        }
    },
    procFailMessage(body) {
      // this.$notify({ group: 'notification', clean: true })
      
      // this.$notify({
      //   group: 'notification',
      //   title: 'FAIL',
      //   text: body.message
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.dom-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  
  /* border: 1px solid red; */
  /* gap: 10px; */
  .dom-grid {
    grid-template-columns: repeat(5, 1fr);
    /* border: 1px dashed green; */
    /* height: 100%; */
    .item{
      height:24px
    }
  }
}

.order-standard-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0px;
  // gap: 10px;
  // margin-top: 10px;
  font-weight: 100;
}

.dom-order-option {
  margin-top: 10px;
}

.balance-table {
  margin-top: 0;
  width: 285px;
}

.buyColor {
  color : #ff0000 !important;
  font-weight: 400;
}

.sellColor {
  color : #00b7ff !important;
  font-weight: 400;
}

.zeroColor {
  color : #ffffff !important;
  font-weight: 400;
}

.yesPrice {
  padding: 0 2px;
  border-radius: 4px;
  background-color: black;
  color:white;
}

.highPrice {
  padding: 0 2px;
  border-radius: 4px;
  background-color: red;
  color:white;
}

.lowPrice {
  padding: 0 2px;
  border-radius: 4px;
  background-color: blue;
  color:white;
}

.currentPrice {
  color : yellow !important;
  font-weight: 400;
}
</style>
