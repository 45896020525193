<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16">
    <path
      data-name="search"
      d="M11.273 11.98 7.747 8.455a4.754 4.754 0 1 1 .707-.707l3.525 3.525-.707.707zM4.757 1a3.756 3.756 0 1 0 3.755 3.757A3.761 3.761 0 0 0 4.757 1z"
      transform="translate(2 2)"
      :style="{ fill: fill }"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSearch',
  props: {
    width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
}
</style>
