<template>
  <div class="container">
    <AvatarComp :width="16" :height="16" :fontSize="10" :name="params.data.usrNick.substring(0, 1)" :color="params.data.prfBgCol" />
    {{params.data.usrNick}}
  </div>
</template>

<script>
import AvatarComp from '@/components/AvatarComp.vue'

export default {
  name: 'AgDemoUserCell',

  components: { AvatarComp },

  data() {
    return {
			
    }
  },

  mounted() {

  },

  methods: {
		
  },
}
</script>

<style lang="scss" scoped>
  .container {
    display: flex;
    align-items: center;
    gap: 5px;
  }
</style>