<template>
  <div class="panel-container">
    <div class="row between gap10">
      <div class="overlay">
        <router-link to="/signup" class="signup-link"><h3>무료회원가입</h3></router-link>
      </div>      
        <div class="inner">
          <div class="row-item">
            <h3>AI매매신호</h3>
            <div class="row-item-content">
              <DropDown id="symbolDropdown" :options="symbolList" :defaultOption="defaultOption" width="60" @symbolDropdown="symbolDropdown" />
              <AiDropDown id="aiDropDown" :options="['준비중']" default="신호선택" width="120" @aiDropDown="aiDropDown" />
            </div>
          </div>
          <!-- <div class="row-item">
            <h3>차트주문</h3>
            <div class="row-item-content">
              <BtnLine text="매수" red  />
              <BtnLine text="매수ST" red />
              <BtnLine text="매도" blue />
              <BtnLine text="매도ST" blue />
            </div>
          </div>
          <div class="row-item">
            <h3>주문량</h3>
            <div class="row-item-content">
              <CounterComp />
              <BtnLine text="1" white />
              <BtnLine text="2" white />
              <BtnLine text="5" white />
            </div>
          </div> -->
        </div>
        <!-- <GnbDropDown right>
          <template #gnb-title>
            <div class="panel-menu-container">
              <img src="@/assets/icons/ellipsis-menu.svg" alt="" />
              <div class="tool-tip down"><h3>주문수량편집</h3></div>
            </div>
          </template>
          <template #dropdown-list>
            <h4 class="dropdown-subtitle">주문수량 편집</h4>
            <div class="dropdown-list-item no-hover">
              <div class="col btn-group">
                <BtnLine text="1" white />
                <BtnLine text="2" white />
                <BtnLine text="5" white />
              </div>
            </div>
            <div class="dropdown-division-line"></div>
            <h4 class="dropdown-subtitle">기타설정</h4>
            <div class="dropdown-list-item">
              <div class="col">
                <BtnCheckbox id="주문확인창 보기" text="주문확인창 보기" />
              </div>
            </div>
            <div class="dropdown-list-button-container">
              <BtnPrimary text="저장" class="save-btn" />
            </div>
          </template>
        </GnbDropDown> -->
      </div>
    <div class="chart-panel">
      <chartBox :symbol="symbol" :symbolList="symbolList" :width="width" :height="height" />
    </div>
  </div>
</template>

<script>
// import agAccountTable from './agAccountTable.vue';
import BtnCheckbox from '@/components/BtnCheckbox.vue'
import BtnLine from '@/components/BtnLine.vue'
import GnbDropDown from '@/components/GnbDropDown.vue'
import CounterComp from '@/components/CounterComp.vue'
import DropDown from '@/components/DropDown.vue'
import AiDropDown from '@/components/AiDropDown.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import ChartBox from '@/components/ChartBox'
import EventBus from '@/modules/event-bus'

export default {
  name: 'PanelComp3',
  components: {
    BtnCheckbox,
    BtnLine,
    GnbDropDown,
    CounterComp,
    DropDown,
    AiDropDown,
    BtnPrimary,
    ChartBox
  },
  props: {
    symbolList : {
      type: Array
    },
    account: {
      type: Object
    },
    chgSymbol: {
      type: Object
    }
  },
  watch: {
    symbolList: {
      handler(newSymbolList){
        if (newSymbolList != null) {
          if (newSymbolList.length > 0 ) {        
            this.symbolDropdown(newSymbolList[0]);
            this.defaultOption = newSymbolList[0].name;
          }
        }
      }
    },
    account: {
      handler(){
      },
      deep:true
    },
    chgSymbol: {
      handler(newVal){
        this.symbol = newVal;
      },
      deep:true
    },
  },
  data() {
    return {
      symbol: {},
      symbolNameList:[],
      defaultOption:'',
      width: 757,
      height: 570,

    };
  },
  created(){
    //종목 리턴 호출 이벤트 추가
    // EventBus.$on('PanelComp3:event:setSymbolList', symbolList => {
    //   this.setSymbolList(symbolList)
    // });
  },
  mounted() {
    const chartPanel = document.querySelector('.chart-panel');

    //차트 사이즈 자동인식
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const {width, height} = entry.contentRect;
        this.height = height;
        this.width = width;
      }
    });

    //자동으로 인식할 부분 추가
    observer.observe(chartPanel);
  },
  destroyed() {
    //이벤트 해제
    EventBus.$off();
  },
  methods: {
    // setSymbolList(symbolList){
    //   this.symbolList = symbolList;

    //   if ( this.symbolList.length > 0 ) {        
    //     this.symbolDropdown(this.symbolList[0]);
    //     this.defaultOption = this.symbolList[0].name;
    //   }
    // },
    // async setSymbolList() {
    //   const response = await window.$http.get('chart/datafeed/symbols')

    //   if ( response ) {
    //     // console.log("response", response)
    //     // this.symbolNameList = new Array();
    //     // response.data.forEach((item)=>{
    //     //   this.symbolNameList.push(item.name);
    //     // });
    //     this.symbolList = response.data;

    //     if ( this.symbolList.length > 0 ) {        
    //       this.symbolDropdown(this.symbolList[0]);
    //       this.defaultOption = this.symbolList[0].name;
    //     }
    //   }
    // },
    symbolDropdown(value) {
      this.symbol = value;
      // this.symbolName = value;
      // this.symbolList.forEach((item)=>{
      //   if (item.name == value){
      //     this.symbol = item;
      //   }
      // });
    },
    aiDropDown(value) {
      console.log(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-container {
  padding: 0 20px 20px 20px;
  border-top: 1px solid var(--border-color-dom);
  padding-top: 10px;
}
.save-btn {
  margin-left: auto;
}
.row {
  position: relative;
}
.landing {
  .overlay {
    display: flex;
  }
}
.overlay {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 2;
  margin: -10px -20px -10px -20px;
}
.signup-link {
  border-radius: 50vh;
  border: 1px solid rgba(255,255,255,0.5);
  padding: 8px 26px;
}
</style>
