<template>
  <div>
    <modal name="modalTeenPolicy" class="custom-modal" :adaptive="true" :width="width" :height="height">
      <div class="modal-header-terms">
        <h1>청소년 보호 정책</h1>
        <BaseBtn @click="closeModal" naked>
          <img src="@/assets/icons/close-large.svg" alt="" />
        </BaseBtn>
      </div>

      <div class="modal-body-terms">
        <div class="terms-container">
          <pre>
‘TAP트레이딩 주식회사(이하 “회사”라 함)’는 각종 각종 청소년유해정보로부터 청소년을 보호하고자 관련 법률에 따라 19세 미만의 청소년들이 청소년유해정보에 접근할 수 없도록 청소년보호정책을 마련하여 시행하고 있습니다. 또한 회사는 청소년의 건전한 성장을 저해하는 청소년유해정보와 비윤리적, 반사회적 행위에 대해서는 엄격하게 제재하기 위하여 다음과 같이 활동하고 있습니다.

1. 유해정보에 대한 청소년접근제한 및 관리조치
회사는 청소년이 아무런 제한장치 없이 유해정보에 노출되지 않도록 청소년유해매체물에 대해서는 인증장치를 마련·적용하고 있으며, 유해정보가 노출되지 않게 하기 위한 예방 차원의 조치를 강구하고 있습니다.

2. 유해정보로부터의 청소년 보호를 위한 교육 시행
회사는 청소년보호책임자를 대상으로 청소년 보호를 위한 각종 관련 법령 및 제재기준, 유해정보 발견 시 대처방법, 위반사항 처리에 대한 보고절차 등을 교육하고 있습니다.

3. 유해정보로 인한 피해상담 및 고충처리
회사는 유해정보로 인한 피해상담 및 고충처리를 위한 전문인력을 배치하여 구제조치의 지연 및 처리 미숙으로 인한 피해가 확산되지 않도록 노력하고 있습니다.

4. 청소년보호책임자 지정
청소년보호책임자를 지정하여 청소년유해정보의 차단 및 관리, 청소년유해정보로부터의 청소년보호정책을 수립하는 등 청소년보호업무에 최선을 다하고 있습니다.

청소년보호책임자
-이름 : 이남행
-전화 : 02-2068-9464
-이메일 : lnh@delkoit.co.kr
          </pre>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import BaseBtn from '../BaseBtn.vue';
export default {
  components: { BaseBtn },
  name: 'ModalTeenPolicy',

  data() {
    return {};
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 800;
      this.height = 670;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$modal.hide('modalTeenPolicy');
    },
    
  },
};
</script>

