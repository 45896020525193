import { render, staticRenderFns } from "./IconHelpCenter.vue?vue&type=template&id=4a51650a&scoped=true"
import script from "./IconHelpCenter.vue?vue&type=script&lang=js"
export * from "./IconHelpCenter.vue?vue&type=script&lang=js"
import style0 from "./IconHelpCenter.vue?vue&type=style&index=0&id=4a51650a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a51650a",
  null
  
)

export default component.exports