<template>
  <div class="container" ref="dropdown">
    <input type="number" v-model="modelPrice" @click="handleClick" :class="{ active: active }" step="0.25" ref="inputNum" />
    <div class="counter-input">
      <BaseBtn naked class="up" @click="countUp">
        <img src="@/assets/icons/triangle.svg" alt="" />
      </BaseBtn>
      <BaseBtn naked class="down" @click="countDown">
        <img src="@/assets/icons/triangle.svg" alt="" />
      </BaseBtn>
    </div>
    <div class="price-dropdown" v-if="isDropdownVisible" :class="{modal:modal}">
      <ul>
				<li class="price-item" 
					v-for="(price, index) in priceList"
					:key="index"
          :class="{'current-price': Number(currentPrice) === Number(price)}"
					@click="modelPrice = price, isDropdownVisible = false"
				>
					<h3>{{ price.toFixed(tikDotSz) }}</h3>
				</li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue';
import { stringCount } from '@/modules/helper'

export default {
  name: 'OrderInput',
  components: {
    BaseBtn,
  },
  props: {
    symbol: {
      type: Object,
      default: () => {
        return { name: '', minmov : 0.1, pricescale : 100 }
      },
    },
    nowPrice : Number,
    modal: Boolean
  },
  watch: {
    symbol: {
      handler(newSymbol) {
        this.symbolTik = newSymbol.minmov/newSymbol.pricescale,
        this.currentPrice = 0;
        this.modelPrice = 0;
        this.priceList = [];
        this.priceList.push(this.currentPrice);
        //input 증가값 변경
        this.$refs.inputNum.step= newSymbol.minmov/newSymbol.pricescale;
      }
    },
    nowPrice: {
      handler(newPrice) {
        this.currentPrice = newPrice;

        if (newPrice > 0){
          if (this.modelPrice < 1){
            this.modelPrice = newPrice;
          }
        }
      }
    },
    modelPrice : {
      handler(newPrice) {
        this.modelPrice = Number(newPrice).toFixed(this.tikDotSz);
        this.$emit("updateOrdPrc", newPrice);
      }
    }
  },
  data() {
    return {
      priceMakeCnt: 10, //시세 위아래 그려질 개수
      modelPrice: 0,
      isDropdownVisible: false,
			active: false,
      currentPrice: 0,
      priceList: [],
      setStep : 0.25,
    };
  },
	computed: {
    tikVal(){
      return (this.symbol.minmov / this.symbol.pricescale);
    },
    tikDotSz(){
      return stringCount(this.symbol.pricescale)
    }
	},
  mounted() {
    window.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeDropdown);
  },

  methods: {
    handleClick() {
      this.isDropdownVisible = !this.isDropdownVisible;
      this.active = !this.active;

      if (this.active){
        let priceList = [];

        if (this.currentPrice > 0){
          if (this.modelPrice < 1){
            this.modelPrice = this.currentPrice;
          }


          //현재가 위
          for (let i=this.priceMakeCnt; i>0; i--){
            let price = (this.currentPrice + (this.tikVal * i) );
            priceList.push(price);
          }

          //현재가
          priceList.push(this.currentPrice);

          //현재가 아래
          for (let i=1; i<=10; i++){
            let price = (this.currentPrice - (this.tikVal * i) );
            priceList.push(price);
          }
        }

        this.priceList = priceList;
      }
    },
    closeDropdown(element) {
      if (!this.$refs.dropdown.contains(element.target)) {
        this.isDropdownVisible = false;
        this.active = false;
      }
    },
    countUp(){
      this.modelPrice = (Number(this.modelPrice) + Number(this.tikVal)).toFixed(stringCount(this.symbol.pricescale));
    },
    countDown(){
      if (this.modelPrice > 0){
        this.modelPrice = (Number(this.modelPrice) - Number(this.tikVal)).toFixed(stringCount(this.symbol.pricescale));
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 30px;
  position: relative;

  input[type='number'] {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 20px 2px 10px;
		background-color: transparent;
    &:hover {
      border-color: var(--main-orange);
    }
    &:focus {
      border-color: var(--main-orange);
    }
    &.active {
      border-color: var(--main-orange);
    }
  }

  .counter-input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    .down {
      transform: rotate(180deg);
    }
  }

  .price-dropdown {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: var(--main-black);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: none;
    padding: 5px 0;
    &.modal {
      height: 140px;
      overflow-y: auto;
    }
    .price-item {
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      &.current-price {
        background-color: var(--main-orange);
        h3 {
          font-weight: bold;
        }
      }
    }
    
  }
}
</style>
