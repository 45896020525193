<template>
  <h3 :class="{ active: active }">{{ lang }}</h3>
</template>

<script>
export default {
  name: 'LangOption',
  props: {
    lang: String,
    active: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.active {
  color: var(--main-orange);
}
</style>
