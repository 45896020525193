<template>
  <div class="dom-grid-container" ref="gridContainer">
    <StockOrderBook
      ref="refStockOrderBook"
      :itemList="stockOrderBook.itemList"
      :nowPrice="stockOrderBook.nowPrice"
      :buy1stPrice="stockOrderBook.buy1stPrice"
      :highPrice="stockOrderBook.highPrice"
      :lowPrice="stockOrderBook.lowPrice"
      :focusPrice="stockOrderBook.focusPrice"
      :yesterdayClosingPrice="stockOrderBook.yesterdayClosingPrice"
      :stepPrice="stockOrderBook.stepPrice"
      :dailyCandlePerPercent="stockOrderBook.dailyCandlePerPercent"
      :dailyCandleMaxPercent="stockOrderBook.dailyCandleMaxPercent"
      :visibleRowCount="stockOrderBook.visibleRowCount"
      :availScrollRowCount="stockOrderBook.availScrollRowCount"
      :scrollMoveRowCount="stockOrderBook.scrollMoveRowCount"
      :scrollHold="true"
      :itemColumnDoubleClickTime="itemColumnDoubleClickTime"
      @update:scrollPosition="onScrollChange"
      @event:keyup="onKeyup"
      @event:keydown="onKeydown"
      @event:columnHover="onItemHover"
      @event:columnClick="onItemClick"
      @event:columnDoubleClick="onItemDoubleClick"
      @event:columnDrag="onItemDrag"
    >

      <!-- [시가, 고가, 저가] 및 [매수, 매도] 표시 -->
      <template v-slot:itemRowItemPrice="{ item, itemPriceSubStyle }">
        <div :style="itemPriceSubStyle.COLUMN" class="sob_col sob_col_item sob_col_center sob_col_price">
          <!-- [시가, 고가, 저가] 표시 -->
            <template v-if="[stockOrderBook.highPrice, stockOrderBook.lowPrice, stockOrderBook.yesterdayClosingPrice, stockOrderBook.avgPrice].includes(item.price)">
            <div
              style="
                position: absolute;
                right: 85%;
                display: flex;
                color: white;
                font-size: 12px;
                font-weight: bold;
                overflow: hidden;
                z-index: 1;
              "
            >  
              <!-- 시가 -->
              <span v-if="item.price === stockOrderBook.yesterdayClosingPrice" style="padding: 2px 2px; border-radius: 4px; background-color: purple">시</span>              
              <!-- 고가 -->
              <span v-if="item.price === stockOrderBook.highPrice" style="padding: 2px 2px; border-radius: 4px; background-color: red">고</span>
              <!-- 저가 -->
              <span v-if="item.price === stockOrderBook.lowPrice" style="padding: 2px 2px; border-radius: 4px; background-color: blue">저</span>

              <!-- 매도 평균가 -->
              <span v-if="item.price === stockOrderBook.avgPrice && stockOrderBook.balSide == 1 " style="padding: 2px 2px; border-radius: 4px; background-color: green">평</span>
            </div>
          </template>
          <div :style="itemPriceSubStyle.PRICE" class="sob_col_value" :class="{ sob_col_nowPrice : (item.price === stockOrderBook.nowPrice ? true : false)}">{{ item.price > 0 ? item.price.toFixed(stockOrderBook.pricescale) : "" }}</div>
          <template v-if="[stockOrderBook.avgPrice].includes(item.price)">
            <div
              style="
                position: absolute;
                left: 85%;
                display: flex;
                color: white;
                font-size: 12px;
                font-weight: bold;
                overflow: hidden;
                z-index: 1;
              "
            >
              <!-- 매수 평균가 -->
              <span v-if="item.price === stockOrderBook.avgPrice && stockOrderBook.balSide == 2 " style="padding: 2px 2px; border-radius: 4px; background-color: green">평</span>
            </div>
          </template>
          <div :style="itemPriceSubStyle.BORDER" class="sob_col_border"></div>
        </div>
      </template>

      <template #itemRowFooter="{ totalSum }">
        <div class="sob_col sob_col_footer sob_col_left sob_col_st">
          <div class="sob_col_value">{{ totalSum.orderCountSellST ? totalSum.orderCountSellST.toLocaleString() : "" }}</div>
          <div class="sob_col_border"></div>
        </div>
        <div class="sob_col sob_col_footer sob_col_left sob_col_sell">
          <div class="sob_col_value">{{ totalSum.orderCountSell ? totalSum.orderCountSell.toLocaleString() : "" }}</div>
          <div class="sob_col_border"></div>
        </div>
        <div class="sob_col sob_col_footer sob_col_left sob_col_number">
          <div class="sob_col_value">{{ totalSum.countSellNumber ? totalSum.countSellNumber.toLocaleString() : "" }}</div>
          <div class="sob_col_border"></div>
        </div>
        <div class="sob_col sob_col_footer sob_col_left sob_col_remain">
          <div class="sob_col_value">{{ totalSum.countSellRemain ? totalSum.countSellRemain.toLocaleString() : "" }}</div>
          <div class="sob_col_border"></div>
        </div>
        <div class="sob_col sob_col_footer sob_col_center sob_col_price">
          <div class="sob_col_value" :class="{sellColor:(totalSum.countSellRemain - totalSum.countBuyRemain) < 0, buyColor:(totalSum.countSellRemain - totalSum.countBuyRemain)>0, zeroColor:(totalSum.countSellRemain - totalSum.countBuyRemain)==0}">{{ totalSum.countSellRemain && totalSum.countBuyRemain ? (totalSum.countSellRemain - totalSum.countBuyRemain) : "" }}</div>
          <div class="sob_col_border"></div>
        </div>
        <div class="sob_col sob_col_footer sob_col_right sob_col_remain">
          <div class="sob_col_value">{{ totalSum.countBuyRemain ? totalSum.countBuyRemain.toLocaleString() : "" }}</div>
          <div class="sob_col_border"></div>
        </div>
        <div class="sob_col sob_col_footer sob_col_right sob_col_number">
          <div class="sob_col_value">{{ totalSum.countBuyNumber ? totalSum.countBuyNumber.toLocaleString() : "" }}</div>
          <div class="sob_col_border"></div>
        </div>
        <div class="sob_col sob_col_footer sob_col_right sob_col_buy">
          <div class="sob_col_value">{{ totalSum.orderCountBuy ? totalSum.orderCountBuy.toLocaleString() : "" }}</div>
          <div class="sob_col_border"></div>
        </div>
        <div class="sob_col sob_col_footer sob_col_right sob_col_st">
          <div class="sob_col_value">{{ totalSum.orderCountBuyST ? totalSum.orderCountBuyST.toLocaleString() : "" }}</div>
          <div class="sob_col_border"></div>
        </div>
      </template>
    </StockOrderBook>

    <div class="dom-footer" ref="domFooter">
      <div class="dom-st-container">
        <div class="col">
          <BtnLine width="70" blue text="ST취소" @click="cancelSellStAll" />
          <BtnLine width="70" blue text="취소" @click="cancelSellAll" />
        </div>
        <!-- <BtnLine width="70" white text="exec테스트" @click="doCenterAlign" /> -->
        <div class="col middle">
          <BtnLine width="100" green text="전종목청산" @click="clearOrderData('all')" />
          <BtnLine width="100" green text="현종목청산" @click="clearOrderData('symbol')" />
        </div>
        <div class="col">
          <BtnLine width="70" red text="취소" @click="cancelBuyAll" />
          <BtnLine width="70" red text="ST취소" @click="cancelBuyStAll" />
        </div>
      </div>
      <div class="dom-order-option">
        <div class="row gap10">
          <div class="row-item">
            <ul class="balance-table">
              <li>
                <div class="label"><h3>매도가능</h3></div>
                <div class="balance blue"><h3>{{ sellAbleQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</h3></div>
              </li>
              <li>
                <div class="label"><h3>매수가능</h3></div>
                <div class="balance red"><h3>{{ buyAbleQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</h3></div>
              </li>
            </ul>
          </div>
          <div class="row-item">
            <h3>주문수량</h3>
            <div class="row-item-content">
              <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickOrd" v-bind:value="ordClick" />
              <BtnLine :text="clickSetList[0]" white :value="clickSetList[0]" @click="setOrdClick" />
              <BtnLine :text="clickSetList[1]" white :value="clickSetList[1]" @click="setOrdClick" />
              <BtnLine :text="clickSetList[2]" white :value="clickSetList[2]" @click="setOrdClick" />
              <BtnLine :text="clickSetList[3]" white :value="clickSetList[3]" @click="setOrdClick" />
              <BtnLine :text="clickSetList[4]" white :value="clickSetList[4]" @click="setOrdClick" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CounterComp from '@/components/CounterComp.vue';
import BtnLine from './BtnLine.vue';
//import BtnCheckbox from '@/components/BtnCheckbox.vue';
import CounterComp from '@/components/CounterComp.vue';
import StockOrderBook from '@/modules/stock-order-book/stock-order-book.umd.min'
import { stringCount } from '@/modules/helper'
import Utils from '@/modules/utils'
import EventBus from '@/modules/event-bus'

export default {
  name: 'DomGridClick',
  props: {
    symbol: {
      type: Object,
      default: () => {
        return { name: '', minmov : 0.1, pricescale : 100 }
      },
    },
    gridrow: {
      type: Number,
    },
    rStock: {
      type: Object
    },
    // stockPrice: {
    //   type: Object
    // },
    slStockVal1: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    slStockVal2: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    sellSlTick: {
      type : Number,
      default: () => {
        return 1;
      }
    },
    buySlTick: {
      type : Number,
      default: () => {
        return 1;
      }
    },
    // setSlStockVal: {
    //   type: Object,
    // },
    selectOrderData: {
      type: Object,
    },
    sendOrderData: {
      type: Object
    },
    sellAbleQty: {
      type: Number
    },
    buyAbleQty: {
      type: Number
    },
    accId: {
      type: Number
    },
    clickGridCenter: {
      type: Number
    },
    selectLoadCustom: {
      type: Number
    },
    account: {
      type: Object
    },
    avgPrice: {
      type: Object
    }
  },
  computed: {
    getUsrAccPass(){
      const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))
      if (accPassObj == undefined && !this.account.accPass){
        //저장된 계좌비번이 없으면
        return "";
      }else if (accPassObj != undefined && accPassObj[this.account.accNo] && !this.account.accPass){
        //저장된 계좌비번
        return accPassObj[this.account.accNo];
      }else if (this.account.accPass){
        //비밀번호를 직접입력
        return this.account.accPass;
      }else{
        return "";
      }
    },
    tikVal(){
      return (this.symbol.minmov / this.symbol.pricescale);
    },
    resultOms: function(){
      return window.$store.getters['SocketOms/getCompleteOrder']
    },
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData']
      return ovcData
    },
    //소켓호가
    changeOvhData: function(){
      let ovhData = window.$store.getters['SocketPrice/getOvhData']
      return ovhData
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList']
      return ovcData
    },
    //폴링호가
    changeOvhDataList: function(){
      let ovhData = window.$store.getters['SocketPrice/getOvhDataList']
      return ovhData
    },
  },
  watch: {
    gridrow: {
      handler(newRow) {
        //그리드 row개수 설정
        this.stockOrderBook.visibleRowCount = newRow;
      },
      deep: true,
    },
    rStock: {
      handler(newRStock) {
        //초기 시세? 정보 설정
        this.setStockInfo(newRStock, this.symbol);
      },
    },
    // stockPrice: {
    //   handler(newPrice) {
    //     //시세 OVC, OVH 셋팅
    //     if ( newPrice.apiType == 'OVC' && newPrice.symbol == this.symbol.name) {
    //       this.stockOrderBook.nowPrice  = newPrice.curPr
    //       this.stockOrderBook.highPrice = newPrice.high
    //       this.stockOrderBook.lowPrice  = newPrice.low
    //       this.stockOrderBook.yesterdayClosingPrice  = newPrice.open

    //     } else if (newPrice.apiType == 'OVH' && newPrice.symbol == this.symbol.name){
    //       this.setItemCountTransaction(1, 0, 0, newPrice.bidHo1, newPrice.bidRem1, newPrice.bidNo1)
    //       this.setItemCountTransaction(2, 0, 0, newPrice.bidHo2, newPrice.bidRem2, newPrice.bidNo2)
    //       this.setItemCountTransaction(3, 0, 0, newPrice.bidHo3, newPrice.bidRem3, newPrice.bidNo3)
    //       this.setItemCountTransaction(4, 0, 0, newPrice.bidHo4, newPrice.bidRem4, newPrice.bidNo4)
    //       this.setItemCountTransaction(5, 0, 0, newPrice.bidHo5, newPrice.bidRem5, newPrice.bidNo5)
    //       this.setItemCountTransaction(6, newPrice.offerRem1, newPrice.offerNo1, newPrice.offerHo1, 0, 0)
    //       this.setItemCountTransaction(7, newPrice.offerRem2, newPrice.offerNo2, newPrice.offerHo2, 0, 0)
    //       this.setItemCountTransaction(8, newPrice.offerRem3, newPrice.offerNo3, newPrice.offerHo3, 0, 0)
    //       this.setItemCountTransaction(9, newPrice.offerRem4, newPrice.offerNo4, newPrice.offerHo4, 0, 0)
    //       this.setItemCountTransaction(10, newPrice.offerRem5, newPrice.offerNo5, newPrice.offerHo5, 0, 0)

    //       this.stockOrderBook.buy1stPrice = newPrice.bidHo1
    //     }
    //   },
    //   deep: true,
    // },
    selectOrderData:{
      handler(newList) {
                this.sellData.set(this.symbol.name, new Map())
        this.buyData.set(this.symbol.name, new Map())
        this.sellStData.set(this.symbol.name, new Map())
        this.buyStData.set(this.symbol.name, new Map())
        this.stockOrderBook.itemList = []
        
        if (this.getUsrAccPass){
          this.orderList = newList.selectOrderList;
          for (let order of newList.selectOrderList) {
            if ( order.ordSdCd == '1' ) {
              if (order.ordType == "ORD"){
                this.setSellData(order)
              }else if (order.ordType == "ST"){
                this.setSellStData(order)
              } 
            } else if ( order.ordSdCd == '2' ) {
              if (order.ordType == "ORD"){
                this.setBuyData(order)
              }else if (order.ordType == "ST"){
                this.setBuyStData(order)
              }
            }
          }
        }
        this.resetOrderData(this.symbol.name)
      },
      deep: true,
    },
    // setSlStockVal(data) { //손절, 익절 체크
    //   if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
    //     window.$alert.alert('선택된 계좌가 없습니다.', '알림')
    //     return;
    //   }

    //   if (!this.getUsrAccPass){
    //     window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
    //     return;
    //   }

    //   if (data.chkType == "2"){ //손절
    //     this.sellSlStock();
    //   }else if(data.chkType == "1"){ //익절
    //     this.buySlStock();
    //     //stop loss line 설정
    //     // if (!this.slStockVal1){
    //     //   this.stopLossLinePrice = this.stockOrderBook.nowPrice + (this.stockOrderBook.stepPrice * this.buySlTick);
    //     //   this.setStopLossLine();
    //     // }else{
    //     //   //this.stopLossLinePrice = this.stockOrderBook.nowPrice + (this.stockOrderBook.stepPrice * this.buySlTick);
    //     //   this.clearStopLossLine();
    //     // }
    //   }
    // },
    symbol(){
      //this.selectOrder();
      // handler(newPrice) {
        
      // }
    },
    sendOrderData:{
      handler(newData) {
        if (newData != null && newData.apiCode != null){

          if (newData.apiCode == "OMS0012"){ //일괄 취소
            const order = {
              usrKey: Utils.getUuidv4(),
              accId : this.account.accId,
              symCd : this.symbol.name,
              symNo : this.symbol.symbolno,
              trdDt : '',
              delList : newData.selectList,
              accPass : this.getUsrAccPass,
            }
            this.sendOmsMessage(newData.apiCode, order)
          }else if (newData.apiCode == "OMS0003"){ //정정
            let orgOrders = new Array()

            const orderItem = {
              usrKey: Utils.getUuidv4(),
              accId : this.account.accId,
              symCd : newData.selectList[0].symCd,
              symNo : newData.selectList[0].symNo,
              trdDt : newData.selectList[0].trdDt,
              ordId : 0,
              orgOrdNo: newData.selectList[0].ordId,
              ordSdCd : newData.selectList[0].ordSdCd,
              ordTpCd : newData.selectList[0].ordTpCd,
              ordPrc : newData.selectList[0].editPrc,
              ordQty : newData.selectList[0].ordQty,
              repReqQty : newData.selectList[0].ordQty,
              cxlReqQty : 0,
              orgUsrKey : newData.selectList[0].usrKey
            }

            orgOrders.push(orderItem)

            let order = {
                usrKey : Utils.getUuidv4(),
                accId : this.account.accId,
                symCd : this.symbol.name,
                symNo : this.symbol.symbolno,
                accPass : this.getUsrAccPass,
                orderList : orgOrders
            }
            
            this.sendOmsMessage(newData.apiCode, order)
          }else if (newData.apiCode == "OMS0014"){ //선택종목 보유포지션 청산
            const order = {
              usrKey: Utils.getUuidv4(),
              accId : this.account.accId,
              trdDt : '',
              accPass : this.getUsrAccPass,
              symbolList : newData.selectList,
            }
            this.sendOmsMessage(newData.apiCode, order)

          }else if (newData.apiCode == "OMS0001"){ //신규
            let ordSdCd = "1"; // 1: 매도, 2: 매수
            //신규 입력이 다른곳에서 들어오면 여기서 처리, 종목코드가 다를경우 symCd 변경
            
            //시장가
            const marketOrder = {
              usrKey: Utils.getUuidv4(),
              accId : this.account.accId,
              symCd : this.symbol.name,
              symNo : this.symbol.symbolno,
              trdDt : '',
              ordId : 0,
              orgOrdNo: 0,
              ordSdCd : ordSdCd,
              ordTpCd : '2',
              ordPrc : 0, //지정가 입력
              ordQty : 0, //수량 입력
              repReqQty : 0,
              cxlReqQty : 0,
            }

            //지정가
            const order = {
              usrKey: Utils.getUuidv4(),
              accId : this.account.accId,
              symCd : this.symbol.name,
              symNo : this.symbol.symbolno,
              trdDt : '',
              ordId : 0,
              orgOrdNo: 0,
              ordSdCd : ordSdCd,
              ordTpCd : '1',
              ordPrc : 0, //지정가 입력
              ordQty : 0, //수량 입력
              repReqQty : 0,
              cxlReqQty : 0,
            }
            // 
            //this.sendOmsMessage('OMS0001', order)
          } 
        }
      }
    },
    accId: {
      handler(){
        //계좌정보 변경시 아이템정보 갱신
      },
      deep: true,
    },
    account: {
      handler(val){
      },
      deep: true,
    },
    clickGridCenter(){
      this.doCenterAlign();

      //테스트용
      // 서버구분  주문서버 ORDER, 체결서버 : EXEC, 체결처리: EXECU, 스탑 : STOP , 스탑로스 : STOPLS
      let order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "STOP",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "EXEC",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "STOPLS",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "ORDER",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "EXECU",
      }

      this.sendOmsMessage("OMS9997", order);

      order = {
      usrKey: Utils.getUuidv4(),
      accId : this.account.accId,
      symCd : this.symbol.name,
      symNo : this.symbol.symbolno,
      serverType : "RMS",
      }

      this.sendOmsMessage("OMS9997", order);

          },
    selectLoadCustom:{
      handler(){
        this.loadCustom();
      }
    },
    avgPrice: {
      handler(val){
        this.setAvgPrice(val.avgPrice, val.ordSdCd);
      },
      deep: true,
    },
    resultOms(res){
      if (res.result == "S"){
        this.completeOrder(res);
      }else{
        this.procFailMessage(res);
        //실패시 리스트목록 주문 목록 재조회
        if (res.body.code != "OMSFL007" && res.body.code != "OMSFL011" && res.body.code != "OMSFL002" && res.body.code != "OMSFL012"
          && res.body.code != "OMSFL014" && res.body.code != "OMSFL016" && res.body.code != "OMSFL017"){
          //this.selectOrder(this.symbol.name);
        }
      }

      //this.rtPosSearch(this.symbol.name);
      
      // 알림 재조회
      EventBus.$emit('Alarm:event:refresh', {})
    },
    //소켓시세
    changeOvcData(newPrice){
      if (newPrice.symbol == this.symbol.name) {
          this.stockOrderBook.nowPrice  = Number(newPrice.curPr)
          this.stockOrderBook.highPrice = newPrice.high
          this.stockOrderBook.lowPrice  = newPrice.low
          this.stockOrderBook.yesterdayClosingPrice  = newPrice.open
        }
    },
    //소켓호가
    changeOvhData(newPrice){
      if (newPrice.symbol == this.symbol.name){
          this.setItemCountTransaction(1, 0, 0, newPrice.bidHo1, newPrice.bidRem1, newPrice.bidNo1)
          this.setItemCountTransaction(2, 0, 0, newPrice.bidHo2, newPrice.bidRem2, newPrice.bidNo2)
          this.setItemCountTransaction(3, 0, 0, newPrice.bidHo3, newPrice.bidRem3, newPrice.bidNo3)
          this.setItemCountTransaction(4, 0, 0, newPrice.bidHo4, newPrice.bidRem4, newPrice.bidNo4)
          this.setItemCountTransaction(5, 0, 0, newPrice.bidHo5, newPrice.bidRem5, newPrice.bidNo5)
          this.setItemCountTransaction(6, newPrice.offerRem1, newPrice.offerNo1, newPrice.offerHo1, 0, 0)
          this.setItemCountTransaction(7, newPrice.offerRem2, newPrice.offerNo2, newPrice.offerHo2, 0, 0)
          this.setItemCountTransaction(8, newPrice.offerRem3, newPrice.offerNo3, newPrice.offerHo3, 0, 0)
          this.setItemCountTransaction(9, newPrice.offerRem4, newPrice.offerNo4, newPrice.offerHo4, 0, 0)
          this.setItemCountTransaction(10, newPrice.offerRem5, newPrice.offerNo5, newPrice.offerHo5, 0, 0)

          this.stockOrderBook.buy1stPrice = newPrice.bidHo1
      }
    },
    //폴링시세
    changeOvcDataList(newPrice){
      
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (data.symbol == this.symbol.name){
            this.stockOrderBook.nowPrice  = data.curPr
            this.stockOrderBook.highPrice = data.high
            this.stockOrderBook.lowPrice  = data.low
            this.stockOrderBook.yesterdayClosingPrice  = data.open
          }
        })
      }
    },
    //폴링호가
    changeOvhDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (data.symbol == this.symbol.name){
            //console.log("newPrice ", data)
            this.setItemCountTransaction(1, 0, 0, data.bidHo1, data.bidRem1, data.bidNo1)
            this.setItemCountTransaction(2, 0, 0, data.bidHo2, data.bidRem2, data.bidNo2)
            this.setItemCountTransaction(3, 0, 0, data.bidHo3, data.bidRem3, data.bidNo3)
            this.setItemCountTransaction(4, 0, 0, data.bidHo4, data.bidRem4, data.bidNo4)
            this.setItemCountTransaction(5, 0, 0, data.bidHo5, data.bidRem5, data.bidNo5)
            this.setItemCountTransaction(6, data.offerRem1, data.offerNo1, data.offerHo1, 0, 0)
            this.setItemCountTransaction(7, data.offerRem2, data.offerNo2, data.offerHo2, 0, 0)
            this.setItemCountTransaction(8, data.offerRem3, data.offerNo3, data.offerHo3, 0, 0)
            this.setItemCountTransaction(9, data.offerRem4, data.offerNo4, data.offerHo4, 0, 0)
            this.setItemCountTransaction(10, data.offerRem5, data.offerNo5, data.offerHo5, 0, 0)

            this.stockOrderBook.buy1stPrice = data.bidHo1
          }
        })
      }
    },
  },
  components: {
    //BtnCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BtnLine,
    CounterComp,
    StockOrderBook
  },
  data() {
    return {
      stockOrderBook : {
        itemList: [],                   // 특정 가격의 그리드 데이터
        nowPrice: 0,                    // 현재가
        buy1stPrice: 0,                 // 매수 1호가
        highPrice: 0,                   // 고가    
        lowPrice: 0,                    // 저가
        focusPrice: 0,                  //
        yesterdayClosingPrice: 0,       // 전일 종가
        stepPrice: 0.1,                 // 
        dailyCandlePerPercent: 0.004,    // 일봉 칸당 퍼센티지
        dailyCandleMaxPercent: 1000,      // 일봉 전체 칸 수
        pricescale: 2,
        visibleRowCount: 14,            // 화면에 표시된 row 수
        availScrollRowCount: 1000,      // 스크롤 가능한 줄 갯수
        scrollMoveRowCount: 2,          // 한 번 스크롤당 이동할 줄 갯수
        keys: {},                       // 키보드 이벤트
        avgPrice:0,                     // 매도/매수 평균가
        balSide:0,                      // 매도/매수 구분
      },
      subscriptionUID : null,
      sellData: new Map(),
      buyData: new Map(),
      sellStData: new Map(),
      buyStData: new Map(),
      btnSellMarketFlag : true,
      btnBuyMarketFlag : true,
      btnCancelSellStAllFlag : true,
      btnCancelBuyStAllFlag : true,
      btnCancelSellAllFlag : true,
      btnCancelBuyAllFlag : true,
      btnCancelAllFlag : true,
      // sellSlTick : 1,
      // buySlTick : 1,
      // slStockVal1 : false,
      // slStockVal2 : false,


      gridContainer: 0,
      dom: 0,
      domFooter: 0,
			gap: 0,
			hasSpace: '',
			howManyMore: 0,
      clickSetList: ['1','5','10','20','50'],
      ordClick: 1,
      orderList: [],
      itemColumnDoubleClickTime:0, //더블클릭 인정시간, 디폴트 원클릭
      //stopLossLineBuyPrice:0, //stop loss 익절 설정 금액
      //stopLossLineSellPrice:0, //stop loss 손절 설정 금액
      stopLossLineBuyPrice:[],
      stopLossLineSellPrice:[],
      orderSetting: null
    }
  },
  created() {
    this.subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( this.subscriptionUID == null ) {
      this.subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', this.subscriptionUID)
    }

    window.addEventListener('resize', this.myEventHandler);

    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom()
    })
    
  },
  destroyed() {
    window.removeEventListener('resize', this.myEventHandler);
    //이벤트 해제
    // EventBus.$off();

    this.stockOrderBook.yesterdayClosingPrice = 0;
    this.stockOrderBook.stepPrice  = 0;
    this.stockOrderBook.pricescale = 0;
    this.stockOrderBook.nowPrice   = 0;
    this.stockOrderBook.focusPrice = 0;

    this.stockOrderBook.itemList = []
  },

  mounted() {
    // this.gridContainer = this.$refs.gridContainer.clientHeight;
    // this.dom = this.$refs.refStockOrderBook.clientHeight;
    // this.domFooter = this.$refs.domFooter.clientHeight;
    // console.log('grid-container: ' + this.gridContainer);
    // console.log('dom: ' + this.dom);
    // console.log('domFooter: ' + this.domFooter);
    this.loadCustom();

    const chartPanel = document.querySelector('.dom-grid-container');

    //클릭 그리드 사이즈 자동인식
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const {width, height} = entry.contentRect;
        if (Number(height) <= 520){
          //클릭 그리드 영역이 650이하면 14개 고정
          this.stockOrderBook.visibleRowCount = 14;
        }else if (Number(height) > 520 && height < 650){
          //클릭 그리드 영역이 700이하면 16개 고정
          this.stockOrderBook.visibleRowCount = 16;
        }else{
          //클릭 그리드 700이상일경우 계산으로 row개수를 구함
          const count = Math.round((Number(height) - 70 ) / 28);
          if ((count%2) != 0){
            this.stockOrderBook.visibleRowCount = count-1;
          }else{
            this.stockOrderBook.visibleRowCount = count;
          }
          
        }
      }
    });

    //자동으로 인식할 부분 추가
    observer.observe(chartPanel);
  },

  methods: {
    loadCustom(){
      this.orderSetting = window.$store.getters['Layout/getOrderSetting'];
      if (this.orderSetting != null){
        //주문수량 설정
        this.clickSetList[0] = this.orderSetting.clickVal1.val.toString();
        this.clickSetList[1] = this.orderSetting.clickVal2.val.toString();
        this.clickSetList[2] = this.orderSetting.clickVal3.val.toString();
        this.clickSetList[3] = this.orderSetting.clickVal4.val.toString();
        this.clickSetList[4] = this.orderSetting.clickVal5.val.toString();

        //원클릭, 더블클릭 설정
        if (this.orderSetting.oneClick == true){
          this.itemColumnDoubleClickTime = 0;
        }else if (this.orderSetting.dbClick == true){
          this.itemColumnDoubleClickTime = 200;
        }
      }
    },
    setClickOrd(val){
      this.ordClick = Number(val);
    },
    setOrdClick(e){
      this.ordClick = Number(e.target.innerText);
    },
    myEventHandler() {
      this.gridContainer = this.$refs.gridContainer.clientHeight;
      this.dom = this.$refs.refStockOrderBook.clientHeight;
      this.domFooter = this.$refs.domFooter.clientHeight;
      // console.log('grid-container: ' + this.gridContainer);
      // console.log('dom: ' + this.dom);
      // console.log('domFooter: ' + this.domFooter);
			this.gap = this.gridContainer - this.domFooter - this.dom;
			// console.log('gap: ' + this.gap )
			if (this.gap > 30) {
				this.howManyMore = this.gap / 30
				// console.log('has space' + 'can add: ' + this.howManyMore.toFixed(0));
			}
    },
    // 스크롤 양 끝단에 위치시 양 끝단 스크롤 가능 범위 늘림
    onScrollChange(scrollPosition) {
      if (90 <= scrollPosition || scrollPosition <= 10) {
        this.stockOrderBook.availScrollRowCount += 10;
      }
    },
    // 키 눌림 이벤트
    onKeydown(event) {
      //this.keys[event.key] = true;

      // 스페이스바 클릭
      // if ( event.keyCode == 32 ) {
      //   this.doCenterAlign()
      // }
    },
    // 키 뗌 이벤트
    onKeyup(event) {
      if (this.orderSetting == undefined) return;
      //delete this.keys[event.key];
      //센터이동 단축키 (ctrl + 숫자는 브라우저 탭으로 이동하여 사용할 수 없음, 리눅스 alt + 숫자키 탭이동됨...)
      if (this.orderSetting.centerKey.primaryKey == "ALT" && event.keyCode == this.orderSetting.centerKey.keyCode && event.altKey){
        this.doCenterAlign()
      }

      //시장가매도
      if (this.orderSetting.marketSellKey.primaryKey == "ALT" && event.keyCode == this.orderSetting.marketSellKey.keyCode && event.altKey){
        console.log("시장가매도")
        this.sellMarket()
      }

      //시장가매수
      if (this.orderSetting.marketBuyKey.primaryKey == "ALT" && event.keyCode == this.orderSetting.marketBuyKey.keyCode && event.altKey){
        console.log("시장가매수")
        this.buyMarket()
      }
      
      //매도취소
      if (this.orderSetting.cancelSell.primaryKey == "ALT" && event.keyCode == this.orderSetting.cancelSell.keyCode && event.altKey){
        console.log("매도취소")
        this.cancelSellAll()
      }

      //매수취소
      if (this.orderSetting.cancelBuy.primaryKey == "ALT" && event.keyCode == this.orderSetting.cancelBuy.keyCode && event.altKey){
        console.log("매수취소")
        this.cancelBuyAll()
      }

      //매도ST취소
      if (this.orderSetting.stopSellKey.primaryKey == "ALT" && event.keyCode == this.orderSetting.stopSellKey.keyCode && event.altKey){
        console.log("매도ST취소")
        this.cancelSellStAll()
      }

      //매수ST취소
      if (this.orderSetting.stopBuyKey.primaryKey == "ALT" && event.keyCode == this.orderSetting.stopBuyKey.keyCode && event.altKey){
        console.log("매수ST취소")
        this.cancelBuyStAll()
      }

      //전종목청산
      if (this.orderSetting.allClear.primaryKey == "ALT" && event.keyCode == this.orderSetting.allClear.keyCode && event.altKey){
        console.log("전종목청산")
        this.clearOrderData('all')
      }

      //현종목청산
      if (this.orderSetting.symbolClear.primaryKey == "ALT" && event.keyCode == this.orderSetting.symbolClear.keyCode && event.altKey){
        console.log("현종목청산")
        this.clearOrderData('symbol')
      }
    },   
    // 아이템 컬럼 호버
    onItemHover(price, columnType) {

    },
    // 아이템 클릭
    onItemClick(price, columnType) {
      if (this.itemColumnDoubleClickTime > 0){ //더블클릭 시간이 0 이상이면 더블클릭으로 인식
        return;
      }
      this.onItemOrder(price, columnType);
    },
    onItemOrder(price, columnType){
      if (["SELL_STOP_LOSS", "SELL", "BUY", "BUY_STOP_LOSS"].includes(columnType) === false) return;

      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }
     

      if (columnType === 'SELL') {
        this.sellStock(price, this.ordClick)
      } else if (columnType === 'BUY') {
        this.buyStock(price, this.ordClick)
      } else if (columnType === 'SELL_STOP_LOSS') {
        this.sellStStock(price, this.ordClick)
      } else if (columnType === 'BUY_STOP_LOSS') {
        this.buyStStock(price, this.ordClick)
      }
    },
    sellMarket(){
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnSellMarketFlag){
        return;
      } 

      this.btnSellMarketFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : this.ordClick,
        repReqQty : 0,
        cxlReqQty : 0,
      }

      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매도(시장가)' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + this.ordClick + '</br>' +
          '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
          '<b style="font-weight:600;">매도 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnBuyMarketFlag = true
            return;
          }else{
            this.sendOmsMessage('OMS0001', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnSellMarketFlag){
        setTimeout(() => {
          this.btnSellMarketFlag = true
        }, 1500)
      }
    },
    buyMarket(){
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnBuyMarketFlag){
        return;
      } 

      this.btnBuyMarketFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : this.ordClick,
        repReqQty : 0,
        cxlReqQty : 0,
      }

      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매수(시장가)' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + this.ordClick + '</br>' +
          '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
          '<b style="font-weight:600;">매수 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnBuyMarketFlag = true
            return;
          }else{
            this.sendOmsMessage('OMS0001', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnBuyMarketFlag){
        setTimeout(() => {
          this.btnBuyMarketFlag = true
        }, 1500)
      }
    },

    // 아이템 더블 클릭
    onItemDoubleClick(price, columnType) {
      this.onItemOrder(price, columnType);
    },
    // 아이템 드래그
    onItemDrag(originPrice, originColumnType, targetPrice, targetColumnType) {    
      const canModifyKeys = ["SELL_STOP_LOSS", "SELL", "BUY", "BUY_STOP_LOSS"];

      // 다른 컬럼으로 드래그 -> 취소
      if ( canModifyKeys.includes(originColumnType) && originColumnType !== targetColumnType ) {
        if ( originColumnType === 'SELL' ) {
          this.cancelSellStock(originPrice)
        } else if ( originColumnType === 'BUY' ) {
          this.cancelBuyStock(originPrice)
        } else if ( originColumnType === 'SELL_STOP_LOSS' ) {
          this.cancelSellStStock(originPrice)
        } else if ( originColumnType === 'BUY_STOP_LOSS' ) {
          this.cancelBuyStStock(originPrice)
        }
      }

      // 같은 컬럼으로 드래그 -> 정정
      if (canModifyKeys.includes(originColumnType) && originColumnType === targetColumnType
            && originPrice != targetPrice ) {
        if ( originColumnType === 'SELL' ) {
          this.repairSellStock(originPrice, targetPrice)
        } else if ( originColumnType === 'BUY' ) {
          this.repairBuyStock(originPrice, targetPrice)
        } else if ( originColumnType === 'SELL_STOP_LOSS' ) {
          this.repairSellStStock(originPrice, targetPrice)
        } else if ( originColumnType === 'BUY_STOP_LOSS' ) {
          this.repairBuyStStock(originPrice, targetPrice)
        }
      }      
    },
    doCenterAlign() {
      this.$refs.refStockOrderBook.doCenterAlign()
    },
    // 호가 update
    setItemCountTransaction(hoNo, offerRem, offerNo, ho, bidRem, bidNo) {
      const priceIndex = this.stockOrderBook.itemList.findLastIndex((item) => item.price === ho)
      const hoIndex    = this.stockOrderBook.itemList.findLastIndex((item) => item.hoNo === hoNo)      

      // 
      if ( hoIndex > -1 ) {
        if ( offerRem > 0 ) {
          this.stockOrderBook.itemList[hoIndex].countSellNumber = 0
          this.stockOrderBook.itemList[hoIndex].countSellRemain = 0
        }

        if ( bidRem > 0 ) {
          this.stockOrderBook.itemList[hoIndex].countBuyRemain  = 0
          this.stockOrderBook.itemList[hoIndex].countBuyNumber  = 0
        }

        this.stockOrderBook.itemList[hoIndex].hoNo = 0

        this.stockOrderBook.itemList = Object.seal(
          this.stockOrderBook.itemList.map(function (item) {
            return Object.assign({}, item)
          })
        ) 
      } 

      if ( priceIndex === -1 ) {
        // insert
        const itemList = this.stockOrderBook.itemList.map(function (item) {
          return Object.assign({}, item)
        })

        itemList.push({
          orderCountSellST: 0,
          orderCountSell: 0,
          countSellNumber: offerNo,
          countSellRemain: offerRem,
          price: ho,
          countBuyRemain: bidRem,
          countBuyNumber: bidNo,
          orderCountBuy: 0,
          orderCountBuyST: 0,
          hoNo: hoNo,
          orderYn: 'N',
        });

        this.stockOrderBook.itemList = Object.seal(itemList)   
      }  else {
        // update
        this.stockOrderBook.itemList[priceIndex].countSellNumber = offerNo
        this.stockOrderBook.itemList[priceIndex].countSellRemain = offerRem
        this.stockOrderBook.itemList[priceIndex].countBuyRemain  = bidRem
        this.stockOrderBook.itemList[priceIndex].countBuyNumber  = bidNo
        this.stockOrderBook.itemList[priceIndex].hoNo            = hoNo

        this.stockOrderBook.itemList = Object.seal(
          this.stockOrderBook.itemList.map(function (item) {
            return Object.assign({}, item)
          })
        )
      }

    },
    /**
     * 해당 컬럼 아이템 매수 / 매도
     * @param { number } price
     * @param { 'SELL_STOP_LOSS' | 'SELL' | 'BUY' | 'BUY_STOP_LOSS' } transactionType
     * @param { number } count
     * @param { boolean } lateUpdate
     */
     setItemColumnTransaction(price, transactionType, count, lateUpdate) {
      if (typeof count !== "number") return;

      const targetPriceItemIndex = this.stockOrderBook.itemList.findLastIndex(function (item) {
        return item.price === price;
      });

      if (targetPriceItemIndex === -1) {
        const itemList = this.stockOrderBook.itemList.map(function (item) {
          return Object.assign({}, item);
        });

        itemList.push({
          orderCountSellST: transactionType === "SELL_STOP_LOSS" ? count : 0,
          orderCountSell: transactionType === "SELL" ? count : 0,
          countSellNumber: 0,
          countSellRemain: 0,
          price: price,
          countBuyRemain: 0,
          countBuyNumber: 0,
          orderCountBuy: transactionType === "BUY" ? count : 0,
          orderCountBuyST: transactionType === "BUY_STOP_LOSS" ? count : 0,
          hoNo: 0,
          orderYn: 'Y'
        });

        if (lateUpdate !== true) {
          this.stockOrderBook.itemList = Object.seal(itemList);
        }

        return;
      }

      const buyOrSellKeyMap = {
        SELL_STOP_LOSS: "orderCountSellST",
        SELL: "orderCountSell",
        BUY: "orderCountBuy",
        BUY_STOP_LOSS: "orderCountBuyST",
      };

      const buyOrSellKey = buyOrSellKeyMap[transactionType]
      this.stockOrderBook.itemList[targetPriceItemIndex][buyOrSellKey] = count
      this.stockOrderBook.itemList[targetPriceItemIndex].orderYn = 'Y'

      if (lateUpdate !== true) {
        this.stockOrderBook.itemList = Object.seal(
          this.stockOrderBook.itemList.map(function (item) {
            return Object.assign({}, item);
          })
        );
      }
    },    
    // 종목 정보 조회
    setStockInfo(newRStock, newSymbol) {
      if ( newSymbol != null ) {
        this.stockOrderBook.stepPrice  = newSymbol.minmov / newSymbol.pricescale;
        this.stockOrderBook.pricescale = stringCount(newSymbol.pricescale);
        if ( newRStock.data != null ) {
          this.stockOrderBook.yesterdayClosingPrice = newRStock.data.open;
          this.stockOrderBook.nowPrice   = newRStock.data.curPr;
          this.stockOrderBook.focusPrice = newRStock.data.curPr;
          this.stockOrderBook.highPrice = newRStock.data.high;
          this.stockOrderBook.lowPrice  = newRStock.data.low;
          this.stockOrderBook.buy1stPrice = newRStock.data.curPr; //매수1호가에 화면고정이 설정되어 초기값을 설정
          //this.stockOrderBook.buy1stPrice = newRStock.data.bidHo1
          //호가 설정
          
          //초기? 설정시 시간 딜레이 없으면 깜빡이고 사라짐...
          setTimeout(() => {
            this.setItemCountTransaction(1, 0, 0, newRStock.data.bidHo1, newRStock.data.bidRem1, newRStock.data.bidNo1)
            this.setItemCountTransaction(2, 0, 0, newRStock.data.bidHo2, newRStock.data.bidRem2, newRStock.data.bidNo2)
            this.setItemCountTransaction(3, 0, 0, newRStock.data.bidHo3, newRStock.data.bidRem3, newRStock.data.bidNo3)
            this.setItemCountTransaction(4, 0, 0, newRStock.data.bidHo4, newRStock.data.bidRem4, newRStock.data.bidNo4)
            this.setItemCountTransaction(5, 0, 0, newRStock.data.bidHo5, newRStock.data.bidRem5, newRStock.data.bidNo5)
            this.setItemCountTransaction(6, newRStock.data.offerRem1, newRStock.data.offerNo1, newRStock.data.offerHo1, 0, 0)
            this.setItemCountTransaction(7, newRStock.data.offerRem2, newRStock.data.offerNo2, newRStock.data.offerHo2, 0, 0)
            this.setItemCountTransaction(8, newRStock.data.offerRem3, newRStock.data.offerNo3, newRStock.data.offerHo3, 0, 0)
            this.setItemCountTransaction(9, newRStock.data.offerRem4, newRStock.data.offerNo4, newRStock.data.offerHo4, 0, 0)
            this.setItemCountTransaction(10, newRStock.data.offerRem5, newRStock.data.offerNo5, newRStock.data.offerHo5, 0, 0)
          }, 300)
        }
        this.stockOrderBook.itemList = []
        this.resetOrderData(newSymbol.name)
      }
    },
    sendOmsMessage(apiCode, body) {
      if ( !window.$store.getters['SocketOms/getIsConnectOms'] ) {
        window.$alert.alert('OMS 서버 연결 종료.')
        return
      }

      //비밀번호 추가
      body.accPass = this.getUsrAccPass;

      window.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body})
    },  
    setSellData(order) {
      if (this.account.accId != order.accId) return;
      if (!this.getUsrAccPass) return;
      if ( this.sellData.get(order.symCd) === undefined ) {
        this.sellData.set(order.symCd, new Map())
      } 

      if ( this.sellData.get(order.symCd).get(order.ordPrc) === undefined ) {
        this.sellData.get(order.symCd).set(order.ordPrc, new Array())
      }

      this.sellData.get(order.symCd).get(order.ordPrc).push(order)
    },
    setBuyData(order) {
      if (this.account.accId != order.accId) return;
      if (!this.getUsrAccPass) return;
      if ( this.buyData.get(order.symCd) === undefined ) {
        this.buyData.set(order.symCd, new Map())
      } 

      if ( this.buyData.get(order.symCd).get(order.ordPrc) === undefined ) {
        this.buyData.get(order.symCd).set(order.ordPrc, new Array())
      }

      this.buyData.get(order.symCd).get(order.ordPrc).push(order)
    },    
    setSellStData(order) {
      if (this.account.accId != order.accId) return;
      if (!this.getUsrAccPass) return;
      if ( this.sellStData.get(order.symCd) === undefined ) {
        this.sellStData.set(order.symCd, new Map())
      } 

      if ( this.sellStData.get(order.symCd).get(order.ordPrc) === undefined ) {
        this.sellStData.get(order.symCd).set(order.ordPrc, new Array())
      }

      this.sellStData.get(order.symCd).get(order.ordPrc).push(order)
    },
    setBuyStData(order) {
      if (this.account.accId != order.accId) return;
      if (!this.getUsrAccPass) return;
      if ( this.buyStData.get(order.symCd) === undefined ) {
        this.buyStData.set(order.symCd, new Map())
      } 

      if ( this.buyStData.get(order.symCd).get(order.ordPrc) === undefined ) {
        this.buyStData.get(order.symCd).set(order.ordPrc, new Array())
      }

      this.buyStData.get(order.symCd).get(order.ordPrc).push(order)
    },   
    deleteSellOrderData(symCd, price, ordId, accId) {
      if (this.account.accId != accId) return;
      if (!this.getUsrAccPass) return;
      if (this.sellData.get(symCd) == undefined){
        return;
      }
      let values = this.sellData.get(symCd).get(price)
      let vCnt   = 0
      let idx    = -1

      if (values == undefined || values == null){
        if ( this.symbol.name === symCd ) {
          this.setItemColumnTransaction(price, 'SELL', vCnt)
        }
        return
      } 

      values.forEach(function(value, i) {
        if ( value.ordId == ordId ) {
          idx = i
        } else {
          vCnt += value.ordQty
        }
      })

      values.splice(idx, 1)

      if ( values.length > 0 ) {
        this.sellData.get(symCd).set(price, values)
      } else {
        this.sellData.get(symCd).delete(price)
      }  

      if ( this.symbol.name === symCd ) {
        this.setItemColumnTransaction(price, 'SELL', vCnt)
      }
    },
    deleteBuyOrderData(symCd, price, ordId, accId) {
      if (this.account.accId != accId) return;
      if (!this.getUsrAccPass) return;
      if (this.buyData.get(symCd) == undefined){
        return;
      }
      let values = this.buyData.get(symCd).get(price)
      let vCnt   = 0
      let idx    = -1

      if (values == undefined || values == null){
        if ( this.symbol.name === symCd ) {
          this.setItemColumnTransaction(price, 'BUY', vCnt)
        }
        return
      } 

      values.forEach(function(value, i) {
        if ( value.ordId == ordId ) {
          idx = i
        } else {
          vCnt += value.ordQty
        }
      })

      values.splice(idx, 1)

      if ( values.length > 0 ) {
        this.buyData.get(symCd).set(price, values)
      } else {
        this.buyData.get(symCd).delete(price)
      }  

      if ( this.symbol.name === symCd ) {
        this.setItemColumnTransaction(price, 'BUY', vCnt)
      }
    },
    deleteSellStOrderData(symCd, price, ordId, accId) {
      if (this.account.accId != accId) return;
      if (!this.getUsrAccPass) return;
      let values = this.sellStData.get(symCd).get(price)
      let vCnt   = 0
      let idx    = -1

      if (values == undefined || values == null){
        console.log("deleteSellStOrderData dell fail ", values,symCd, price, ordId)

        if ( this.symbol.name === symCd ) {
          this.setItemColumnTransaction(price, 'SELL_STOP_LOSS', vCnt)
        }
        return
      } 

      values.forEach(function(value, i) {
        if ( value.ordId == ordId ) {
          idx = i
        } else {
          vCnt += value.ordQty
        }
      })

      values.splice(idx, 1)

      if ( values.length > 0 ) {
        this.sellStData.get(symCd).set(price, values)
      } else {
        this.sellStData.get(symCd).delete(price)
      }  

      if ( this.symbol.name === symCd ) {
        this.setItemColumnTransaction(price, 'SELL_STOP_LOSS', vCnt)
      }
    },
    deleteBuyStOrderData(symCd, price, ordId, accId) {
      if (this.account.accId != accId) return;
      if (!this.getUsrAccPass) return;
      let values = this.buyStData.get(symCd).get(price)
      let vCnt   = 0
      let idx    = -1

      if (values == undefined || values == null){
        console.log("deleteBuyStOrderData dell fail ", values,symCd, price, ordId)

        if ( this.symbol.name === symCd ) {
          this.setItemColumnTransaction(price, 'BUY_STOP_LOSS', vCnt)
        }
        return
      } 

      values.forEach(function(value, i) {
        if ( value.ordId == ordId ) {
          idx = i
        } else {
          vCnt += value.ordQty
        }
      })

      values.splice(idx, 1)

      if ( values.length > 0 ) {
        this.buyStData.get(symCd).set(price, values)
      } else {
        this.buyStData.get(symCd).delete(price)
      }  

      if ( this.symbol.name === symCd ) {
        this.setItemColumnTransaction(price, 'BUY_STOP_LOSS', vCnt)
      }
    },
    resetSellOrderData(symCd) {
      const data = this.sellData.get(symCd)

      if ( data !== undefined ) {
        for (const [key, values] of data) {
          let vCnt   = 0

          values.forEach(function(value) {
            //vCnt += value.ordQty - value.cxlReqQty - value.repReqQty
            vCnt += value.ordQty
          })

          if ( this.symbol.name === symCd ) {
            this.setItemColumnTransaction(key, 'SELL', vCnt)
          }
        }
      }
    },     
    resetBuyOrderData(symCd) {
      const data = this.buyData.get(symCd)

      if ( data !== undefined ) {
        for (const [key, values] of data) {
          let vCnt   = 0

          values.forEach(function(value) {
            //vCnt += value.ordQty - value.cxlReqQty - value.repReqQty
            vCnt += value.ordQty 
          })

          if ( this.symbol.name === symCd ) {
            this.setItemColumnTransaction(key, 'BUY', vCnt)        
          }
        }
      }
    },
    resetSellStOrderData(symCd) {
      const data = this.sellStData.get(symCd)

      if ( data !== undefined ) {
        for (const [key, values] of data) {
          let vCnt   = 0

          values.forEach(function(value) {
            // vCnt += value.ordQty - value.cxlReqQty - value.repReqQty
            vCnt += value.ordQty
          })

          if ( this.symbol.name === symCd ) {
            this.setItemColumnTransaction(key, 'SELL_STOP_LOSS', vCnt)        
          }
        }
      }
    },     
    resetBuyStOrderData(symCd) {
      const data = this.buyStData.get(symCd)

      if ( data !== undefined ) {
        for (const [key, values] of data) {
          let vCnt   = 0

          values.forEach(function(value) {
            //vCnt += value.ordQty - value.cxlReqQty - value.repReqQty
            vCnt += value.ordQty
          })

          if ( this.symbol.name === symCd ) {
            this.setItemColumnTransaction(key, 'BUY_STOP_LOSS', vCnt)        
          }
        }
      }
    },
    resetOrderData(symCd) {
      this.resetSellOrderData(symCd)
      this.resetBuyOrderData(symCd)
      this.resetSellStOrderData(symCd)
      this.resetBuyStOrderData(symCd)
    },   
    setSellValue(symCd, price) {
      if (!this.getUsrAccPass) return;
      if ( this.sellData.get(symCd) === undefined 
        || this.sellData.get(symCd).get(price) === undefined ) {
        return
      } 

      let vCnt = 0

      this.sellData.get(symCd).get(price).forEach(function(value) {
        vCnt += Utils.nvl(value.ordQty, 0) - Utils.nvl(value.cxlReqQty, 0) - Utils.nvl(value.repReqQty, 0)
      })

      // 
      if ( this.symbol.name === symCd ) {
        this.setItemColumnTransaction(price, 'SELL', vCnt)      
      }
    },
    setBuyValue(symCd, price) {
      if (!this.getUsrAccPass) return;
      if ( this.buyData.get(symCd) === undefined 
        || this.buyData.get(symCd).get(price) === undefined ) {
        return
      } 

      let vCnt = 0

      this.buyData.get(symCd).get(price).forEach(function(value) {
        vCnt += Utils.nvl(value.ordQty, 0) - Utils.nvl(value.cxlReqQty, 0) - Utils.nvl(value.repReqQty, 0)
      })

      // 
      if ( this.symbol.name === symCd ) {
        this.setItemColumnTransaction(price, 'BUY', vCnt)      
      }
    },
    setSellStValue(symCd, price) {

      if ( this.sellStData.get(symCd) === undefined 
        || this.sellStData.get(symCd).get(price) === undefined ) {
        return
      } 

      let vCnt = 0

      this.sellStData.get(symCd).get(price).forEach(function(value) {
        vCnt += Utils.nvl(value.ordQty, 0) - Utils.nvl(value.cxlReqQty, 0) - Utils.nvl(value.repReqQty, 0)
      })

      // 
      if ( this.symbol.name === symCd ) {
        this.setItemColumnTransaction(price, 'SELL_STOP_LOSS', vCnt)      
      }
    },
    setBuyStValue(symCd, price) {
      if ( this.buyStData.get(symCd) === undefined 
        || this.buyStData.get(symCd).get(price) === undefined ) {
        return
      } 

      let vCnt = 0

      this.buyStData.get(symCd).get(price).forEach(function(value) {
        vCnt += Utils.nvl(value.ordQty, 0) - Utils.nvl(value.cxlReqQty, 0) - Utils.nvl(value.repReqQty, 0)
      })

      // 
      if ( this.symbol.name === symCd ) {
        this.setItemColumnTransaction(price, 'BUY_STOP_LOSS', vCnt)      
      }
    },
    sellStock(price, count) {
      if (this.sellAbleQty == 0){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 주문가능 수량이 부족합니다.'
        })

        return
      }
      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        accPass : this.getUsrAccPass
      }

      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매도(지정가)' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + count + '</br>' +
          '<b style="font-weight:600;">가격</b> : ' + price.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">매도 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0001', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0001', order)
      }
    },
    buyStock(price, count) {
      if (this.buyAbleQty == 0){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 주문가능 수량이 부족합니다.'
        })

        return
      }
      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '1',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        accPass : this.getUsrAccPass
      }


      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매수(지정가)' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + count + '</br>' +
          '<b style="font-weight:600;">가격</b> : ' + price.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">매수 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0001', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0001', order)
      }
    },
    sellStStock(price, count) {
      if (this.sellAbleQty == 0){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 주문가능 수량이 부족합니다.'
        })

        return
      }
      let stTpCd = "2";
      //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
      if (this.stockOrderBook.nowPrice == price){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 현재가에 ST주문이 불가능합니다.'
        })
        return
      } else if (this.stockOrderBook.nowPrice < price){
        stTpCd = "1"
      }
      
      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '2',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        stTpCd : stTpCd,
        accPass : this.getUsrAccPass
      }

      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : ST매도' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + count + '</br>' +
          '<b style="font-weight:600;">가격</b> : ' + price.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">ST매도 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0004', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0004', order)
      }
    },
    buyStStock(price, count) {
      if (this.buyAbleQty == 0){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 주문가능 수량이 부족합니다.'
        })

        return
      }
      let stTpCd = "2";
      //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
      if (this.stockOrderBook.nowPrice == price){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 현재가에 ST주문이 불가능합니다.'
        })
        return
      } else if (this.stockOrderBook.nowPrice < price){
        stTpCd = "1"
      }
      
      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '2',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        stTpCd : stTpCd,
        accPass : this.getUsrAccPass
      }

      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : ST매수' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + count + '</br>' +
          '<b style="font-weight:600;">가격</b> : ' + price.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">ST매수 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0004', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0004', order)
      }
    },
    // sellSlStock() {
    //   // apiType : 주문타입     //SLNEW (신규), SLCANCEL (취소)
    //   // stkCd : 종목코드        
    //   // ordPrc : 주문가격       
    //   // uId : 사용자ID
    //   // accNo : 계좌번호 
    //   // ordNo : 주문번호 (null) 
    //   // tickVal : 틱값
    //   // chkVal : 체크값     // '1' 익절 '2' 손절 

    //   const order = {
    //     usrKey: Utils.getUuidv4(),
    //     accId : this.account.accId,
    //     symCd : this.symbol.name,
    //     symNo : this.symbol.symbolno,
    //     trdDt : '',
    //     ordId : 0,
    //     orgOrdNo: 0,
    //     ordSdCd : '1',
    //     ordTpCd : '1',
    //     ordPrc : 0,
    //     tickVal : this.sellSlTick,
    //     chkVal : '2', // '1' 익절 '2' 손절
    //     accPass : this.getUsrAccPass
    //   }
    //   let apiCode = "OMS0007"
    //   if (this.slStockVal2){ //체크값이 true이면 삭제처리
    //     apiCode = "OMS0008"
    //   }
    //   this.sendOmsMessage(apiCode, order)
    // },
    // buySlStock() {
    //   const order = {
    //     usrKey: Utils.getUuidv4(),
    //     accId : this.account.accId,
    //     symCd : this.symbol.name,
    //     symNo : this.symbol.symbolno,
    //     trdDt : '',
    //     ordId : 0,
    //     orgOrdNo: 0,
    //     ordSdCd : '1',
    //     ordTpCd : '1',
    //     ordPrc : 0,
    //     tickVal : this.buySlTick,
    //     chkVal : '1', // '1' 익절 '2' 손절 
    //     accPass : this.getUsrAccPass
    //   }

    //   let apiCode = "OMS0007"
    //   if (this.slStockVal1){ //체크값이 true이면 삭제처리
    //     apiCode = "OMS0008"
    //   }
    //   this.sendOmsMessage(apiCode, order)
    // },
    cancelSellStock(price) {
      if ( this.sellData.get(this.symbol.name) === undefined 
        || this.sellData.get(this.symbol.name).get(price) === undefined ) {
        return
      } 

      let orgOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      for ( let value of this.sellData.get(this.symbol.name).get(price) ) {
        if ( value.ordPrc === price ) {
          totCnt++;
          totOrdQty += value.ordQty

          const orgOrder = {
            accId : this.account.accId,
            symCd : this.symbol.name,
            symNo : this.symbol.symbolno,
            trdDt : value.trdDt,
            ordId : 0,
            orgOrdNo: value.ordId,
            ordSdCd : value.ordSdCd,
            ordTpCd : value.ordTpCd,
            ordPrc : value.ordPrc,
            ordQty : value.ordQty,
            repReqQty : value.repReqQty,
            cxlReqQty : value.ordQty,
            orgUsrKey : value.usrKey
          }

          orgOrders.push(orgOrder)
        }
      }
      
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          accPass : this.getUsrAccPass,
          orderList : orgOrders,
          ordSdCd : "1"
      }

      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매도취소' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + totOrdQty + '</br>' +
          '<b style="font-weight:600;">주문가격</b> : ' + price.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">매도취소 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0002', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0002', order)
      }

      //취소 처리될 주문 삭제
      orgOrders.forEach(item=>{
        this.deleteSellOrderData(item.symCd, item.ordPrc, item.orgOrdNo, item.accId)
      })
    },
    cancelBuyStock(price) {
      if ( this.buyData.get(this.symbol.name) === undefined 
        || this.buyData.get(this.symbol.name).get(price) === undefined ) {
        return
      } 

      let orgOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      for ( let value of this.buyData.get(this.symbol.name).get(price) ) {
        if ( value.ordPrc === price ) {
          totCnt++;
          totOrdQty += value.ordQty

          const orgOrder = {
            accId : this.account.accId,
            symCd : this.symbol.name,
            symNo : this.symbol.symbolno,
            trdDt : value.trdDt,
            ordId : 0,
            orgOrdNo: value.ordId,
            ordSdCd : value.ordSdCd,
            ordTpCd : value.ordTpCd,
            ordPrc : value.ordPrc,
            ordQty : value.ordQty,
            repReqQty : value.repReqQty,
            cxlReqQty : value.ordQty,
            orgUsrKey : value.usrKey
          }

          orgOrders.push(orgOrder)
        }
      }
      
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          accPass : this.getUsrAccPass,
          orderList : orgOrders,
          ordSdCd : "2"
      }

      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매수취소' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + totOrdQty + '</br>' +
          '<b style="font-weight:600;">주문가격</b> : ' + price.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">매수취소 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0002', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0002', order)
      }

      //취소 처리될 주문 삭제
      orgOrders.forEach(item=>{
        this.deleteBuyOrderData(item.symCd, item.ordPrc, item.orgOrdNo, item.accId)
      })
    },
    cancelSellStStock(price) {
      if ( this.sellStData.get(this.symbol.name) === undefined 
        || this.sellStData.get(this.symbol.name).get(price) === undefined ) {
        return
      } 

      // for ( let value of this.sellStData.get(this.symbol.name).get(price) ) {
      //   if ( value.ordPrc === price ) {
      //     value.cxlReqQty = value.ordQty
      //     value.orgOrdNo  = value.ordId
      //     value.accPass = this.getUsrAccPass

      //     // 
      //     this.sendOmsMessage('OMS0005', value)
      //   }
      // }

      let orgStOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      for ( let value of this.sellStData.get(this.symbol.name).get(price) ) {
        if ( value.ordPrc === price ) {
          totCnt++;
          totOrdQty += value.ordQty

          const orgOrder = {
            accId : this.account.accId,
            symCd : this.symbol.name,
            symNo : this.symbol.symbolno,
            trdDt : value.trdDt,
            ordId : 0,
            orgOrdNo: value.ordId,
            ordSdCd : value.ordSdCd,
            ordTpCd : value.ordTpCd,
            ordPrc : value.ordPrc,
            ordQty : value.ordQty,
            repReqQty : value.repReqQty,
            cxlReqQty : value.ordQty,
            orgUsrKey : value.usrKey
          }

          orgStOrders.push(orgOrder)
        }
      }
      
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          ordSdCd : '1',
          accPass : this.getUsrAccPass,
          orderList : orgStOrders
      }

      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : ST매도취소' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + totOrdQty + '</br>' +
          '<b style="font-weight:600;">주문가격</b> : ' + price.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">ST매도취소 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0005', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0005', order)
      }

      //취소 처리될 주문 삭제
      orgStOrders.forEach(item=>{
        this.deleteSellStOrderData(item.symCd, item.ordPrc, item.orgOrdNo, item.accId)
      })
    },
    cancelBuyStStock(price) {
      if ( this.buyStData.get(this.symbol.name) === undefined 
        || this.buyStData.get(this.symbol.name).get(price) === undefined ) {
        return
      } 

      // for ( let value of this.buyStData.get(this.symbol.name).get(price) ) {
      //   if ( value.ordPrc === price ) {
      //     value.cxlReqQty = value.ordQty
      //     value.orgOrdNo  = value.ordId
      //     value.accPass = this.getUsrAccPass

      //     // 
      //     this.sendOmsMessage('OMS0005', value)
      //   }
      // }

      let orgOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      for ( let value of this.buyStData.get(this.symbol.name).get(price) ) {
        if ( value.ordPrc === price ) {
          totCnt++;
          totOrdQty += value.ordQty

          const orgOrder = {
            accId : this.account.accId,
            symCd : this.symbol.name,
            symNo : this.symbol.symbolno,
            trdDt : value.trdDt,
            ordId : 0,
            orgOrdNo: value.ordId,
            ordSdCd : value.ordSdCd,
            ordTpCd : value.ordTpCd,
            ordPrc : value.ordPrc,
            ordQty : value.ordQty,
            repReqQty : value.repReqQty,
            cxlReqQty : value.ordQty,
            orgUsrKey : value.usrKey
          }

          orgOrders.push(orgOrder)
        }
      }
      
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          ordSdCd : '2',
          accPass : this.getUsrAccPass,
          orderList : orgOrders
      }

      if (this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : ST매수취소' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + totOrdQty + '</br>' +
          '<b style="font-weight:600;">주문가격</b> : ' + price.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">ST매수취소 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0005', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0005', order)
      }

      //취소 처리될 주문 삭제
      orgOrders.forEach(item=>{
        this.deleteBuyStOrderData(item.symCd, item.ordPrc, item.orgOrdNo, item.accId)
      })
    },
    cancelSellAll(){ //SELL 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelSellAllFlag){
        return;
      }

      this.btnCancelSellAllFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '1',
        accPass : this.getUsrAccPass
      }

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        this.$alert.confirm('매도주문을 취소 하시겠습니까?', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnCancelSellAllFlag = true
            return;
          }else{
            this.sendOmsMessage("OMS0010", order)
          }
        })
      }else{
        this.sendOmsMessage("OMS0010", order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelSellAllFlag){
        setTimeout(() => {
          this.btnCancelSellAllFlag = true
        }, 1500)
      }
    },
    cancelBuyAll(){ //BUY 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelBuyAllFlag){
        return;
      }

      this.btnCancelBuyAllFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '2',
        accPass : this.getUsrAccPass
      }

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        this.$alert.confirm('매수주문을 취소 하시겠습니까?', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnCancelBuyAllFlag = true
            return;
          }else{
            this.sendOmsMessage("OMS0010", order)
          }
        })
      }else{
        this.sendOmsMessage("OMS0010", order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelBuyAllFlag){
        setTimeout(() => {
          this.btnCancelBuyAllFlag = true
        }, 1500)
      }
    },
    cancelSellStAll(){ //SELL STOP 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelSellStAllFlag){
        return;
      }

      this.btnCancelSellStAllFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '1',
        accPass : this.getUsrAccPass
      }

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        this.$alert.confirm('ST매도주문을 취소 하시겠습니까?', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnCancelSellStAllFlag = true
            return;
          }else{
            this.sendOmsMessage("OMS0011", order)
          }
        })
      }else{
        this.sendOmsMessage("OMS0011", order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelSellStAllFlag){
        setTimeout(() => {
          this.btnCancelSellStAllFlag = true
        }, 1500)
      }
    },
    cancelBuyStAll(){ //BUY STOP 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }
      
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelBuyStAllFlag){
        return;
      }

      this.btnCancelBuyStAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '2',
        accPass : this.getUsrAccPass
      }

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        this.$alert.confirm('ST매수주문을 취소 하시겠습니까?', '주문 확인')
        .then((response) => {
          if (!response) {
            this.btnCancelBuyStAllFlag = true
            return;
          }else{
            this.sendOmsMessage("OMS0011", order)
          }
        })
      }else{
        this.sendOmsMessage("OMS0011", order)
      }
      
      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelBuyStAllFlag){
        setTimeout(() => {
          this.btnCancelBuyStAllFlag = true
        }, 1500)
      }
    },
    repairSellStock(originPrice, targetPrice) {
      if ( this.sellData.get(this.symbol.name) === undefined 
        || this.sellData.get(this.symbol.name).get(originPrice) === undefined ) {
        return
      }

      let orgOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      for ( let value of this.sellData.get(this.symbol.name).get(originPrice) ) {
        if ( value.ordPrc === originPrice ) {
          totCnt++;
          totOrdQty += value.ordQty

          const orgOrder = {
            accId : this.account.accId,
            symCd : this.symbol.name,
            symNo : this.symbol.symbolno,
            trdDt : value.trdDt,
            ordId : 0,
            orgOrdNo: value.ordId,
            ordSdCd : value.ordSdCd,
            ordTpCd : value.ordTpCd,
            ordPrc : targetPrice,
            ordQty : value.ordQty,
            repReqQty : value.ordQty,
            cxlReqQty : 0,
            orgUsrKey : value.usrKey
          }

          orgOrders.push(orgOrder)
        }
      }

      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          accPass : this.getUsrAccPass,
          orderList : orgOrders,
          ordSdCd : "1"
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매도정정' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + totOrdQty + '</br>' +
          '<b style="font-weight:600;">원주문가격</b> : ' + originPrice.toFixed(this.stockOrderBook.pricescale) + '</br>' +
          '<b style="font-weight:600;">정정가격</b> : ' + targetPrice.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">매도정정 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0003', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0003', order)
      }

      //정정 처리될 주문 삭제
      orgOrders.forEach(item=>{
        this.deleteSellOrderData(item.symCd, originPrice, item.orgOrdNo, item.accId);
      })
      
    },
    repairBuyStock(originPrice, targetPrice) {
      if ( this.buyData.get(this.symbol.name) === undefined 
        || this.buyData.get(this.symbol.name).get(originPrice) === undefined ) {
        return
      }

      let orgOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      for ( let value of this.buyData.get(this.symbol.name).get(originPrice) ) {
        if ( value.ordPrc === originPrice ) {
          totCnt += value.ordQty
          totOrdQty += value.ordQty

          const orgOrder = {
            accId : this.account.accId,
            symCd : this.symbol.name,
            symNo : this.symbol.symbolno,
            trdDt : value.trdDt,
            ordId : 0,
            orgOrdNo: value.ordId,
            ordSdCd : value.ordSdCd,
            ordTpCd : value.ordTpCd,
            ordPrc : targetPrice,
            ordQty : value.ordQty,
            repReqQty : value.ordQty,
            cxlReqQty : 0,
            orgUsrKey : value.usrKey
          }

          orgOrders.push(orgOrder)          
        }
      }

      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          accPass : this.getUsrAccPass,
          orderList : orgOrders,
          ordSdCd : "2"
      }

      

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : 매수정정' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + totCnt + '</br>' +
          '<b style="font-weight:600;">원주문가격</b> : ' + originPrice.toFixed(this.stockOrderBook.pricescale) + '</br>' +
          '<b style="font-weight:600;">정정가격</b> : ' + targetPrice.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">매수정정 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0003', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0003', order)
      }

      //정정 처리될 주문 삭제
      orgOrders.forEach(item=>{
        this.deleteBuyOrderData(item.symCd, originPrice, item.orgOrdNo, item.accId);
      })
    },
    repairSellStStock(originPrice, targetPrice) {
      if ( this.sellStData.get(this.symbol.name) === undefined 
        || this.sellStData.get(this.symbol.name).get(originPrice) === undefined ) {
        return
      } 

      let stTpCd = "2";
      //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
      if (this.stockOrderBook.nowPrice == targetPrice){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 현재가에 ST주문이 불가능합니다.'
        })
        return;
      } else if (this.stockOrderBook.nowPrice < targetPrice){
        stTpCd = "1"
      }

      // let orgOrders = new Array()

      // for ( let value of this.sellStData.get(this.symbol.name).get(originPrice) ) {
      //   if ( value.ordPrc === originPrice ) {
      //     value.repReqQty = value.ordQty

      //     orgOrders.push(value)
      //   }
      // }

      // for ( let order of orgOrders ) {
      //   const value = {
      //     usrKey: Utils.getUuidv4(),
      //     accId : this.account.accId,
      //     symCd : this.symbol.name,
      //     symNo : this.symbol.symbolno,
      //     trdDt : order.trdDt,
      //     ordId : 0,
      //     orgOrdNo: order.ordId,
      //     ordSdCd : order.ordSdCd,
      //     ordTpCd : order.ordTpCd,
      //     ordPrc : targetPrice,
      //     ordQty : order.ordQty,
      //     repReqQty : order.repReqQty,
      //     cxlReqQty : 0,
      //     orgUsrKey : order.usrKey,
      //     stTpCd : stTpCd,
      //     accPass : this.getUsrAccPass,
      //   }

      //   // 
      //   this.sendOmsMessage('OMS0006', value)
      // } 

      let orgOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      for ( let value of this.sellStData.get(this.symbol.name).get(originPrice) ) {
        if ( value.ordPrc === originPrice ) {
          totCnt++;
          totOrdQty += value.ordQty

          const orgOrder = {
            accId : this.account.accId,
            symCd : this.symbol.name,
            symNo : this.symbol.symbolno,
            trdDt : value.trdDt,
            ordId : 0,
            orgOrdNo: value.ordId,
            ordSdCd : value.ordSdCd,
            ordTpCd : value.ordTpCd,
            ordPrc : targetPrice,
            ordQty : value.ordQty,
            repReqQty : value.ordQty,
            cxlReqQty : 0,
            orgUsrKey : value.usrKey,
            stTpCd : stTpCd,
          }

          orgOrders.push(orgOrder)
        }
      }

      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          ordSdCd : '1',
          stTpCd : stTpCd,
          accPass : this.getUsrAccPass,
          orderList : orgOrders
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : ST매도정정' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + totOrdQty + '</br>' +
          '<b style="font-weight:600;">원주문가격</b> : ' + originPrice.toFixed(this.stockOrderBook.pricescale) + '</br>' +
          '<b style="font-weight:600;">정정가격</b> : ' + targetPrice.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">ST매도정정 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0006', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0006', order)
      }

      //정정 처리될 주문 삭제
      orgOrders.forEach(item=>{
        this.deleteSellStOrderData(item.symCd, originPrice, item.orgOrdNo, item.accId);
      })
    },
    repairBuyStStock(originPrice, targetPrice) {
      if ( this.buyStData.get(this.symbol.name) === undefined 
        || this.buyStData.get(this.symbol.name).get(originPrice) === undefined ) {
        return
      } 

      let stTpCd = "2";
      //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
      if (this.stockOrderBook.nowPrice == targetPrice){
        this.$notify({ group: 'notification', clean: true })

        this.$notify({
          group : 'notification',
          title : 'FAIL',
          text : '[주문거부] 현재가에 ST주문이 불가능합니다.'
        })
        return;
      } else if (this.stockOrderBook.nowPrice < targetPrice){
        stTpCd = "1"
      }

      // let orgOrders = new Array()

      // for ( let value of this.buyStData.get(this.symbol.name).get(originPrice) ) {
      //   if ( value.ordPrc === originPrice ) {
      //     value.repReqQty = value.ordQty

      //     orgOrders.push(value)
      //   }
      // }

      // for ( let order of orgOrders ) {
      //   const value = {
      //     usrKey: Utils.getUuidv4(),
      //     accId : this.account.accId,
      //     symCd : this.symbol.name,
      //     symNo : this.symbol.symbolno,
      //     trdDt : order.trdDt,
      //     ordId : 0,
      //     orgOrdNo: order.ordId,
      //     ordSdCd : order.ordSdCd,
      //     ordTpCd : order.ordTpCd,
      //     ordPrc : targetPrice,
      //     ordQty : order.ordQty,
      //     repReqQty : order.repReqQty,
      //     cxlReqQty : 0,
      //     orgUsrKey : order.usrKey,
      //     stTpCd : stTpCd,
      //     accPass : this.getUsrAccPass,
      //   }

      //   this.sendOmsMessage('OMS0006', value)
      // } 

      let orgOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      for ( let value of this.buyStData.get(this.symbol.name).get(originPrice) ) {
        if ( value.ordPrc === originPrice ) {
          totCnt += value.ordQty
          totOrdQty += value.ordQty

          const orgOrder = {
            accId : this.account.accId,
            symCd : this.symbol.name,
            symNo : this.symbol.symbolno,
            trdDt : value.trdDt,
            ordId : 0,
            orgOrdNo: value.ordId,
            ordSdCd : value.ordSdCd,
            ordTpCd : value.ordTpCd,
            ordPrc : targetPrice,
            ordQty : value.ordQty,
            repReqQty : value.ordQty,
            cxlReqQty : 0,
            orgUsrKey : value.usrKey,
            stTpCd : stTpCd,
          }

          orgOrders.push(orgOrder)          
        }
      }

      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          ordSdCd : '2',
          stTpCd : stTpCd,
          accPass : this.getUsrAccPass,
          orderList : orgOrders
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : ST매수정정' + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + totCnt + '</br>' +
          '<b style="font-weight:600;">원주문가격</b> : ' + originPrice.toFixed(this.stockOrderBook.pricescale) + '</br>' +
          '<b style="font-weight:600;">정정가격</b> : ' + targetPrice.toFixed(this.stockOrderBook.pricescale) + '</br></br>' +
          '<b style="font-weight:600;">ST매수정정 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{
            this.sendOmsMessage('OMS0006', order)
          }
        })
      }else{
        this.sendOmsMessage('OMS0006', order)
      }

      //정정 처리될 주문 삭제
      orgOrders.forEach(item=>{
        this.deleteBuyStOrderData(item.symCd, originPrice, item.orgOrdNo, item.accId);
      })
    },
    soundOn(file){
      if (this.orderSetting != undefined && this.orderSetting.soundAlert){
        new Audio("/wav"+file).play();
      }
    },
    completeOrder(res) {
      let body = res.body;
      let apiCode = res.apiCode
    //completeOrder(apiCode, body) {

      // 주문
      if ( apiCode == 'OMS0001' ) {
        if ( body.ordSdCd == '1' ) {
         
          if ( body.execYn == 'Y' ) {
            this.deleteSellOrderData(body.symCd, body.ordPrc, body.ordId, body.accId) 
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[주문완료] 주문이 처리되었습니다.' , body : body , apiCode:apiCode});           
          } else {
              this.soundOn('/SELL_ORDER.WAV')
            if (body.ordTpCd == "2"){
              this.btnSellMarketFlag = true;
            }
            this.setSellData(body)
            this.setSellValue(body.symCd, body.ordPrc)
          }

        } else if ( body.ordSdCd == '2' ) {
          if ( body.execYn == 'Y' ) {        
            this.deleteBuyOrderData(body.symCd, body.ordPrc, body.ordId, body.accId)
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[주문완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode})
          } else {
              this.soundOn('/BUY_ORDER.WAV')
            if (body.ordTpCd == "2"){
              this.btnBuyMarketFlag = true;
            }
            this.setBuyData(body)
            this.setBuyValue(body.symCd, body.ordPrc)           
          }
        }

      // 취소  
      } else if ( apiCode == 'OMS0002' ) {
        if ( body.execYn == 'Y' ) {
          if (body.delList != null){
            body.delList.forEach(del => {
              if ( del.ordSdCd == '1' ) {
                //this.deleteSellOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
              }else if ( del.ordSdCd == '2'){
                //this.deleteBuyOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
              }
            })
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode})
          }
        }
      // 정정
      } else if ( apiCode == 'OMS0003' ) {
        if ( body.execYn == 'Y' ) {
          if (body.delList != null){
            body.delList.forEach(del => {
              if ( del.ordSdCd == '1' ) {
                //개별정정 시 선삭제를 못하여 후삭제
                if (del.selectMod != undefined && del.selectMod == "Y"){
                  this.deleteSellOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
                }
                this.setSellData(del)
                this.setSellValue(del.symCd, del.ordPrc, del.accId)
              }else if ( del.ordSdCd == '2'){
                //개별정정 시 선삭제를 못하여 후삭제
                if (del.selectMod != undefined && del.selectMod == "Y"){
                  this.deleteBuyOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
                }
                this.setBuyData(del)
                this.setBuyValue(del.symCd, del.ordPrc, del.accId)
              }
            })
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[정정완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode})
          }
        }
      // STOP 주문
      } else if (apiCode == 'OMS0004') {
        if ( body.ordSdCd == '1' ) {
          if ( body.execYn == 'Y' ) {
            this.deleteSellStOrderData(body.symCd, body.ordPrc, body.ordId, body.accId)

            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST주문완료] 주문이 처리되었습니다.' })
          } else {
            this.setSellStData(body)
            this.setSellStValue(body.symCd, body.ordPrc, body.accId)
          }
        } else if ( body.ordSdCd == '2' ) {
          if ( body.execYn == 'Y' ) {        
            this.deleteBuyStOrderData(body.symCd, body.ordPrc, body.ordId, body.accId)

            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST주문완료] 주문이 처리되었습니다.' })
          } else {
            this.setBuyStData(body)
            this.setBuyStValue(body.symCd, body.ordPrc, body.accId)           
          }
        }
      // STOP 취소
      }  else if ( apiCode == 'OMS0005') {
        if ( body.execYn == 'Y' ) {
          if (body.delList != null){
            body.delList.forEach(del => {
              if ( del.ordSdCd == '1' ) {
                //this.deleteSellStOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo)
              }else if ( del.ordSdCd == '2'){
                //this.deleteBuyStOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo)
              }
            })
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode })
          }
        }



        // if ( body.ordSdCd == '1' ) {
        //   if ( body.execYn == 'Y' ) {
        //     //this.deleteSellStOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo)

        //     this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 주문이 처리되었습니다.' })
        //   }

        // } else if ( body.ordSdCd == '2' ) {
        //   if ( body.execYn == 'Y' ) {        
        //     //this.deleteBuyStOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo)

        //     this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 주문이 처리되었습니다.' })
        //   }
        // }
      // STOP 정정
      } else if ( apiCode == 'OMS0006') {
        if ( body.execYn == 'Y' ) {
          if (body.delList != null){
            body.delList.forEach(del => {
              if ( del.ordSdCd == '1' ) {
                //this.deleteSellStOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo)
                this.setSellStData(del)
                this.setSellStValue(del.symCd, del.ordPrc, del.accId)
              }else if ( del.ordSdCd == '2'){
                // this.deleteBuyStOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo)
                this.setBuyStData(del)
                this.setBuyStValue(del.symCd, del.ordPrc, del.accId)
              }
            })
           // this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST정정완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode})
          }
        }


        // if ( body.ordSdCd == '1' ) {
        //   if ( body.execYn == 'Y' ) {
        //     //this.deleteSellStOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo)
        //     this.setSellStData(body)
        //     this.setSellStValue(body.symCd, body.ordPrc)

        //     this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST정정완료] 주문이 처리되었습니다.' })
        //   }

        // } else if ( body.ordSdCd == '2' ) {
        //   if ( body.execYn == 'Y' ) {        
        //     //this.deleteBuyStOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo)
        //     this.setBuyStData(body)
        //     this.setBuyStValue(body.symCd, body.ordPrc)

        //     this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST정정완료] 주문이 처리되었습니다.' })
        //   }
        // }
      // STOP LOSS 주문
      } else if ( apiCode == 'OMS0007') {
        if ( body.chkVal == '1' && body.accId == this.account.accId) {
            //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
        } else if ( body.chkVal == '2' && body.accId == this.account.accId) {
            //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
        }
      // STOP LOSS 취소 
      } else if ( apiCode == 'OMS0008') {
        if ( body.chkVal == '1' && body.accId == this.account.accId) {
          if ( body.execYn == 'Y' ) {
            //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
          }
        } else if ( body.chkVal == '2' && body.accId == this.account.accId) {
          if ( body.execYn == 'Y' ) {
            //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
          }
        }
      // 종목선택 취소
      } else if ( apiCode == 'OMS0010') {
        if ( body.ordSdCd == '1' ) {
          if ( body.execYn == 'Y' ) {
            if (body.delList != null){
              body.delList.forEach(del => {
                this.deleteSellOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
              })
            }
            this.btnCancelSellAllFlag = true;
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 주문이 처리되었습니다.' ,body : body , apiCode:apiCode})
          }
        } else if ( body.ordSdCd == '2' ) {
          if ( body.execYn == 'Y' ) { 
            if (body.delList != null){
              body.delList.forEach(del => {
                this.deleteBuyOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
              })
            }
            this.btnCancelBuyAllFlag = true;
            //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 주문이 처리되었습니다.' , body : body , apiCode:apiCode})
          }
        }
        // STOP 종목선택 취소
      } else if ( apiCode == 'OMS0011') {
          if ( body.ordSdCd == '1' ) {
            if ( body.execYn == 'Y' ) {
              if (body.delList != null){
                body.delList.forEach(del => {
                  this.deleteSellStOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
                })
              }
              this.btnCancelSellStAllFlag = true;
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode})
            }
          } else if ( body.ordSdCd == '2' ) {
            if ( body.execYn == 'Y' ) {        
              if (body.delList != null){
                body.delList.forEach(del => {
                  this.deleteBuyStOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
                })
              }
              this.btnCancelBuyStAllFlag = true;
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode})
            }
          }
      // 상단 일괄 취소 버튼
      } else if ( apiCode == 'OMS0012') {
        if ( body.execYn == 'Y' ) {
          body.delList.forEach(del => {
            //같은 종목만 삭제 처리
            if (del.symCd == this.symbol.name){
              if ( del.ordSdCd == '1' ) {
                this.deleteSellOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
              }else{
                this.deleteBuyOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
              }
            }           
          });

          //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 주문이 처리되었습니다.' , body : body , apiCode:apiCode})
        }
      // 전/현 종목 청산
      } else if ( apiCode == 'OMS0013') {
        if (body.delList != undefined){
          body.delList.forEach(del => {
            //같은 종목만 삭제 처리
            if (del.symCd == this.symbol.name){
              if ( del.ordSdCd == '1' ) {
                if (body.delType == "ORD"){
                  this.deleteSellOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
                }else{
                  this.deleteSellStOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
                }
                
              }else if(del.ordSdCd == '2'){
                if (body.delType == "ORD"){
                  this.deleteBuyOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
                }else{
                  this.deleteBuyStOrderData(del.symCd, del.orgOrdPrc, del.orgOrdNo, del.accId)
                }
                
              }else{
                if ( del.chkVal == '1' ) {
                  if ( del.execYn == 'Y' ) {
                    //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
                  }
                } else if ( del.chkVal == '2' ) {
                  if ( del.execYn == 'Y' ) {        
                    //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
                  }
                }
              }
            }           
          });
        }

        if ( body.execYn == 'Y' ) {
          this.btnCancelAllFlag = true
          //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[청산완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode})
        }

      // PanelComp2 청산버튼 - 선택 종목의 보유포지션 청산
      } else if ( apiCode == 'OMS0014') {
        if ( body.execYn == 'Y' ) {
          //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[청산완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode})
        }
      } else if ( apiCode == 'OMS0015'){
          console.log("OMS0015 >>>> ", body);
          //ordApiType 정정, 취소 구분
        if (body.ordApiType == 'OMS0003'){ //정정
          if ( body.ordSdCd == '1' ) {
            if ( body.execYn == 'Y' ) {
              this.deleteSellOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo, body.accId)
              
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 원주문이 이미 처리되었습니다.' })
            }

          } else if ( body.ordSdCd == '2' ) {
            if ( body.execYn == 'Y' ) {
              this.deleteBuyOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo, body.accId)
              
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[취소완료] 원주문이 이미 처리되었습니다.' })
            }
          }
        } else if (body.ordApiType == 'OMS0005'){ //STOP 취소
          if ( body.ordSdCd == '1' ) {
            if ( body.execYn == 'Y' ) {
              this.deleteSellStOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo, body.accId)
              
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 원주문이 이미 처리되었습니다.' })
            }

          } else if ( body.ordSdCd == '2' ) {
            if ( body.execYn == 'Y' ) {
              this.deleteBuyStOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo, body.accId)
              
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 원주문이 이미 처리되었습니다.' })
            }
          }          
        } else if (body.ordApiType == 'OMS0006'){ //STOP 정정
          if ( body.ordSdCd == '1' ) {
            if ( body.execYn == 'Y' ) {
              this.deleteSellStOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo, body.accId)
              
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 원주문이 이미 처리되었습니다.' })
            }

          } else if ( body.ordSdCd == '2' ) {
            if ( body.execYn == 'Y' ) {
              this.deleteBuyStOrderData(body.symCd, body.orgOrdPrc, body.orgOrdNo, body.accId)
              
              //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[ST취소완료] 원주문이 이미 처리되었습니다.' })
            }
          }  
        }
      //RMS 청산
      }else if (apiCode == 'OMS0017'){
        //
        console.log("OMS0017 >>>> ", body);

        this.sellData.set(this.symbol.name, new Map())
        this.buyData.set(this.symbol.name, new Map())
        this.sellStData.set(this.symbol.name, new Map())
        this.buyStData.set(this.symbol.name, new Map())
        this.stockOrderBook.itemList = []
        
        // this.$emit('updateSlStock', {chkType:'1', chkVal:false});

        // this.$emit('updateSlStock', {chkType:'2', chkVal:false});

        this.resetOrderData(this.symbol.name)
      //배치 청산
      } else if ( apiCode == 'OMS9995'){ 
        
        console.log("bat clearing >>> ", body)
        if (body.execYn == "Y"){
          //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[장마감] 장마감 청산이 완료되었습니다.' , body : body , apiCode:apiCode});

          this.sellData.set(this.symbol.name, new Map())
          this.buyData.set(this.symbol.name, new Map())
          this.sellStData.set(this.symbol.name, new Map())
          this.buyStData.set(this.symbol.name, new Map())
          this.stockOrderBook.itemList = []
          
          // this.$emit('updateSlStock', {chkType:'1', chkVal:false});

          // this.$emit('updateSlStock', {chkType:'2', chkVal:false});

          this.resetOrderData(this.symbol.name)
        }
      } else if ( apiCode == 'OMS9997'){ //서버 상태 조회
          console.log("OMS9997 >>>> ", body.serverType, body);
      } else if ( apiCode == 'OMS9996'){
        console.log("OMS9996 >>>> ", body);
      } else if ( apiCode == 'OMS9993'){
        console.log("OMS9993 >>>> ", body);
      }

        //색상표시 테스트
        // body.balAvg //수량
        // body.balQty //평균가
        // body.balSide //포지션 방향
      if (body.balAvg && body.balQty && body.balSide){
        let ordPrc = 0;
        if (body.balQty > 0){
          ordPrc = body.balAvg;
        }
        this.setAvgPrice(ordPrc, body.balSide);
      }else if (body.balAvg == 0 || body.balQty == 0){
        this.setAvgPrice(0, 0);
      }
        
      

      //PanelComp5 포지션 조회
      if (this.getUsrAccPass){
        //this.$emit('completeOrder');
      }
    },
    procFailMessage(apiCode, body) {
      console.log("procFailMessage >>> ", apiCode, body)
      // if ( body.ordSdCd == '1' ) {
      //   if (body.code === undefined || body.code == null)
      //     this.deleteSellOrderData(body.symCd, body.ordPrc, body.ordId, body.accId)
      // } else {
      //   if (body.code === undefined || body.code == null)
      //     this.deleteBuyOrderData(body.symCd, body.ordPrc, body.ordId, body.accId)
      // }

      // if (apiCode == "OMS0010"){ // 주문 취소
      //   if ( body.ordSdCd == '1' ) {
      //     //this.btnCancelSellAllFlag = true;
      //   }else{
      //     //this.btnCancelBuyAllFlag = true;
      //   }
      // }else if (apiCode == "OMS0011"){
      //   if ( body.ordSdCd == '1' ) { //stop취소
      //     //this.btnCancelSellStAllFlag = true;
      //   }else{
      //     //this.btnCancelBuyStAllFlag = true;
      //   }
      // }

      // this.$notify({ group: 'notification', clean: true })

      // this.$notify({
      //   group: 'notification',
      //   title: 'FAIL',
      //   text: body.message
      // })
    },
    clearOrderData(type){ //전/현 종목 청산
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelAllFlag){
        return;
      }
      
      if (type == 'all' || type == 'symbol'){
        let msg = "현종목";
        let symCd = this.symbol.name;
        let symNo = this.symbol.symbolno;
        if (type == 'all'){
          msg = "전종목";
          symCd = "";
        }

        this.btnCancelAllFlag = false;

        const order = {
          usrKey: Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : symCd,
          symNo : symNo,
          trdDt : '',
          clearType : type,
          accPass : this.getUsrAccPass,
        }

        if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
          this.$alert.confirm(msg+'을 청산 하시겠습니까?', '주문 확인')
          .then((response) => {
            if (!response) {
              this.btnCancelAllFlag = true;
              return;
            }else{
              this.sendOmsMessage("OMS0013", order);
            }
          })
        }else{
          this.sendOmsMessage("OMS0013", order);
        }

        // this.$alert.confirm(msg+'을 청산 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (response) {
        //     this.btnCancelAllFlag = false;
            
        //     const order = {
        //       usrKey: Utils.getUuidv4(),
        //       accId : this.account.accId,
        //       symCd : symCd,
        //       trdDt : '',
        //       clearType : type,
        //     }
        
        //     this.sendOmsMessage("OMS0013", order);
        //   }
        // });
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelAllFlag){
        setTimeout(() => {
          this.btnCancelAllFlag = true
        }, 1500)
      }
    },
    //--- 평균가 색상 설정 ---
    setAvgPrice(price, balSide){
      let buyPrice = 0;
      let sellPrice = 0;
      //소수점이 있을경우 가까운 가격쪽으로 설정
      if ((price).toString().indexOf(".") > 0){
        let avgPrice = price.toFixed(this.stockOrderBook.pricescale);     

        if (balSide == "1"){ //매도
          price = Math.round((Number(avgPrice) / this.tikVal) + 0.0000000001) * this.tikVal
        }else if (balSide == "2"){ //매수
          price = Math.round(Number(avgPrice) / this.tikVal) * this.tikVal
        }
      }

      if (this.slStockVal1){ //익절
        if (price == 0 || balSide == 0){
          this.clearStopLossLine('slBuy');
        }else{
          if (balSide == "2"){
            //this.stopLossLineBuyPrice = price + (this.tikVal * this.buySlTick);
            buyPrice = price + (this.tikVal * this.buySlTick);
          }else{
            //this.stopLossLineBuyPrice = price - (this.tikVal * this.buySlTick);
            buyPrice = price - (this.tikVal * this.buySlTick);
          }
          

          this.setStopLossLine(buyPrice, 'slBuy', balSide);
        }
      }else if (!this.slStockVal1){
        if (this.stopLossLineBuyPrice){
          this.clearStopLossLine('slBuy');
        }
      }

      if (this.slStockVal2){ //손절
        if (price == 0 || balSide == 0){
          this.clearStopLossLine('slSell');
        }else{
          if (balSide == "2"){
            //this.stopLossLineSellPrice = price - (this.tikVal * this.sellSlTick)
            sellPrice = price - (this.tikVal * this.sellSlTick);
          }else{
            //this.stopLossLineSellPrice = price + (this.tikVal * this.sellSlTick)
            sellPrice = price + (this.tikVal * this.sellSlTick);
          }
          
          this.setStopLossLine(sellPrice, 'slSell', balSide);
        }
      }else if (!this.slStockVal2){
        if (this.stopLossLineSellPrice){
          this.clearStopLossLine('slSell');
        }
      }

      this.stockOrderBook.avgPrice = price;
      this.stockOrderBook.balSide = Number(balSide);
    },
    // -------------------------------------------------------------- [ STOP LOSS LINE ]
    /* 스탑 로스 라인 설정 */
    setStopLossLine(slPrice, type, balSide) {
      if (slPrice=== undefined) return;
      //balSide 매도 1, 매수 2

      let cssTopBottom;
      this.clearStopLossLine(type);
      if (type == "slBuy"){
        if (balSide == "2"){
          cssTopBottom = "top";
        }else{
          cssTopBottom = "bottom";
        }
        let chkYn = "N";
        this.stopLossLineBuyPrice.forEach(item=>{
          if (item == slPrice){
            chkYn = "Y";
          }
        })

        if (chkYn == "N"){
          this.stopLossLineBuyPrice.push(slPrice);
        }

      }else{
        if (balSide == "2"){
          cssTopBottom = "bottom";
        }else{
          cssTopBottom = "top";
        }

        let chkYn = "N";
        this.stopLossLineSellPrice.forEach(item=>{
          if (item == slPrice){
            chkYn = "Y";
          }
        })

        if (chkYn == "N"){
          this.stopLossLineSellPrice.push(slPrice);
        }
      }

      //const borderColor = slPrice === "TAKE_PROFIT" ? "#bc3d2e" : "#387eb8";
      const borderColor = type === "slBuy" ? "#bc3d2e" : "#387eb8";
      //const stopLossLineStyle = "border-top: 2px solid " + borderColor;
      let stopLossLineStyle;

      stopLossLineStyle ="border-"+cssTopBottom+": 2px solid " + borderColor;

      // if (type == "slBuy"){
      //   stopLossLineStyle ="border-top: 2px solid " + borderColor;
      // } else{
      //   stopLossLineStyle ="border-bottom: 2px solid " + borderColor;
      // }
      //let stopLossLineStyle = type === "slBuy" ? "border-top: 2px solid " : "border-bottom: 2px solid ";
      //stopLossLineStyle + borderColor;

      const columnTypeList = [
        "SELL_STOP_LOSS",
        "SELL",
        "SELL_NUMBER",
        "SELL_REMAIN",
        "PRICE",
        "BUY_REMAIN",
        "BUY_NUMBER",
        "BUY",
        "BUY_STOP_LOSS",
      ];

      columnTypeList.forEach(
        function (columnType) {
          this.$refs.refStockOrderBook.doAddColumnStyle(slPrice, columnType, "BORDER", stopLossLineStyle);
        }.bind(this)
      );     
    },

    /* 스탑 로스 라인 해제 */
    clearStopLossLine(type) {
      let slPrice = 0;
      if (type == "slBuy"){
        slPrice = this.stopLossLineBuyPrice;
      }else if (type == "slSell"){
        slPrice = this.stopLossLineSellPrice;
      }

      if (slPrice === undefined) return;

      //const stopLossLineStyleRegExr = /border-top: 2px solid (?:#bc3d2e|#387eb8)/gi;
      let stopLossLineStyleRegExr;
      if (type == "slBuy"){
        stopLossLineStyleRegExr = /border-(?:top|bottom): 2px solid #bc3d2e/gi;
      } else{
        stopLossLineStyleRegExr = /border-(?:top|bottom): 2px solid #387eb8/gi;
      }
      
      //const stopLossLineStyleRegExr = /border-(?:top|bottom): 2px solid (?:#bc3d2e|#387eb8)/gi;
      const columnTypeList = [
        "SELL_STOP_LOSS",
        "SELL",
        "SELL_NUMBER",
        "SELL_REMAIN",
        "PRICE",
        "BUY_REMAIN",
        "BUY_NUMBER",
        "BUY",
        "BUY_STOP_LOSS",
      ];

      slPrice.forEach((price,index) =>{
        columnTypeList.forEach(
          function (columnType) {
            const originColumnStyle = this.$refs.refStockOrderBook.doGetColumnStyle(price, columnType, "BORDER");

            this.$refs.refStockOrderBook.doChangeColumnStyle(
              price,
              columnType,
              "BORDER",
              originColumnStyle.replace(stopLossLineStyleRegExr, "")
            );
          }.bind(this)
        );
        slPrice.splice(index, 1);
      })


      //slPrice = 0;
    },
    // -------------------------------------------------------------- [ STOP LOSS LINE ]
  },
};
</script>

<style lang="scss" scoped>
@import '@/modules/stock-order-book/stock-order-book_new.css';

.dom-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* border: 1px solid red; */
  /* gap: 10px; */
  .dom-grid {
    grid-template-columns: repeat(9, 1fr);
    /* border: 1px dashed green; */
    /* height: 100%; */
  }
}

.dom-st-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 10px; */
  .col {
    display: flex;
    margin-left: 1px;
    button {
      margin-left: -1px;
    }
    &.middle {
      gap: 0 70px;
    }
  }
}

.dom-order-option {
  margin-top: 10px;
}

.balance-table {
  margin-top: 0;
  width: 285px;
}

.buyColor {
  color : #ff0000 !important;
}

.sellColor {
  color : #00b7ff !important;
}

.zeroColor {
  color : #ffffff !important;
}
</style>
