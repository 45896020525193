<template>
  <div class="login-page">
    <div class="left">
      <div class="hero-title">
        <h1 class="title">Plan&Timing</h1>
        <h2 class="subtitle">The Art of Trading</h2>
      </div>
    </div>
    <div class="right">
      <div class="header">
        <IconChevronDown left width="24" height="24" class="hidden" />
        <h4 class="header-title">
          <b>TAP</b>
          <span>TradingAsPlan</span>
        </h4>
        <BaseBtn naked @click="close">
          <IconClose width="24" height="24" />
        </BaseBtn>
      </div>
      <div class="body">
        <div class="body-inner">
          <h3 class="body-title">로그인</h3>
          <div class="login-ui">
						<div class="sns-login-container">
            <a href="javascript:;" @click="loginGooglePop">
              <img src="@/assets/images/google-logo.png" alt="" />
              google
            </a>
            <a href="javascript:;" @click="loginKakaoPop">
              <img src="@/assets/images/kakao-logo.png" alt="" />
              kakao
            </a>
          </div>
          <div class="login-division">
            <span></span>
            <p>또는</p>
            <span></span>
          </div>
          <div class="email-login-container">
            <router-link to="/login-email" class="email-login">이메일</router-link>
          </div>
					</div>
          <div class="signup-container">
            어카운트가 없나요?
            <router-link to="/signup" class="signup-link">Sign Up</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
import IconClose from '@/components/icons/IconClose.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import Popup from '@/modules/Popup'
import Const from '@/const'

export default {
  components: { BaseBtn, IconClose, IconChevronDown },
  name: 'LoginPage',

  data() {
    return {
    }
  },

  mounted() {
    //
    window.addEventListener('message', this.receiveMessage)

  },

  beforeDestroy() {
    //
    window.removeEventListener('message', this.receiveMessage)
  },  

  methods: {
    close () {
      this.$router.push('/')
    },
    loginGooglePop () {
      const url = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' + process.env.VUE_APP_GOOGLE_CLIENT_ID +
                  '&redirect_uri=' + window.location.protocol +'//'+ window.location.host + process.env.VUE_APP_GOOGLE_REDIRECT_URL +
                  '&response_type=code' +
                  '&scope=email'

      Popup.openWinPop( url, 'loginExternalPop', 500, 600 )
    },
    loginKakaoPop () {
      const url = 'https://kauth.kakao.com/oauth/authorize?client_id=' + process.env.VUE_APP_KAKAO_JS_KEY +
                  '&redirect_uri=' + window.location.protocol +'//'+ window.location.host + process.env.VUE_APP_KAKAO_REDIRECT_URL +
                  '&response_type=code&' +
                  'scope=account_email'


      Popup.openWinPop( url, 'loginExternalPop', 500, 600 )
    },    
    receiveMessage(message) {
      if ( message.origin === process.env.VUE_APP_WEB_BASE_URL ) {
        const {type, payload} = message.data

        if (type === 'LOGIN_GOOGLE') {
          if ( payload !== null ) {
            this.loginGoogle(payload)
          }
        } else if (type === 'LOGIN_KAKAO') {
          if ( payload !== null ) {
            this.loginKakao(payload)
          }
        }
      }      
    },
    loginGoogle(data) {
      window.$store
      .dispatch('Login/loginGoogle', data)
      .then((response) => {
        // 로그인 성공
        if ( response.data.code == Const.LOGIN_RESPONSE_TYPE.SUCCESS ) {

          this.$router.push('/main')

        // 로그인 실패  
        } else { 

          if ( response.data.code == Const.LOGIN_RESPONSE_TYPE.FAIL ) {
            this.$alert.alert('TAP 회원으로 등록되어 있는 이메일이 아닙니다.<br/>먼저 회원 가입 해 주십시오.', 'Warnning')
            .then((response) => {
              this.$router.push('/signup')
            })
          } else {
            this.$alert.alert(response.data.message, 'Warnning')
          }

        }
      })
    },   
    loginKakao(data) {     
      window.$store
        .dispatch('Login/loginKakao', data)
        .then((response) => {
          // 로그인 성공
          if ( response.data.code == Const.LOGIN_RESPONSE_TYPE.SUCCESS ) {

            this.$router.push('/main')

          // 로그인 실패  
          } else { 

            if ( response.data.code == Const.LOGIN_RESPONSE_TYPE.FAIL ) {
              this.$alert.alert('TAP 회원으로 등록되어 있는 이메일이 아닙니다.<br/>먼저 회원 가입 해 주십시오.', 'Warnning')
              .then((response) => {
                this.$router.push('/signup')
              })
            } else {
              this.$alert.alert(response.data.message, 'Warnning')
            }

          }
        })
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
