import { render, staticRenderFns } from "./NotificationItem.vue?vue&type=template&id=2e4a0e57&scoped=true"
import script from "./NotificationItem.vue?vue&type=script&lang=js"
export * from "./NotificationItem.vue?vue&type=script&lang=js"
import style0 from "./NotificationItem.vue?vue&type=style&index=0&id=2e4a0e57&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e4a0e57",
  null
  
)

export default component.exports