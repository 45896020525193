<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="width" viewBox="0 0 16 16" :class="{faceDown:down, faceUp:up, faceLeft:left, faceRight:right}">
    <path d="M.919 0 4.46 3.54 8 0l.919.919-4.459 4.46L0 .919z" transform="translate(3.54 5.54)" :style="{ fill: fill }" />
  </svg>
</template>

<script>
export default {
  name: 'IconChevronDown',
  props: {
    down: Boolean,
    up: Boolean,
    left: Boolean,
    right: Boolean,
     width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
  &.faceUp {
    transform: rotate(180deg);
  }
  &.faceLeft {
    transform: rotate(90deg);
  }
  &.faceRight {
    transform: rotate(-90deg);
  }
}
</style>
