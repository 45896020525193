<template>
  <div>
	<div class="tab-nav-container" :style="{ width: width + 'px' }" :class="{line:line}">
      <div class="tab-nav" v-for="tab in tabs" :key="tab.id" :class="{ active: tab.id === selected}" @click="setTab(tab)">
		<h3 class="bold">{{ tab.name }}</h3>
      </div>
	</div>
	<slot></slot>
  </div>
</template>

<script>
export default {
	name: 'TabNav',
	props: {
		tabs: Array,
		selected: String,
		width: [String, Number],
		line: Boolean,
	},
	data() {
		return {
			
		};
	},

	mounted() {
		
	},

	methods: {
		setTab(tab) {
			this.$emit('selectedTab', tab);
		}
	},
};
</script>

<style lang="scss" scoped>
.tab-nav-container {
	display: flex;
	align-items: center;
	width: 100%;
	height: 30px;
	&.line {
		border-bottom: 1px solid rgba(255,255,255,0.1);
		.tab-nav {
			border: none;
			border-bottom: 3px solid transparent;
			background-color: transparent;
			&.active {
				background-color: transparent;
				border-color: var(--main-orange);
			}
		}
	}
	.tab-nav {
		flex: 1;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid var(--border-color-dom);
		background-color: var(--main-gray-dark);
		cursor: pointer;
		&:not(:first-child) {
			margin-left: -1px;
		}
		&.active {
			background-color: var(--main-orange);
			h3 {
				color: white;
			}
		}
		h3 {
			color: rgba(255,255,255,0.6);
		}
	}
	@media (max-width: $mobile) {
		height: 40px;
	}
}
</style>