<template>
  <div class="trade-page">
    <div class="panel-header">
      <div class="row between">
        <div class="row-item">
          <BaseBtn naked>
            <IconChevronDown left />
            <!-- <img src="@/assets/icons/chevron-left.svg" alt="" /> -->
          </BaseBtn>
          <div class="breadcrumb">
            <span><h3>AI매매신호 /</h3></span><h3>E-mini NASDAQ</h3>
          </div>
        </div>
        <!-- <div class="row-item">
          <BaseBtn naked>
            <img src="@/assets/icons/add.svg" alt="" />
          </BaseBtn>
        </div> -->
      </div>
    </div>
    <div class="table-header">
      <h3 class="table-subtitle">나의 신호 / 가용 신호 (4/5)</h3>
      <BtnSub text="업그레이드" />
    </div>
    <div class="table-container">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        class="ag-theme-delkoit"
        alwaysShowHorizontalScroll="true"
        :columnDefs="grid.columnDefs"
        :rowData="grid.rowData"
        :headerHeight="30"
        :rowHeight="110"
      ></ag-grid-vue>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
import BtnSub from '@/components/BtnSub.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import { AgGridVue } from "ag-grid-vue" // Vue Grid Logic
import AiSignalCustomRow from '@/components/grid/AiSignalCustomRow.vue'
import AiSignalCustomRow2 from '@/components/grid/AiSignalCustomRow2.vue'

export default {
  name: 'TradePageSub',
  components: { 
    BtnSub, 
    BaseBtn, 
    IconChevronDown, 
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AiSignalCustomRow,
    // eslint-disable-next-line vue/no-unused-components
    AiSignalCustomRow2,
  },  
  data() {
    return {
			grid: {
        columnDefs: [
          { 
            headerName: "전략", 
            field: "전략",
            minWidth: 89, 
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            cellRenderer: 'AiSignalCustomRow',
            headerClass: "center-align-header",
            suppressMovable: true,
            wrapText: true,
          },
          { 
            headerName: "일손익($)",
            field: "일손익",
            minWidth: 89,
            flex: 0.5,
            cellStyle: {
              textAlign: 'center'
            },
            cellRenderer: 'AiSignalCustomRow2',
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          
        ],
        rowData: [
          { 
            전략: {
              title: 'Early Trend Catcher',
              type: 'Type: Trend Following',
              desc: '특징 : 장초반 자체 분석 추세확장 가능성이 높다고 판단 될 때 추세 확장으로 진입시도'
            },
            일손익: 2542,
          },
          { 
            전략: {
              title: 'Early Trend Catcher',
              type: 'Type: Trend Following',
              desc: '특징 : 장초반 자체 분석 추세확장 가능성이 높다고 판단 될 때 추세 확장으로 진입시도'
            },
            일손익: -2542,
          },
          { 
            전략: {
              title: 'Early Trend Catcher',
              type: 'Type: Trend Following',
              desc: '특징 : 장초반 자체 분석 추세확장 가능성이 높다고 판단 될 때 추세 확장으로 진입시도'
            },
            일손익: -2542,
          },
        ],        
      }      
    }
  },
  mounted() {},
  methods: {},

};
</script>

<style lang="scss" scoped>
.trade-page {
  padding: 10px 20px;
}
.panel-header {
	margin-bottom: 10px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 5px;
  span {
    opacity: 0.8;
    font-weight: 400;
  }
}

.table-subtitle {
  opacity: 0.6;
}
.table-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
}

.table-container {
  height: 400px;
}

@import '@/assets/scss/agGridStyles.scss';
</style>
