import Utils from '@/modules/utils'

const state = {
  channelToPrice: new Map(),  
  socketPrice : null,
  isConnectPrice: false,
  stockPrice: null,
  sessionStorage: null,
  ovcData: null,
  ovhData: null,
  priceInterval: null,
  connectPriceTimeout: null,
  selectSymbol: null, //선택한 종목
  //---폴링시세 관련---
  isPolingPrice: false,
  pollingInterVal: null,
  ovcDataList: null,
  ovhDataList: null,  
  //---폴링시세 관련---
}
  
const getters = {
  getIsConnectPrice(state){
      return state.isConnectPrice
  },
  getOvcData(state){
    return state.ovcData
  },
  getOvhData(state){
    return state.ovhData
  },
  getStockPrice(state){
    return state.stockPrice
  },
  getSelectSymbol(state){
    return state.selectSymbol
  },
  //---폴링시세 관련---
  getIsPolingPrice(state){
    return state.isPolingPrice
  },
  getOvcDataList(state){
    return state.ovcDataList
  },
  getOvhDataList(state){
    return state.ovhDataList
  },
  //---폴링시세 관련---
}
  
const mutations = {
  setSessionStorage (state, {sessionStorage}){
    state.sessionStorage = sessionStorage
  },
  setConectPrice (state, {socketPrice}){
    state.socketPrice = socketPrice
  },
  setIsConnectPrice(state, {isConnect}){
    // console.log("setIsConnectPrice >>> ", isConnect)
    state.isConnectPrice = isConnect
  },
  setStockPrice(state, {stockPrice}){
      state.stockPrice = stockPrice
  },
  sendPriceMessage(state, {apiCode, apiType, symbolList, symbol}){
    //console.log("sendPriceMessage >>>>> ", apiCode, apiType, symbolList, symbol)
    let apiTypeData = ['OVC']

    if (!symbolList && !symbol) return;

    let ovcReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'OVC',
        apiTypeData: apiTypeData,
        symbolData : symbolList
      }
    }

    if (apiCode == "DSA0004"){
      ovcReqData.body = {
        apiType : 'OVC',
        symbol : symbol,
        apiTypeData: apiTypeData,
        symbolData : [symbol]
      }
    }

    if (!symbol && state.selectSymbol){
      symbol = state.selectSymbol.name;
    }

    //호가는 선택한 종목 단건만 요청
    const ovhReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'OVH',
        apiTypeData: ['OVH'],
        symbol : symbol,
        symbolData : [symbol]
      }
    }
    try{
      if (state.socketPrice.readyState == 1){
        if (!apiType || apiType == "OVC"){
          state.socketPrice.send(JSON.stringify(ovcReqData))
        }
        if (!apiType || apiType == "OVH"){
          state.socketPrice.send(JSON.stringify(ovhReqData))
        }        
      }
    }catch(e){
      console.log("e >>> ", e, ovcReqData, ovhReqData)
    }
  
  },
  setChannelToPrice(state,  {subscribeItem}){
    state.channelToPrice.set(subscribeItem.symbol, subscribeItem)
  },
  delChannelToPrice(state,  {symbol}){
    state.channelToPrice.delete(symbol)
  },
  sendPriceHeartBeat(state) {
    // console.log("sendPriceHeartBeat ", state.sessionStorage)
      const reqData = {
        header: {
        apiCode : 'DSA0000',
        token : state.sessionStorage
        },
        body : {
        }
      }
      if (state.socketPrice.readyState == 1){
        state.socketPrice.send(JSON.stringify(reqData))
      }
  },
  setOvcData(state,  {ovcData}){
    state.ovcData = ovcData
  },
  setOvhData(state,  {ovhData}){
    state.ovhData = ovhData
  },
  setSelectSymbol(state, symbol){
    state.selectSymbol = symbol
  },
  setDisconnect(state){
    if (state.isConnectPrice){
      state.socketPrice.onclose = function () {}
      state.socketPrice.close();
      state.isConnectPrice = false
    }
    clearInterval(state.priceInterval)
  },
  //---폴링시세 관련---
  setIsPolingPrice(state){
    state.isPolingPrice = true;
  },
  setClearPolling(state){
    if (state.isPolingPrice){
      state.isPolingPrice = false
    }
    clearInterval(state.pollingInterVal)
  },
  setPollingData(state, {data:dataList}){
    //시세, 호가 데이터 초기화
    state.ovcDataList = [];
    state.ovhDataList = [];
    dataList.forEach(data=>{
      const ovcData = {
        curPr: data.curPr,
        high: data.high,
        low: data.low,
        open: data.open,
        symbol: data.symbol,
        ydiffPr: data.ydiffPr,
        ydiffSign: data.ydiffSign,
        totQ: data.totQ
      }; 

      const ovhData = {
        bidHo1: data.bidHo1,
        bidHo2: data.bidHo2,
        bidHo3: data.bidHo3,
        bidHo4: data.bidHo4,
        bidHo5: data.bidHo5,
        bidNo1: data.bidNo1,
        bidNo2: data.bidNo2,
        bidNo3: data.bidNo3,
        bidNo4: data.bidNo4,
        bidNo5: data.bidNo5,
        bidRem1: data.bidRem1,
        bidRem2: data.bidRem2,
        bidRem3: data.bidRem3,
        bidRem4: data.bidRem4,
        bidRem5: data.bidRem5,
        offerHo1: data.offerHo1,
        offerHo2: data.offerHo2,
        offerHo3: data.offerHo3,
        offerHo4: data.offerHo4,
        offerHo5: data.offerHo5,
        offerNo1: data.offerNo1,
        offerNo2: data.offerNo2,
        offerNo3: data.offerNo3,
        offerNo4: data.offerNo4,
        offerNo5: data.offerNo5,
        offerRem1: data.offerRem1,
        offerRem2: data.offerRem2,
        offerRem3: data.offerRem3,
        offerRem4: data.offerRem4,
        offerRem5: data.offerRem5,
        symbol: data.symbol,
        totBidCnt: data.totBidCnt,
        totBidRem: data.totBidRem,
        totOfferCnt: data.totOfferCnt,
        totOfferRem: data.totOfferRem
      };
      state.ovcDataList.push(ovcData);
      state.ovhDataList.push(ovhData);
    })
  }
  //---폴링시세 관련---
}
  
const actions = {
  //소켓 커넥션 생성
  setConectPrice({commit, state, dispatch}, sessionStorage){
    commit('setSessionStorage', {sessionStorage:sessionStorage.sessionStorage})
    const websocketPath = process.env.VUE_APP_QUOTE_SOCKET_BASE_URL

    if(state.socketPrice && state.socketPrice.readyState == WebSocket.OPEN){     
        state.socketPrice.close()
    }
    let socketPrice = new WebSocket(websocketPath);

    commit('setConectPrice', {socketPrice: socketPrice})   

    state.socketPrice.onopen = () => {
      commit('setIsConnectPrice', {isConnect:true})
      //state.isConnectPrice = true
      //commit('sendPriceHeartBeat', {})
      state.priceInterval = setInterval(() => {
        commit('sendPriceHeartBeat', {})
      }, 50000)
    }

    state.socketPrice.onmessage = (e) => {
      //console.log("onMessage?", e.data)
      const returnData = JSON.parse(e.data)
      const data = returnData.body

      //state.stockPrice = data;
      commit('setStockPrice', {stockPrice:data})

      if ( data.apiType == 'OVC' ) {         
        //console.log("ovc data >>>> ", data.symbol, data.curPr, data);
        commit('setOvcData', {ovcData:data})
      }else if (data.apiType == 'OVH'){
        //console.log("ovh data >>>> ", data.symbol, data.hotTime, data);
        commit('setOvhData', {ovhData:data})
      }
    }

    state.socketPrice.onerror = (e) => {
      commit('setIsConnectPrice', {isConnect:false})
      console.error("[Error] socket Error")
    }

    //socket.onclose = () => {
    state.socketPrice.onclose = (e) => {
      commit('setIsConnectPrice', {isConnect:false})
      console.error("[OnClose] socket close", e.code, e.wasClean);
      state.connectPriceTimeout = setTimeout(() => {
        dispatch('setConectPrice', {sessionStorage:state.sessionStorage})
      }, 1000)
    }
  },
  setSubscribeOnPriceList({commit, state}, symbolList) {
    if (!symbolList || symbolList.length == 0) return

    let subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', subscriptionUID)
    }

    if (symbolList && symbolList.length > 0){
      symbolList.forEach(symbol=>{
        // 소켓 구독
        const subscribeItem = {
          symbol: symbol,
          subscriptionUID: sessionStorage.getItem('subscriptionUID'),
          data: new Array()
        }

        commit('setChannelToPrice', {subscribeItem:subscribeItem})
      })
    }



    //this.channelToPrice.set(symbol, subscribeItem)
    //commit('setChannelToPrice', {subscribeItem:subscribeItem})
    commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'', symbolList})
    //commit('setConnectSymbol', {symbol:symbol})
  },
  setUpdateSubscribeOnPriceList({commit, state}, symbolList) {
    // 소켓 구독 수정
    // const subscribeItem = {
    //   //symbol,
    //   subscriptionUID: sessionStorage.getItem('subscriptionUID'),
    //   data: new Array()
    // }

    if (symbolList && symbolList.length > 0){

      let delSymbolList = [];
      for (let symbol of state.channelToPrice.keys()) {
        if (symbolList.indexOf(symbol) == -1){ //symbolList 접속중인 symbol 없으면 구독 해지
          //구독 해지
          commit('delChannelToPrice', {symbol:symbol})
          delSymbolList.push(symbol)
          commit('sendPriceMessage', {apiCode:'DSA0004', apiType:'OVC', symbolList:null, symbol:symbol})
        }
      }

      if (delSymbolList.length > 0){
        //commit('sendPriceMessage', {apiCode:'DSA0004', apiType:'OVC', symbolList:delSymbolList})
      }

      symbolList.forEach(symbol=>{
        // 소켓 구독
        const subscribeItem = {
          symbol: symbol,
          subscriptionUID: sessionStorage.getItem('subscriptionUID'),
          data: new Array()
        }

        

        commit('setChannelToPrice', {subscribeItem:subscribeItem})
      })
    }

    //commit('setChannelToPrice', {subscribeItem:subscribeItem})
    commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'', symbolList:symbolList})
    
  },
  setSubscribeOnPrice({commit, state}, symbol) {
    //console.log("setSubscribeOnPrice >>> ", symbol, sessionStorage.getItem('subscriptionUID'))

    let subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', subscriptionUID)
    }

    // 소켓 구독
    const subscribeItem = {
      symbol,
      subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      data: new Array()
    }

    commit('setChannelToPrice', {subscribeItem:subscribeItem})

    //this.channelToPrice.set(symbol, subscribeItem)
    //commit('setChannelToPrice', {subscribeItem:subscribeItem})
    commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'', symbol:symbol})
    commit('setConnectSymbol', {symbol:symbol})
    
  },
  setUpdateSubscribeOnPrice({commit, state}, symbol) {
    // 소켓 구독 수정
    const subscribeItem = {
      //symbol,
      subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      data: new Array()
    }

    commit('setChannelToPrice', {subscribeItem:subscribeItem})

    commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'', symbol:symbol})
    
  },
  setUnSubscribeOnPrice({commit, state}, symbol){
    // 소켓 구독 종료
    const data = state.channelToPrice.get(symbol)
    if(data != null){
      if(state.isConnectPrice){
        commit('sendPriceMessage', {apiCode:'DSA0004', apiType:'', symbol:symbol})
        commit('delChannelToPrice', {symbol:symbol})
      }
    }    
  },
  setSelectSymbol({commit, state}, symbol){
    //시세 변경시 호가 구독 종료 호출
    if (state.selectSymbol){
      commit('sendPriceMessage', {apiCode:'DSA0004', apiType:'OVH', symbolList:null, symbol:state.selectSymbol.name})
    }

    commit('setSelectSymbol', symbol)
  },
  //---폴링시세 관련---
  setPollingPrice({commit, state, dispatch}, {symbolList:symbolList}){
    commit('setIsPolingPrice', {})
    state.priceInterval = setInterval(() => {
      return new Promise((resolve, reject) => {
        window.$http
          .get('chart/datafeed/stockdata/symbols', { params: { symbolList: symbolList } })
          .then(response => {
            if ( response ) {
              if ( response.data !== null ) {
                commit('setPollingData', {data:response.data})
              }
            }
            resolve()
          })
          .catch(e => reject(e))
      }) 
    }, 300)
  },
  //---폴링시세 관련---

  // getOVCReqData({commit, state}, {apiCode:apiCode, symbol:symbol}) {
  //   let apiTypeData = ['OVC'];
  //   let apiType = 'OVC';

  //   const reqData = {
  //     header: {
  //       apiCode : apiCode,
  //       token : state.sessionStorage,
  //     },
  //     body : {
  //       apiType : apiType,
  //       symbol : symbol,
  //       apiTypeData: apiTypeData,
  //       symbolData : [symbol]
  //     }
  //   }
  //   return reqData

  // },    
  // getOVHReqData({commit, state}, {apiCode:apiCode, symbol:symbol}) {
  //     let apiTypeData = ['OVH'];
  //     let apiType = 'OVH';
      
  //     const reqData = {
  //         header: {
  //         apiCode : apiCode,
  //         token : state.sessionStorage,
  //         },
  //         body : {
  //         apiType : apiType,
  //         symbol : symbol,
  //         apiTypeData: apiTypeData,
  //         symbolData : [symbol]
          
  //         }
  //     }
  //     return reqData
  // },
  //커넥션 종료
  setDisconnect({commit, state}){
    commit('setDisconnect', {})
  },
  //폴링 종료
  setClearPolling({commit, state}){
    commit("setClearPolling")
  }
}
  
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}