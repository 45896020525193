<template>
  <div class="category-item-container">
    <div class="col">
      <h3 class="title">{{title}}</h3>
      <h5 class="amount">{{amount}}</h5>
    </div>
    <div class="col">
      <div class="alert-icon">
        <img :src="require(`@/assets/icons/${icon}.svg`)" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryItem',
  props: {
    title: String,
    amount: String,
    icon: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.category-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  background-color: var(--main-gray-dark);
  &:hover {
    background-color: var(--main-orange);
  }
  .col {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .title {
    font-weight: 600;
  }
  .alert-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50vh;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .amount {
    opacity: 0.6;
		margin-top: 2px;
  }
}
</style>
