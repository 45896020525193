<template>
  <modal name="profitLossPop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="누적손익 현황" modalName="profitLossPop">
      <template #body>
        <div class="table-header-grid">
          <div class="row">
            <div class="row-item">
              <h3>계좌번호</h3>
              <div class="row-item-content">
                <AccountDropDown id="accountDropdown" width="120" popup v-model="gridSearch.accId" @event:selectAccount="selectAccount" />
                <InputComp readonly width="120" v-model="account.accNo" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              <h3>비밀번호</h3>
              <InputComp type="password" :width="65" v-model="account.accPass" />
            </div>
            <div class="row-item">
              <h3>원/달러</h3>
              <div class="radio-group">
                <div class="label">  
                  <BtnRadio id="profit_loss_pop_radio_usd" name="profit_loss_pop_radio_crc" value="USD" text="달러" checked @click="selectCurrency" />
                  <BtnRadio id="profit_loss_pop_radio_krw" name="profit_loss_pop_radio_crc" value="KRW" text="원" @click="selectCurrency" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              <h3 class="large">상품</h3>
              <div class="row-item-content">
                <SymbolDropDown id="symbolDropdown" popup width="60" v-model="gridSearch.symCd" @event:selectSymbol="selectSymbol" />
                <InputComp width="180" readonly v-model="symbol.engNm" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              <h3>조회일자</h3>
              <div class="row-item-content">
                <DatePicker v-model="gridSearch.fromDate" />
                ~
                <DatePicker v-model="gridSearch.toDate" />
              </div>
            </div>
            <div class="row-item">
              <BtnPrimary text="조회" icon="search" :width="70" @click="search(1)" />
            </div>
          </div>
        </div>

        <div class="table-container">
          <ag-grid-vue
            style="width: 100%; height: 100%"
            class="ag-theme-delkoit"
            alwaysShowHorizontalScroll="true"
            :columnDefs="grid.columnDefs"
            :rowData="grid.rowData"
            :headerHeight="30"
            :rowHeight="30">
          </ag-grid-vue>
          <PaginationComp :paging="grid.paging" @onPage="search" />
        </div>
      </template>
    </ModalContent>
  </modal>
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputComp from '@/components/InputComp.vue'
import BtnRadio from '@/components/BtnRadio.vue'
import DatePicker from '@/components/DatePicker.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import AccountDropDown from '@/components/AccountDropDown.vue'
import SymbolDropDown from '@/components/SymbolDropDown.vue'

import { AgGridVue } from "ag-grid-vue" // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import {currencyFormatter, cellClassRulesMinus} from '@/modules/grid-utils.js'

import Utils from '@/modules/utils'

import PaginationComp from '@/components/grid/PaginationComp.vue'

export default {
  name: 'profitLossPop',
  components: {
    ModalContent,
    InputComp,
    BtnRadio,
    DatePicker,
    BtnPrimary,
    AccountDropDown,
    SymbolDropDown,
    AgGridVue,
    PaginationComp,    
  },
  data() {
    return {
      account: {
        accNo: '',
        accPass: ''
      },
      symbol: {
        shtNm: '전체'
      },      
      gridSearch: {
        accId: 0,
        accPass: '',
        symCd: '',
        fromDate: Utils.getDatePrevMonth(1), 
        toDate: Utils.getToday(), 
        crc: 'USD',
      },        
      grid : {
        columnDefs: [
          { 
            headerName: "거래일자", 
            field: "trdDt", 
            flex: 0.4,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "종목", 
            field: "symCd", 
            flex: 0.4,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },          
          { 
            headerName: "매매손익",
            field: "ordPlMn",
            minWidth: 80,
            flex: 1,
            headerClass: "right-aligned-header-type2",
            cellStyle: {
              justifyContent: 'flex-end'
            },
            cellClassRules: cellClassRulesMinus,
            valueFormatter: currencyFormatter,
            suppressMovable: true,
          },
          { 
            headerName: "수수료",
            field: "cms",
            minWidth: 80,
            flex: 1,
            headerClass: "right-aligned-header-type2",
            cellStyle: {
              justifyContent: 'flex-end'
            },
            valueFormatter: currencyFormatter ,
            suppressMovable: true,
          },
          { 
            headerName: "순손익",
            field: "profit",
            minWidth: 80,
            flex: 1,
            headerClass: "right-aligned-header-type2",
            cellStyle: {
              justifyContent: 'flex-end'
            },
            cellClassRules: cellClassRulesMinus,
            valueFormatter: currencyFormatter,
            suppressMovable: true, 
          },
        ],
        rowData: [],
        paging: {
          page: 1,
          size: 20,
          total: 0,
          pageSize : 10
        }                   
      }
    }
  },

  mounted() {},

  methods: {
    beforeOpen(e) {  
      this.grid.rowData = []
      this.grid.paging.page = 1      
    },    
    opened() {
    },    
    selectAccount(account) {
      this.account = account
    },    
    selectSymbol(symbol) {
      this.symbol = symbol
    },          
    selectCurrency(value) {
      this.gridSearch.crc = value
    },
    async search(page) {
      if (page) {
        this.grid.paging.page = page
      }

      // 조회 조건
      this.gridSearch.accId   = this.account.accId
      this.gridSearch.accPass = this.account.accPass
      this.gridSearch.page    = this.grid.paging.page
      this.gridSearch.size    = this.grid.paging.size

      const response = await this.$lhttp.get('/api/account/profit/list/page', { params: this.gridSearch })

      if ( response && response.data ) {
        this.grid.paging.total = response.data.total
        this.grid.rowData = response.data.content

        if ( response.data.content.length <= 0 ) {
          this.$alert.alert('조회된 누적손익 현황이 없습니다.', 'Warnning')
        }
      } 
    }          
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  /* max-width: 751px; */
  width: 100%;
  height: 393px;
  border-color: #565b66;
  margin-top: 10px;
}
.table-header-grid {
  display: grid;
  grid-template-columns: 328px 423px;
  gap: 10px 0;
  .large {
    margin-right: 24px;
  }
}

@import '@/assets/scss/agGridStyles.scss';

.ag-theme-delkoit {
  &::v-deep {
    .ag-cell {
      display: flex;
      align-items: center;
      /* justify-content: center; */
    }
  }
}
</style>
