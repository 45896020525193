<template>
<modal name="eventRequestPop" class="custom-modal" adaptive :minWidth="800" :minHeight="750" :clickToClose="false"
  @before-open="beforeOpen"
  @opened="opened">  
    <ModalContent text="이벤트계좌 신청" modalName="eventRequestPop" footer>
    <template #body>
      <div>
        <div class="table-header">
          <h3 class="table-subtitle">이벤트 계좌</h3>
          <BtnSub text="업그레이드" @click="goPlan" />
        </div>
        <div class="table-wrapper">
          <div class="row free-height">
          <!-- <BtnCheckbox text="이벤트" bold /> -->
            <div class="row-item">
              <h3 class="label">계좌명</h3>
              <eventDropDown width="110" v-model="eventForm.evtId" @event:selectEvent="selectEvent" popup /> 
            </div>
            <div class="row-item">
              <h3 class="label">비밀번호</h3>
              <h3>0000</h3>
            </div>
            <div class="row-item">
              <h3 class="label">투자원금</h3>
              <span class="amount"><h3>{{ event.evtDpsMn | currency('USD') }}</h3></span>
            </div>
            <div class="row-item-event">
              <ul class="event-list">
                <li>
                  <span>접수기간</span> 
                  {{ event.reqStDt }} ~
                  {{ event.reqEdDt }}
                </li>
                <li>
                  <span>이벤트기간</span> 
                  {{ event.evtStDt }} 00:00 ~ 
                  {{ event.evtEdDt }} 23:59
                </li>
                <li>
                  <span>참가비</span> {{ event.useFee | currency('KRW') }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="table-wrapper event-info">
          <!--
          <div class="event-info-row">
            <div class="event-info-label">
              <h3>승부방식</h3>
            </div>
            <div class="event-info-content">
              <ul>
                <li><h3>최소거래 수익률 우선</h3></li>
                <li><h3>최소거래 기준 : 10</h3></li>
                <li><h3>최소거래 기준 이상 거래한 참가자 중 최고 수익률 순위로 승부</h3></li>
              </ul>
            </div>
          </div>
          <div class="event-info-row">
            <div class="event-info-label">
              <h3>포상방식</h3>
            </div>
            <div class="event-info-content">
              <ul>
                <li><h3>최소거래 수익률 우선</h3></li>
                <li><h3>최소거래 기준 : 10</h3></li>
                <li><h3>최소거래 기준 이상 거래한 참가자 중 최고 수익률 순위로 승부</h3></li>
              </ul>
            </div>
          </div>
          -->
          <div class="event_content" v-html="event.evtTxt"></div>
        </div>
        <ul class="info-list">
          <li>이벤트 계좌는 이벤트 기간에만 신청 가능하며, 모의계좌 수에는 포함되지 않습니다.</li>
          <li>이벤트 계좌의 비밀번호는 0000 입니다.</li>
        </ul>
      </div>
    </template>
    <template #footer>
      <BtnSub text="취소" @click="close" />
      <BtnPrimary text="신청" @click="save" />
    </template>      
  </ModalContent>
</modal>
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import InputComp from '@/components/InputComp.vue'
import BtnRadio from '@/components/BtnRadio.vue'
import EventDropDown from '@/components/EventDropDown.vue'

export default {
name: 'ModalDemoRequest',
components: {
  ModalContent,
  BtnSub,
  BtnPrimary,
  InputComp,
  BtnRadio,
  EventDropDown,
},
data() {
  return {
    eventForm: {
      evtId: 0,
      accPass: '0000',
      accDivCd: 'ACCDIV03',
      invPrinCd: 'EINVPRIN01',
      invPerCd: 'event',
    },
    event: {
      evtPer: 0
    },    
  }
},

mounted() {},

filters: {
  currency(value, currency) {
    if ( value ) {
      return new Intl.NumberFormat('ja-JP', { style: 'currency', currency }).format(value)
    } else {
      return ''
    }
  },
},

methods: {
  beforeOpen(e) {  
    if ( e.params !== undefined && e.params.evtId !== undefined ) {
      this.eventForm.evtId = e.params.evtId
    } else {
      this.eventForm.evtId = 0
    }
  },    
  opened() {
  },        
  close() {
    this.$modal.hide('eventRequestPop')
  },    
  goPlan() {
    this.$router.push('/plan')
  },  
  selectEvent(event) {
    this.event = event
  },
  save() {
    if ( this.eventForm.evtId === '' ) {
      this.$alert.alert('선택하신 이벤트 정보가 없습니다. 먼저 이벤트를 선택 하십시오.', 'Warnning')
    } else {
      this.$alert.confirm('이벤트 모의계좌를 신청하시겠습니까?', '확인')
      .then((response) => {
        if (response) {
          this.$http
          .post('/api/account/event/request',
          this.eventForm)
          .then(() => {
            this.$alert.alert('이벤트 모의계좌 신청이 완료되었습니다.', '안내').then(() => {
              // 계좌목록 갱신
              this.$parent.$refs.panel1.$refs.accountDropdownMain.selectAccountList()                  
              this.close()
            })
          })
        }
      })
    }

  }
},
}
</script>

<style lang="scss" scoped>
.event-info {
  .event-info-row {
    display: flex;
    &:not(:first-child) {
      margin-top: 14px;
    }
    .event-info-label {
      margin-right: 20px;
      opacity: 0.6;
    }
    .event-info-content {
      li {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }  
}

.table-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
	.table-subtitle {
		opacity: 0.6;
	}
}
.row {
  display: grid;
  grid-template-columns: 150px 229px 1fr;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
	.row-item {
		.label {
			opacity: 0.6;
		}
	}
  .row-item-event {
    grid-column: -1/1;
    .event-list {
      display: flex;
      align-items: center;
      font-size: 12px;
      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      span {
        opacity: 0.6;
        margin-right: 5px;
      }
    }
    
    /* text-align: center; */
  }
}
.table-wrapper {
	margin-top: 10px;
  padding-bottom: 15px;
}
.info-list {
	margin-top: 30px;
  li {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>

<style lang="scss">
.event_content {
  position: relative;
  line-height: 1.4em;
  ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
    li {
      display: flex;
      gap: 20px;
      .label {
        flex: 0 48px;
        opacity: 0.6;
      }
    }
  }
}
</style>
  