<template>
  <div class="loadLayout-container">
    <div class="customHeaderLabel">{{ params.displayName }}</div>
    <div v-if="params.enableMenu" ref="menuButton" class="customHeaderMenuButton" @click="onMenuClicked($event)">
      <GnbDropDown right loadLayout>
        <template #gnb-title>
          <img src="@/assets/icons/order-descending.svg" alt="" />
        </template>
        <template #dropdown-list>
          <div class="dropdown-list-item" @click="order('NA')">
            <div class="col">
              <img src="@/assets/icons/order-ascending.svg" alt="" /> 
              레이아웃 이름 (A-Z)
            </div>
          </div>
          <div class="dropdown-list-item" @click="order('ND')">
            <div class="col">
              <img src="@/assets/icons/order-descending.svg" alt="" />
              레이아웃 이름 (Z-A)
            </div>
          </div>
          <div class="dropdown-list-item" @click="order('DA')">
            <div class="col">
              <img src="@/assets/icons/order-ascending.svg" alt="" />
              바뀐날짜 (옛것부터)
            </div>
          </div>
          <div class="dropdown-list-item" @click="order('DD')">
            <div class="col">
              <img src="@/assets/icons/order-descending.svg" alt="" />
              바뀐날짜 (새것부터)
            </div>
          </div>
				</template>
      </GnbDropDown>
    </div>
  </div>
</template>

<script>
import GnbDropDown from '@/components/GnbDropDown.vue'

export default {
  components: {
    GnbDropDown,
  },
  name: 'CustomHeaderLoadLayout',

  data() {
    return {
    }
  },

  mounted() {},

  methods: {
    onMenuClicked() {
    },
    order(order) {
      this.$refs.menuButton.click()
      this.params.order(order)
    }
  },
};
</script>

<style lang="scss" scoped>
.loadLayout-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
}
.ag-custom-checkbox {
  width: 16px;
  padding-top: 2px;
  label {
    padding-left: 0 !important;
  }
}
.customHeaderMenuButton {
  .loadLayout {
    .dropdown-list {
      top: calc(100% + 7px) !important;
    }
  }
}
</style>
