<template>
  <div class="add-delete-container">
    <BaseBtn naked @click="handleButton(params)" :class="{ active: params.data.active }">
    <IconAdd class="add" />
    </BaseBtn>
  </div>
</template>
  
<script>
import BaseBtn from '@/components/BaseBtn.vue'
import IconAdd from '@/components/icons/IconAdd.vue'

export default {
  components: { BaseBtn, IconAdd },
  name: 'AddDelete',
  props: {
    // active: Boolean,
  },
  data() {
    return {
      active: false,
    }
  },
  mounted() {
  },
  methods: {
    handleButton(params) {
      //this.active = !this.active
      params.clicked(params.data);  
    },
  },
}
</script>
  
<style lang="scss" scoped>
  .add-delete-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    height: 100%;
      /* padding-top: 3px; */
  }
  .add {
    opacity: 0.6;
    transform: rotate(45deg);
      transition: transform 0.2s;
  }
  .active {
      .add {
          opacity: 1;
          transform: rotate(90deg);
          fill: var(--main-orange);
      }
  }
</style>
  