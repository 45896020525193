<template>
  <div class="landing-body" ref="landingBody">
    <LandingHeader :isLoggedIn="false" />

    <div class="landing-page-container">
      <div class="landing-page-section" id="partnership" style="scroll-margin-top: 179px">
        <h1 class="bold center">
          Partership을 통한
          <br />
          WinWin전략
        </h1>
        <h4 class="center subpara">귀사의 마케팅자원과 TAP의 기술을 연계한 다양한 사업제휴가 가능합니다.</h4>
        <ul class="tap-feature">
          <li>
            <img src="@/assets/icons/landing-connect.svg" alt="" />
            <h3 class="bold">공동사업</h3>
            <h4>귀사의 브랜드 및 마케팅 자원 기반 하에 TAP 서비스 사업의 수익을 공유할 수 있습니다.</h4>
          </li>
          <li>
            <img src="@/assets/icons/landing-trophy.svg" alt="" />
            <h3 class="bold">모의투자 대회</h3>
            <h4>유료 모의투자대회를 공동 개최하고, 수익을 공유할 수 있습니다.</h4>
          </li>
          <li>
            <img src="@/assets/icons/landing-money.svg" alt="" />
            <h3 class="bold">커미션</h3>
            <h4>추천 고객의 1년간 구독료 수익을 공유할 수 있습니다</h4>
          </li>
        </ul>
      </div>

      <div class="landing-page-section">
        <h3 class="center">이 밖의 다양한 Partership 제안이 오픈되어 있습니다.</h3>
        <div class="landing-round-btn-container">
          <a href="http://www.delkoit.co.kr/contact" class="landing-round-btn">Become a Partner</a>
        </div>
      </div>

      <div class="landing-page-section" id="advertisement" style="scroll-margin-top: 179px">
        <h2 class="bold center">실투자자를 대상으로 한 탁월한 광고효과</h2>
        <h4 class="center subpara">잠재적 실매매 투자자에게 상시 노출</h4>
        <img src="@/assets/images/demo-screen.png" alt="" class="product-image" />
      </div>

      <div class="landing-page-section">
        <div class="footer-hero">
          <h3 class="center">이 밖의 다양한 광고제안이 오픈되어 있습니다.</h3>
          <div class="footer-hero-cta-container">
            <a href="http://www.delkoit.co.kr/contact" class="signup-cta">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
    <LandingFooter />
  </div>
</template>

<script>
import BtnSlide from '@/components/BtnSlide.vue';
import FeatureBar from '@/components/FeatureBar.vue';
import LandingHeader from '@/components/LandingHeader.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import DateSort from '@/components/DateSort.vue';
import AvatarComp from '@/components/AvatarComp.vue';
import LandingFooter from '@/components/LandingFooter.vue';

export default {
  name: 'ProfilePage',
  components: {
    BtnSlide,
    FeatureBar,
    LandingHeader,
    IconChevronDown,
    DateSort,
    AvatarComp,
    LandingFooter,
  },

  data() {
    return {};
  },

  mounted() {
    var section = this.$router.currentRoute.hash.replace('#', '');
    //console.log(section);
    if (section) this.$nextTick(() => window.document.getElementById(section).scrollIntoView({
      behavior: "smooth",
      block: "start"
    }));
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
