<template>
  <div class="main-container" :class="{ landing: isLanding }">
    <GnbComp @event:hidePannel="hidePannel" @event:hideSideMenu="hideSideMenu" />
    <main :class="{ hideAccount: hideAccount, hidePending: hidePending, changeGrid: hideOrders, sidemenuActive:hideSide }" ref="main">
      <section class="section1">
        <div class="demo-overlay-panel1" v-if="showDemoOverlay">
          <div class="overlay-title">
            <h1>계좌 신청후 TAP 트레이더의 기능을 이용할 수 있습니다.</h1>
            <BaseBtn naked class="request-demo-btn" @click="requestDemo">모의계좌 신청</BaseBtn>
          </div>
        </div>        
        <PanelComp ref="panel1" class="panel1" :setTotalValPl="totalValPl" :selectChgComp="selectChgComp" @updateCurrency="updateCurrency" @updateAccount="updateAccount" @event:updateAccPassNull="updateAccPassNull" />
        <PanelComp2 class="panel2" :symbolList="symbolList" :ovcData="ovcData" :selectChgComp2="selectChgComp2" :currencyInfo="currencyInfo" :account="account" @updateTotalValPl="updateTotalValPl" @sendOrder="sendOrder" @updateSubscribeOnPrice="updateRtPosSymbol" />
        <div class="chart-container">
          <div class="chart-container-inner">
            <!-- 차트 -->
            <PanelComp3 class="panel3" :symbolList="symbolList" :chgSymbol="chgSymbol" :account="account" />
          </div>
        </div>
      </section>
      <section class="section2">
        <div class="demo-overlay-panel2" v-if="showDemoOverlay">
          <div class="overlay-title">
            <h1>계좌 신청후 TAP 트레이더의 기능을 이용할 수 있습니다.</h1>
            <BaseBtn naked class="request-demo-btn" @click="requestDemo">모의계좌 신청</BaseBtn>
          </div>
        </div>        
        <PanelComp4 class="panel4" :setSymbolList="symbolList" @sendOrder="sendOrder" :selectChgComp4="selectChgComp4" :account="account" />
        <!--클릭 주문 -->
        <PanelComp5 class="panel5" :symbolList="symbolList" :chgSymbol="chgSymbol" :sendOrder="sendData" :currencyInfo="currencyInfo" :account="account" :selectChgComp5="selectChgComp5"  @updateCurPr="updateCurPr" @PanelComp4Select="PanelComp4Select" />
      </section>
      <div class="section3">
        <router-view />
      </div>
      <Sidemenu :selectNotice="selectNotice" @event:hideSideMenu="hideSideMenu"/>
    </main>

    <!-- 모달 -->
    <transactionPop />
    <profitLossPop />
    <marginStatusPop />
    <depositWithdrawPop />
    <requestDepositPop />
    <demoRequestPop />
    <eventRequestPop />
    <firstDemoRequestPop />
    <upgradePop />
  </div>
</template>

<script>
import GnbComp from '@/components/GnbComp.vue'
import Sidemenu from '@/components/Sidemenu.vue'

import PanelComp from '@/pages/main/PanelComp.vue'
import PanelComp2 from '@/pages/main/PanelComp2.vue'
import PanelComp3 from '@/pages/main/PanelComp3.vue'
import PanelComp4 from '@/pages/main/PanelComp4.vue'
import PanelComp5 from '@/pages/main/PanelComp5.vue'

import TransactionPop from '@/pages/account/TransactionPop.vue'
import ProfitLossPop from '@/pages/account/ProfitLossPop.vue'
import MarginStatusPop from '@/pages/account/MarginStatusPop.vue'
import DepositWithdrawPop from '@/pages/account/DepositWithdrawPop.vue'
import RequestDepositPop from '@/pages/account/RequestDepositPop.vue'
import DemoRequestPop from '@/pages/account/DemoRequestPop.vue'
import EventRequestPop from '@/pages/account/EventRequestPop.vue'
import FirstDemoRequestPop from '@/pages/main/FirstDemoRequestPop.vue'
import UpgradePop from '@/pages/profile/UpgradePop.vue'

import EventBus from '@/modules/event-bus'

export default {
  name: 'MainPage',
  props: {
    reloadPanelComp: {
      type: Number
    },
    reloadNotice: {
      type: Number
    }
    
  },
  components: {
    TransactionPop,
    ProfitLossPop,
    MarginStatusPop,
    DepositWithdrawPop,
    RequestDepositPop,
    DemoRequestPop,
    EventRequestPop,
    FirstDemoRequestPop,

    GnbComp,
    // eslint-disable-next-line vue/no-unused-components
    Sidemenu,

    PanelComp,
    // eslint-disable-next-line vue/no-unused-components
    PanelComp2,
    // eslint-disable-next-line vue/no-unused-components
    PanelComp3,
    // eslint-disable-next-line vue/no-unused-components
    PanelComp4,
    // eslint-disable-next-line vue/no-unused-components
    PanelComp5,

    UpgradePop,
  },

  data() {
    return {
      hideAccount: false,
      hidePending: false,
      hideOrders: false,
      hideSide: true,
      landing: '',
      symbolList:[],
      curPr:0,
      channelToPrice: new Map(),  
      socketPrice : null,
      isConnectPrice: false,
      ovcData:{cnt:0, ovcData:new Map()},
      sendData:null,
      selectNotice:0, //가능수량 페이지로드
      selectChgComp5:0, //PanelComp5 가능수량 조회
      selectChgComp4:0, //PanelComp4 그리드 조회
      selectChgComp2:0, //PanelComp2 그리드 조회
      selectChgComp:0, //PanelComp 그리드 조회
      currencyInfo: {currency:"USD", usdExR:0, hkdExR:0},
      totalValPl: 0,
      showDemoOverlay: false,
      accId: null,
      account: {
        accNm: '',
        accPass: '',
        accCnt: 0
      },
      tradeExec: false,
      tradeExecList: [],
      symbolCdList: [],
      socketSymbolCnt:1,
      rtPosSymbolList:[],
      priceInterval: null,
      connectTimeout: null,
      chgSymbol: null,
      isLanding: true,
      priceReqType: "socket", //시세 받는방식 socket, polling
    };
  },

  created() {

    // 로그인 overlay 처리
    if ( this.$sessionStorage.get('xDkToken') !== null ) {
      this.isLanding = false
    }

    // 
    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.setLayout(layout)
    })

    EventBus.$on('Favorite:event:setSymbol', (symbol) => {      
      this.symbolList.forEach(item => {
        if (item.name == symbol){
          this.chgSymbol = item;
          return;
        }
      })
      //console.log("chgSymbol >>> ", this.chgSymbol)      
    })

    this.selectSymbolList()

    //소켓연결시
    if (this.priceReqType == "socket"){
      this.connectPrice();
    }

    //주문 소켓 서버 연결
    this.connectOms();
  },
  mounted() {

    if ( this.isLogin ) {
      this.$nextTick(function() { 
        this.popAccountManage()
      })
    }

  },
  destroyed() {
    //oms
    if (window.$store.getters['SocketOms/getIsConnectOms']) {
      //
      window.$store.dispatch('SocketOms/setDisconnect');
    }

    //소켓 시세
    if (window.$store.getters['SocketPrice/getIsConnectPrice']) {
      //setDisconnect
      window.$store.dispatch('SocketPrice/setDisconnect');
    }

    //폴링 시세
    if (window.$store.getters['SocketPrice/getIsPolingPrice']) {
      //clear polling
      window.$store.dispatch('SocketPrice/setClearPolling');
    }
  },

  watch: {
    //App.vue 에서 PanelComp 데이터 갱신 요청
    reloadPanelComp: {
      handler(newData){
        this.PanelComp4Select();
        this.selectChgComp5 = this.selectChgComp5+1;
      },
      deep: true,
    },
    reloadNotice: {
      handler(newData){
        this.selectNotice = this.selectNotice+1;
      },
      deep: true,
    },
    changeFvSymbol(val){
      this.updateSubscribeOnPrice()
    },
    isLogin(value) {
      if ( value ) {
        this.isLanding = false

        this.$nextTick(function() { 
          this.popAccountManage()
        })        
      } else {
        this.isLanding = true
      }
    },
    getSelectSymbol(newVal){
      //panelcomp5 종목이 변경될경우 소켓데이터 연결 갱신
      this.updateSubscribeOnPrice()
    }
  },

  computed: {
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin']
    }, 
    changeFvSymbol: function(){
      let custom = window.$store.getters['Layout/getFavoriteGridCustom']
      if (custom == undefined){
        return null;
      }
      return {socketSymbol: custom.socketSymbol, socketSymbolCnt: this.socketSymbolCnt+1}
    },
    getSelectSymbol: function(){
      return window.$store.getters['SocketPrice/getSelectSymbol']
    }
  },  

  methods: {
    // disconnect() {
    //   if (this.isConnectPrice) {
    //     this.socketPrice.onclose = function () {}
    //     this.socketPrice.close()
    //     this.isConnectPrice = false 
    //   }
    // },  
    hidePannel(e) {
      if ( e.eventName === 'hideAccount' ) {
        this.hideAccount = !this.hideAccount
        if ( this.hideOrders ) {
          this.hidePending = !this.hidePending
        }
      } else if ( e.eventName === 'hidePending' ) {
        this.hidePending = !this.hidePending;
        if ( this.hideOrders ) {
          this.hideAccount = !this.hideAccount
        }
      } else if ( e.eventName === 'hideOrders' ) {
        this.hideOrders = !this.hideOrders

        if ( this.hideAccount || this.hidePending ) {
          this.hideAccount = true
          this.hidePending = true
        }
      }

      window.$store.commit('Layout/setHideAccount', { hideAccount: this.hideAccount })
      window.$store.commit('Layout/setHidePending', { hidePending: this.hidePending })
      window.$store.commit('Layout/setHideOrders',  { hideOrders: this.hideOrders })

    },
    hideSideMenu(e) {
      if ( e.isCurrent ) {
        this.hideSide = ( this.hideSide ) ? false : true
      } else {
        this.hideSide = false
      }

      //체결리스트이면 데이터 전달받기 위해 div 활성화 (테스트용)
      if (this.$route.path == '/main/tradeExecList'){
        this.tradeExec = true;
      }else{
        this.tradeExec = false;
      }

      window.$store.commit('Layout/setHideSide', { hideSide: this.hideSide })
    },
    setLayout(layout) {
      this.hideAccount = layout.hideAccount
      this.hidePending = layout.hidePending
      this.hideOrders  = layout.hideOrders
      this.hideSide    = layout.hideSide
      
      //관심종목 설정이 null일경우? 에러방지를 위해 디폴트 설정 추가
      if (layout.favoriteGridCustom == undefined){
        layout.favoriteGridCustom = {
          itrstNm: '관심종목',
          shtNm: true,
          curPr: true,
          ydiffPr: true,
          chGrate: true,
          totQ: true,
          seq:0,
          socketSymbol: []
        }
      }

      //관심종목 조회 후 싱크 필요
      
    },
    requestDemo() {
      this.$modal.show('firstDemoRequestPop')      
    },
    // oms socket 연결
    async connectOms() {
      await window.$store.dispatch('SocketOms/setConectOms', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
    },
    //종목 조회
    async selectSymbolList() {      
      await window.$store.dispatch('Symbol/load')
      this.symbolList = window.$store.getters['Symbol/getSymbolList'];

      // const response = await window.$http.get('chart/datafeed/symbols')

      // if ( response ) {
      //   this.symbolList = response.data;
      // }

      //소켓 시세
      if (this.priceReqType == "socket"){
        let symCdList = [];
        this.symbolList.forEach(item=>{
          symCdList.push(item.name);
        })
        if (!window.$store.getters['SocketPrice/getIsConnectPrice']) {
          window.$store.watch(() => window.$store.getters['SocketPrice/getIsConnectPrice'], socketPrice => {
            window.$store.dispatch('SocketPrice/setSubscribeOnPriceList', [])
          })
        }else{
          window.$store.dispatch('SocketPrice/setSubscribeOnPriceList', symCdList)
        }
      //폴링 시세
      }else if (this.priceReqType == "polling"){
        let symbolList = [];
        this.symbolList.forEach(item=>{
          symbolList.push(item.name);
        })

        this.connectPrice(symbolList);
      }

      this.setStockInfo(this.symbolList, null);
    },
    updateCurPr(newVal){
      this.curPr = newVal;
    },
    sendOrder(selList){ //PannelComp4 주문 취소, 정정, PannelComp2 청산
      this.sendData = null;
      if (selList == null || selList.length == 0){
        return;
      }
      this.sendData = selList;
    },
    PanelComp4Select(){ //PanelComp2, PanelComp4 컴퍼넌트 재조회
      this.selectChgComp4 = this.selectChgComp4+1;
      this.selectChgComp2 = this.selectChgComp2+1;
      this.selectChgComp = this.selectChgComp+1;
    },
    updateCurrency(newVal){ //달러/원 선택
      this.currencyInfo = newVal;
    },
    updateTotalValPl(newVal){ //전체 평가손익
      this.totalValPl = newVal;
    },
    updateAccount(account){ //계좌정보 업데이트
      this.account = Object.assign({}, account);
    },
    updateAccPassNull(){
      if (this.account.accPass){
        this.account.accPass = "";
        this.account = Object.assign({}, this.account);
      }
    },
    updateRtPosSymbol(dataList){
      this.rtPosSymbolList = dataList;
      this.updateSubscribeOnPrice();
    },
    updateSubscribeOnPrice(){
      //소켓 구독 종목 업데이트
      this.symbolCdList = [];

      //관심종목 리스트
      let fvCustom = window.$store.getters['Layout/getFavoriteGridCustom']
      if (fvCustom.socketSymbol != undefined && fvCustom.socketSymbol != null && fvCustom.socketSymbol.length >0) {       

        fvCustom.socketSymbol.forEach(data =>{
          this.symbolList.forEach(symbol =>{
            if (symbol.symbolno == data){
              this.symbolCdList.push(symbol.name);
              return;
            }
          })
        });

        //잔고 포지션 종목
        this.rtPosSymbolList.forEach(data =>{
          let chkYn = "N";
          this.symbolCdList.forEach(item =>{
            if (item == data.symCd){
              chkYn = "Y";
              return;
            }
          })
          if (chkYn == "N"){
            this.symbolCdList.push(data.symCd);            
          }
        });
      }else if (this.rtPosSymbolList.length > 0){
        //관심종목이 없고 잔고 목록만 있을경우
        this.rtPosSymbolList.forEach(data =>{
          this.symbolCdList.push(data.symCd);
        });
      }

      //선택 종목
      const selSymbol = window.$store.getters['SocketPrice/getSelectSymbol']

      if (selSymbol != undefined && selSymbol.name){
        let chkYn = "N"
        this.symbolCdList.forEach(item =>{
          if (selSymbol.name == item){
            chkYn = "Y"
            return
          }
        })
        if (chkYn == "N"){
          this.symbolCdList.push(selSymbol.name)
        }
      }
      

      //console.log("소켓 구독 수정", this.symbolCdList)
      
      this.subscribeOnPriceModify(this.symbolCdList);
    },

    /* 소켓 데이터 관련 이동 */
    async setStockInfo(newSymbol, oldSymbol) {

      const rStock = await window.$http.get('chart/datafeed/stockdata', { params: {  } } )
        if ( rStock != null ) {
          let ovc = this.ovcData.ovcData;
          //window.$store.ovcData = rStock.data;
          

          //시세 db값으로 초기 설정
          rStock.data.forEach(item => {
            ovc.set(item.symbol, item);
          });
          
          let cnt = this.ovcData.cnt; //map으로 전달하면 PanelComp2 watch에서 감지를 못하여 cnt값을 계속 증가시켜 감지
          const param = {cnt:cnt+1, ovcData:ovc};         
          this.ovcData = param;

          //window.$store.ovcData = this.ovcData;
          //minmov pricescale
          //

          // if (this.curPr == null || this.curPr == 0){
          //   this.curPr = rStock.data.curPr;
          //   this.$emit("updateCurPr", this.curPr);
          // }
          // this.rStock = rStock;
        }   
    },
    // 시세 socket 연결
    connectPrice(symbolList) {
      if (this.priceReqType == "socket"){
        window.$store.dispatch('SocketPrice/setConectPrice', {sessionStorage:this.$sessionStorage.get('xDkToken')});
      }else if (this.priceReqType == "polling"){
        window.$store.dispatch('SocketPrice/setPollingPrice', {symbolList:symbolList});
      }
    },
    subscribeOnPriceModify(symbolList) {
      if (this.priceReqType == "socket"){
        window.$store.dispatch('SocketPrice/setUpdateSubscribeOnPriceList', symbolList);
      }else if (this.priceReqType == "polling"){
        window.$store.dispatch('SocketPrice/setPollingPrice', {symbolList:symbolList});
      }
    },
    popAccountManage() {
      if ( this.$sessionStorage.get('acc_pass_store') === null ) {
        this.$http
          .get('/api/account/count', { params: {allYn: 'Y'} })
          .then((response) => {
            if (response) {
              if ( response.data > 0 ) {
        this.$refs.panel1.$refs.accountDropdownMain.accountManage()        
              }
            }
          })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.landing {
  .panel1,
  .panel2,
  .panel4 {
    display: none;
  }
}
.demo-overlay-panel1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 290px;
  background-color: rgba(8, 15, 31, 0.9);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.demo-overlay-panel2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 202px;
  background-color: rgba(8, 15, 31, 0.9);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-height: 500px) and (max-height: 1000px) {
    height: 142px;
  }
  @media (min-height: 1000px) and (max-height: 1024px) {
    height: 172px;
  }
}
.request-demo-btn {
  border-radius: 50vh;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 13px 33px 15px !important;
  margin: 20px auto 0 auto;
}
.row {
  margin-top: 10px;
}
</style>
