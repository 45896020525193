<template>
  <div class="chat-panel">
    <div class="chat-panel-header" v-if="!noHeader">
      <h3 class="bold">{{ form.symCd }}</h3>
    </div>
    <div class="chat-body">

      <template v-for="(chat, index) in chatList">

        <ChatBubble
          v-bind:key="`chat-bubble-${index}`"
          :me="chat.regrId === userId"
          :chatNo="chat.chatNo"
          :regrId="chat.regrId"
          :usrNick="chat.usrNick"
          :time="chat.regTime"
          :repoCo="chat.repoCo"
          :chat="chat.chatCont"
          :prfBgCol="chat.prfBgCol"
          @event:repoty="reportChat"
        />        

        <ChatDate v-bind:key="`chat-date-${index}`" :chatDate="chat.regDay" v-if="isChageDate(index)" />

      </template>

    </div>
		<div class="chat-input">
			<BaseBtn class="send" @click="insertChat">
        <img src="@/assets/icons/send-off.svg" alt="">
      </BaseBtn>
			<input type="text" placeholder="Write a message..." v-model="form.chatCont" @keyup.enter="insertChat" />
		</div>

    <reportPop @event:insertReport="search" />        
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
import ChatBubble from '@/pages/community/ChatBubble.vue'
import ChatDate from '@/pages/community/ChatDate.vue'
import ReportPop from '@/pages/community/ReportPop.vue'

export default {
  name: 'ChatPanel',
  components: {
    ChatBubble,
    ChatDate,
    BaseBtn,
    ReportPop,
  },

  props: {
    noHeader: Boolean
  },

  data() {
    return {
      chatList: [],
      form: {
        symCd: 'NQH24',
        chatCont : ''
      },
      interval : null,
    }
  },

  mounted() {
    this.search()

    // 로그인 처리
    window.$store.watch(() => window.$store.getters['Login/isLogin'], isLogin => {
      if (isLogin) {

        // 
        this.interval = setInterval(() => {
          this.search()
        }, 5000)    

      } else {

        clearInterval(this.interval)

      }
    })
  },

  beforeDestroy() { 
    clearInterval(this.interval)
  },

  computed: {
    // 로그인id
    userId () {
      return window.$store.getters['Login/getUsrId']
    },
  },  

  methods: {
    async search() {
      const response = await window.$http.get('/api/chat/list', {params : {symCd : this.form.symCd}})
      
      if (response.data && response.data.length > 0) {
        this.chatList = response.data
      }      
    },
    isChageDate(index) {
      if ( index <= 0 ) {
        return false
      }

      if ( index + 1 <= this.chatList.length - 1  
            && this.chatList[index + 1] && this.chatList[index] 
            && this.chatList[index + 1].regDay != this.chatList[index].regDay ) {
              return true
      }

      return false
    },
    insertChat() {
      if (this.form.chatCont === '') {
        return
      }

      if (this.form.chatCont.length > 140) {
        this.$alert.alert('140자를 초과 입력하실 수 없습니다.', 'Warnning')
        return
      }      

      this.$http
        .post('/api/chat/insert',
            this.form)
        .then(() => {
          this.form.chatCont = ''
          this.search()
        })
    },
    reportChat(chatNo) {
      this.$modal.show('reportPop', { chatNo : chatNo })
    }    
  },
};
</script>

<style lang="scss" scoped>
.chat-panel {
  position: relative;
	border-top: 1px solid var(--border-color-dom);
  height: calc(100% - 270px);
  @media (max-width: $mobile) {
    height: calc(100% - 202px);
    border-top: none; 
  }
  .chat-panel-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* flex: 0 0 auto; */
    display: flex;
    align-items: center;
    height: 50px;
		padding-left: 20px;
		padding-right: 20px;
    background-color: var(--main-black);
    /* border-bottom: 1px solid var(--border-color-dom); */
		z-index: 1;
  }
	.chat-body {
    position: relative;
    /* flex: 1 1 auto; */
		/* display: grid; */
    display: flex;
    flex-direction: column-reverse;
		gap: 20px;
		padding: 70px 20px 20px 20px;
    height: 100%;
		overflow-y: scroll;
    @media (max-width: $mobile) {
      padding-top: 0;
      /* padding-bottom: 50px; */
      /* background-color: red; */
    }
	}
	.chat-input {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /* flex: 0 0 auto; */
    position: relative;
		height: 50px;
		border-top: 1px solid var(--border-color-dom);
		input {
			width: 100%;
			height: 50px;
      padding-right: 50px;
      padding-left: 20px;
			border: none;
		}
		.send {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
		}
	}
}
</style>
