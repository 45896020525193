<template>
  <div class="help-page">
    <div class="panel-header">
      <div class="row">
        <div class="row-item">
          <BaseBtn naked>
            <IconChevronDown left />
          </BaseBtn>
          <p class="breadcrumb">
            <span>헬프센터 /</span>
            비용청구
          </p>
        </div>
      </div>
    </div>
    <div class="help-context">
      <h1 class="bold">이런질문 저런질문 질문질문 하는데, 질문인가요?</h1>
      <p class="context">
        PSE의 역사는 1871년으로 거슬러 올라가며 오스트리아-헝가리 제국의 핵심 시장으로 빠르게 성장했지만 두 차례 세계대전이 연이어 일어나면서
        1993년에야 거래소를 폐쇄하고 재개장했습니다. 그 이후로 더 많은 투자자와 기업을 유치하기 위해 노력해 왔으며, 투자자에게는 현지 시장에 대한
        접근성을, 기업에게는 자본 조달을 위한 공간을 제공했습니다. 현재 유럽증권거래소연맹(FESE)의 회원사이며 미국 증권거래위원회(SEC)가 부여하는
        ‘지정 역외 증권시장’의 지위를 보유하고 있습니다. PSE는 주식, 채권 및 기타 자산을 거래하고 관찰할 수 있어 현지 시장으로의 도피처 역할을 합니다.
      </p>
      <p class="context">
        트레이딩뷰에서 바로 프라하 증권거래소 데이터에 접속해 주식, 펀드, 워런트, 채권, 지수 등 체코 시장에 대한 정보에 입각한 분석을 할 수 있습니다.
        새로 통합된 데이터에 접속하려면 심볼 서치창을 열고 “PSEСZ:”를 입력하면 됩니다.
      </p>
      <p class="context">
        데이터는 많을수록 좋으니 이번 업데이트가 여러분의 분석에 도움이 될 것으로 믿습니다. 이번 업데이트에 대한 의견을 알려주셔서 완벽한 기능을
        갖추었는지 확인해 주세요.
      </p>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';

export default {
  name: 'HelpPageSub2',
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  components: {
    BaseBtn,
    IconChevronDown,
  },
};
</script>

<style lang="scss" scoped>
.help-page {
	padding: 10px 20px;
}
.panel-header {
  border-bottom: 1px solid var(--border-color-dom);
}
h1 {
  line-height: 1.4em;
  margin-bottom: 20px;
}
.context {
  line-height: 1.4em;
  opacity: 0.8;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.help-context {
	
	padding-top: 10px;
}
.panel-header {
	height: 40px;
}
</style>
