<template>
  <div class="dropdown-list-item">
    <div class="col">
      <slot name="item-name"></slot>
    </div>
    <div class="col">
      <div class="lang" v-if="lang">
        <h4>한국어</h4>
      </div>
      <img src="@/assets/icons/chevron.svg" alt="" class="chevron" />
    </div>
    <div class="dropdown-sub">
      <slot name="sub-content"></slot>
    </div>
  </div>
</template>

<script>
// import H4Text from './H4Text.vue';

export default {
  name: 'DropDownItemWithSub',
  props: {
    lang: Boolean,
  },
  components: {
    // H4Text
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.dropdown-list-item {
  position: relative;
  padding: 0 10px;
  .col {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
  }
  .lang {
    opacity: 0.6;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      .dropdown-sub {
        display: block;
      }
    }
  }
  .chevron {
    transform: rotate(-90deg);
  }
  .dropdown-sub {
    display: none;
    position: absolute;
    top: -6px;
    left: 100%;
    min-width: 70px;
    border: 1px solid var(--border-color);
    white-space: nowrap;
    background-color: var(--main-black);
    padding: 5px 0;
  }
}
</style>
