// object 초기화
const initObject = function (object, deep) {
    let self = this;
    self.obj = object;
  
    for( var key in object ) {
      switch ( typeof object[key] ) {
        case 'number':          // 정수
          self.obj[key] = 0;   
          break;
        case 'boolean':         // boolean
          self.obj[key] = false;   
          break;
        case 'array':           // array
          self.obj[key] = [];   
          break;
        case 'object':          // object
          if (deep) {
            initObject(self.obj[key]);
          } else {
            self.obj[key] = {};
          }
          break;                
        default:                // 문자
          self.obj[key] = '';
      }    
    }
  }
  
  // 숫자 3자리 콤마
  const numberComma = function( value ) {
    var len, point, str; 
         
    if ( value ) {
      var isMinus = (value < 0) ? true : false;
      value = Math.abs(value) + ""; 
      var pointValue = '';
  
      if ( value.indexOf('.') > -1 ) {
        var arrValue = value.split('.');
        value = arrValue[0];
        pointValue = '.'+ arrValue[1];
      }
  
      point = value.length % 3 ;
      len = value.length; 
     
      str = value.substring(0, point); 
      while (point < len) { 
          if (str != "") str += ","; 
          str += value.substring(point, point + 3); 
          point += 3; 
      } 
  
      if (pointValue != '') {
        str += pointValue;
      }
  
      if (isMinus) {
        str = '-'+ str;
      }
  
    } else {
      str = value;
    }
     
    return str;
  }


const getThisYear = function( ) {
  var d = new Date()

  return d.getFullYear()
}

// 오늘 날짜 yyyy-MM-dd
const getToday = function( ) {
  var d = new Date()

  return getDateStr(d)
}

// ?일전 날짜
const getDatePrevDay = function(day) {
  var d = new Date()
  var dayOfMonth = d.getDate()
  d.setDate(dayOfMonth - day)

  return getDateStr(d)
}

// ?달전 날짜
const getDatePrevMonth = function(month) {
  var d = new Date()
  var monthOfYear = d.getMonth()
  d.setMonth(monthOfYear - month)

  return getDateStr(d)
}

// date yyyy-MM-dd
const getDateStr = function( date ) {
  var month = '' + (date.getMonth() + 1), 
  day = '' + date.getDate(), 
  year = date.getFullYear(); 
  
  if (month.length < 2) month = '0' + month; 
  if (day.length < 2) day = '0' + day; 
  
  return [year, month, day].join('-');
}

const getUuidv4 = function() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}  

/**
 * NVL: expr1 값이 null(undefined) 또는 공백인 경우 expr2 값을 반환, 그렇지 않으면 expr1 값 반환
 * @return expr1
 */
const nvl = function(expr1, expr2) {
	if (expr1 === undefined || expr1 == null || expr1 == "") {
		expr1 = expr2;
	}
	return expr1;
}

// 배열에 값 추가
const addValueInArray = function(array, value) {
  if ( array !== null && Array.isArray(array) ) {
    if ( array.indexOf(value) < 0 )  {
      array.push(value)
    }
  }
}

// 배열에 값 삭제
const delValueInArray = function(array, value) {
  if ( array !== null && Array.isArray(array) ) {
    for ( let i = 0; i < array.length; i++ ) {
      if(array[i] === value)  {
        array.splice(i, 1)
        i--;
      }
    }
  }
}

export default { 
  initObject,
  numberComma,  
  getThisYear,
  getToday,
  getDatePrevDay,
  getDatePrevMonth,
  getUuidv4,
  nvl,
  addValueInArray,
  delValueInArray,
}