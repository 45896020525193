<template>
  <div class="swiper-container-inner">
    <!-- Slider main container -->
    <div class="swiper" ref="swiper">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide" :class="[planId === Const.PLAN_TYPE.NORMAL ? 'active': '']">
          <div class="slide-inner">
            <h1 class="bold">Basic</h1>
            <h4 v-if="planId === Const.PLAN_TYPE.NORMAL">현재플랜</h4>
            <h4 v-else></h4>
            <h1 class="bold">2</h1>
          </div>
        </div>
        <div class="swiper-slide" :class="[planId === Const.PLAN_TYPE.ESSENTIAL ? 'active': '']">
          <div class="slide-inner">
            <h1 class="bold">Essential</h1>
            <h4 v-if="planId === Const.PLAN_TYPE.ESSENTIAL">현재플랜</h4>
            <h4 v-else></h4>
            <h1 class="bold">5</h1>
          </div>
        </div>
        <div class="swiper-slide" :class="[planId === Const.PLAN_TYPE.PLUS ? 'active': '']">
          <div class="slide-inner">
            <h1 class="bold">Plus</h1>
            <h4 v-if="planId === Const.PLAN_TYPE.PLUS">현재플랜</h4>
            <h4 v-else></h4>
            <h1 class="bold">10</h1>
          </div>
        </div>
        <div class="swiper-slide" :class="[planId === Const.PLAN_TYPE.PREMIUM ? 'active': '']">
          <div class="slide-inner">
            <h1 class="bold">Premium</h1>
            <h4 v-if="planId === Const.PLAN_TYPE.PREMIUM">현재플랜</h4>
            <h4 v-else></h4>
            <h1 class="bold">20</h1>
          </div>
        </div>
      </div>
      <!-- If we need pagination -->
      <!-- <div class="swiper-pagination"></div> -->

      <!-- If we need navigation buttons -->
      <!-- <div class="swiper-button-prev"></div> -->
      <!-- <div class="swiper-button-next"></div> -->

      <!-- If we need scrollbar -->
      <!-- <div class="swiper-scrollbar"></div> -->
    </div>
    <div class="custom-next" ref="customNext" id="customNext">
      <IconChevronDown right />
    </div>
    <div class="custom-prev" ref="customPrev" id="customPrev">
      <IconChevronDown left />
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import IconChevronDown from './icons/IconChevronDown.vue'
import Const from '@/const'

// import 'swiper/css/pagination'

export default {
  components: { IconChevronDown },
  name: 'UpgradeSwiper',

  data() {
    return {
      activeIndex: 0,
      Const: Const,
      slider: null
    }
  },

  computed: {
    // 플랜ID
    planId() {
      const loginUserInfo = window.$store.getters['Login/getLoginUserInfo']

      if ( loginUserInfo ) {
        return loginUserInfo.planId
      } else {
        return 'P00'
      }
    },
  },    

  mounted() {
    this.slider = new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Navigation],
      // Optional parameters
      // loop: true,
      // slidesPerView: 4.2,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      // slidesPerView: 'auto',
      breakpoints: {
        360: {
          slidesPerView: 3.4,
        },
        414: {
          slidesPerView: 3.35,
        },
        1200: {
          slidesPerView: 'auto',
        },
      },
      // If we need pagination
      // pagination: {
      //   el: '.swiper-pagination',
      // },

      // Navigation arrows
      navigation: {
        nextEl: '.custom-next',
        prevEl: '.custom-prev',
      },

      on: {
        slideChange: function (index) {
          //console.log(index);
          if (index.activeIndex === 0) {
            document.getElementById('customPrev').style.display = 'none';
          } else {
            document.getElementById('customPrev').style.display = 'flex';
          }
          if (window.innerWidth <= 1200) {
            if (index.activeIndex === 3) {
              document.getElementById('customNext').style.display = 'none';
            } else {
              document.getElementById('customNext').style.display = 'flex';
            }
          } else {
            if (index.activeIndex === 3) {
            document.getElementById('customNext').style.display = 'none';
          } else {
            document.getElementById('customNext').style.display = 'flex';
          }
          }
        },
      },
    })

    if ( this.planId === Const.PLAN_TYPE.PREMIUM ) {
      this.slider.slideTo(4, 1000)
    }    
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.swiper-container-inner {
  position: relative;
}
.swiper {
  /* max-width: 600px; */
  /* margin: 0 20px; */
  width: 100%;
  /* border-top: 1px solid rgba(255, 255, 255, 0.2); */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
  /* border-left: 1px solid rgba(255, 255, 255, 0.2); */
  /* margin-left: 0; */
  /* margin-right: 0; */
}
.swiper-slide {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 186px;
  height: 110px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  &:first-child {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  &.active {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .slide-inner {
    text-align: center;
    /* line-height: 1.4em; */
    h4 {
      color: rgba(255, 255, 255, 0.6);
      height: 15px;
      margin: 5px 0;
    }
  }
  @media (max-width: $mobile) {
    width: 100%;
  }
}
.custom-next {
  position: absolute;
  /* top: 50%; */
  top: 50%;
  transform: translateY(-50%);
  /* width: calc(var(--swiper-navigation-size)/ 44 * 27); */
  /* height: var(--swiper-navigation-size); */
  /* margin-top: calc(0px - (var(--swiper-navigation-size)/ 2)); */
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: var(--swiper-navigation-color,var(--swiper-theme-color)); */

  right: 8px;
  left: auto;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50vh;
  background-color: #212736;
  @media (max-width: $mobile) {
    right: 10px;
  }
}
.custom-prev {
  display: none;
  position: absolute;
  /* top: 50%; */
  top: 50%;
  transform: translateY(-50%);
  /* width: calc(var(--swiper-navigation-size)/ 44 * 27); */
  /* height: var(--swiper-navigation-size); */
  /* margin-top: calc(0px - (var(--swiper-navigation-size)/ 2)); */
  z-index: 10;
  cursor: pointer;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* color: var(--swiper-navigation-color,var(--swiper-theme-color)); */

  left: 6px;
  right: auto;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50vh;
  background-color: #212736;
}
</style>
