<template>
  <modal name="changeOrderPop" class="custom-modal" adaptive :minWidth="400" :minHeight="313" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="정정 주문" modalName="changeOrderPop" footer>
      <template #body>
        <div class="table-wrapper">
          
          <div class="table-row">
              <h3 class="label">종목</h3>
              <h3>{{ order.symCd }}</h3>
              <!--<DropDown id="changeOrder" popup :options="['NQZ23', 'NQZ23', 'NQZ23']"  full @changeOrder="changeOrder" />-->
            </div>
            <div class="table-row">
              <h3 class="label">종목명</h3>
              <h3>{{ order.symKorNm }} (<span :style="order.ordSdCd == '1' ? 'color:blue' : 'color:red'">{{ order.ordSdNm }}</span>)</h3>
            </div>
            <div class="table-row">
              <h3 class="label">원주문</h3>
              <h3>{{ order.ordId }}</h3>
            </div>
            <div class="table-row">
              <h3 class="label">가격</h3>
              <!-- <CounterComp full alignRight /> -->
              <OrderInput modal :symbol="selectSymbol" :nowPrice="nowPrice" @updateOrdPrc="updateOrdPrc" />
            </div>
          
        </div>
      </template>
      <template #footer>
        <BtnSub text="취소" @click="closeOrderPop" />
        <BtnPrimary text="정정" @click="correctionOrderPop" />
      </template>
    </ModalContent>
  </modal>  
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
//import CounterComp from '@/components/CounterComp.vue'
import DropDown from '@/components/DropDown.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import OrderInput from '@/components/OrderInput.vue';

export default {
	name: 'ChangeOrderPop',
	components: {
    ModalContent,
  //  CounterComp,
    DropDown,
    BtnSub,
    BtnPrimary,
    OrderInput,
  },
  props: {
    // symbol: {
    //   type: Object
    // },
    // ovcData: {
    //   type: Array
    // },
    // selectList: {
    //   type : Array,
    // },
  },
  watch:{
    symbol(newSymbol) {

    },
    // ovcData:{
    //   handler(newOvc){
    //     //this.nowPrice = newOvc.curPr;
    //   }
    // },
    selectList:{
      handler(newData){
        if (newData != null && newData.length > 0){
          this.data = newData;
        }
      }
    }
  },
	data() {
		return {
			order:{
        symCd:'',
        symKorNm:'',
        ordPrc:0,
        ordId:0,
      },
      nowPrice:0,
      ordPrice:0,
      //symbol: { name: '', minmov : 0.1, pricescale : 100 },
      data:null,
      selectSymbol:null,
		};
	},
	methods: {
    beforeOpen(e) {  
      if (e.params == null || e.params.length == 0){
        this.$alert.alert("선택한 주문이 없습니다.", 'Warnning');
        return;
      }else{      
        this.ordPrice = 0;
        this.nowPrice = 0;
        this.data = e.params;
        if (this.data.list[0].ordTpCd == "2"){
          this.$alert.alert("시장가 주문은 주문가격을 수정할 수 없습니다.", 'Warnning');
          this.closeOrderPop();
        }
      }
    },
    opened(){
      this.order = this.data.list[0];
      this.ordPrice = this.order.ordPrc;
      this.nowPrice = this.order.ordPrc;
      this.selectSymbol = this.data.symbol;
      if (this.data.list[0].ordTpCd == "2"){
          this.closeOrderPop();
      }
    },
    updateOrdPrc(newVal){
      this.ordPrice = newVal;
    },
    closeOrderPop(){
      this.$modal.hide('changeOrderPop');
    },
    correctionOrderPop(){
      if (Number(this.ordPrice) == Number(this.order.ordPrc)){
        this.$alert.alert("주문가격과 정정가격이 동일합니다.", 'Warnning');
        return;
      }
      this.order.editPrc = Number(this.ordPrice);
      this.$emit('update-order-complete', this.order);
      this.closeOrderPop();
    }
	},
};
</script>

<style lang="scss" scoped>
.table-row {
    display: flex;
    align-items: center;
    height: 30px;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
    .input-container {
      width: 155px;
      input {
        width: 155px;
        padding-right: 32px !important;
      }
    }
    .amount-btn {
      margin-right: 5px;
      width: 60px;
    }
    h3 {
      margin-right: 15px;
    }
    .label {
      color: rgba(255, 255, 255, 0.6);
      /* width: 68px; */
      flex: 0 68px;
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      flex: 1;
    }
  }
</style>