<template>
  <div class="landing-body" ref="landingBody">
    <LandingHeader :isLoggedIn="false" />

    <div class="landing-page-container">
      <div class="landing-page-section">
        <h1 class="bold center">
          Click 주문 & AI Signal 탑재
          <br />
          강력한 WTS
        </h1>
        <div class="landing-round-btn-container">
          <button class="landing-round-btn no-border" :class="{active:pcDemo}" @click="pcDemo = true; mobileDemo = false">PC 웹</button>
          <button class="landing-round-btn no-border" :class="{active:mobileDemo}" @click="pcDemo = false; mobileDemo = true">모바일 & 태블릿</button>
        </div>
        <!-- <img src="@/assets/images/product-sample.png" alt="" class="product-image variant" /> -->
        <video class="product-image variant" ref="video" autoplay>
          <source src="@/assets/images/TAPTrader.mp4" type="video/mp4">
        </video>
      </div>

      <div class="landing-page-section">
        <h3 class="center">당신의 투자 타이밍을 지켜주는 최적의 투자솔루션</h3>
        <div class="landing-round-btn-container">
          <router-link to="/main" class="landing-round-btn">Trader 시작</router-link>
        </div>
      </div>

      <div class="landing-page-section">
        <h2 class="bold center">왜 TAP Trader를 써야 하는가?</h2>
        <ul class="why-tap-feature">
          <li>
            <h3 class="bold">Fast</h3>
            <h4>국내 최초 Web Click 주문</h4>
            <img src="@/assets/images/landing-feature1.png" alt="" />
          </li>
          <li>
            <h3 class="bold">No Install</h3>
            <h4>설치 없이 Web Browser 환경 어디서나</h4>
            <img src="@/assets/images/landing-feature2.png" alt="" />
          </li>
          <li>
            <h3 class="bold">AI Signal</h3>
            <h4>AI 매매타이밍 신호 제공</h4>
            <img src="@/assets/images/landing-feature3.png" alt="" />
          </li>
        </ul>
      </div>

      <div class="landing-page-section">
        <h2 class="bold center">원하는 호가를 빠르게 잡아내는 클릭주문</h2>
        <h4 class="center subpara">빠른 주문은 트레이딩의 기본! 누구보다 빠르게 원하는 호가를 확보하세요.</h4>
        <video class="product-image variant" ref="video" autoplay>
          <source src="@/assets/images/TAPClickOrder.mp4" type="video/mp4">
        </video>
        <ul class="feature-grid">
          <li>
            <img src="@/assets/icons/landing-thunder-white.svg" alt="" />
            <div class="title">빠른 시세</div>
            <div class="desc">Polling 방식의 기존 WTS와 차원이 다른 소켓통신이 시세 속도를 보장합니다.</div>
          </li>
          <li>
            <img src="@/assets/icons/landing-pointer.svg" alt="" />
            <div class="title">빠른 주문</div>
            <div class="desc">원클릭 스피드 주문으로 현존하는 가장 빠른 WTS 주문을 내실 수 있습니다.</div>
          </li>
          <li>
            <img src="@/assets/icons/landing-timer.svg" alt="" />
            <div class="title">자동 손절,익절</div>
            <div class="desc">자동 손절, 익절 기능을 제공해 효과적인 트레이딩 전략을 구사할 수 있습니다.</div>
          </li>
          <li>
            <img src="@/assets/icons/landing-stop.svg" alt="" />
            <div class="title">스탑 주문</div>
            <div class="desc">스탑주문을 통해 원하는 호가에 주문을 걸어 두실 수 있습니다.</div>
          </li>
          <li>
            <img src="@/assets/icons/landing-keyboard.svg" alt="" />
            <div class="title">단축키</div>
            <div class="desc">단축키를 통해 빠른 주문과 주문환경 설정이 가능합니다.</div>
          </li>
        </ul>
      </div>

      <div class="landing-page-section">
        <h2 class="bold center">직관적으로 트레이딩에 도움주는 AI매매신호</h2>
        <h4 class="center subpara">누구라도 따라할 수 있는 검증된 AI매매신호로 투자기회를 포착하세요.</h4>
        <img src="@/assets/images/product-sample-click-order.png" alt="" class="product-image" />
        <ul class="feature-grid">
          <li>
            <img src="@/assets/icons/landing-recycle.svg" alt="" />
            <div class="title">AI 검증</div>
            <div class="desc">3년 이상의 성능검증을 거친 AI 매매신호를 제공</div>
          </li>
          <li>
            <img src="@/assets/icons/landing-line-chart.svg" alt="" />
            <div class="title">멀티 시그널</div>
            <div class="desc">한 차트 위에 다양한 AI신호를 띄워 다양한 투자전략 구사 가능</div>
          </li>
          <li>
            <img src="@/assets/icons/landing-shield.svg" alt="" />
            <div class="title">데이트레이딩</div>
            <div class="desc">데이트레이딩 전략 기반 오버나잇 포지션 리스크 축소</div>
          </li>
          <li>
            <img src="@/assets/icons/landing-brain.svg" alt="" />
            <div class="title">투자상품 맞춤형</div>
            <div class="desc">투자상품별 전략 검증 수행</div>
          </li>
          <li>
            <img src="@/assets/icons/landing-folder.svg" alt="" />
            <div class="title">성능 추적</div>
            <div class="desc">실시간 성능을 추적해 테스트 성능과 비교분석 가능</div>
          </li>
        </ul>
      </div>

      <div class="landing-page-section">
        <h2 class="bold center">종목별 투자의견 소통하는 웹챗 제공</h2>
        <h4 class="center subpara">소셜 트레이딩을 통해 윈윈하는 정보를 교환해 보세요.</h4>
        <img src="@/assets/images/product-sample-click-order.png" alt="" class="product-image" />
        <ul class="feature-grid">
          <li>
            <img src="@/assets/icons/landing-chat.svg" alt="" />
            <div class="title">실시간 채팅</div>
            <div class="desc">
              실시간으로 투자자간 정보
              <br />
              교환이 가능
            </div>
          </li>
          <li>
            <img src="@/assets/icons/landing-group-chat.svg" alt="" />
            <div class="title">투자상품별</div>
            <div class="desc">
              투자상품별 채팅방을 제공해
              <br />
              공통 관심을 공유
            </div>
          </li>
          <li>
            <img src="@/assets/icons/landing-filter.svg" alt="" />
            <div class="title">소셜 필터링</div>
            <div class="desc">
              신고기능 부여 및 3진 아웃제 운영
              <br />
              등으로 건전한 채팅 문화 지향
            </div>
          </li>
          <li>
            <img src="@/assets/icons/landing-settings.svg" alt="" />
            <div class="title">투자레벨 표시</div>
            <div class="desc">
              투자레벨 표시제를 통해 상대 발언의
              <br />
              신뢰도를 평가 가능
            </div>
          </li>
        </ul>
      </div>

      <div class="landing-page-section">
        <div class="footer-hero">
          <h2 class="bold center">
            한 발 앞선 트레이딩을 하고 있는
            <br />
            <span class="tap-comm">TAP Community</span>
            에
            <br />
            지금 참여하세요
          </h2>
          <div class="footer-hero-cta-container">
            <router-link to="/signup" class="signup-cta">평생 무료가입</router-link>
          </div>
        </div>
      </div>
    </div>
    <LandingFooter />
  </div>
</template>

<script>
import BtnSlide from '@/components/BtnSlide.vue';
import FeatureBar from '@/components/FeatureBar.vue';
import LandingHeader from '@/components/LandingHeader.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import DateSort from '@/components/DateSort.vue';
import AvatarComp from '@/components/AvatarComp.vue';
import LandingFooter from '@/components/LandingFooter.vue';

export default {
  name: 'ProfilePage',
  components: {
    BtnSlide,
    FeatureBar,
    LandingHeader,
    IconChevronDown,
    DateSort,
    AvatarComp,
    LandingFooter,
  },

  data() {
    return {
      pcDemo: true,
      mobileDemo: false
    };
  },
  computed: {
    videoElement () {
      return this.$refs.video;
    },
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
