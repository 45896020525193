import { render, staticRenderFns } from "./UpgradePop.vue?vue&type=template&id=254e4362&scoped=true"
import script from "./UpgradePop.vue?vue&type=script&lang=js"
export * from "./UpgradePop.vue?vue&type=script&lang=js"
import style0 from "./UpgradePop.vue?vue&type=style&index=0&id=254e4362&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254e4362",
  null
  
)

export default component.exports