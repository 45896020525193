<template>
  <div class="panel-container">
    <div class="row">
      <div class="row-item">
        <h3>계좌번호</h3>
        <div class="row-item-content">
          <AccountDropDown ref="accountDropdownMain" id="accountDropdown" width="100" v-model="accId" @event:selectAccount="selectAccount" :updateAccountPass="updateAccountPass" store />
          <InputComp readonly width="150" v-model="account.accNo" />
        </div>
      </div>
      <div class="row-item">
        <h3>비밀번호</h3>
        <InputComp type="password" :width="65" v-model="accPass" @input="chgPwd" />
      </div>
      <div class="row-item">
        <h3>원/달러</h3>
        <div class="radio-group">
          <div class="label">
            <BtnRadio id="dollar" name="currency" text="달러" @click="dollarChecked" checked />
            <BtnRadio id="krw" name="currency" text="원" @click="krwChecked" />
          </div>
          <!-- <div class="won-dollar-value"><h3>{{ this.dailyPlMn.usdExR }}</h3></div> -->
        </div>
      </div>
    </div>
    <!-- balance table -->
		<ul class="balance-table">
      <li>
        <div class="label"><h3>평가손익</h3></div>
        <div class="balance"><BalanceText :balance="dailyPlMn.totalValPl" /></div>
      </li>
      <li>
        <div class="label"><h3>청산손익</h3></div>
        <div class="balance"><BalanceText :balance="dailyPlMn.fxPlMn" /></div>
      </li>
      <li>
        <div class="label"><h3>수수료</h3></div>
        <div class="balance"><BalanceText :balance="dailyPlMn.fxCms" /></div>
      </li> 
      <li>
        <div class="label"><h3>순손익</h3></div>
        <div class="balance"><BalanceText :balance="dailyPlMn.niPlMn" /></div>
      </li>
      <li>
        <div class="label"><h3>예수금</h3></div>
        <div class="balance"><BalanceText :balance="dailyPlMn.usdBalMn" /></div>
      </li>
      <li>
        <div class="label"><h3>환율</h3></div>
        <div class="balance"><BalanceText :balance="dailyPlMn.usdExR" /></div>
      </li>
    </ul>
  </div>
</template>

<script>
import AccountDropDown from '@/components/AccountDropDown.vue'
import BtnRadio from '@/components/BtnRadio.vue'
import InputComp from '@/components/InputComp.vue'
import BalanceText from '@/components/BalanceText.vue'

export default {
  name: 'PanelComp',
  components: {
    AccountDropDown,
    BtnRadio,
    InputComp,
    BalanceText,
  },
  props: {
    setTotalValPl: Number,
    selectChgComp: {
      type: Number
    },
  },
  watch: {
    setTotalValPl: {
      handler(newValPl){
        this.totalValPl = newValPl; //usd 금액
        this.setTotalVal();
      }
    },
    selectChgComp:{
      handler(){
        this.search();
      }
    },
  },
  data() {
    return {
      accId: 0,
      account: {
        accNo: '',
        accPass: ''
      },
      accPass:'',
      currencyInfo: {currency:"USD", usdExR:0, hkdExR:0, usdExHkd:0},
      totalValPl:"0",
      dailyPlMn:{fxPlMn:"0", fxCms:"0", niPlMn:"0", usdBalMn:"0", usdExR:"0", totalValPl:"0", data:null},
      updateAccountPass: null,
    }
  },

  created(){
    //원/달러 초기값 메인전달
    this.$emit("updateCurrency", this.currencyInfo);    
  },
  mounted() {

  },

  methods: {
    chgPwd(){
      //수동입력시 저장된 세션의 계좌 비밀번호 초기화
      if ( this.$sessionStorage.get('acc_pass_store') ) {
        let accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))
        accPassObj[this.account.accNo] = '';
        this.account.accPass = "";
        this.updateAccountPass = this.account;
        this.$sessionStorage.set('acc_pass_store', JSON.stringify(accPassObj))
      }

      //계좌비밀번호 수동 입력시
      if (this.accPass && this.accPass.length == 4){
        this.account.accPass = this.accPass;
      }else{
        //암호가 빈값이거나 4자리가 아닐경우 공백저장
        this.accPass = "";
        this.account.passYn = "N";
        this.account.accPass = "";
        this.resetDailyPlMn();
        this.$emit("event:updateAccPassNull", this.account);
        return;
      }

      this.selectAccount(this.account);
    },
    dollarChecked(value) {
      this.currencyInfo.currency = "USD";
      this.$emit("updateCurrency", this.currencyInfo);
      this.setDailyPlMn(this.dailyPlMn.data);
      this.setTotalVal();
    },
    krwChecked(value) {
      this.currencyInfo.currency = "WON";
      this.$emit("updateCurrency", this.currencyInfo);
      this.setDailyPlMn(this.dailyPlMn.data);
      this.setTotalVal();
    },
    async selectAccount(account) {

      //계좌가 다를경우 정보 갱신
      if (this.account.id == undefined  || this.account.id != account.id){
        this.account = Object.assign({}, account);
        this.account.accPass = '';
        this.$emit("updateAccount", account);
      }

      if (!account.accPass){
        this.accPass = "";
      }else{
        this.accPass = account.accPass;
      }
      this.accId = account.accId;

      this.resetDailyPlMn();

      //비밀번호 확인
      const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))
      let accPass = "";
      if (account.accPass){
        accPass = this.accPass;
      }else if (accPassObj != undefined && accPassObj[account.accNo]){
        accPass = accPassObj[account.accNo];
        this.accPass = accPassObj[account.accNo];
      }

      if (accPass){
        const response = await this.$lhttp.post('/api/account/pass/check',{accId : this.account.accId, accPass : accPass})
        if ( response && response.data ) {
          if (response.data){
            this.account.accPass = accPass;
            this.account.passYn = "Y";
            this.$emit("updateAccount", this.account);
            this.search();
          }else{
            this.account.accPass = '';
            this.account.passYn = "N";
            this.$emit("updateAccount", this.account);
            this.$alert.alert("계좌 비밀번호를 확인 해주세요.", 'Warnning');
          }
        }else{
          this.account.accPass = '';
          this.account.passYn = "N";
          this.$emit("updateAccount", this.account);
          this.$alert.alert("계좌 비밀번호를 확인 해주세요.", 'Warnning');
        }
      }

    },
    //일별 청산, 손익, 예수금, 달러 조회
    async search() {
      const response = await this.$http.get('/api/order/daily-pl-mn', { params: {accId: this.accId} })
      if ( response && response.data ) {
          let data = response.data;
          this.dailyPlMn.data = data;
          this.setDailyPlMn(data);
      }
    },
    setDailyPlMn(data){
      if (data == undefined || data.null){
        return;
      }
      this.currencyInfo.usdExR = data.usdExR;
      this.currencyInfo.hkdExR = data.hkdExR;
      this.currencyInfo.usdExHkd = data.usdExHkd;
      if (this.currencyInfo.currency == "WON"){
            this.dailyPlMn.fxPlMn = (Math.ceil(data.fxPlMn * data.usdExR) ).toString();
            this.dailyPlMn.fxCms = (Math.ceil(data.fxCms * data.usdExR) * -1).toString();
            this.dailyPlMn.niPlMn = (Math.ceil(data.niPlMn * data.usdExR)).toString();
            this.dailyPlMn.usdBalMn = (Math.ceil(data.usdBalMn * data.usdExR)).toString();
            this.dailyPlMn.usdExR = (data.usdExR).toString();   
          }else{
            this.dailyPlMn.fxPlMn = (data.fxPlMn).toFixed(2);
            this.dailyPlMn.fxCms = (data.fxCms * -1).toFixed(2);
            this.dailyPlMn.niPlMn = (data.niPlMn).toFixed(2);
            //this.dailyPlMn.balMn = (data.balMn).toString();
            this.dailyPlMn.usdBalMn = (data.usdBalMn).toFixed(2);
            this.dailyPlMn.usdExR = (data.usdExR).toString();   
          }
    },
    setTotalVal(){
      if (this.currencyInfo.currency == "WON"){
        this.dailyPlMn.totalValPl = (this.totalValPl).toString();
      }else{
        if (this.totalValPl == 0){
          this.dailyPlMn.totalValPl = "0";
        }else{
          this.dailyPlMn.totalValPl = (this.totalValPl).toFixed(2);
        }
        
      }

    },
    resetDailyPlMn(){
      this.dailyPlMn.fxPlMn = (0).toString();
      this.dailyPlMn.fxCms = (0).toString();
      this.dailyPlMn.niPlMn = (0).toString();
      this.dailyPlMn.usdBalMn = (0).toString();
      this.dailyPlMn.usdExR = (0).toString();
      this.dailyPlMn.totalValPl = (0).toString();
    }
  },
};
</script>

<style lang="scss" scoped>
.panel-container {
  padding: 0 20px;
}
</style>
