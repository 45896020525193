<template>
  <div class="input-container" :class="{ full: full }" :style="{ width: width + 'px' }">
    <textarea
      name=""
      id=""
      cols="30"
      :rows="rows"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :class="{ error: error }"
      v-model="inputValue" 
      @input="onInput"
    ></textarea>
    <div class="textarea-icon" v-if="inputValue">
      <BtnDelete @deleteClicked="clearInput" />
    </div>
  </div>
</template>

<script>
import BtnDelete from '@/components/BtnDelete.vue';
export default {
  name: 'InputCompTextarea',
  components: {
    BtnDelete,
  },
  props: {
    value: String,
    width: [String, Number],
    rows: {
      type: [String, Number],
      default: 7,
    },
    full: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    placeholder : {
      type: String,
      default: '내용을 입력하세요.',
    }
  },

  data() {
    return {
      inputValue: '',
    };
  },

  watch: {
    value (newValue, oldValue) {
      this.inputValue = newValue
    }
  },  

  mounted() {
    this.inputValue = this.value
  },

  methods: {
    clearInput: function () {
      this.inputValue = '';
    },
    onInput: function (e) {
      this.$emit('input', e.target.value)
    },    
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
	width: 100%;
  textarea {
		width: 100%;
    font-size: 12px;
    border: 1px solid var(--border-color);
    background-color: transparent;
    padding: 7px 10px;
    color: var(--main-white);
    resize: none;
    line-height: 1.8;
    &::placeholder {
      color: var(--border-color);
    }
    &:focus {
      border-color: var(--main-orange);
    }
    &:disabled {
      opacity: 0.4;
    }
    &:read-only {
      border-color: #313643;
      color: rgb(166, 165, 165);
    }
    &.error {
      border-color: var(--main-red);
    }
  }
  .textarea-icon {
    position: absolute;
    right: 10px;
    bottom: 13px;
  }
}
.full {
  display: flex;
  /* width: 100%; */
  textarea {
    flex: 1;
    /* width: 100%; */
  }
}
</style>
