<template>
  <footer class="landing-footer">
    <div class="landing-footer-inner">
      <ul class="landing-footer-menu">
        <li>
          <div class="menu-title">Company</div>
          <a href="http://www.delkoit.co.kr/about" class="landing-footer-links">About</a>
          <a href="http://www.delkoit.co.kr/contact" class="landing-footer-links">Contacts</a>
        </li>
        <li>
          <div class="menu-title">Business</div>
          <router-link  to="/landing3#partnership" class="landing-footer-links">Partnership</router-link>
          <router-link  to="/landing3#advertisement" class="landing-footer-links">Advertisement</router-link>
        </li>
        <li>
          <div class="menu-title">Products</div>
          <a href="http://www.delkoit.co.kr/tap" class="landing-footer-links">TAP Trader</a>
          <a href="http://www.delkoit.co.kr/delkoithts" class="landing-footer-links">DELKOIT HTS</a>
        </li>
        <li>
          <div class="menu-title">Solutions</div>
          <a href="http://www.delkoit.co.kr/webtradingterminal" class="landing-footer-links">Web Trader</a>
          <a href="http://www.delkoit.co.kr/mobiletradingterminal" class="landing-footer-links">Mobile App</a>
          <a href="http://www.delkoit.co.kr/shortselling" class="landing-footer-links">Short Selling Simulator</a>
          <a href="http://www.delkoit.co.kr/derivativessimulator" class="landing-footer-links">Derivatives Simulator</a>
          <a href="http://www.delkoit.co.kr/ordermanagementsystem" class="landing-footer-links">Order Management System</a>
          <a href="http://www.delkoit.co.kr/riskmanagementsystem" class="landing-footer-links">Risk Management System</a>
          <a href="http://www.delkoit.co.kr/sto" class="landing-footer-links">STO Issuance System</a>
          <a href="http://www.delkoit.co.kr/autotrading" class="landing-footer-links">Auto Trading System</a>
        </li>
      </ul>
    </div>
    <div class="footer-company-info">
      <div class="left">
        <img src="@/assets/images/ci_w@2x.png" alt="logo" class="company-logo" />
        <p>
          Copyright © 2021-2024 Delkoit Limited. All Rights Reserved. Delkoit is a software business that provides software products and services.
          <br />
          Delkoit does not provide any investment advice or brokerage services.
        </p>
      </div>
      <div class="right">
        <button class="terms-btn" @click="popTerms('modalTerms')">이용약관</button>
        <span class="div-line">|</span>
        <button class="terms-btn" @click="popTerms('modalPrivacy')">개인정보 처리 방침</button>
        <span class="div-line">|</span>
        <button class="terms-btn" @click="popTerms('modalTeenPolicy')">청소년 보호 정책</button>
      </div>
    </div>

    <ModalTerms />
    <ModalPrivacy />
    <ModalTeenPolicy />    
  </footer>
</template>

<script>
import ModalPrivacy from '@/components/modals/ModalPrivacy.vue'
import ModalTeenPolicy from '@/components/modals/ModalTeenPolicy.vue'
import ModalTerms from '@/components/modals/ModalTerms.vue'

export default {
  components: { ModalPrivacy, ModalTeenPolicy, ModalTerms },
  name: 'WtsVueLandingFooter',

  data() {
    return {};
  },

  mounted() {

    const terms = this.$route.query.terms

    if ( terms !== undefined && terms !== null
          && ( terms === 'Terms' || terms === 'Privacy' || terms === 'TeenPolicy' )
     ) {
      this.popTerms('modal'+ terms)
    }

    

  },

  methods: {
    popTerms(name) {
      this.$modal.show(name)
    },    
  },
};
</script>

<style lang="scss" scoped></style>
