<template>
  <div class="help-page">
	<div class="panel-header">
      <div class="row">
		<div class="row-item">
          <BaseBtn naked @click="goBack()">
            <IconChevronDown left />
          </BaseBtn>
          <p class="breadcrumb">
            <span>헬프센터 /</span>
            {{ form.postDivNm }}
			<template v-if="form.keyword != ''">
              ( {{ form.keyword }} )
			</template>
          </p>
        </div>
      </div>
	</div>
	
	<router-link v-for="help in helpList" v-bind:key="help.postNo" :to="{path: '/main/board/post', query: {brdCd: 'HELP', postNo: help.postNo, keyword : form.keyword, postDivNm: help.postDivNm}}">
      <FaqItem :faq="help.postSj" />
	</router-link>

	<PaginationComp />
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
import FaqItem from '@/components/FaqItem.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import PaginationComp from '@/components/grid/PaginationComp.vue'

export default {
  name: 'HelpPageSub1',
  components: { BaseBtn, IconChevronDown, FaqItem, PaginationComp },
  data() {
    return {
      form: {
		postDivCd: '',
		postDivNm: '',
        keyword: ''
      },
      paging : {
        page: 1,
        size: 20,
        total: 0,
        pageSize: 5,        
      }, 		
      helpList: [] 	  
	}
  },
  mounted() {
    this.form.postDivCd = this.$route.query.postDivCd
	this.form.postDivNm = this.$route.query.postDivNm
	this.form.keyword   = this.$route.query.keyword

	this.search()
  },
  methods: {
    async search(page) {
      if (page) {
        this.paging.page = page
      }

      // 조회 조건
      const params = {
        brdCd : 'HELP',
        page : this.paging.page,
        size : this.paging.size,
		postDivCd : this.form.postDivCd,
        keyword : this.form.keyword
      }

      const response = await this.$lhttp.get('/api/board/list/page', { params: params })

      if ( response && response.data ) {
        this.paging.total = response.data.total
        this.helpList = response.data.content
      }         
    },	
    goBack() {
      if ( window.history.length > 2 ) {
        this.$router.go(-1) 
      } else {
        this.$router.push('/main/help')
      }
    }	
  },
}
</script>

<style lang="scss" scoped>
.help-page {
	padding: 10px 20px;
}
.panel-header {
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
.breadcrumb {
  font-weight: 600;
	span {
		opacity: 0.8;
    font-weight: 400;
	}
}
.panel-header {
	height: 40px;
}
</style>