import Vue from 'vue'
import axios from 'axios'
import EventBus from "@/modules/event-bus"
import Const from '@/const'

const state = {
  usrId: null,
  cpnId: null,
  authId: null,
  usrNick: null,
  prfBgCol: null,
  isLogin : false,
  loginUserInfo: {
    usrId: null,					// 사용자id
    cpnId: 0,						  // 회사id
    authId: null,					// 권한id
    usrNick: null,				// 닉네임
    prfBgCol: null,				// 프로필배경색
    planId: null,					// 플랜ID
    planNm: null,					// 플랜명
    mtPrc: 0, 						// 월가격
    demoAccCnt: 0,				// 모의계좌수
    accLmtMn: 0,					// 계좌한도금액
    mtInvMnChrgCnt: 0,		// 월투자금액충전수
    itrstSymCnt: 0,				// 관심종목수
    layCnt: 0,					  // 레이아웃수
    aiTrdSigCnt: 0,				// AI거래신호수
    evtAccCnt: 0,					// 이벤트계좌수
    adYn: 'Y',					  // 광고여부
    stlsAtOrdYn: 'N',			// 손절자동주문여부
    prAtOrdYn: 'N',				// 익절자동주문여부
    normOrdYn: 'N',				// 일반주문여부
    clckOrdYn: 'N',				// 클릭주문여부
    chtOrdYn: 'N',				// 차트주문여부    
  }
}

const getters = {
  getUsrId (state) {
    return state.usrId
  },
  getCpnId (state) {
    return state.cpnId
  },
  getAuthId (state) {
    return state.authId
  },
  getUsrNick (state) {
    return state.usrNick
  },
  getPrfBgCol (state) {
    return state.prfBgCol
  },
  isLogin (state) {
    return state.isLogin
  }, 
  getLoginUserInfo (state) {
    return state.loginUserInfo
  }
}

const mutations = {
  setUsrId (state, { usrId }) {
    state.usrId = usrId
  },
  setCpnId (state, { cpnId }) {
    state.cpnId = cpnId
  },
  setAuthId (state, { authId }) {
    state.authId = authId
  },
  setUsrNick (state, { usrNick }) {
    state.usrNick = usrNick
  },    
  setPrfBgCol (state, { prfBgCol }) {
    state.prfBgCol = prfBgCol
  },    
  setLogin (state, { isLogin }) {
    state.isLogin = isLogin
  },      
  setLoginUserInfo (state, { loginUserInfo }) {
    state.loginUserInfo = loginUserInfo
  },        
}  

const actions = {
  login ({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      window.$loading.show()

      axios
        .post(process.env.VUE_APP_SERVER_BASE_URL + '/auth/login', params, 
          { crossDomain: true, withCredentials: true })
        .then(response => {
          window.$loading.hide()

          if ( response.data.data.code == Const.LOGIN_RESPONSE_TYPE.SUCCESS ) {
            commit('setUsrId', { usrId: response.data.data.loginUserInfo.usrId })
            commit('setCpnId', { cpnId: response.data.data.loginUserInfo.cpnId })
            commit('setAuthId', { authId: response.data.data.loginUserInfo.authId })
            commit('setUsrNick', { usrNick: response.data.data.loginUserInfo.usrNick })
            commit('setPrfBgCol', { prfBgCol: response.data.data.loginUserInfo.prfBgCol })
            commit('setLogin', { isLogin: true })

            commit('setLoginUserInfo', { loginUserInfo: response.data.data.loginUserInfo })

            Vue.$sessionStorage.set('xDkToken', response.headers['x-dk-token'])

            EventBus.$emit("event-login")
          }

          resolve(response.data)
        })
        .catch(e => {
          window.$loading.hide()
          reject(e)
        })
    })    
  },
  loginGoogle ({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      window.$loading.show()

      axios
        .post(process.env.VUE_APP_SERVER_BASE_URL + '/auth/login/google', params, 
          { crossDomain: true, withCredentials: true })
        .then(response => {
          window.$loading.hide()

          if ( response.data.data.code == Const.LOGIN_RESPONSE_TYPE.SUCCESS ) {
            //console.log(response.data.data)

            commit('setUsrId', { usrId: response.data.data.loginUserInfo.usrId })
            commit('setCpnId', { cpnId: response.data.data.loginUserInfo.cpnId })
            commit('setAuthId', { authId: response.data.data.loginUserInfo.authId })
            commit('setUsrNick', { usrNick: response.data.data.loginUserInfo.usrNick })
            commit('setPrfBgCol', { prfBgCol: response.data.data.loginUserInfo.prfBgCol })
            commit('setLogin', { isLogin: true })

            commit('setLoginUserInfo', { loginUserInfo: response.data.data.loginUserInfo })

            Vue.$sessionStorage.set('xDkToken', response.headers['x-dk-token'])

            EventBus.$emit("event-login")
          }

          resolve(response.data)
        })
        .catch(e => {
          window.$loading.hide()
          reject(e)
        })
    })    
  },  
  loginKakao ({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      window.$loading.show()

      axios
        .post(process.env.VUE_APP_SERVER_BASE_URL + '/auth/login/kakao', params, 
          { crossDomain: true, withCredentials: true })
        .then(response => {
          window.$loading.hide()

          if ( response.data.data.code == Const.LOGIN_RESPONSE_TYPE.SUCCESS ) {
            commit('setUsrId', { usrId: response.data.data.loginUserInfo.usrId })
            commit('setCpnId', { cpnId: response.data.data.loginUserInfo.cpnId })
            commit('setAuthId', { authId: response.data.data.loginUserInfo.authId })
            commit('setUsrNick', { usrNick: response.data.data.loginUserInfo.usrNick })
            commit('setPrfBgCol', { prfBgCol: response.data.data.loginUserInfo.prfBgCol })
            commit('setLogin', { isLogin: true })

            commit('setLoginUserInfo', { loginUserInfo: response.data.data.loginUserInfo })

            Vue.$sessionStorage.set('xDkToken', response.headers['x-dk-token'])

            EventBus.$emit("event-login")
          }

          resolve(response.data)
        })
        .catch(e => {
          window.$loading.hide()
          reject(e)
        })
    })    
  },   
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      window.$loading.show()

      axios
        .post(process.env.VUE_APP_SERVER_BASE_URL + '/auth/logout', {}, 
          { headers: { 'x-dk-token': Vue.$sessionStorage.get('xDkToken') }, crossDomain: true, withCredentials: true })
        .then(response => {
          window.$loading.hide()

          commit('setUsrId', { usrId: null })
          commit('setCpnId', { cpnId: null })
          commit('setAuthId', { authId: null })
          commit('setUsrNick', { usrNick: null })
          commit('setPrfBgCol', { prfBgCol: null })
          commit('setLogin', { isLogin: false })

          commit('setLoginUserInfo', { loginUserInfo: null })

          Vue.$sessionStorage.remove('xDkToken')
          Vue.$sessionStorage.remove('acc_pass_store')

          EventBus.$emit("event-logout")

          resolve(response.data)
        })      
        .catch(e => {
          window.$loading.hide()
          reject(e)
        })        
    })
  },
  refresh ({ commit, state }) {
    return new Promise((resolve, reject) => {
      window.$loading.show()

      axios
        .post(process.env.VUE_APP_SERVER_BASE_URL + '/auth/login/refresh', {}, 
          { headers: { 'x-dk-token': Vue.$sessionStorage.get('xDkToken') }, crossDomain: true, withCredentials: true })
        .then(response => {
          window.$loading.hide()

          const xDkToken = response.headers['x-dk-token']

          if ( xDkToken !== null && xDkToken !== ''
               && response.data.data.code == Const.LOGIN_RESPONSE_TYPE.SUCCESS ) {
            commit('setUsrId', { usrId: response.data.data.loginUserInfo.usrId })
            commit('setCpnId', { cpnId: response.data.data.loginUserInfo.cpnId })
            commit('setAuthId', { authId: response.data.data.loginUserInfo.authId })
            commit('setUsrNick', { usrNick: response.data.data.loginUserInfo.usrNick })
            commit('setPrfBgCol', { prfBgCol: response.data.data.loginUserInfo.prfBgCol })
            commit('setLogin', { isLogin: true })

            commit('setLoginUserInfo', { loginUserInfo: response.data.data.loginUserInfo })

            Vue.$sessionStorage.set('xDkToken', xDkToken)

            EventBus.$emit("event-login")
          } else {
            commit('setUsrId', { usrId: null })
            commit('setCpnId', { cpnId: null })
            commit('setAuthId', { authId: null })
            commit('setUsrNick', { usrNick: null })            
            commit('setPrfBgCol', { prfBgCol: null })
            commit('setLogin', { isLogin: false })

            commit('setLoginUserInfo', { loginUserInfo: null })

            Vue.$sessionStorage.remove('xDkToken')

            EventBus.$emit("event-logout")
          }

          resolve(response.data)
        })
        .catch(e => {
          window.$loading.hide()
          reject(e)
        })
    })    
  },   
  refreshInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      window.$lhttp
        .post('/auth/login/info', {})
        .then(response => {
          if ( response ) {
            commit('setUsrId', { usrId: response.data.usrId })
            commit('setCpnId', { cpnId: response.data.cpnId })
            commit('setAuthId', { authId: response.data.authId })
            commit('setUsrNick', { usrNick: response.data.usrNick })
            commit('setPrfBgCol', { prfBgCol: response.data.prfBgCol })

            commit('setLoginUserInfo', { loginUserInfo: response.data })
          }          

          resolve(response.data)
        })
        .catch(e => reject(e))
    })    
  },     
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
