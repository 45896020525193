var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-page"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"header"},[_c('BaseBtn',{attrs:{"naked":""},on:{"click":_vm.goBack}},[_c('IconChevronDown',{attrs:{"left":"","width":"24","height":"24"}})],1),_vm._m(1),_c('BaseBtn',{attrs:{"naked":""},on:{"click":_vm.close}},[_c('IconClose',{attrs:{"width":"24","height":"24"}})],1)],1),_c('div',{staticClass:"body"},[_c('div',{staticClass:"body-inner"},[_vm._m(2),_vm._m(3),_c('button',{staticClass:"send-again",on:{"click":_vm.sendSignUp}},[_vm._v("활성화 링크 다시 보내기")]),_vm._m(4),_c('p',{staticClass:"desc"},[_vm._v("다음 계정으로 가입할 수 있습니다.")]),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"hero-title"},[_c('h1',{staticClass:"title"},[_vm._v("Plan&Timing")]),_c('h2',{staticClass:"subtitle"},[_vm._v("The Art of Trading")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"header-title"},[_c('b',[_vm._v("TAP")]),_c('span',[_vm._v("TradingAsPlan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"body-title"},[_vm._v(" 귀하의 이메일을 "),_c('br'),_vm._v(" 인증하시기 바랍니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"desc"},[_vm._v(" 인증 링크가 전송되었습니다. 받은 편지함에 "),_c('br'),_vm._v(" 인증 링크가 없으면 스팸/정크함을 확인하세요. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-division"},[_c('span'),_c('p',[_vm._v("또는")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sns-login-container"},[_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/images/google-logo.png"),"alt":""}}),_vm._v(" google ")]),_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":require("@/assets/images/kakao-logo.png"),"alt":""}}),_vm._v(" kakao ")])])
}]

export { render, staticRenderFns }