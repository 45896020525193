<template>
  <div class="list-container">
    <div class="list-header">
      <h3>{{ title }}</h3>
    </div>
    <ul class="list">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
// import AlertItem from './AlertItem.vue';

export default {
  name: 'PanelBoxList',
	props: {
		title: String,
	},
	components: {
		// AlertItem
	},
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.list-container {
  border: 1px solid rgba(255,255,255,0.2);
  @media (max-width: $mobile) {
    border-left: none;
    border-right: none;
  }
  /* border-bottom: 1px solid rgba(255,255,255,0.2); */
	.list-header {
  background-color: var(--main-gray-dark);
  border-bottom: 1px solid rgba(255,255,255,0.1);
  color: rgba(255, 255, 255, 0.6);
  padding: 10px 20px;
  
}
.list {
  li {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
}
</style>
