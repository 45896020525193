<template>
  <modal name="transactionPop" class="custom-modal" adaptive :minWidth="1000" :minHeight="564" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="거래내역" modalName="transactionPop">
      <template #body>
        <div class="row">
          <div class="row-item">
            <h3>계좌번호</h3>
            <div class="row-item-content">
              <AccountDropDown id="accountDropdown" popup v-model="gridSearch.accId" @event:selectAccount="selectAccount" />
              <InputComp readonly width="120" class="account-type" v-model="account.accNo" />
            </div>
          </div>
          <div class="row-item">
            <h3>비밀번호</h3>
              <InputComp type="password" :width="65" v-model="account.accPass" />
          </div>

          <div class="row-item">
            <h3 class="datepicker-label">조회일자</h3>
            <div class="row-item-content">
              <DatePicker v-model="gridSearch.fromDate" />
              <span style="width: 10px">~</span>
              <DatePicker v-model="gridSearch.toDate" />
            </div>
          </div>

          <div class="row-item">
            <BtnPrimary text="조회" icon="search" :width="70" @click="search(1)" />
          </div>
        </div>
      
        <div class="table-container">
          <ag-grid-vue
            style="width: 100%; height: 100%"
            class="ag-theme-delkoit"
            alwaysShowHorizontalScroll="true"
            :columnDefs="grid.columnDefs"
            :rowData="grid.rowData"
            :headerHeight="55"
          ></ag-grid-vue>
          <PaginationComp :paging="grid.paging" @onPage="search" />
        </div>  
      </template>  
    </ModalContent>
  </modal>
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import DatePicker from '@/components/DatePicker.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import InputComp from '@/components/InputComp.vue'
import AccountDropDown from '@/components/AccountDropDown.vue'

import { AgGridVue } from 'ag-grid-vue' // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import AgTransactionRow from '@/components/grid/AgTransactionRow.vue'
// eslint-disable-next-line no-unused-vars
import CustomHeader2Rows from '@/components/grid/CustomHeader2Rows.vue'

import Utils from '@/modules/utils'

import PaginationComp from '@/components/grid/PaginationComp.vue'

export default {
  name: 'TransactionPop',
  components: {
    ModalContent,
    AccountDropDown,
    DatePicker,
    BtnPrimary,
    InputComp,

    AgGridVue, // Add AG Grid Vue3 component
    // eslint-disable-next-line vue/no-unused-components
    AgTransactionRow,
    agColumnHeader: CustomHeader2Rows,
    PaginationComp,    
  },
  data() {
    return {
      account: {
        accNo: '',
        accPass: ''
      },
      gridSearch: {
        accId: 0,
        accPass: '',
        fromDate: Utils.getToday(), 
        toDate: Utils.getToday(), 
      },      
      grid: {
        columnDefs: [
          {
            headerName: '',
            field: 'trdDt',
            resizable: false,
            minWidth: 85,
            flex: 0.9,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'trdDt' },
            // cellRenderer: SimpleComp,
            autoHeight: true,
            headerComponentParams: {
              name: '거래일자',
              name2: '거래시간',
            },
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
            suppressMovable: true,
            // headerComponentParams: {
            //   enableMenu: true,
            // },
            // children: [
            // 	{
            // 		headerName: '거래시간',
            // 		field: '거래시간',
            // 		flex: 1,
            // 	}
            // ]
          },
          {
            field: 'ordId',
            resizable: false,
            minWidth: 76,
            flex: 0.8,
            autoHeight: true,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'ordId' },
            headerComponentParams: {
              name: '거래번호',
              name2: '원거래번호',
            },
            headerClass: 'center-align-header',
            cellStyle: {
              textAlign: 'right',
            },
            suppressMovable: true,
          },
          {
            field: 'symCd',
            resizable: false,
            minWidth: 189,
            flex: 2,
            autoHeight: true,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'symCd' },
            headerComponentParams: {
              name: '종목코드',
              name2: '종목명',
            },
            headerClass: 'center-align-header',
            suppressMovable: true,
          },
          {
            field: 'ordSdCd',
            resizable: false,
            minWidth: 76,
            flex: 0.8,
            autoHeight: true,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'ordSdCd' },
            headerComponentParams: {
              name: '거래구분',
              name2: '주문상태',
            },
            headerClass: 'center-align-header',
            cellStyle: {
              textAlign: 'center',
            },
            suppressMovable: true,
            // cellClassRules: buySellType,
          },
          {
            field: 'ordTpCd',
            resizable: false,
            minWidth: 76,
            flex: 0.8,
            autoHeight: true,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'ordTpCd' },
            headerComponentParams: {
              name: '주문유형',
              name2: '주문수량',
            },
            headerClass: 'center-align-header',
            cellStyle: {
              textAlign: 'center',
            },
            suppressMovable: true,
          },
          {
            field: 'execQty',
            resizable: false,
            minWidth: 94,
            flex: 1,
            autoHeight: true,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'execQty' },
            headerComponentParams: {
              name: '거래수량',
              name2: '주문가격',
            },
            headerClass: 'right-aligned-header',
            cellStyle: {
              textAlign: 'right',
            },
            suppressMovable: true,
          },
          {
            field: 'bfAvgPrc',
            resizable: false,
            minWidth: 94,
            flex: 1,
            autoHeight: true,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'bfAvgPrc' },
            headerComponentParams: {
              name: '이전평단가',
              name2: '이후평단가',
            },
            headerClass: 'right-aligned-header',
            cellStyle: {
              textAlign: 'right',
            },
            suppressMovable: true,
          },
          {
            field: 'execAmt',
            resizable: false,
            minWidth: 104,
            flex: 1.1,
            autoHeight: true,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'execPrc' },
            headerComponentParams: {
              name: '거래가격',
              name2: '매매손익',
            },
            headerClass: 'right-aligned-header',
            cellStyle: {
              textAlign: 'right',
            },
            suppressMovable: true,
          },
          {
            field: 'cms',
            resizable: false,
            minWidth: 89,
            flex: 0.94,
            autoHeight: true,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'cms' },
            headerComponentParams: {
              name: '거래수수료',
              name2: '제세금',
            },
            headerClass: 'center-align-header',
            cellStyle: {
              textAlign: 'right',
            },
            suppressMovable: true,
          },
          {
            field: 'usdExR',
            resizable: false,
            minWidth: 75,
            flex: 0.8,
            autoHeight: true,
            cellRenderer: 'AgTransactionRow',
            cellRendererParams: { col : 'usdExR' },
            headerComponentParams: {
              name: '환율',
              name2: '',
            },
            headerClass: 'center-align-header single-label',
            cellStyle: {
              textAlign: 'center',
            },
            suppressMovable: true,
          },
        ],

        rowData: [],    
        
        paging: {
            page: 1,
            size: 20,
            total: 0,
            pageSize : 10
        }        
      }
    }
  },

  mounted() {
  },

  methods: {
    beforeOpen(e) {  
      this.grid.rowData = []
      this.grid.paging.page = 1
    },    
    opened() {
    },    
    selectAccount(account) {
      this.account = account
    },  
    async search(page) {
      if (page) {
        this.grid.paging.page = page
      }

      // 조회 조건
      this.gridSearch.accId   = this.account.accId
      this.gridSearch.accPass = this.account.accPass      
      this.gridSearch.page    = this.grid.paging.page
      this.gridSearch.size    = this.grid.paging.size

      const response = await this.$lhttp.get('/api/account/trd/list/page', { params: this.gridSearch })

      if ( response && response.data ) {
        this.grid.paging.total = response.data.total
        this.grid.rowData = response.data.content

        if ( response.data.content.length <= 0 ) {
          this.$alert.alert('조회된 거래내역이 없습니다.', 'Warnning')
        }
      }         
    }  
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  width: 100%;
  height: 400px;
  margin-top: 10px;
}
.transaction-table {
  /* table-layout: fixed; */
  margin-top: 10px;
  font-size: 12px;
  /* max-width: 944px; */
  width: 100%;
  border: 1px solid #565B66;
  line-height: 28px;
  thead {
    background-color: rgba(255,255,255,0.05);
    color: rgba(255,255,255,0.6);
    th {
      padding: 2px 15px;
      border-bottom: 1px solid #424754;
    }
  }
  td {
    padding: 0;
    margin: 0;
    padding: 0 15px;
    border: none;
    border-bottom: 1px solid #424754;
  }
  .top-row {
    display: table;
    width: 100%;
    
  }
  .center {
    text-align: center;
  }
  .right {
    text-align: right;
  }
  .blue {
    color: var(--main-blue);
  }
  .red {
    color: var(--main-red);
  }
}

@import '@/assets/scss/agGridStyles.scss';
</style>
