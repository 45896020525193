<template>
  <BaseBtn @click="handleClick" :style="{ width: width + 'px' }" :class="{full:full}">
    <h3>{{ text }}</h3>
  </BaseBtn>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'

export default {
  name: 'BtnSub',
  props: {
    text: {
      type: String,
      default: 'name me',
    },
    width: [String, Number],
    full: Boolean
  },
  components: { BaseBtn },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleClick(e) {
      this.$emit('click',e);
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 80px;
  background-color: var(--main-gray);
  color: white;
  &.full {
    flex: 1;
    width: 100%;
  }
}
</style>
