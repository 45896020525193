<template>
  <div class="base-dropdown" :style="{ width: width + 'px', height: height + 'px' }" :class="{ full: full }" ref="dropdown">
    <div class="dropdown-title" @click="handleDropdown" :class="{ active: isDropdownVisible }" :style="{ height: height + 'px' }">
      <h3>
        {{ selectedName }}
      </h3>
      <img class="triangle" :class="{ open: isDropdownVisible }" src="@/assets/icons/triangle-down.svg" alt="" />
    </div>
    <transition name="fade">
      <DropDownList v-if="isDropdownVisible" v-bind:width="width" :popup="popup">
        <div
          class="dropdown-list-item"
          v-for="event in eventList"
          :key="event.evtId"
          @click="onSelect(event)">
            <div class="col">
              <h3>{{ event.evtNm }}</h3>
            </div>
        </div>
      </DropDownList>
    </transition>
  </div>
</template>

<script>
import DropDownList from '@/components/DropDownList.vue'

export default {
  name: 'EventListDropDown',
  components: {
    DropDownList,
  },
  props: {
    width: [String, Number],
    height: [String, Number],
    full: Boolean,
    popup: Boolean,
    allYn: {
      type: String,
      default: 'N'
    },     
    value: {
      type: Number,
      default: 0,
    },
    year: {
      type: String,
    },     
  },    

  data() {
    return {
      eventList: [],
      isDropdownVisible: false,
    }
  },

  computed: {
    selectedName() {
      let rtn = ''  

      if ( this.allYn != 'Y' && this.value === 0 ) {
        if (this.eventList.length > 0 ) {
          rtn = this.eventList[0].evtNm
        }
      } else {
        for (let i in this.eventList) {
          if ( this.value === this.eventList[i].evtId ) {
            rtn = this.eventList[i].evtNm
            break
          }  
        }
      }

      return rtn 
    }
  },  

  mounted() {
    this.selectList()
  },

  methods: {
    selectList() {
      this.$http
      .get('/api/demo/join/list', {params : {"year": this.year}})
      .then((result) => {
        this.eventList = result.data

        if (this.allYn !== 'Y' && this.value === 0) {
          this.$emit('input', this.eventList[0].evtId)
          this.$emit('event:selectEvent', this.eventList[0])
        } else {
          for (let i in this.eventList) {
            if ( this.value === this.eventList[i].evtId ) {
              this.$emit('input', this.eventList[i].evtId)
              this.$emit('event:selectEvent', this.eventList[i])
              break
            }  
          }          
        }       
      })
    },
    handleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    onSelect(event) {
      this.$emit('input', event.evtId)
      this.$emit('event:selectEvent', event)

      this.isDropdownVisible = false
    }
  },
};
</script>

<style lang="scss" scoped>
.base-dropdown {
  position: relative;
  white-space: nowrap;
  height: 30px;
  cursor: pointer;
  &.full {
    flex: 1;
    width: 100%;
    .dropdown-list {
      width: 100%;
    }
  }
  .dropdown-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    height: 100%;
    /* height: 30px; */
    border: 1px solid var(--border-color);
    &:hover {
      border-color: var(--main-orange);
    }
    &.active {
      border-color: var(--main-orange);
    }
  }
  h3 {
    font-size: 12px;
    // font-weight: 400;
    padding-bottom: 2px;
  }
  .triangle {
    margin-left: 5px;
    transition: transform 0.2s;
    &.open {
      transform: rotate(180deg);
    }
  }
  @media (max-width: $mobile) {
    height: 40px;
  }
}
</style>
        