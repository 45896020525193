import { render, staticRenderFns } from "./IconPageLast.vue?vue&type=template&id=fb3b36c8&scoped=true"
import script from "./IconPageLast.vue?vue&type=script&lang=js"
export * from "./IconPageLast.vue?vue&type=script&lang=js"
import style0 from "./IconPageLast.vue?vue&type=style&index=0&id=fb3b36c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb3b36c8",
  null
  
)

export default component.exports