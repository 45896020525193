<template>
  <BaseBtn @click="handleButton">
    <!-- <button class="delete-btn" ></button> -->
    <img src="@/assets/icons/inputdelete.svg" alt="" />
  </BaseBtn>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue';

export default {
  name: 'BtnDelete',
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleButton() {
      this.$emit('deleteClicked');
    },
  },
  components: { BaseBtn },
};
</script>

<style lang="scss" scoped>
button {
  min-width: unset;
  height: unset;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
}
</style>
