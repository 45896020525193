<template>
  <div class="notification-item-container">

    <template v-if="msg.msgTpCd === Const.MSG_TP_TYPE.NOTICE_REGIST || msg.msgTpCd === Const.MSG_TP_TYPE.ETC">
      <p style="color:#F67C32;">{{ msg.msgTpNm }}</p>
      <p>{{ msg.msgTm }}</p>    
      <p style="color:#999999;">{{ msg.msg }}</p>        
    </template>
    <template v-else-if="msg.msgTpCd === Const.MSG_TP_TYPE.DEPOSIT_APPROVAL 
                          || msg.msgTpCd === Const.MSG_TP_TYPE.DEPOSIT_REJECT
                          || msg.msgTpCd === Const.MSG_TP_TYPE.WITHDRAWAL_APPROVAL
                          || msg.msgTpCd === Const.MSG_TP_TYPE.WITHDRAWAL_REJECT
                          ">
      <p class="long">{{ msg.msgTpNm }}</p>
      <p>{{ msg.accNo }} · {{ msg.msgTm }}</p>    
      <p style="color:#999999;">{{ msg.msg }}</p>        
    </template>
    <template v-else>
      <p>
        <span class="long" v-if="msg.msgDivCd === Const.MSG_DIV_TYPE.BUY || msg.msgDivCd === Const.MSG_DIV_TYPE.PROFIT">{{msg.msgDivNm}}</span>
        <span class="short" v-if="msg.msgDivCd === Const.MSG_DIV_TYPE.SELL || msg.msgDivCd === Const.MSG_DIV_TYPE.STOP_LOSS">{{msg.msgDivNm}}</span>
        <span class="green" v-if="msg.msgDivCd === Const.MSG_DIV_TYPE.ORDER_CLEAR">{{msg.msgDivNm}}</span>
        <span v-if="msg.msgDivCd !== Const.MSG_DIV_TYPE.SELL 
                    &&  msg.msgDivCd !== Const.MSG_DIV_TYPE.BUY 
                    && msg.msgDivCd !== Const.MSG_DIV_TYPE.STOP_LOSS 
                    && msg.msgDivCd !== Const.MSG_DIV_TYPE.ORDER_REJECT
                    && msg.msgDivCd !== Const.MSG_DIV_TYPE.ORDER_CLEAR
                    && msg.msgDivCd !== Const.MSG_DIV_TYPE.PROFIT
                    && msg.msgDivCd !== Const.MSG_DIV_TYPE.STOP_LOSS
                    && msg.msgDivCd !== Const.MSG_DIV_TYPE.NORMAL
                   ">{{msg.msgDivNm}}</span>
        &nbsp;{{ msg.msgTpNm }}
        <span v-if="msg.msgDivCd !== Const.MSG_DIV_TYPE.ORDER_REJECT 
                    && msg.msgTpCd !== Const.MSG_TP_TYPE.ORDER_CLEAR
                    && msg.msgTpCd !== Const.MSG_TP_TYPE.ORDER_EXEC
                    && msg.msgTpCd !== Const.MSG_TP_TYPE.CURRENT_CLEAR
                    && msg.msgTpCd !== Const.MSG_TP_TYPE.ALL_CLEAR
                    && msg.msgTpCd !== Const.MSG_TP_TYPE.RMS_CLEAR
        ">
          <span class="divline">|</span> 수량 {{ msg.qty }}
        </span>
      </p>
      <p><span v-if="msg.shtNm">{{ msg.shtNm }} ·</span> {{ msg.accNo }} · {{ msg.msgTm }}</p>    
      <p style="color:#999999;">{{ msg.msg }}</p>    
    </template>  

  </div>
</template>

<script>
import Const from '@/const'

export default {
  name: 'NotificationItem',
  props: {
    msg: {
      type: Object,
      default: () => {
        return { 
          seq: 0,
          msgDay: '',
          msgTm: '',
          msgTpCd: '',
          msgTpNm: '',
          accId: 0,
          accNo: '',
          trdDt: '',
          msgDivCd: '',
          msgDivNm: '',
          msg: '',
          symCd: '',
          shtNm: '',
          qty: 0,
          addFld1: '',
          addFld2: '',
        }
      },
    },
  },

  data() {
    return {
      Const : Const
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.notification-item-container {
  padding: 10px 20px;
  text-align: left;
  p {
    line-height: 18px;
  }
  .long {
    color: var(--main-red);
  }
  .short {
    color: var(--main-blue);
  }
  .divline {
    opacity: 0.4;
  }
  .green{
    color: var(--sub-green);
  }
}
</style>
