var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-item-container"},[(_vm.msg.msgTpCd === _vm.Const.MSG_TP_TYPE.NOTICE_REGIST || _vm.msg.msgTpCd === _vm.Const.MSG_TP_TYPE.ETC)?[_c('p',{staticStyle:{"color":"#F67C32"}},[_vm._v(_vm._s(_vm.msg.msgTpNm))]),_c('p',[_vm._v(_vm._s(_vm.msg.msgTm))]),_c('p',{staticStyle:{"color":"#999999"}},[_vm._v(_vm._s(_vm.msg.msg))])]:(_vm.msg.msgTpCd === _vm.Const.MSG_TP_TYPE.DEPOSIT_APPROVAL 
                        || _vm.msg.msgTpCd === _vm.Const.MSG_TP_TYPE.DEPOSIT_REJECT
                        || _vm.msg.msgTpCd === _vm.Const.MSG_TP_TYPE.WITHDRAWAL_APPROVAL
                        || _vm.msg.msgTpCd === _vm.Const.MSG_TP_TYPE.WITHDRAWAL_REJECT
                        )?[_c('p',{staticClass:"long"},[_vm._v(_vm._s(_vm.msg.msgTpNm))]),_c('p',[_vm._v(_vm._s(_vm.msg.accNo)+" · "+_vm._s(_vm.msg.msgTm))]),_c('p',{staticStyle:{"color":"#999999"}},[_vm._v(_vm._s(_vm.msg.msg))])]:[_c('p',[(_vm.msg.msgDivCd === _vm.Const.MSG_DIV_TYPE.BUY || _vm.msg.msgDivCd === _vm.Const.MSG_DIV_TYPE.PROFIT)?_c('span',{staticClass:"long"},[_vm._v(_vm._s(_vm.msg.msgDivNm))]):_vm._e(),(_vm.msg.msgDivCd === _vm.Const.MSG_DIV_TYPE.SELL || _vm.msg.msgDivCd === _vm.Const.MSG_DIV_TYPE.STOP_LOSS)?_c('span',{staticClass:"short"},[_vm._v(_vm._s(_vm.msg.msgDivNm))]):_vm._e(),(_vm.msg.msgDivCd === _vm.Const.MSG_DIV_TYPE.ORDER_CLEAR)?_c('span',{staticClass:"green"},[_vm._v(_vm._s(_vm.msg.msgDivNm))]):_vm._e(),(_vm.msg.msgDivCd !== _vm.Const.MSG_DIV_TYPE.SELL 
                  &&  _vm.msg.msgDivCd !== _vm.Const.MSG_DIV_TYPE.BUY 
                  && _vm.msg.msgDivCd !== _vm.Const.MSG_DIV_TYPE.STOP_LOSS 
                  && _vm.msg.msgDivCd !== _vm.Const.MSG_DIV_TYPE.ORDER_REJECT
                  && _vm.msg.msgDivCd !== _vm.Const.MSG_DIV_TYPE.ORDER_CLEAR
                  && _vm.msg.msgDivCd !== _vm.Const.MSG_DIV_TYPE.PROFIT
                  && _vm.msg.msgDivCd !== _vm.Const.MSG_DIV_TYPE.STOP_LOSS
                  && _vm.msg.msgDivCd !== _vm.Const.MSG_DIV_TYPE.NORMAL
                 )?_c('span',[_vm._v(_vm._s(_vm.msg.msgDivNm))]):_vm._e(),_vm._v("  "+_vm._s(_vm.msg.msgTpNm)+" "),(_vm.msg.msgDivCd !== _vm.Const.MSG_DIV_TYPE.ORDER_REJECT 
                  && _vm.msg.msgTpCd !== _vm.Const.MSG_TP_TYPE.ORDER_CLEAR
                  && _vm.msg.msgTpCd !== _vm.Const.MSG_TP_TYPE.ORDER_EXEC
                  && _vm.msg.msgTpCd !== _vm.Const.MSG_TP_TYPE.CURRENT_CLEAR
                  && _vm.msg.msgTpCd !== _vm.Const.MSG_TP_TYPE.ALL_CLEAR
                  && _vm.msg.msgTpCd !== _vm.Const.MSG_TP_TYPE.RMS_CLEAR
      )?_c('span',[_c('span',{staticClass:"divline"},[_vm._v("|")]),_vm._v(" 수량 "+_vm._s(_vm.msg.qty)+" ")]):_vm._e()]),_c('p',[(_vm.msg.shtNm)?_c('span',[_vm._v(_vm._s(_vm.msg.shtNm)+" ·")]):_vm._e(),_vm._v(" "+_vm._s(_vm.msg.accNo)+" · "+_vm._s(_vm.msg.msgTm))]),_c('p',{staticStyle:{"color":"#999999"}},[_vm._v(_vm._s(_vm.msg.msg))])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }