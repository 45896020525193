<template>
  <modal name="layoutRenamePop" class="custom-modal" adaptive :minWidth="400" :minHeight="216" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">
    <ModalContent text="레이아웃 이름 바꾸기" modalName="layoutRenamePop" footer>
      <template #body>
        <div class="container">
          <h3>새 레이아웃 이름을 넣으세요.</h3>
          <validation-observer ref="validator">
            <validation-provider rules="required|limit:40" name="레이아웃명" v-slot="{errors}">
              <InputComp placeholder="레이아웃명을 입력 해 주십시오." :error="errors[0] ? true: false" v-model="form.layNm" />
              <div class="error-message"><h3>{{errors[0]}}</h3></div>
            </validation-provider>
          </validation-observer>          
        </div>          
      </template>
      <template #footer>
        <BtnSub text="취소" @click="close" />
        <BtnPrimary text="저장" @click="save" />
      </template>
    </ModalContent>
  </modal>
</template>  

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputComp from '@/components/InputComp.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'

export default {
  name: 'LayoutRenamePop',
  components: {
    ModalContent,
    InputComp,
    BtnSub,
    BtnPrimary,    
  },
  data() {
    return {
      form: {
        laySeq: 0,
        layNm: '',
      }
    }
  },

  mounted() {},

  methods: {
    beforeOpen(e) {  
      this.form.laySeq = window.$store.getters['Layout/getLaySeq']
      this.form.layNm = window.$store.getters['Layout/getLayoutName']      
    },    
    opened() {
    },  
    close() {
      this.$modal.hide('layoutRenamePop')
    },
    save() {
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {

          this.$alert.confirm('레이아웃 이름을 수정 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {
                this.$http
                  .post('/common/layout/rename',
                     this.form)
                  .then(() => {
                     window.$store.commit('Layout/setLayoutName', { layoutName: this.form.layNm })  

                     this.$alert.alert('레이아웃 이름 수정이 완료되었습니다.', '안내').then(() => {
                       this.$emit('event:renameLayout')
                       this.close()
                     })
                  })
              }
            })      

        } else {
          this.$alert.alert('먼저 입력 항목을 확인 하십시오.', 'Warnning')
        }

      })
    }      
  },
}
</script>    

<style lang="scss" scoped>
.container {
  /* height: 100%; */
  margin-bottom: 20px;
}
h3 {
  opacity: 0.6;
  margin-bottom: 10px;
}
</style>