<template>
  <div class="alert-item-container">
    <div class="col">
      <h3>{{ text }}</h3>
      <p>{{ symbol }} · ${{ price | usd }}</p>
    </div>
    <div class="col">
      <AddDeleteBtn />
      <!-- <IconAdd v-if="add" fill="var(--main-orange)" />
      <IconClose v-if="close" /> -->
    </div>
  </div>
</template>

<script>
import AddDeleteBtn from './AddDeleteBtn.vue';

export default {
  name: 'AlertItem',
  props: {
    text: String,
    symbol: String,
    price: [String, Number],
  },
  data() {
    return {};
  },
  mounted() {},
  filters: {
    usd(value) {
      return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'USD' }).format(value);
    },
  },
  methods: {},
  components: {
    AddDeleteBtn,
  },
};
</script>

<style lang="scss" scoped>
.alert-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  h3 {
    font-weight: 600;
  }
  p {
    line-height: 18px;
    opacity: 0.8;
  }
}
</style>
