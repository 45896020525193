<template>
  <div class="login-page">
    <div class="left">
      <div class="hero-title">
        <h1 class="title">Plan&Timing</h1>
        <h2 class="subtitle">The Art of Trading</h2>
      </div>
    </div>
    <div class="right">
      <div class="header">
        <div></div>
        <h4 class="header-title">
          <b>TAP</b>
          <span>TradingAsPlan</span>
        </h4>
        <BaseBtn naked @click="close">
          <IconClose width="24" height="24" />
        </BaseBtn>
      </div>
      <div class="body">
        <div class="body-inner">
          <h3 class="body-title">
            거의 다 왔습니다.
          </h3>
          <p class="desc">
            <span class="title">TAP</span><span class="result">계정 생성 완료</span>
          </p>

          <div class="email-login-container">
            <validation-observer ref="validator">
              <validation-provider rules="required|limit:15" name="유저 네임" v-slot="{errors}">
                <input type="text" placeholder="유저 네임 생성" class="input" :class="[errors[0] ? 'error': '']" v-model="form.usrNick">
                <p class="login-error-message">{{errors[0]}}</p>
              </validation-provider>
            </validation-observer>
          </div>

          <p class="desc notice">유저 네임은 두 번 변경할 수 있습니다. 신중히 선택 하십시오.</p>

          <div class="checkbox-container">
            <BtnCheckbox id="opout" text="마케팅 이메일 수신을 빼고 싶습니다." :fontSize="13" @click="toggleMktAgrYn" />

            <div class="checkbox-box">
              <div @click="clickUsePolAgrYn">
                <BtnCheckbox id="agree" text="" :disabled="!isTermsAgree" :fontSize="13" @click="toggleUsePolAgrYn" />
              </div>
              <h3 :style="{ fontSize: '13px' }">나는 <span class='agree-link' @click="popTerms('modalTerms')">이용 약관</span> 및 <span class='agree-link' @click="popTerms('modalPrivacy')">개인 정보 처리 방침</span>을 읽었으며 동의합니다.</h3>
            </div>              
          </div>

          <button class="login-btn" @click="signup">완료</button>
        </div>
      </div>
    </div>

    <ModalTerms @event:terms-complete="completeTerms" />
    <ModalPrivacy @event:privacy-complete="completePrivacy" />
  </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import BtnCheckbox from '@/components/BtnCheckbox.vue'
import BaseBtn from '@/components/BaseBtn.vue'
import Const from '@/const'
import ModalPrivacy from '@/components/modals/ModalPrivacy.vue'
import ModalTerms from '@/components/modals/ModalTerms.vue'

export default {
  components: {
    IconClose,
    IconChevronDown,
    BtnCheckbox,
    BaseBtn,
    ModalPrivacy, 
    ModalTerms
  },

  name: 'SignupFinalPage',

  data() {
    return {
      form : {
        loginId: '',
        key: '',
        usrNick: '',
        mktAgrYn: 'Y',
        usePolAgrYn: 'N'
      },
      isTermsAgree : false,
      isOpenTerms : false,
      isOpenPrivacy : false,
    }
  },

  created() {
    this.form.loginId = this.$route.params.loginId
    this.form.key = this.$route.params.key
  },

  methods: {
    close () {
      this.$router.push('/')
    },        
    toggleMktAgrYn(value) {
      if (value) {
        this.form.mktAgrYn = 'N'
      } else {
        this.form.mktAgrYn = 'Y'
      }
    },
    clickUsePolAgrYn() {
      if ( !this.isOpenTerms ) {
        this.$alert.alert('이용 약관을 열람 해 주십시오.', 'Warnning')
        return
      }

      if ( !this.isOpenPrivacy ) {
        this.$alert.alert('개인 정보 처리 방침을 열람 해 주십시오.', 'Warnning')
        return
      }

      this.isTermsAgree = true
    },
    toggleUsePolAgrYn(value) {
      if (value) {
        this.form.usePolAgrYn = 'Y'
      } else {
        this.form.usePolAgrYn = 'N'
      }
    },
    popTerms(name) {
      this.$modal.show(name)
    },  
    completeTerms() {
      this.isOpenTerms = true
    },
    completePrivacy() {
      this.isOpenPrivacy = true
    },
    signup () {
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {
          if ( this.form.usePolAgrYn !== 'Y' ) {
            this.$alert.alert('이용 약관 및 개인 정보 처리 방침을 읽고<br/>동의에 체크 하십시오.', 'Warnning')
            return
          }

          this.$lhttp
            .post('/auth/signup/confirm',
              this.form)
            .then((response) => {

              // 로그인 성공
              if ( response.data.code == Const.SIGN_UP_RESPONSE_TYPE.SUCCESS ) {

                this.$alert.alert('회원가입이 완료되었습니다.', '안내')
                  .then(() => {
                    this.$router.push('/login')
                  })

              } else {
                if ( response.data.code == Const.SIGN_UP_RESPONSE_TYPE.VALID_FAIL_AUTH_KEY ) {

                  this.$alert.alert(response.data.message, 'Warnning')
                  .then(() => {
                    this.$router.push('/signup')
                  })                  

                } else {

                  this.$alert.alert(response.data.message, 'Warnning')

                }
              }

            })
          
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.body-title {
  margin-bottom: 20px;
}
.desc {
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
  opacity: 1 !important;
  &.notice {
    font-size: 12px;
    text-align: left;
    width: 100%;
    opacity: 0.6 !important;
  }
  .title {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    margin-right: 5px;
  }
  .result {
    opacity: 0.8;
  }
}
.input {
  width: 100%;
  padding: 20px 10px;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 10px;
  &.error {
    border-color: var(--main-red);
  }
}
.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  align-self: flex-start;
}
.agree-link {
  color: var(--main-orange);
  cursor: pointer;
}
.email-login-container {
  width: 100%;
}  
  
.login-error-message {
  font-size: 12px;
  color: var(--main-red);
  margin-bottom: 20px;
}

.checkbox-box {
  display: flex;
  div {
    align-items: flex-start;
  }
} 
</style>
