<template>
  <BaseBtn @click="handleClick" :style="{ width: width + 'px', height: height + 'px' }" :disabled="disabled"  :class="{full:full}">
    <img :src="require(`@/assets/icons/${icon}.svg`)" alt="" v-if="icon" />
    <h3 :style="{fontSize: fontSize + 'px', fontWeight: fontWeight}">{{ text }}</h3>
  </BaseBtn>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue';

export default {
  name: 'BtnPrimary',
  props: {
    text: {
      type: String,
      default: 'name me',
    },
		icon: String,
    width: [String, Number],
    height: [String, Number],
    fontSize: [String, Number],
    fontWeight: String,
    full: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },    
  },
  methods: {
    handleClick(e) {
      this.$emit('click',e);
    }
  },
  components: { 
    BaseBtn
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 80px;
  background-color: var(--main-orange) !important;
  color: white;
  &.full {
    flex: 1;
    width: 100%;
  }
}
</style>
