<template>
  <div class="demo-page" ref="demoPage">
    <div class="tab-ui">
      <button class="tab" :class="{ active: selectedTab === 'tab1' }" @click="selectedTab = 'tab1'"><h3>대회 현황</h3></button>
      <button class="tab" :class="{ active: selectedTab === 'tab2' }" @click="selectedTab = 'tab2'"><h3>대회별 정보</h3></button>
      <button class="tab" :class="{ active: selectedTab === 'tab3' }" @click="selectedTab = 'tab3'"><h3>나의 참가 대회</h3></button>
    </div>

    <div class="demo-page-tab-content" v-if="selectedTab === 'tab1'">
      <div class="demo-table-container">
        <div class="panel-header">
          <div class="row">
            <div class="row-item">
              <h3 class="bold">참가 중 대회 현황</h3>
            </div>
          </div>
        </div>
        <div class="table-container mid">
          <ag-grid-vue
            style="width: 100%; height: 100%"
            class="ag-theme-delkoit"
            :enableBrowserTooltips="true"
            :columnDefs="myGrid.columnDefs"
            :autoSizeStrategy="myGrid.autoSizeStrategy"
            :rowData="myGrid.rowData"
            :overlayNoRowsTemplate="myGrid.overlayNoRowsTemplate"
            :headerHeight="30"
            :rowHeight="30"
            @row-clicked="selectDetail">
          </ag-grid-vue>
        </div>
      </div>

      <div class="demo-table-container">
        <div class="panel-header">
          <div class="row">
            <div class="row-item">
              <h3 class="bold">사전예약 진행 중</h3>
            </div>
          </div>
        </div>
        <div class="table-container small">
          <ag-grid-vue
            style="width: 100%; height: 100%"
            class="ag-theme-delkoit"
            :enableBrowserTooltips="true"
            :columnDefs="reservGrid.columnDefs"
            :autoSizeStrategy="reservGrid.autoSizeStrategy"
            :rowData="reservGrid.rowData"
            :overlayNoRowsTemplate="reservGrid.overlayNoRowsTemplate"
            :headerHeight="30"
            :rowHeight="30"
            @row-clicked="selectDetail">
          </ag-grid-vue>            
        </div>
      </div>

      <div class="demo-table-container">
        <div class="panel-header">
          <div class="row">
            <div class="row-item">
              <h3 class="bold">빅 매치 대회 현황</h3>
            </div>
          </div>
        </div>
        <div class="table-container mid">
          <ag-grid-vue
            style="width: 100%; height: 100%"
            class="ag-theme-delkoit"
            :enableBrowserTooltips="true"
            :columnDefs="bigGrid.columnDefs"
            :autoSizeStrategy="bigGrid.autoSizeStrategy"
            :rowData="bigGrid.rowData"
            :overlayNoRowsTemplate="bigGrid.overlayNoRowsTemplate"
            :headerHeight="30"
            :rowHeight="30"
            @row-clicked="selectDetail">
          </ag-grid-vue>
        </div>
      </div>

      <div class="demo-table-container">
        <div class="panel-header">
          <div class="row">
            <div class="row-item">
              <h3 class="bold">최신 실운용 손익</h3>
            </div>
          </div>
        </div>
        <div class="table-container small">
          <ag-grid-vue
            style="width: 100%; height: 100%"
            class="ag-theme-delkoit"
            :enableBrowserTooltips="true"
            :columnDefs="realPl.columnDefs"
            :autoSizeStrategy="realPl.autoSizeStrategy"
            :rowData="realPl.rowData"
            :overlayNoRowsTemplate="realPl.overlayNoRowsTemplate"
            :headerHeight="30"
            :rowHeight="30">
          </ag-grid-vue>
        </div>
      </div>

      <div class="demo-table-container" v-if="detail !== null">
        <div class="panel-header">
          <div class="row">
            <div class="row-item">
              <h3 class="bold">대회 정보</h3>
            </div>
          </div>
        </div>
        <div class="demo-competition-info">
          <BaseBtn naked class="join-btn" @click="joinDetail">
            <h3>참가하기</h3>
          </BaseBtn>
          <ul>
            <li>
              <div class="label"><h3>대회명</h3></div>
              <div class="content"><h3>{{detail.evtNm}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>대회기간</h3></div>
              <div class="content"><h3>{{detail.evtStDt}} ~ {{detail.evtEdDt}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>후원기관</h3></div>
              <div class="content"><h3>{{detail.sptCpnNm}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>접수마감</h3></div>
              <div class="content"><h3>{{detail.reqEdDt}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>참가비</h3></div>
              <div class="content"><h3>{{detail.useFee | thousand}}원</h3></div>
            </li>
          </ul>
        </div>
        <div class="demo-competition-info-txt" v-html="detail.evtTxt"></div>
      </div>
      <div class="demo-table-container" v-if="detail !== null">
        <div class="panel-header">
          <div class="row">
            <div class="row-item">
              <h3 class="bold">대회 정보</h3>
            </div>
          </div>
        </div>
        <div class="table-container no-border temp-height">
          <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-delkoit rank"
              :enableBrowserTooltips="true"
              :columnDefs="detailRankerGrid.columnDefs"
              :autoSizeStrategy="detailRankerGrid.autoSizeStrategy"
              :rowData="detailRankerGrid.rowData"
              :overlayNoRowsTemplate="detailRankerGrid.overlayNoRowsTemplate"
              :headerHeight="30"
              :rowHeight="30">
            </ag-grid-vue> 
        </div>
      </div>

    </div>

    <div class="demo-page-tab-content" v-if="selectedTab === 'tab2'">
      <div class="page-header">
        <yearDropDown width="80" v-model="year" @event:selectYear="selectYear" />

        <eventListDropDown :year="year" v-model="evtId" @event:selectEvent="selectEvent" full ref="eventListDropDown" />
      </div>
      <div class="demo-table-container" v-if="event !== null">
        <div class="panel-header">
          <div class="row">
            <div class="row-item">
              <h3 class="bold">대회 정보</h3>
            </div>
          </div>
        </div>
        <div class="demo-competition-info">
          <BaseBtn naked class="join-btn" @click="joinEvent">
            <h3>참가하기</h3>
          </BaseBtn>
          <ul>
            <li>
              <div class="label"><h3>대회명</h3></div>
              <div class="content"><h3>{{event.evtNm}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>대회기간</h3></div>
              <div class="content"><h3>{{event.evtStDt}} ~ {{event.evtEdDt}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>후원기관</h3></div>
              <div class="content"><h3>{{event.sptCpnNm}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>접수마감</h3></div>
              <div class="content"><h3>{{event.reqEdDt}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>참가비</h3></div>
              <div class="content"><h3>{{event.useFee | thousand}}원</h3></div>
            </li>
          </ul>
        </div>
        <div class="demo-competition-info-txt" v-html="event.evtTxt"></div>
      </div>
      <div class="demo-table-container" v-if="event !== null">
        <div class="panel-header">
          <div class="row">
            <div class="row-item">
              <h3 class="bold">대회 정보</h3>
            </div>
          </div>
        </div>
        <div class="table-container no-border temp-height">
          <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-delkoit rank"
              :enableBrowserTooltips="true"
              :columnDefs="rankerGrid.columnDefs"
              :autoSizeStrategy="rankerGrid.autoSizeStrategy"
              :rowData="rankerGrid.rowData"
              :overlayNoRowsTemplate="rankerGrid.overlayNoRowsTemplate"
              :headerHeight="30"
              :rowHeight="30">
            </ag-grid-vue> 
        </div>
      </div>
    </div>

    <div class="demo-page-tab-content" v-if="selectedTab === 'tab3'">
      <div class="page-header">
        <yearDropDown width="80" v-model="year" @event:selectYear="selectYear" />

        <eventMyListDropDown :year="year" v-model="myEvtId" @event:selectEvent="selectMyEvent" full ref="eventMyListDropDown" />
      </div>

      <div class="demo-table-container" v-if="myEvent !== null">
        <div class="panel-header">
          <div class="row">
            <div class="row-item">
              <h3 class="bold">대회 정보</h3>
            </div>
          </div>
        </div>
        <div class="demo-competition-info">
          <ul>
            <li>
              <div class="label"><h3>대회명</h3></div>
              <div class="content"><h3>{{myEvent.evtNm}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>대회기간</h3></div>
              <div class="content"><h3>{{myEvent.evtStDt}} ~ {{myEvent.evtEdDt}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>후원기관</h3></div>
              <div class="content"><h3>{{myEvent.sptCpnNm}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>접수마감</h3></div>
              <div class="content"><h3>{{myEvent.reqEdDt}}</h3></div>
            </li>
            <li>
              <div class="label"><h3>참가비</h3></div>
              <div class="content"><h3>{{myEvent.useFee | thousand}}원</h3></div>
            </li>
          </ul>
        </div>
        <div class="demo-competition-info-txt" v-html="myEvent.evtTxt"></div>

        <div class="demo-table-container">
          <div class="panel-header">
            <div class="row">
              <div class="row-item">
                <h3 class="bold">대회 순위</h3>
              </div>
            </div>
          </div>
          <div class="table-container mid" style="height: 70px;">
            <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-delkoit"
              :columnDefs="myRankGrid.columnDefs"
              :autoSizeStrategy="myRankGrid.autoSizeStrategy"
              :rowData="myRankGrid.rowData"
              :overlayNoRowsTemplate="myRankGrid.overlayNoRowsTemplate"
              :headerHeight="30"
              :rowHeight="30"
            ></ag-grid-vue>
          </div>
        </div>
        <div class="demo-table-container">
          <div class="panel-header">
            <div class="row">
              <div class="row-item">
                <h3 class="bold">최근 참여 대회 순위</h3>
              </div>
            </div>
          </div>
          <div class="table-container mid">
            <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-delkoit"
              :columnDefs="recentRankGrid.columnDefs"
              :autoSizeStrategy="recentRankGrid.autoSizeStrategy"
              :rowData="recentRankGrid.rowData"
              :overlayNoRowsTemplate="recentRankGrid.overlayNoRowsTemplate"
              :headerHeight="30"
              :rowHeight="30"
            ></ag-grid-vue>            
          </div>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
import DropDown from '@/components/DropDown.vue'
import YearDropDown from '@/components/YearDropDown.vue'
import EventListDropDown from '@/components/EventListDropDown.vue'
import EventMyListDropDown from '@/components/EventMyListDropDown.vue'
import { AgGridVue } from 'ag-grid-vue'
import AgDemoUserCell from '@/components/grid/AgDemoUserCell.vue'
import { volumnFormatter, cellClassRulesMinus } from '@/modules/grid-utils.js'
import Utils from '@/modules/utils'

export default {
  name: 'DemoPage',
  components: { 
    BaseBtn,
    DropDown,
    YearDropDown,
    EventListDropDown,
    EventMyListDropDown,
    AgGridVue,
    AgDemoUserCell,
  },
  data() {
    return {
      myGrid: {
        columnDefs: [
          {
            headerName: '대회명',
            field: 'evtNm',
            flex: 0.8,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '수익률 (%)',
            field: 'perf',
            flex: 0.7,
            cellStyle: {
              justifyContent: 'flex-end',
            },
            headerClass: 'right-aligned-header-type2',
            cellClassRules: cellClassRulesMinus,
          },
          {
            headerName: '종료일',
            field: 'evtEdDt',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            cellRenderer : function( param ) {
              if ( param.data.evtEdYn === 'Y' ) {
                return '마감집계'
              } else {
                return param.value
              }
            },            
            headerClass: 'center-align-header',
          },
          {
            headerName: '순위',
            field: 'rank',
            flex: 0.6,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            field: 'evtEdYn',
            hide: "true",
          },
        ],
        autoSizeStrategy: null,
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 참가중 대회 현황이 없습니다.</span>',
        rowData: [],        
      },   
      reservGrid: {
        columnDefs: [
          {
            headerName: '대회명',
            field: 'evtNm',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '예약마감일',
            field: 'reqEdDt',
            flex: 0.8,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '대회기간',
            field: 'evtEdDt',
            flex: 0.8,
            cellStyle: {
              justifyContent: 'center',
            },
            cellRenderer : function( param ) {
              const value = param.data.evtStDt +'~'+ param.value

              return value
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '참가비',
            field: 'useFee',
            flex: 0.6,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            cellRenderer : function( param ) {
              if ( param.value > 0 ) {
                return volumnFormatter(param)
              } else {
                return '무료'
              }
            }
          },
        ],
        autoSizeStrategy: null,
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 사전예약 진행 중 현황이 없습니다.</span>',
        rowData: [],
      },
      bigGrid: {
        columnDefs: [
          {
            headerName: '대회명',
            field: 'evtNm',
            flex: 0.8,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '1위',
            field: 'topUsrNick',
            flex: 0.7,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '수익율 (%)',
            field: 'topPerf',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            cellClassRules: cellClassRulesMinus,
          },
          {
            headerName: '참가자',
            field: 'joinCnt',
            flex: 0.6,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
        ],
        autoSizeStrategy: null,
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 빅매치 대회 현황이 없습니다.</span>',
        rowData: [],
      },
      realPl: {
        columnDefs: [
          {
            headerName: '유저네임',
            field: '유저네임',
            flex: 0.8,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '손익',
            field: '손익',
            flex: 0.7,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            valueFormatter: volumnFormatter,
            cellClassRules: cellClassRulesMinus,
          },
          {
            headerName: '위험한도',
            field: '위험한도',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            valueFormatter: volumnFormatter,
          },
        ],
        autoSizeStrategy: null,
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 최신 실운용 손익이 없습니다.</span>',
        rowData: [],
      },
      detailRankerGrid: {
        columnDefs: [
          {
            headerName: '',
            field: 'rank',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '유저네임',
            field: 'usrNick',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            cellRenderer: 'AgDemoUserCell'
          },
          {
            headerName: '수익율 (%)',
            field: 'perf',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            cellClassRules: cellClassRulesMinus,
          },
          {
            field: 'prfBgCol',
            hide: "true",
          },          
        ],
        autoSizeStrategy: null,
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 대회 순위가 없습니다.</span>',
        rowData: [],
      },      
      rankerGrid: {
        columnDefs: [
          {
            headerName: '',
            field: 'rank',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '유저네임',
            field: 'usrNick',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            cellRenderer: 'AgDemoUserCell'
          },
          {
            headerName: '수익율 (%)',
            field: 'perf',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            cellClassRules: cellClassRulesMinus,
          },
          {
            field: 'prfBgCol',
            hide: "true",
          },          
        ],
        autoSizeStrategy: null,
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 대회 순위가 없습니다.</span>',
        rowData: [],
      },
      myRankGrid: {
        columnDefs: [
          {
            headerName: '대회명',
            field: 'evtNm',
            flex: 0.8,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '수익률',
            field: 'perf',
            flex: 0.7,
            cellStyle: {
              justifyContent: 'flex-end',
            },
            cellRenderer : function( param ) {
              return param.value + '%'
            },
            headerClass: 'right-aligned-header-type2',
            cellClassRules: cellClassRulesMinus,
          },
          {
            headerName: '종료일',
            field: 'evtEdDt',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            cellRenderer : function( param ) {
              if ( param.data.evtEdYn === 'Y' ) {
                return '마감집계'
              } else {
                return param.value
              }
            },            
            headerClass: 'center-align-header',
          },
          {
            headerName: '순위',
            field: 'rank',
            flex: 0.6,
            cellStyle: {
              justifyContent: 'center',
            },
            cellRenderer : function( param ) {
              if ( param.data.evtEdYn === 'Y' ) {
                return '마감집계'
              } else {
                return param.value
              }
            },                        
            headerClass: 'center-align-header',
          },
        ],
        rowData: [],
        autoSizeStrategy: null,
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 순위가 없습니다.</span>',
      },
      recentRankGrid: {
        columnDefs: [
          {
            headerName: '대회명',
            field: 'evtNm',
            flex: 0.8,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '수익률',
            field: 'perf',
            flex: 0.7,
            cellStyle: {
              justifyContent: 'flex-end',
            },
            cellRenderer : function( param ) {
              return param.value + '%'
            },            
            headerClass: 'right-aligned-header-type2',
            cellClassRules: cellClassRulesMinus,
          },
          {
            headerName: '종료일',
            field: 'evtEdDt',
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '순위',
            field: 'rank',
            flex: 0.6,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
        ],
        rowData: [],
        autoSizeStrategy: null,
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 순위가 없습니다.</span>',
      },
      selectedTab: 'tab1',
      evtId: 0,
      myEvtId: 0,
      year: Utils.getThisYear().toString(),
      detail: null,
      event: null,
      myEvent: null,
    }
  },
  mounted() {

    this.searchMyList()
    this.searchReservList()
    this.searchBigList()

  },
  watch: {
    detail (value) {
      if ( value !== null ) {
        this.$nextTick(function() { 
          let demoPage = this.$refs.demoPage
          demoPage.scrollTo({ top: demoPage.scrollHeight, behavior: 'smooth' })          
        })
      }
    },
  },
  filters: {
    thousand(value) {
      if ( value === null || value === '' ) {
        return ''
      } else {
        return value.toLocaleString('en-US', { minimumFractionDigits: 0 })
      }
    },
  },
  methods: {
    async searchMyList() {
      const response = await this.$http.get('/api/demo/my/list')

      if ( response && response.data ) {
        this.myGrid.rowData = response.data
      }
    },
    async searchReservList() {
      const response = await this.$http.get('/api/demo/reserv/list')

      if ( response && response.data ) {
        this.reservGrid.rowData = response.data
      }      
    },    
    async searchBigList() {
      const response = await this.$http.get('/api/demo/big/list')

      if ( response && response.data ) {
        this.bigGrid.rowData = response.data
      }
    },
    async searchMyRankList(evtId) {
      const response = await this.$http.get('/api/demo/my/rank/list', { params: { evtId : evtId } })

      if ( response ) {
        this.myRankGrid.rowData = response.data
      }
    },
    async searchRecentRankList() {
      const response = await this.$http.get('/api/demo/recent/rank/list')

      if ( response ) {
        this.recentRankGrid.rowData = response.data
      }
    },        
    selectYear() {
      this.$refs.eventListDropDown.selectList()
    },
    async selectDetail(e) {
      const response = await this.$http.get('/api/demo', { params: { evtId : e.data.evtId } })

      if ( response && response.data ) {
        this.detail = response.data
        this.detailRankerGrid.rowData = response.data.rankerList
      }            
    },    
    async selectEvent(event) {
      const response = await this.$http.get('/api/demo', { params: { evtId : event.evtId } })

      if ( response && response.data ) {
        this.event = response.data
        this.rankerGrid.rowData = response.data.rankerList
      }            
    },
    async selectMyEvent(event) {
      const response = await this.$http.get('/api/demo', { params: { evtId : event.evtId } })

      if ( response && response.data ) {
        this.myEvent = response.data

        this.searchMyRankList(event.evtId)
        this.searchRecentRankList()
      }            
    },    
    joinDetail() {
      if ( this.detail ) {
        const now = new Date()
        const reqEdDt = new Date(this.detail.reqEdFullDt)

        const diffMSec = now.getTime() - reqEdDt.getTime()
        const diffDate = diffMSec / (24 * 60 * 60 * 1000)

        if (diffDate > 0) {
          this.$alert.alert('모의투자 접수 기간이 종료되었습니다.<br/>먼저 접수 마감일을 확인 해 주십시오.', 'Warnning')
        } else {
          this.$modal.show('eventRequestPop', { evtId : this.detail.evtId })
        }
      }
    },
    joinEvent() {
      if ( this.event ) {
        const now = new Date()
        const reqEdDt = new Date(this.event.reqEdFullDt)

        const diffMSec = now.getTime() - reqEdDt.getTime()
        const diffDate = diffMSec / (24 * 60 * 60 * 1000)

        if (diffDate > 0) {
          this.$alert.alert('모의투자 접수 기간이 종료되었습니다.<br/>먼저 접수 마감일을 확인 해 주십시오.', 'Warnning')
        } else {
          this.$modal.show('eventRequestPop', { evtId : this.event.evtId })
        }
      }
    }    
  },
}
</script>

<style lang="scss" scoped>
.demo-page {
  padding: 10px 20px;
  overflow-y: auto;
  height: calc(100vh - 50px);
  .tab-ui {
    margin-bottom: 10px;
  }
  .page-header {
    display: flex;
    align-items: center;
    gap: 0 10px;
    margin-top: 20px;
  }
  .demo-table-container {
    &:not(:first-child) {
      margin-top: 10px;
    }
    .subtitle {
      opacity: 0.8;
    }
    .table-container {
      &.mid {
        height: 121px;
      }
      &.small {
        height: 93px;
      }
      &.no-border {
        border: none;
      }
      &.temp-height {
        height: 213px;
      }
    }
  }
}
.panel-header {
  display: flex;
  align-items: center;
  height: 50px;
  /* border: 1px solid gray; */
  /* margin-bottom: 11px; */
  /* margin-top: 10px; */
}

.demo-competition-info {
  position: relative;
  border-top: 1px solid rgba(255,255,255,0.2);
  border-left: 1px solid rgba(255,255,255,0.2);
  border-right: 1px solid rgba(255,255,255,0.2);
  background-color: #141A25;
  padding: 20px 20px 10px 20px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
    li {
      display: flex;
      gap: 20px;
      .label {
        flex: 0 48px;
        opacity: 0.6;
      }
    }
  }

  .custom-counter {
    display: flex;
    flex-direction: column;
    gap: 4px;
    counter-reset: counter;
    li {
      counter-increment: counter;
      gap: 5px;
      line-height: 1.3em;
      &::before {
        content: counter(counter) '. ';
        font-size: 12px;
      }
    }
  }
}
.join-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--main-orange);
  border-radius: 50vh;
  padding: 6px 17px 8px !important;
}

@import '@/assets/scss/agGridStyles.scss';

.ag-theme-delkoit {
  &::v-deep {
    .ag-cell {
      display: flex;
      align-items: center;
      /* justify-content: center; */
    }
  }
}

.rank {
  --ag-header-background-color: transparent;    
}
</style>

<style lang="scss">
.demo-competition-info-txt {
  position: relative;
  line-height: 1.4em;
  border-left: 1px solid rgba(255,255,255,0.2);
  border-right: 1px solid rgba(255,255,255,0.2);
  border-bottom: 1px solid rgba(255,255,255,0.2);
  background-color: #141A25;
  padding: 0 20px 20px 20px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
    li {
      display: flex;
      gap: 20px;
    }
  }

  ul > li > p {
    flex: 0 48px;
    opacity: 0.6;
  }

  .custom-counter {
    display: flex;
    flex-direction: column;
    gap: 4px;
    counter-reset: counter;
    li {
      counter-increment: counter;
      gap: 5px;
      line-height: 1.3em;
      &::before {
        content: counter(counter) ". ";
        font-size: 12px;
      }
    }
  }

  img {
    width : 100%;
    object-fit: cover;
  }  
}
</style>
  