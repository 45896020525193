<template>
  <modal name="manageAccountPop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="계좌관리" modalName="manageAccountPop" footer>
      <template #body>
        <div class="row gap10">
          <div class="row-item">
            <h3>계좌비밀번호</h3>
            <InputComp type="password" placeholder="비밀번호" v-model="accPass" />
          </div>
          <div class="row-item">
            <BtnSub text="일괄적용" @click="updateAccPass" />
          </div>
          <div class="row-item">
            <BtnSub text="일괄삭제" @click="clearAccPass" />
          </div>
        </div>
        <div class="table-container">
          <ag-grid-vue
            style="width: 100%; height: 100%"
            alwaysShowHorizontalScroll="true"
            class="ag-theme-delkoit"
            rowSelection="single"
            :columnDefs="grid.columnDefs"
            :alwaysShowVerticalScroll="true"
            :rowData="grid.rowData"
            @grid-ready="onGridReady"
            @rowDataUpdated="onRowDataUpdated"
            @cellValueChanged="onCellValueChanged">
          </ag-grid-vue>
        </div>
        <div class="row gap10">
          <BtnLine text="위로" white icon="arrow-up" @click="moveRow(-1)" />
          <BtnLine text="아래로" white icon="arrow-down" @click="moveRow(+1)" />
          <BtnLine text="맨위로" white icon="arrow-up" @click="moveTop('top')" />
          <BtnLine text="끝으로" white icon="arrow-down" @click="moveTop('bottom')" />
        </div>
        <ul class="info-list">
          <li>계좌비밀번호는 프로그램 종료시까지 유지됩니다.</li>
          <li>일괄적용 및 개별 계좌별 적용이 가능합니다.</li>
          <li>해당 기능 사용시 발생할 수 있는 타인의 사용등으로 인한 손실해 대한 책임은 당사에 없음을 알려 드립니다.</li>
        </ul>
      </template>
      <template #footer>
        <BtnSub text="확인" @click="apply('confirm')" />
        <BtnSub text="취소" @click="close" />
        <BtnPrimary text="적용" @click="apply('apply')" />
      </template>
    </ModalContent>
  </modal>      
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import BtnLine from '@/components/BtnLine.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import InputComp from '@/components/InputComp.vue'

import { AgGridVue } from 'ag-grid-vue' // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import AgCheckbox from '@/components/grid/AgCheckbox.vue'
import CustomHeader from '@/components/grid/CustomHeader.vue'

export default {
  name: 'ManageAccountPop',
  components: {
    ModalContent,
    InputComp,
    BtnSub,
    BtnPrimary,
    BtnLine,
    AgGridVue, // Add AG Grid Vue3 component
    // eslint-disable-next-line vue/no-unused-components
    AgCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    agColumnHeader: CustomHeader,    
  },
  data() {
    return {
      grid: {
        columnDefs: [
          {
            headerName: '계좌번호',
            field: 'accNo',
            resizable: false,
            flex: 1,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '계좌구분',
            field: 'accDivNm',
            resizable: false,
            flex: 1,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '표시계좌명',
            field: 'accNm',
            resizable: false,
            flex: 1,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '비밀번호',
            field: 'accPass',
            resizable: false,
            flex: 0.5,
            cellStyle: {
              textAlign: 'center',
            },
            cellEditor: 'agTextCellEditor',
            cellEditorParams: {
              maxLength: 4
            },            
            editable: true,
            singleClickEdit: true,
            valueFormatter: function (params) {
              if ( params.value === undefined || params.value === null || params.value === '' ) {
                return ''
              } else {
                return params.value.replace(/./g, '*')
              }
            },
            headerClass: 'center-align-header',
          },
          {
            field: 'accId',
            hide: "true"
          }
        ],
        rowData: [],        
      },
      gridApi: null,
      changedIndex: -1,
      changedPosion: 'middle',
      accPass: ''
    }
  },
  mounted() {},
  methods: {
    beforeOpen(e) {  
    },    
    opened() {
      this.search()
    },       
    close() {
      this.$modal.hide('manageAccountPop')
    },   
    onGridReady(params) {
      this.gridApi = params.api
    },    
    async search() {
      this.$lhttp
      .get('/api/account/list')
      .then((result) => {
        if ( result && result.data !== null ) {
          this.grid.rowData = result.data

          if ( this.$sessionStorage.get('acc_pass_store') ) {
            const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))

            this.grid.rowData.forEach(item => {
              item.accPass = accPassObj[item.accNo]
            })          
          }

        }
      })      
    },
    moveRow(index) {
      const selectedNodes = this.gridApi.getSelectedNodes()

      if ( selectedNodes == null || selectedNodes.length <= 0 ) {
        this.$alert.alert('먼저 계좌를 선택하십시오.', 'Warnning')
      } else {

        if ( index < 0 ) {
          this.changedPosion = 'middle'
        } else {
          this.changedPosion = 'bottom'
        }

        let list = [...this.grid.rowData]

        const seletedIndex = selectedNodes[0].rowIndex

        if ( seletedIndex + index < 0 || seletedIndex + index >= list.length ) {
          return
        }

        let item = list [seletedIndex]
        list [seletedIndex] = list [seletedIndex + index]
        list [seletedIndex + index] = item

        this.changedIndex = seletedIndex + index

        this.updateAccSeq(list)
        this.grid.rowData = list 
        
      }
    },
    moveTop(type) {
      const selectedNodes = this.gridApi.getSelectedNodes()

      if ( selectedNodes == null || selectedNodes.length <= 0 ) {
        this.$alert.alert('먼저 계좌를 선택하십시오.', 'Warnning')
      } else {

        let list = [...this.grid.rowData]

        const seletedIndex = selectedNodes[0].rowIndex

        if ( ( type === 'top' && seletedIndex === 0 ) 
            || ( type === 'bottom' && seletedIndex === list.length - 1 ) ) {
          return
        }

        let item = list[seletedIndex]

        list.splice(seletedIndex, 1)

        if ( type === 'top' ) {
          list.unshift(item)

          this.changedPosion = 'top'
          this.changedIndex  = 0
        } else {
          list.push(item)

          this.changedPosion = 'bottom'
          this.changedIndex  = list.length - 1
        }

        this.updateAccSeq(list)
        this.grid.rowData = list 
        
      }
    },    
    updateAccSeq(list) {
      list.forEach(function(item, idx) {
        item.accSeq = idx
      })
    },
    onRowDataUpdated() {
      if ( this.gridApi !== null && this.changedIndex > -1) {
        this.gridApi.forEachNode(node => {
          if (node.rowIndex === this.changedIndex) {
            node.setSelected(true)
          }
        })

        this.gridApi.ensureIndexVisible(this.changedIndex, this.changedPosion)
      }
    },    
    onCellValueChanged(event) {
      console.log(event.newValue)

      if ( event.column.colId === 'accPass' &&  event.newValue !== undefined && event.newValue !== '' ) {
        this.$http
          .post('/api/account/pass/check',
            {accId : event.data.accId, accPass : event.newValue})
          .then((result) => {
            if( !result.data ) {
              this.$alert.alert('비밀 번호가 일치하지 않습니다.', 'Warnning').then(() => {
                const index = this.grid.rowData.findLastIndex((item) => item.accId === event.data.accId)

                if ( index > -1 ) {
                  this.grid.rowData[index].accPass = ''
                }

                this.gridApi.redrawRows()
              })
            }
          })
      }
    },
    async updateAccPass() {
      const accPass = this.accPass
      let isFail = false

      if ( accPass === undefined || accPass === null || accPass === '' ) {
        this.$alert.alert('먼저 비밀번호를 입력하십시오.', 'Warnning')
        return
      }


      for (const item of this.grid.rowData) {
        const response = await this.$lhttp.post('/api/account/pass/check',{accId : item.accId, accPass : accPass})

        if( response.data ) {
          item.accPass = accPass
        } else {
          item.accPass = ''
          isFail = true
        }
      }

      this.gridApi.redrawRows()

      if (isFail) {
        this.$alert.alert('비밀 번호가 일치하지 않는 계좌가 있습니다.', 'Warnning')
      }
    },
    clearAccPass() {
      this.grid.rowData.forEach(function(item) {
        item.accPass = ''
      })

      this.gridApi.redrawRows()
    },
    apply(type) {
      //그리드 포커스가 있으면 포커스를 제거하여 비밀번호가 저장되도록
      if (type == 'confirm'){
        const index = this.gridApi.getFocusedCell()
        if (index != undefined && index.rowIndex >= 0){
          this.gridApi.clearFocusedCell()
        }
      }
      this.$alert.confirm('수정된 계좌 정보를 적용하시겠습니까?', '확인')
        .then((response) => {
          if (response) {
            this.$lhttp
              .post('/api/account/update',
              {editedList : this.grid.rowData})
              .then(() => {
                this.$alert.alert('계좌 정보 수정이 완료되었습니다.', '안내').then(() => {
                  let accPass = {}

                  this.grid.rowData.forEach(item => {
                    accPass[item.accNo] = item.accPass
                  })

                  this.$sessionStorage.set('acc_pass_store', JSON.stringify(accPass))

                  this.$emit('event:manage-account-complete')

                  if ( type == 'confirm' ) {
                    this.close()
                  } else {
                    this.search()
                  }
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-container {
  margin: 10px 0;
}
button {
  width: 70px;
}
.info-list {
  margin-top: 30px;
}

@import '@/assets/scss/agGridStyles.scss';
</style>
