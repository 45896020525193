<template>
  <modal name="firstDemoRequestPop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="첫 모의계좌 신청" modalName="firstDemoRequestPop" footer>
      <template #body>
        <h3 style="opacity: 0.6">첫 모의계좌 개설시 전화번호 인증이 필요합니다.</h3>
        <div class="row gap10">
          <InputComp placeholder="전화번호 입력" full />
          <BtnSub text="인증" />
        </div>
        <!-- <ModalBasic text="새 레이아웃 이름을 넣으세요." placeholder="새 레이아웃 이름" value="디폴트 화면" /> -->
      </template>
      <template #footer>
        <BtnSub text="취소" />
        <BtnPrimary text="저장" />
      </template>
    </ModalContent>
  </modal>
</template>    

<script>
import ModalContent from '@/components/ModalContent.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import InputComp from '@/components/InputComp.vue'

export default {
  name: 'ModalDemoRequest',
  components: {
    ModalContent,
    BtnSub,
    BtnPrimary,
    InputComp,
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    beforeOpen(e) {  
    },    
    opened() {
    },        
    close() {
      this.$modal.hide('firstDemoRequestPop')
    },    
  },
};
</script>
