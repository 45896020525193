<template>
    <div class="base-dropdown" :id="id" :style="{ width: width + 'px', height: height + 'px' }" :class="{ full: full }" ref="dropdown">
      <div class="dropdown-title" @click="handleDropdown" :class="{ active: isDropdownVisible }" :style="{ height: height + 'px' }">
        <h3>
          {{ selectedName }}
        </h3>
        <img class="triangle" :class="{ open: isDropdownVisible }" src="@/assets/icons/triangle-down.svg" alt="" />
      </div>
      <transition name="fade">
        <DropDownList v-if="isDropdownVisible" v-bind:width="width" :popup="popup">
          <div
            class="dropdown-list-item"
            v-for="(option, index) in options"
            :key="index"
            @click="
              isDropdownVisible = false
              $emit('input', option.code)
            "
          >
            <div class="col">
              <h3>{{ option.name }}</h3>
            </div>
          </div>
        </DropDownList>
      </transition>
    </div>
  </template>
  
  <script>
  import DropDownList from '@/components/DropDownList.vue'
  
  export default {
    name: 'CustomDropDown',
    props: {
      options: {
        type: Array,
        required: true,
      },
      id: String,
      default: String,
      width: [String, Number],
      height: [String, Number],
      full: Boolean,
      popup: Boolean,
      value: {
        type: String,
        default: '',
      }
    },
    components: {
      DropDownList,
    },

    data() {
      return {
        selectedOption: '',
        isDropdownVisible: false,
      };
    },

    computed: {
      selectedName() {
        let rtn = ''  

        if ( this.value === '' ) {
            if (this.codeList.length > 0 ) {
            rtn = this.codeList[0].name
            }
        } else {
            for (let i in this.options) {
            if ( this.value == this.options[i].code ) {
                rtn = this.options[i].name
                break
            }  
            }
        }

        return rtn
      }
    },      
  
    mounted() {
      this.selectedOption = this.default ? this.default : this.options[0];
      window.addEventListener('click', this.closeDropdown);
      //console.log(this.default);
    },
    beforeDestroy() {
      window.removeEventListener('click', this.closeDropdown);
    },
  
    methods: {
      handleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible;
      },
      closeDropdown(element) {
        if (!this.$refs.dropdown.contains(element.target)) {
          this.isDropdownVisible = false;
        }
      },
      accountMange() {
        this.$modal.show('계좌관리');
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .base-dropdown {
    position: relative;
    white-space: nowrap;
    height: 30px;
    cursor: pointer;
    &.full {
      flex: 1;
      width: 100%;
      .dropdown-list {
        width: 100%;
      }
    }
    .dropdown-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px 0 10px;
      height: 100%;
      width: 100%;
      h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      /* height: 30px; */
      border: 1px solid var(--border-color);
      &:hover {
        border-color: var(--main-orange);
      }
      &.active {
        border-color: var(--main-orange);
      }
    }
    h3 {
      font-size: 12px;
      // font-weight: 400;
      padding-bottom: 2px;
    }
    .triangle {
      margin-left: 3px;
      transition: transform 0.2s;
      &.open {
        transform: rotate(180deg);
      }
    }
    @media (max-width: $mobile) {
      height: 40px;
    }
  }
  </style>
  