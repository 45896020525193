<template>
  <modal name="favoriteCreatePop" class="custom-modal" adaptive :minWidth="400" :minHeight="216" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">
    <ModalContent :text="title" modalName="favoriteCreatePop" footer>
      <template #body>
        <div class="container">
          <h3>새 관심종목 이름을 넣으세요.</h3>
          <validation-observer ref="validator">
            <validation-provider rules="required|limit:40" name="관심종목명" v-slot="{errors}">
              <InputComp placeholder="관심종목명을 입력 해 주십시오." :error="errors[0] ? true: false" v-model="form.itrstNm" />
              <div class="error-message"><h3>{{errors[0]}}</h3></div>
            </validation-provider>
          </validation-observer>
        </div>                  
      </template>
      <template #footer>
        <BtnSub text="취소" @click="close" />
        <BtnPrimary text="저장" @click="saveFavorite" />
      </template>
    </ModalContent>
  </modal>
</template>  

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputComp from '@/components/InputComp.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'

export default {
  name: 'FavoriteCreatePop',
  components: {
    ModalContent,
    InputComp,
    BtnSub,
    BtnPrimary,    
  },
  data() {
    return {
      title: '새로운 관심종목 만들기',
      custom : {
        itrstNm: '관심종목',
        shtNm: true,
        curPr: true,
        ydiffPr: true,
        chGrate: true,
        totQ: true,
        seq:0,
        socketSymbol:[],
      },
      form:{
        itrstNm: '',
        copyYn:'N',
        chgNmYn:'N',
        seq:0,
      }

    };
  },

  mounted() {},

  methods: {
    beforeOpen(e) {
      //console.log("e", e.params)
      this.custom = e.params.custom;
      this.form.copyYn = e.params.copyYn;
      this.form.chgNmYn = e.params.chgNmYn;
      if (this.form.copyYn == "N" && this.form.chgNmYn == "N"){
        this.title = '새로운 관심종목 만들기';
        this.form.seq = 0;
      }else if (this.form.copyYn == "Y"){
        this.title = '관심종목 복사본 만들기';
        this.form.seq = this.custom.seq;
        this.form.itrstNm = this.custom.itrstNm + '_복사';
      }else if(this.form.chgNmYn == "Y"){
        this.title = '관심종목 이름 바꾸기';
        this.form.seq = this.custom.seq;
        this.form.itrstNm = this.custom.itrstNm;
      }else{
        this.title = '';
        this.form.seq = 0;
      }
    },    
    opened() {
    },
    saveFavorite(){
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {

          let apiUrl = 'api/symbol/favorite/save'
          if (this.form.copyYn == "Y"){
            if (this.form.seq == 0){
              this.$alert.alert('선택된 관심종목이 없습니다.', '알림')
              return
            }
            apiUrl =  'api/symbol/favorite/copy'
          }else if(this.form.chgNmYn == "Y"){
            apiUrl =  'api/symbol/favorite/changeNm'
          }

          this.$alert.confirm('관심종목을 저장 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {
                this.$http
                  .post(apiUrl,
                      this.form)
                  .then((res) => {
                    //console.log("res ", res.data)
                    this.custom.seq = res.data.seq
                      this.custom.itrstNm = res.data.itrstNm
                      if (res.data.favoriteList != undefined){
                        res.data.favoriteList.forEach(fav=>{
                          if (fav.orgSymNo != null && fav.orgSymNo > 0){
                            this.custom.socketSymbol.push(fav.orgSymNo);
                          }
                        })
                      }
                       this.$alert.alert('관심종목 저장이 완료되었습니다.', '안내').then(() => {
                        window.$store.dispatch('Layout/saveCustom', { type: 'FV', custom: this.custom});
                        this.$emit('event:saveFavorite')
                        this.close()
                      })
                  })
              }
            })      

        } else {
          this.$alert.alert('먼저 입력 항목을 확인 하십시오.', '알림')
        }

      })
    },
    close(){
      this.itrstNm = "";
      this.$modal.hide('favoriteCreatePop');
    },
  },
}
</script>    

<style lang="scss" scoped>
.container {
  /* height: 100%; */
  margin-bottom: 20px;
}
h3 {
  opacity: 0.6;
  margin-bottom: 10px;
}
</style>