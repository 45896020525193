<template>
  <modal name="orderSettingsPop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="true"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="주문 환경 설정" modalName="orderSettingsPop" footer>
      <template #body>      
        <div class="col-2">
          <div class="col">
            <h3 class="subtitle">클릭주문 설정</h3>
            <div class="table-wrapper">
              <div class="table-row">
                <CheckboxBtn text="원클릭" v-bind:value="orderSetting.oneClick" @childEvent="setOneClick" />
                <CheckboxBtn text="더블클릭" v-bind:value="orderSetting.dbClick" @childEvent="setDbClick" />
              </div>
            </div>
          </div>
          <div class="col">
            <h3 class="subtitle">주문 알림/확인 설정</h3>
            <div class="table-wrapper">
              <div class="table-row">
                <div class="inner-row">
                  <CheckboxBtn text="주문알림" v-bind:value="messageAlert" @childEvent="setMsg" />
                </div>
                <div class="inner-row">
                  <CheckboxBtn text="주문확인" v-bind:value="ordMessageAlert" @childEvent="setOrdMsg" />
                </div>
                <div class="inner-row" style="width:110px">
                  <CheckboxBtn text="청산/취소 확인" v-bind:value="clearMessageAlert" @childEvent="setClearMsg" />
                </div>
                <div class="inner-row">
                  <CheckboxBtn text="음성" v-bind:value="soundAlert" @childEvent="setSoundMsg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="col">
            <h3 class="subtitle">주문수량 설정</h3>
            <div class="table-wrapper">
              <div class="table-row">
                <div class="inner-row">
                  <div class="label no-width"><h3>1</h3></div>
                  <!-- <InputCompSimple v-bind:value="orderSetting.clickSetList[0]" width="50" alignRight /> -->
                  <CounterComp width="55" :maxCnt="200" :maxLength=3 @childEvent="setClickVal1" :value="orderSetting.clickVal1.val" />
                </div>
                <div class="inner-row">
                  <div class="label no-width"><h3>2</h3></div>
                  <!-- <InputCompSimple :value="orderSetting.clickSetList[1]" width="50" alignRight /> -->
                  <CounterComp width="55" :maxCnt="200" :maxLength=3 @childEvent="setClickVal2" :value="orderSetting.clickVal2.val" />
                </div>
                <div class="inner-row">
                  <div class="label no-width"><h3>3</h3></div>
                  <!-- <InputCompSimple :value="orderSetting.clickSetList[2]" width="50" alignRight /> -->
                  <CounterComp width="55" :maxCnt="200" :maxLength=3 @childEvent="setClickVal3" :value="orderSetting.clickVal3.val" />
                </div>
                <div class="inner-row">
                  <div class="label no-width"><h3>4</h3></div>
                  <!-- <InputCompSimple :value="orderSetting.clickSetList[3]" width="50" alignRight /> -->
                  <CounterComp width="55" :maxCnt="200" :maxLength=3 @childEvent="setClickVal4" :value="orderSetting.clickVal4.val" />
                </div>
                <div class="inner-row">
                  <div class="label no-width"><h3>5</h3></div>
                  <!-- <InputCompSimple :value="orderSetting.clickSetList[4]" width="50" alignRight /> -->
                  <CounterComp width="55" :maxCnt="200" :maxLength=3 @childEvent="setClickVal5" :value="orderSetting.clickVal5.val" />
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <h3 class="subtitle">스탑로스 틱 설정</h3>
            <div class="table-wrapper">
              <div class="table-row">
                <div class="inner-row">
                  <div class="label no-width"><h3>손절</h3></div>
                  <!-- <InputCompSimple v-bind:value="orderSetting.slTikVal1" width="50" alignRight @childEvent="setSlTikVal1" /> -->
                  <CounterComp width="55" :maxCnt="200" :maxLength=3 @childEvent="setSlTikVal1" :value="orderSetting.slTikVal1.tikVal" />
                </div>
                <div class="inner-row">
                  <div class="label no-width"><h3>익절</h3></div>
                <!-- <InputCompSimple v-bind:value="orderSetting.slTikVal2" width="50" alignRight @childEvent="setSlTikVal2" /> -->
                <CounterComp width="55" :maxCnt="200" :maxLength=3 @childEvent="setSlTikVal2" :value="orderSetting.slTikVal2.tikVal" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 class="subtitle">키보드 단축키 설정</h3>
        <div class="table-wrapper">
          <div class="col">
            <div class="table-row">
              <div class="label"><h3>중앙정렬</h3></div>
              <div class="content">
                <DropDown id="ordSetCenterKey"
                  popup
                  :options="keyOptionList"
                  :defaultOption="defaultOption.centerKey"
                  width="180"
                  @ordSetCenterKey="ordSetCenterKey"
                />
                +
                <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.centerKey.numberKey" @childEvent="setCenterNumberKey" />
              </div>
            </div>
            <div class="table-row">
              <div class="label"><h3>시장가매도</h3></div>
              <div class="content">   
                <DropDown id="ordSetMarketSellKey"
                  popup
                  :options="keyOptionList"
                  :defaultOption="defaultOption.marketSellKey"
                  width="180"
                  @ordSetMarketSellKey="ordSetMarketSellKey"
                />
                +
                <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.marketSellKey.numberKey" @childEvent="setMarketSellNumberKey" />
              </div>
            </div>
            <div class="table-row">
              <div class="label"><h3>시장가매수</h3></div>
              <div class="content">
                <DropDown id="ordSetMarketBuyKey"
                  popup
                  :options="keyOptionList"
                  :defaultOption="defaultOption.marketBuyKey"
                  width="180"
                  @ordSetMarketBuyKey="ordSetMarketBuyKey"
                />
                +
                <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.marketBuyKey.numberKey" @childEvent="setMarketBuyNumberKey" />
              </div>
            </div>
            <div class="table-row">
              <div class="label"><h3>매도취소</h3></div>
              <div class="content">
                <DropDown id="ordSetCancelSell"
                  popup
                  :options="keyOptionList"
                  :defaultOption="defaultOption.cancelSell"
                  width="180"
                  @ordSetCancelSell="ordSetCancelSell"
                />
                +
                <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.cancelSell.numberKey" @childEvent="setCancelSellNumberKey" />
              </div>
            </div>
            <div class="table-row">
              <div class="label"><h3>매수취소</h3></div>
              <div class="content">
                <DropDown id="ordSetCancelBuy"
                  popup
                  :options="keyOptionList"
                  :defaultOption="defaultOption.cancelBuy"
                  width="180"
                  @ordSetCancelBuy="ordSetCancelBuy"
                />
                +
                <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.cancelBuy.numberKey" @childEvent="setCancelBuyNumberKey" />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="table-row">
              <div class="label"><h3>매도ST취소</h3></div>
              <div class="content">
                <DropDown id="ordSetStopSellKey"
                  popup
                  :options="keyOptionList"
                  :defaultOption="defaultOption.stopSellKey"
                  width="180"
                  @ordSetStopSellKey="ordSetStopSellKey"
                />
                +
                <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.stopSellKey.numberKey" @childEvent="setStopSellNumberKey" />
              </div>
            </div>
            <div class="table-row">
              <div class="label"><h3>매수ST취소</h3></div>
              <div class="content">
                <DropDown id="ordSetStopBuyKey"
                  popup
                  :options="keyOptionList"
                  :defaultOption="defaultOption.stopBuyKey"
                  width="180"
                  @ordSetStopBuyKey="ordSetStopBuyKey"
                />
                +
                <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.stopBuyKey.numberKey" @childEvent="setStopBuyNumberKey" />
              </div>
            </div>
            <div class="table-row">
              <div class="label"><h3>전종목청산</h3></div>
              <div class="content">
                <DropDown id="ordSetAllClear"
                  popup
                  :options="keyOptionList"
                  :defaultOption="defaultOption.allClear"
                  width="180"
                  @ordSetAllClear="ordSetAllClear"
                />
                +
                <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.allClear.numberKey" @childEvent="setAllClearNumberKey" />
              </div>
            </div>
            <div class="table-row">
              <div class="label"><h3>현종목청산</h3></div>
              <div class="content">
                <DropDown id="ordSetSymbolClear"
                  popup
                  :options="keyOptionList"
                  :defaultOption="defaultOption.symbolClear"
                  width="180"
                  @ordSetSymbolClear="ordSetSymbolClear"
                />
                +
                <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.symbolClear.numberKey" @childEvent="setSymbolClearNumberKey" />
              </div>
            </div>
          </div>
        </div>
      </template>     
      <template #footer>
        <!-- <BtnSub text="취소" @click="close" /> -->
        <BtnPrimary text="확인" @click="setCustom" />
      </template>        
    </ModalContent>
  </modal>
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
//import BtnCheckbox from '@/components/BtnCheckbox.vue'
import CounterComp from '@/components/CounterComp.vue'
import DropDown from '@/components/DropDown.vue'
// import InputCompSimple from '@/components/InputCompSimple.vue'

import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import CheckboxBtn from '@/components/CheckboxBtn.vue'

export default {
  name: 'ModalOrderSettings',
  components: {
    ModalContent,
//    BtnCheckbox,
    CounterComp,
    DropDown,
//    InputCompSimple,
    BtnSub,
    BtnPrimary,
    CheckboxBtn
  },  
  data() {
    return {
      keyOptionList: [],
      orderSetting:{
        oneClick: false,
        dbClick: false,
        slTikVal1: {tikVal:1},
        slTikVal2: {tikVal:1},
        clickVal1: {val:1},
        clickVal2: {val:1},
        clickVal3: {val:1},
        clickVal4: {val:1},
        clickVal5: {val:1},
        centerKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelSell: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelBuy: {primaryKey: '', numberKey: 1, keyCode:null},
        stopSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        stopBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        allClear: {primaryKey: '', numberKey: 1, keyCode:null},
        symbolClear: {primaryKey: '', numberKey: 1, keyCode:null},
        messageAlert: false,
        soundAlert: false,
        ordMessageAlert: false,
      },
      defaultOption: {
        centerKey: '',
        marketSellKey: '',
        marketBuyKey: '',
        cancelSell: '',
        cancelBuy: '',
        stopSellKey: '',
        stopBuyKey: '',
        allClear: '',
        symbolClear: '',
      },
      loadOption: null,
      keyCodeList: [
        {keyCode:49, Number:1},
        {keyCode:50, Number:2},
        {keyCode:51, Number:3},
        {keyCode:52, Number:4},
        {keyCode:53, Number:5},
        {keyCode:54, Number:6},
        {keyCode:55, Number:7},
        {keyCode:56, Number:8},
        {keyCode:57, Number:9}
      ],
      keyCheckList: [],
      messageAlert: false,
      ordMessageAlert: false,
      clearMessageAlert: false,
      soundAlert: false,
    }
  },
  mounted() {
  },
  methods: {
    setOneClick(){
      this.orderSetting.oneClick = true;
      this.orderSetting.dbClick = false;
    },
    setDbClick(){
      this.orderSetting.oneClick = false;
      this.orderSetting.dbClick = true;
    },
    //손절 틱설정값 저장
    setSlTikVal1(tickVal){
      this.orderSetting.slTikVal1.tikVal = Number(tickVal);
    },
    //익절 틱설정값 저장
    setSlTikVal2(tickVal){
      this.orderSetting.slTikVal2.tikVal = Number(tickVal);
    },
    setClickVal1(val){
      this.orderSetting.clickVal1.val = Number(val);
    },
    setClickVal2(val){
      this.orderSetting.clickVal2.val = Number(val);
    },
    setClickVal3(val){
      this.orderSetting.clickVal3.val = Number(val);
    },
    setClickVal4(val){
      this.orderSetting.clickVal4.val = Number(val);
    },
    setClickVal5(val){
      this.orderSetting.clickVal5.val = Number(val);
    },
    setOrdMsg(){
      this.orderSetting.ordMessageAlert = !this.ordMessageAlert;
      this.ordMessageAlert = !this.ordMessageAlert;
    },
    setMsg(){
      this.orderSetting.messageAlert = !this.messageAlert;
      this.messageAlert = !this.messageAlert;
    },
    setClearMsg(){
      this.orderSetting.clearMessageAlert = !this.clearMessageAlert;
      this.clearMessageAlert = !this.clearMessageAlert;
    },
    setSoundMsg(){
      this.orderSetting.soundAlert = !this.soundAlert;
      this.soundAlert = !this.soundAlert;
    },
    ordSetCenterKey(val){
      this.orderSetting.centerKey.primaryKey = val.name;
    },
    ordSetMarketSellKey(val){
      this.orderSetting.marketSellKey.primaryKey = val.name;
    },
    ordSetMarketBuyKey(val){
      this.orderSetting.marketBuyKey.primaryKey = val.name;
    },
    ordSetCancelSell(val){
      this.orderSetting.cancelSell.primaryKey = val.name;
    },
    ordSetCancelBuy(val){
      this.orderSetting.cancelBuy.primaryKey = val.name;
    },
    ordSetStopSellKey(val){
      this.orderSetting.stopSellKey.primaryKey = val.name;
    },
    ordSetStopBuyKey(val){
      this.orderSetting.stopBuyKey.primaryKey = val.name;
    },
    ordSetAllClear(val){
      this.orderSetting.allClear.primaryKey = val.name;
    },
    ordSetSymbolClear(val){
      this.orderSetting.symbolClear.primaryKey = val.name;
    },
    setCenterNumberKey(val){
      this.orderSetting.centerKey.numberKey = Number(val);
    },
    setMarketSellNumberKey(val){
      this.orderSetting.marketSellKey.numberKey = Number(val);
    },
    setMarketBuyNumberKey(val){
      this.orderSetting.marketBuyKey.numberKey = Number(val);
    },
    setCancelSellNumberKey(val){
      this.orderSetting.cancelSell.numberKey = Number(val);
    },
    setCancelBuyNumberKey(val){
      this.orderSetting.cancelBuy.numberKey = Number(val);
    },
    setStopSellNumberKey(val){
      this.orderSetting.stopSellKey.numberKey = Number(val);
    },
    setStopBuyNumberKey(val){
      this.orderSetting.stopBuyKey.numberKey = Number(val);
    },
    setAllClearNumberKey(val){
      this.orderSetting.allClear.numberKey = Number(val);
    },
    setSymbolClearNumberKey(val){
      this.orderSetting.symbolClear.numberKey = Number(val);
    },
    beforeOpen(e) {
      this.loadOption = null;

      //팝업 설정 초기화
      this.orderSetting = {
        oneClick: false,
        dbClick: false,
        slTikVal1: {tikVal:1},
        slTikVal2: {tikVal:1},
        clickVal1: {val:1},
        clickVal2: {val:1},
        clickVal3: {val:1},
        clickVal4: {val:1},
        clickVal5: {val:1},
        centerKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelSell: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelBuy: {primaryKey: '', numberKey: 1, keyCode:null},
        stopSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        stopBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        allClear: {primaryKey: '', numberKey: 1, keyCode:null},
        symbolClear: {primaryKey: '', numberKey: 1, keyCode:null},
        messageAlert: false,
        ordMessageAlert: false,
        clearMessageAlert: false,
        soundAlert: false,
      },

      //체크 설정 디폴트 true, 최근설정 true 화면 체크를 못하여 모두 false
      this.ordMessageAlert = false;
      this.messageAlert = false;
      this.clearMessageAlert = false;
      this.soundAlert = false;

      this.defaultOption = {
        centerKey: '',
        marketSellKey: '',
        marketBuyKey: '',
        cancelSell: '',
        cancelBuy: '',
        stopSellKey: '',
        stopBuyKey: '',
        allClear: '',
        symbolClear: '',
      }

      this.keyOptionList = [];
      if (e.params != undefined && e.params != null){
        //this.loadOption = Object.assign({}, e.params);
        this.loadOption = e.params;
      }
    },    
    opened() {
      //단축키 목록
      this.keyOptionList = [{name:'NONE'},{name:'ALT'}];      

      //설정이 없으면 초기설정값
      if (this.loadOption == null){
        this.orderSetting = {
          oneClick: true,
          dbClick: false,
          slTikVal1: {tikVal:5},
          slTikVal2: {tikVal:5},
          clickVal1: {val:1},
          clickVal2: {val:2},
          clickVal3: {val:3},
          clickVal4: {val:4},
          clickVal5: {val:5},
          centerKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          marketSellKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          marketBuyKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          cancelSell: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          cancelBuy: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          stopSellKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          stopBuyKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          allClear: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          symbolClear: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          messageAlert: true,
          ordMessageAlert: true,
          clearMessageAlert: true,
          soundAlert: true,
        },
        
        this.defaultOption = {
          centerKey: 'NONE',
          marketSellKey: 'NONE',
          marketBuyKey: 'NONE',
          cancelSell: 'NONE',
          cancelBuy: 'NONE',
          stopSellKey: 'NONE',
          stopBuyKey: 'NONE',
          allClear: 'NONE',
          symbolClear: 'NONE',
        }

        this.ordMessageAlert = true;
        this.messageAlert = true;
        this.clearMessageAlert = true;
        this.soundAlert = true;
      }else{
        this.orderSetting = this.loadOption;

        this.ordMessageAlert = this.orderSetting.ordMessageAlert;
        this.messageAlert = this.orderSetting.messageAlert;
        this.clearMessageAlert = this.orderSetting.clearMessageAlert;
        this.soundAlert = this.orderSetting.soundAlert;
        
        this.defaultOption = {
          centerKey: this.orderSetting.centerKey.primaryKey,
          marketSellKey: this.orderSetting.marketSellKey.primaryKey,
          marketBuyKey: this.orderSetting.marketBuyKey.primaryKey,
          cancelSell: this.orderSetting.cancelSell.primaryKey,
          cancelBuy: this.orderSetting.cancelBuy.primaryKey,
          stopSellKey: this.orderSetting.stopSellKey.primaryKey,
          stopBuyKey: this.orderSetting.stopBuyKey.primaryKey,
          allClear: this.orderSetting.allClear.primaryKey,
          symbolClear: this.orderSetting.symbolClear.primaryKey,
        }
      }

    },
    setCustom() {
      //중복체크용
      this.keyCheckList = [];

      //숫자키 이벤트 keyCode 입력
      this.orderSetting.centerKey.keyCode = this.getKeyCode(this.orderSetting.centerKey.numberKey, this.orderSetting.centerKey.primaryKey);
      this.orderSetting.marketSellKey.keyCode = this.getKeyCode(this.orderSetting.marketSellKey.numberKey, this.orderSetting.marketSellKey.primaryKey);
      this.orderSetting.marketBuyKey.keyCode = this.getKeyCode(this.orderSetting.marketBuyKey.numberKey, this.orderSetting.marketBuyKey.primaryKey);
      this.orderSetting.cancelSell.keyCode = this.getKeyCode(this.orderSetting.cancelSell.numberKey, this.orderSetting.cancelSell.primaryKey);
      this.orderSetting.cancelBuy.keyCode = this.getKeyCode(this.orderSetting.cancelBuy.numberKey, this.orderSetting.cancelBuy.primaryKey);
      this.orderSetting.stopSellKey.keyCode = this.getKeyCode(this.orderSetting.stopSellKey.numberKey, this.orderSetting.stopSellKey.primaryKey);
      this.orderSetting.stopBuyKey.keyCode = this.getKeyCode(this.orderSetting.stopBuyKey.numberKey, this.orderSetting.stopBuyKey.primaryKey);
      this.orderSetting.allClear.keyCode = this.getKeyCode(this.orderSetting.allClear.numberKey, this.orderSetting.allClear.primaryKey);
      this.orderSetting.symbolClear.keyCode = this.getKeyCode(this.orderSetting.symbolClear.numberKey, this.orderSetting.symbolClear.primaryKey);

      for(let i=0; i<this.keyCheckList.length; i++){
        let cnt = 0;
        for (let j=0; j<this.keyCheckList.length; j++){
          if (this.keyCheckList[i] == this.keyCheckList[j]){
            cnt++;
          }
        }
        if (cnt > 1){
          this.$alert.alert("단축키는 중복키를 설정할 수 없습니다.", 'Warnning');
          return;
        }
      }

      window.$store.dispatch('Layout/saveCustom', { type: 'OS', custom: this.orderSetting});
      this.$emit('order-setting-complete');
      this.$modal.hide('orderSettingsPop');
    },
    getKeyCode(numKey, primaryKey){
      //숫자키의 keyCode를 반환
      let keyCode = null;
      this.keyCodeList.forEach(item => {
        if (Number(numKey) === Number(item.Number)){
          keyCode = item.keyCode;
          return;
        }
      });

      //중복체크용 리스트 저장
      if (keyCode != null && primaryKey == "ALT"){
        this.keyCheckList.push(keyCode);
      }

      return keyCode;
    },
    close(){
      this.$modal.hide('orderSettingsPop');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  gap: 42px;
  margin-bottom: 20px;
}
.table-row {
  display: flex;
  align-items: center;
  height: 30px;
  gap: 25px;
  &.gap5 {
    gap: 5px;
  }
  .inner-row {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .label {
    color: rgba(255, 255, 255, 0.6);
    width: 68px;
    &.no-width {
      width: unset;
    }
  }
  .content {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    font-weight: 600;
    flex: 1;
    gap: 5px;
  }
}
.subtitle {
  opacity: 0.6;
  margin-bottom: 10px;
}
.col-2 {
  display: flex;
  align-items: center;
  gap: 10px;
  .col {
    flex: 1;
  }
}
</style>
