<template>
  <div class="input-container">
    <div class="input-icon-left">
      <img src="@/assets/icons/search.svg" alt="" />
    </div>
    <input type="text" placeholder="찾기"
      :disabled="disabled"
			:readonly="readonly"
			:class="{error: error}"
			v-model="inputValue" 
      @input="onInput" 
      @keyup.enter="enter" />
    <div class="input-icon-right" v-if="inputValue">
      <BtnDelete @deleteClicked="clearInput" />
    </div>
    
  </div>
</template>

<script>
import BtnDelete from './BtnDelete.vue'
export default {
  name: 'InputCompSearch',
  components: {
    BtnDelete,
  },
  props: {
    value: {
      type: String,
      default: ''      
    },    
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inputValue: '',
    };
  },

  mounted() {
    this.inputValue = this.value      
  },

  methods: {
    onInput: function (e) {
      this.$emit('input', e.target.value)
    },
    clearInput: function () {
      this.inputValue = ''
      this.$emit('input', '')
    },
    enter() {
      this.$emit('event:enter')
    }
	},
};
</script>

<style lang="scss" scoped>
  .input-container {
    position: relative;
    flex: 1;
    width: 100%;
    height: 30px;
    @media (max-width: $mobile) {
      height: 40px;
    }
    .input-icon-left {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }
    input {
      padding-left: 30px;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
    .input-icon-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
</style>
