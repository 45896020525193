<template>
  <div>
    <modal name="modalPrivacy" class="custom-modal" :adaptive="true" :width="width" :height="height">
      <div class="modal-header-terms">
        <h1>개인정보 처리 방침</h1>
        <BaseBtn @click="closeModal" naked>
          <img src="@/assets/icons/close-large.svg" alt="" />
        </BaseBtn>
      </div>

      <div class="modal-body-terms">
        <div class="terms-container">
          <pre>
‘TAP트레이딩 주식회사(이하 “회사”라 함)’는 고객님의 개인정보를 중요시하며, 개인정보보호법 등 국내의 개인정보 보호 법령을 철저히 준수합니다. 회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

회사의 개인정보처리방침은 정부의 법률 및 지침의 변경과 당사의 약관 및 내부 정책에 따라 변경될 수 있으며 이를 개정하는 경우 회사는 변경사항에 대하여 즉시 홈페이지에 게시하도록 하고 있습니다.

1. 개인정보의 처리 목적

회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
1. 회원 가입 및 관리 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
2. 재화 또는 서비스 제공 물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금 결제·정산, 채권추심의 목적으로 개인정보를 처리합니다.


2. 개인정보의 처리 및 보유 기간

① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다. 
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다. 

1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴 시까지 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 
1&#41; 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지 
2&#41; 홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지 
3&#41; &lt;예외사유&gt;시에는 &lt;보유기간&gt;까지 

2. 재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산 완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지 
1&#41; 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록
  - 표시·광고에 관한 기록 : 6개월 - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년
  - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 
2&#41; 「통신비밀보호법」에 따른 통신사실확인자료 보관 
  - 가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년
  - 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
3&#41; 다음 각목의 관계 법령에서 정한 사유에 해당하는 경우 해당 기간 종료시까지

<table class="basic">
	<colgroup>
		<col style="width:40%">
		<col style="width:40%">
	</colgroup>
	<thead>
		<tr>
			<th>구분</th>
			<th>관련법률</th>
			<th>보유기간</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>계약 또는 청약철회 등에 관한 기록</td>
			<td rowspan="3">전자상거래 등에서의 소비자보호에 관한 법률</td>
			<td>5년</td>
		</tr>
		<tr>
			<td>대금결제 및 재화 등의 공급에 관한 기록</td>
			<td>5년</td>
		</tr>
		<tr>
			<td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
			<td>3년</td>
		</tr>
		<tr>
			<td>제세공과금 납부 기록</td>
			<td>국세기본법</td>
			<td>5년</td>
		</tr>
		<tr>
			<td>로그인 기록</td>
			<td>통신비밀보호법</td>
			<td>3개월 이상</td>
		</tr>
	</tbody>
</table>
3. &lt;개인정보 처리업무&gt; : &lt;보유기간&gt;


3. 처리하는 개인정보의 항목

회사는 다음의 개인정보 항목을 처리하고 있습니다. 
1. 회원 가입 및 관리 
  • 필수항목 : 성명, 전화번호, 이메일주소 
2. 재화 또는 서비스 제공 
  • 필수항목 : 성명, 생년월일, 전화번호, 이메일주소, 신용카드번호, 은행 계좌정보 


4. 만 14세 미만 아동의 개인정보 처리에 관한 사항

① 회사는 만 14세 미만 아동에 대해 개인정보를 수집할 때 법정대리인의 동의를 얻어 해당 서비스 수행에 필요한 최소한의 개인정보를 수집합니다. 
  • 필수항목 : 법정 대리인의 성명, 관계, 연락처 
② 또한, 회사의 행상 관련 홍보를 위해 아동의 개인정보를 수집할 경우에는 법정대리인으로부터 별도의 동의를 얻습니다. 
③ 회사는 만 14세 미만 아동의 개인정보를 수집할 때에는 아동에게 법정대리인의 성명, 연락처와 같이 최소한의 정보를 요구할 수 있으며, 다음 중 하나의 방법으로 적법한 법정대리인이 동의하였는지를 확인합니다. 
  • 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 개인정보처리자가 그 동의 표시를 확인했음을 법정대리인의 휴대전화 문자 메시지로 알리는 방법 
  • 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 신용카드·직불카드 등의 카드정보를 제공받는 방법 
  • 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 휴대전화 본인인증 등을 통해 본인 여부를 확인하는 방법 
  • 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편 또는 팩스를 통하여 전달하고 법정대리인이 동의 내용에 대하여 서명날인 후 제출하도록 하는 방법 
  • 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터 동의의 의사표시가 적힌 전자우편을 전송 받는 방법 
  • 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나 인터넷주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차 전화 통화를 통하여 동의를 얻는 방법 
  • 그 밖에 위와 준하는 방법으로 법정대리인에게 동의 내용을 알리고 동의의 의사표시를 확인하는 방법


5. 개인정보의 제3자 제공에 관한 사항

① 개인정보처리자는 다음 각 호의 어느 하나에 해당되는 경우에는 정보주체의 개인정보를 제3자에게 제공(공유를 포함한다. 이하 같다)할 수 있다. 
  1. 정보주체의 동의를 받은 경우
  2. 개인정보보호법 제15조제1항제2호, 제3호 및 제5호부터 제7호까지에 따라 개인정보를 수집한 목적 범위에서 개인정보를 제공하는 경우
② 개인정보처리자는 제1항제1호에 따른 동의를 받을 때에는 다음 각 호의 사항을 정보주체에게 알려야 한다. 다음 각 호의 어느 하나의 사항을 변경하는 경우에도 이를 알리고 동의를 받아야 한다.
  1. 개인정보를 제공받는 자
  2. 개인정보를 제공받는 자의 개인정보 이용 목적
  3. 제공하는 개인정보의 항목
  4. 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간
  5. 동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익이 있는 경우에는 그 불이익의 내용
③ 개인정보처리자는 당초 수집 목적과 합리적으로 관련된 범위에서 정보주체에게 불이익이 발생하는지 여부, 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부 등을 고려하여 대통령령으로 정하는 바에 따라 정보주체의 동의 없이 개인정보를 제공할 수 있다.


6. 개인정보 처리업무의 위탁에 관한 사항

1. 당사는 원칙적으로 고객의 개인정보를 제1조에서 명시한 목적 범위 내에서 처리하며, 고객의 사전 동의 없이는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. 단, 다음의 각 호의 경우에는 고객 또는 제3자의 이익을 부당하게 침해할 우려가 있을 때를 제외하고는 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 수 있습니다.
  가. 고객이 사전에 목적 외 용도로 이용 또는 제3자 제공 및 공개에 동의한 경우
  나. 다른 법률에 특별한 규정이 있는 경우
  다. 고객 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 고객 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우


7.개인정보의 파기 절차 및 방법에 관한 사항

① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 
② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터 베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다. 
  1. 파기절차 은(는) 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인 정보 보호책임자의 승인을 받아 개인정보를 파기합니다. 
  2. 파기방법 은(는) 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.


8. 미이용자의 개인정보 파기 등에 관한 조치

①개인정보처리자는 개인정보 보유기간이 1년 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
②개인정보 파기의 절차 및 방법은 다음과 같습니다.
  1. 파기절차: 개인정보처리자는 파기 사유가 발생한 개인정보를 선정하고, 개인정보처리자의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
  2.파기방법: 개인정보처리자는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.


9. 정보주체와 법정대리인의 권리·의무 행사방법에 관한 사항 

① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다. 
  ※ 만 14세 미만 아동에 관한 개인정보의 열람 등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다. 
② 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다. 
③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. 
④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다. 
⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. 
⑥회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.


10. 개인정보의 안전성 확보조치에 관한 사항

회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. 
1. 관리적 조치 : 내부관리계획 수립·시행, 전담조직 운영, 정기적 직원 교육
2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신 
3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제 
회사는 개인정보의 안전성을 확보하기 위하여 법령에서 규정하고 있는 사항 이외에도 다음과 같은 활동을 시행하고 있습니다. 
1. 개인정보보호 활동: 개인정보보호 관련 블로그·유튜브 운영, 투명성 보고서 발간(연 1회), 공동규제 및 자율규약 참여 현황, 자율규제단체 활동 


11. 개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항

①개인정보처리자는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
②쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장되기도 합니다.
  가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
  나. 쿠키의 설치·운영 및 거부: 웹브라우저마다 다음과 같이 거부 및 차단 할 수 있습니다.
   1&#41; 크롬(Chrome): 상단 맨 오른쪽의 더보기>설정>개인정보 및 보안>쿠키 및 기타 사이트 데이터>모든 사이트 데이터 및 구너한 보기에서 ‘쿠키를 사용할 수 없는 사이트’ 오른쪽의 ‘추가’를 클릭하고 “[*.]abc.com”을 입력합니다. [*.]google.com은 drive.google.com, calendar.google.com, abc.google.com을 모두 포함합니다. 상세한 사항은 구글의 쿠키 차단 도움말을 참고하시기 바랍니다.
   2&#41;인터넷 익스플로러(Internet Explorer): 상단에서 도구>인터넷 옵션>개인정보>고급에서 ‘현재 사이트의 쿠키’아래 ‘차단’을 선택합니다.
   3&#41; 마이크로소프트 엣지(Edge): 상단 맨 오른쪽의 더보기>설정>쿠키 및 사이트 권한>쿠키 및 저장된 데이터에서 ‘차단’오른쪽의 ‘추가’를 클릭하고, “[*.]abc.com”을 입력합니다. [*.]google.com은 drive.google.com, calendar.google.com, abc.google.com을 모두 포함합니다.
  다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.


12. 행태정보의 수집·이용·제공 및 거부 등에 관한 사항

행태정보는 웹사이트 방문 이력, 서비스 사용 이력, 구독 및 검색 이력 등 이용자의 관심, 흥미, 기호 및 성향 등을 파악하고 분석할 수 있는 온라인 상의 이용자 활동정보를 말합니다. ‘회사’는 다음과 같은 행태 정보를 수집합니다.

<table class="basic">
	<!-- <colgroup>
		<col style="width:40%">
		<col style="width:40%">
	</colgroup> -->
	<thead>
		<tr>
			<th>항목</th>
			<th>이용·제공</th>
			<th>목적 보유 및 이용기간</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>웹사이트 방문 이력, 서비스 항목 사용 이력, 구독 및 검색 이력</td>
			<td>시스템 마케팅 및 정기적인 시스템 이용 안내</td>
			<td>회원 탈퇴 시까지 또는 목적 달성 즉시 파기</td>
		</tr>
	</tbody>
</table>
		
13. 추가적인 이용·제공 관련 판단 기준

① 회사는 「개인정보 보호법」 제15조제3항 및 제17조제4항에 따라 「개인정보 보호법」 시행령 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.

<table class="basic">
	<!-- <colgroup>
		<col style="width:40%">
		<col style="width:40%">
	</colgroup> -->
	<thead>
		<tr>
			<th>항목</th>
			<th>이용·제공</th>
			<th>목적 보유 및 이용기간</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>이름, 연락처, 이메일 주소</td>
			<td>시스템 마케팅 및 정기적인 시스템 이용 안내</td>
			<td>회원 탈퇴 시까지 또는 목적 달성 즉시 파기</td>
		</tr>
	</tbody>
</table>
② 이에 따라 회사는 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다. 
  ‣ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부 
  ‣ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부 
  ‣ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부 
  ‣ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부


14. 개인정보 보호책임자에 관한 사항

① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 
  ‣ 개인정보 보호책임자: 경영본부 이남행 /02-2068-9464/privacy@delkoit.co.kr
  ‣ 개인정보보호 담당부서: 개인정보보호팀 /02-2068-9464/privacy@delkoit.co.kr
② 정보주체는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.


15. 개인정보의 열람청구를 접수·처리하는 부서

정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다. 
‣ 개인정보 열람청구 접수·처리 부서: 개인정보보호팀 /02-2068-9464/privacy@delkoit.co.kr


16. 정보주체의 권익침해에 대한 구제방법

① 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다. 
  1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr) 
  2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr) 
  3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr) 
  4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr) 
② 회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다. 
  ‣ 개인정보보호 관련 고객 상담 및 신고: 개인정보보호팀 /02-2068-9464/privacy@delkoit.co.kr
③ 「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다. 
  ‣ 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)


17. 개인정보 처리방침의 변경에 관한 사항

① 이 개인정보 처리방침은 2024. 5. 24일부터 적용됩니다. 
          </pre>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import BaseBtn from '../BaseBtn.vue';
export default {
  components: { BaseBtn },
  name: 'ModalPrivacy',

  data() {
    return {};
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 800;
      this.height = 670;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$emit('event:privacy-complete')
      this.$modal.hide('modalPrivacy')
    },
    
  },
};
</script>