<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="width" viewBox="0 0 16 16" :class="{faceDown:down, faceUp:up, faceLeft:left, faceRight:right}">
    <!-- <path id="bg" d="M16,0V16H0V0Z" transform="translate(0 16) rotate(-90)" fill="none" opacity="0.6"/> -->
		<path id="img" d="M.919,0,4.46,3.54,8,0l.919.919L4.46,5.379,0,.919Z" transform="translate(3.595 12.46) rotate(-90)" :style="{ fill: fill }"/>
		<path id="Vector_276_Stroke_" data-name="Vector 276 (Stroke)" d="M0,0H1.3V8H0Z" transform="translate(11.204 12) rotate(180)" :style="{ fill: fill }"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPageLast',
  props: {
    down: Boolean,
    up: Boolean,
    left: Boolean,
    right: Boolean,
     width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
  &.faceLeft {
    transform: rotate(-180deg);
  }
  &.faceRight {
    transform: rotate(0);
  }
}
</style>
