<template>
  <div class="input-container" :class="{ full: full }" :style="{ width: width + 'px', height: height + 'px' }">
    <input
      :placeholder="placeholder"
      :type="type"
			:maxlength="type === 'password' && !full ? 4 : ''"
      :disabled="disabled"
      :readonly="readonly"
      :class="{ error: error, readonly: readonly }"
      :style="{ textAlign: alignRight ? 'right' : '' }"
      :tabindex="tabIndex"
      v-model="inputValue"
      @input="onInput"
      @keyup.enter="handleEnter"
    />
    <div class="input-icon-right" v-if="inputValue && !readonly && !disabled && !disableDeleteBtn">
      <BtnDelete @deleteClicked="clearInput" />
    </div>
  </div>
</template>

<script>
import BtnDelete from '@/components/BtnDelete.vue'
export default {
  name: 'InputComp',
  components: {
    BtnDelete,
  },
  props: {
    full: Boolean,
    value: [String, Number],
		password: Boolean,
    width: [String, Number],
    alignRight: Boolean,
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disableDeleteBtn: Boolean,
    height: Number,
    max: Number,
    tabIndex: Number,
  },

  data() {
    return { 
      inputValue: '',
    };
  },
  watch: {
    value (newValue, oldValue) {
      this.inputValue = newValue

      if (this.type === 'number' && newValue > this.max) {
        this.inputValue = this.max
      }      
    }
  },  

  mounted() {
    this.inputValue = this.value      
  },

  methods: {
    onInput: function (e) {
      if (this.type === 'number' && e.target.value > this.max) {
        this.inputValue = this.max
      }
      this.$emit('input', e.target.value)
    },
    clearInput: function () {
      this.inputValue = ''
      this.$emit('input', '')
    },
    handleEnter(e) {
      this.$emit('event:enter',e)
    }    
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
  height: 30px;
  @media (max-width: $mobile) {
    height: 40px;
  }
  &.search {
    width: 100%;
    input[type='text'] {
      padding-left: 31px;
      width: 100%;
    }
  }
  &.full {
    display: flex;
    width: 100%;
    input {
      flex: 1;
      width: unset;
    }
  }
  .input-icon-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
  .input-icon-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  input[type='text'],
  input[type='password'] {
    width: 100%;
    height: 100%;
    padding-right: 33px;
    background-color: transparent;
    border-radius: 0;
    &::placeholder {
      color: var(--border-color);
    }
    &:focus {
      border-color: var(--main-orange);
    }
    &:disabled {
      opacity: 0.4;
    }
    &:read-only {
      border-color: #313643;
      color: white;
      padding-right: 10px;
    }
    &.error {
      border-color: var(--main-red);
    }
  }
  input[type='number'] {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-radius: 0;
    &:focus {
      border-color: var(--main-orange);
    }
  }
  /* .error-message {
    position: absolute;
    top: 100%;
    width: fit-content;
    color: var(--main-red);
    background-color: #212736;
    padding: 4px;
    white-space: nowrap;
  } */
  
}
</style>
