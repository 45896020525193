<template>
  <modal name="demoRequestPop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="모의계좌 신청" modalName="demoRequestPop" footer>
      <template #body>
        <div class="table-header">
          <h3 class="table-subtitle">나의 모의계좌 / 가용 계좌 ({{abledAccCnt}}/{{ demoAccCnt }})</h3>
          <BtnSub text="업그레이드" @click="goPlan" />
        </div>
        <div class="table-wrapper">

          <validation-observer ref="validatorFutures" class="row">
            <BtnRadio id="radio_accDiv1" name="radio_accDiv" text="해외선물" value="ACCDIV02" checked @click="changeAccDiv" />
            <div class="row-item">
              <h3 class="label">계좌명</h3>
              <validation-provider rules="required|limit:6" name="계좌명" v-slot="{errors}">
                <InputComp placeholder="계좌명" :error="errors[0] ? true: false" v-model="futuresForm.accNm" />
                <div class="error-message"><h3>{{errors[0]}}</h3></div>
              </validation-provider>
            </div>
            <div class="row-item">
              <h3 class="label">비밀번호</h3>
              <validation-provider rules="required|numeric|limit:4" name="비밀번호" v-slot="{errors}">
                <InputComp type="password" :error="errors[0] ? true: false" v-model="futuresForm.accPass" />
                <div class="error-message"><h3>{{errors[0]}}</h3></div>
              </validation-provider>
            </div>
            <div class="row-item">
              <h3 class="label">투자원금</h3>
              <customDropDown width="82" :options="invPrinCdList" v-model="futuresForm.invPrinCd" popup />
            </div>
            <div class="row-item">
              <h3 class="label">투자기간</h3>
              <codeDropDown width="82" grpCd="INVPER" v-model="futuresForm.invPerCd" popup />
            </div>
          </validation-observer>  

        </div>
        <ul class="info-list">
          <li>해외선물계좌는 투자기간 만료 후 1개월 이내 갱신요청이 없을 시 자동 삭제됩니다.</li>
          <li>모의계좌의 개수는 레벨에 따라 제한되며, 개수를 늘리기 위해서는 레벨 업데이트가 필요합니다.</li>
        </ul>
      </template>
      <template #footer>
        <BtnSub text="취소" @click="close" />
        <BtnPrimary text="신청" :disabled="disabled" @click="save" />
      </template>      
    </ModalContent>
  </modal>
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import InputComp from '@/components/InputComp.vue'
import BtnRadio from '@/components/BtnRadio.vue'
import CustomDropDown from '@/components/CustomDropDown.vue'
import CodeDropDown from '@/components/CodeDropDown.vue'
import Const from '@/const'

export default {
  name: 'ModalDemoRequest',
  components: {
    ModalContent,
    BtnSub,
    BtnPrimary,
    InputComp,
    BtnRadio,
    CustomDropDown,
    CodeDropDown
  },
  data() {
    return {
      accDivCd: 'ACCDIV02',
      futuresForm: {
        accNm: '',
        accPass: '',
        invPrinCd: 'INVPRIN01',
        invPerCd: '',
        accDivCd: 'ACCDIV02'
      },
      disabled: true,
      abledAccCnt : 0
    }
  },

  mounted() {},

  computed: {
    // 모의 계좌 수
    demoAccCnt() {
      const loginUserInfo = window.$store.getters['Login/getLoginUserInfo']

      if ( loginUserInfo ) {
        return loginUserInfo.demoAccCnt
      } else {
        return 2
      }
    },
    // 투자원금 목록
    invPrinCdList() {
      const loginUserInfo = window.$store.getters['Login/getLoginUserInfo']

      if ( loginUserInfo ) {
        if ( loginUserInfo.planId === Const.PLAN_TYPE.PLUS ) {
          return [{code: 'INVPRIN01', name: '5천만원'}, {code: 'INVPRIN02', name: '1억원'}]
        } else if ( loginUserInfo.planId === Const.PLAN_TYPE.PREMIUM ) {
          return [{code: 'INVPRIN01', name: '5천만원'}, {code: 'INVPRIN02', name: '1억원'}, {code: 'INVPRIN03', name: '2억원'}]
        } else {
          return [{code: 'INVPRIN01', name: '5천만원'}]  
        }
      } else {
        return [{code: 'INVPRIN01', name: '5천만원'}]
      }      
    }
  },  

  methods: {
    beforeOpen(e) {  
      this.futuresForm.accNm = ''
      this.futuresForm.accPass = ''
      this.futuresForm.invPrinCd = 'INVPRIN01'
      this.futuresForm.invPerCd = ''
      this.futuresForm.accDivCd = 'ACCDIV02'
    },    
    opened() {
      this.$lhttp
      .get('/api/account/count')
      .then((response) => {
        if (response) {
          this.abledAccCnt = response.data

          if ( response.data >= this.demoAccCnt ) {
            this.$modal.show('upgradePop')
          } else {
            this.disabled = false
          }
        }
      })        
    },        
    close() {
      this.$modal.hide('demoRequestPop')
    },    
    goPlan() {
      this.$router.push('/plan')
    },     
    changeAccDiv(value) {
      this.accDivCd = value

      const { validatorFutures, validatorEvent } = this.$refs

      if ( this.accDivCd === 'ACCDIV03' ) {
        validatorFutures.reset()
      } else {
        validatorEvent.reset()
      }
    },
    save() {
      const { validatorFutures, validatorEvent } = this.$refs

      validatorFutures.validate().then((result) => {
        if (result) {

          this.$alert.confirm('해외선물 모의계좌를 신청하시겠습니까?', '확인')
            .then((response) => {
              if (response) {
                this.$http
                  .post('/api/account/request',
                    this.futuresForm)
                  .then(() => {
                    this.$alert.alert('해외선물 모의계좌 신청이 완료되었습니다.', '안내').then(() => {
                      // 계좌목록 갱신
                      this.$parent.$refs.panel1.$refs.accountDropdownMain.selectAccountList()
                      this.close()
                    })
                  })
              }
            })

        } else {
          this.$alert.alert('먼저 입력 항목을 확인 하십시오.', 'Warnning')            
        }
      })

    }
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
	.table-subtitle {
		opacity: 0.6;
	}
}
.row {
  display: grid;
  grid-template-columns: 80px 151px 124px 140px 140px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
	.row-item {
		.label {
			opacity: 0.6;
		}
	}
}
.table-wrapper {
	
	margin-top: 10px;
}
.info-list {
	margin-top: 30px;
}
</style>
