<template>
  <div class="radio">
    <!-- eslint-disable-next-line vue/no-mutating-props -->
    <input type="radio" :id="id" :name="name" :value="value" :disabled="disabled" @click="clickRadio" :checked="checked" />
    <label :for="id">
      <h3>{{ text }}</h3>
    </label>
  </div>
</template>

<script>

export default {
  name: 'BtnRadio',
  props: {
    text: {
      type: String,
      default: 'name me',
    },
    id: String,
    name: String,
    value: String,
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelName: '',
      isChecked: '',      
    }
  },
  mount() {
  },
  methods: {
    clickRadio(e) {
      //console.log('value: ' + this.modelName + 'Checked', this.modelName);
      //this.$emit(this.modelName + 'Checked', this.modelName)
      this.$emit('click', e.target.value)
    },
  }
};
</script>

<style lang="scss" scoped>
.radio {
  display: flex;
  align-items: center;
  input[type='radio'] {
    display: none;
    & + label {
      display: inline-block;
      position: relative;
      padding-left: 21px;
      cursor: pointer;
      height: 16px;
      line-height: 1.24;
      @media (hover: hover) {
        &:hover {
          opacity: 0.7;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 51%;
        transform: translateY(-50%);
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/icons/radio-off.svg');
        background-size: 16px 16px;
      }
    }
    &:checked {
      & + label {
        &::before {
          background-image: url('@/assets/icons/radio-on.svg');
        }
      }
    }
  }
}
</style>
