<template>
  <div class="panel">
    <div class="row between gap10">
      <div class="inner">
        <div class="row-item">
          <div class="radio-group">
            <div class="label">
              <BtnRadio id="pending" :name="orderType" text="미체결" @click="pendingChecked" checked />
              <BtnRadio id="filled" :name="orderType" text="체결" @click="filledChecked" />
              <BtnRadio id="all" :name="orderType" text="전체" @click="allChecked" />
            </div>
          </div>
        </div>
        <div class="row-item">
          <div class="radio-group">
            <div class="label">
              <BtnRadio id="descending" name="orderDisplay" text="역순" @click="descendingChecked" checked />
              <BtnRadio id="ascending" name="orderDisplay" text="순" @click="ascendingChecked"  />
            </div>
          </div>
        </div>
        <div class="row-item">
          <DropDown id="symbolDropdown" 
            :options="symbolList" 
            default="전체" 
            width="60" 
            @symbolDropdown="symbolDropdown" />
        </div>
        <div class="row-item">
          <BtnSub text="선택취소" width="70" @click="cancelSelect" />
          <BtnSub text="개별정정" width="70" @click="correctionOrder" />
        </div>
      </div>
      <GnbDropDown right>
        <template #gnb-title>
          <div class="panel-menu-container">
          <img src="@/assets/icons/ellipsis-menu.svg" alt="" />
            <div class="tool-tip"><h3>열 설정</h3></div>
          </div>
        </template>
        <template #dropdown-list>
          <h4 class="dropdown-subtitle">열 설정</h4>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="ordTm" text="주문시간" v-model="grid.custom.ordTm" @click="setCustom" />
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="symCd" text="종목코드" v-model="grid.custom.symCd" @click="setCustom" />
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="symKorNm" text="종목명" v-model="grid.custom.symKorNm" @click="setCustom" />
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="ordSdNm" text="구분" v-model="grid.custom.ordSdNm" @click="setCustom" />
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="ordTpNm" text="유형" v-model="grid.custom.ordTpNm" @click="setCustom" />
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="ordQty" text="주문수량" v-model="grid.custom.ordQty" @click="setCustom" />
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="ordPrc" text="주문가" v-model="grid.custom.ordPrc" @click="setCustom" />
            </div>
          </div>          
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="execQty" text="체결수량" v-model="grid.custom.execQty" @click="setCustom" />
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="execPrc" text="체결가" v-model="grid.custom.execPrc" @click="setCustom" />
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="ordId" text="주문NO" v-model="grid.custom.ordId" @click="setCustom" />
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <BtnCheckbox id="orgHstOrdNo" text="원주문NO" v-model="grid.custom.orgHstOrdNo" @click="setCustom" />
            </div>
          </div>
        </template>
      </GnbDropDown>
    </div>
    <div class="table-container">    
      <ag-grid-vue
        style="width: 100%; height: 100%"
        class="ag-theme-delkoit"
        alwaysShowHorizontalScroll="true"
        :defaultColDef="grid.defaultColDef"
        :columnDefs="grid.columnDefs"
        :headerHeight="30"
        :rowHeight="30"
        :rowData="grid.rowData"
        :overlayNoRowsTemplate="grid.overlayNoRowsTemplate"
        :enableBrowserTooltips="true"
        @grid-ready="onGridReady">
      </ag-grid-vue>
    </div>

    <changeOrderPop @update-order-complete="editOrder" />
  </div>
</template>

<script>
import BtnRadio from '@/components/BtnRadio.vue'
import BtnCheckbox from '@/components/BtnCheckbox.vue'
import BtnSub from '@/components/BtnSub.vue'
import GnbDropDown from '@/components/GnbDropDown.vue'
import DropDown from '@/components/DropDown.vue'

import { AgGridVue } from 'ag-grid-vue'; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import AgCheckbox from '@/components/grid/AgCheckbox.vue'
import CustomHeader from '@/components/grid/CustomHeader.vue'
import {buySellType} from '@/modules/grid-utils.js'

import EventBus from '@/modules/event-bus'

import ChangeOrderPop from '@/pages/main/ChangeOrderPop.vue'
import gridUtils from '@/modules/grid-utils'
import { stringCount } from '@/modules/helper'
import Utils from '@/modules/utils'

export default {
  name: 'PanelComp4',
  props: {
    setSymbolList: {
      type: Array
    },
    ovcData : {
      type: Array
    },
    selectChgComp4 : {
      type: Number
    },
    account: {
      type: Object
    },
  },
  computed: {
    getUsrAccPass(){
      const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))
      if (accPassObj == undefined && !this.account.accPass){
        //저장된 계좌비번이 없으면
        return "";
      }else if (accPassObj != undefined && accPassObj[this.account.accNo] && !this.account.accPass){
        //저장된 계좌비번
        return accPassObj[this.account.accNo];
      }else if (this.account.accPass){
        //비밀번호를 직접입력
        return this.account.accPass;
      }else{
        return "";
      }
    },
  },
  watch:{
    setSymbolList: {
      handler(newSymbolList){
        if (newSymbolList != null) {
          
          this.symbolList = [];
          const allData = {name:"전체"};
          this.symbolList.push(allData);
          newSymbolList.forEach(item =>{
            this.symbolList.push(item);
          })

          this.search(this.symbol);
        }
      }
    },
    selectChgComp4:{
      handler(val){
        if (val != null && val != 0){
          this.search(this.symbol);
        }
      },
      deep: true,
    },
    account: {
      handler(val){
        this.grid.rowData = [];
        if (val.accPass && val.passYn != undefined && val.passYn == "Y"){
          this.search();
        }
      },
      deep: true,
    },
  },
  components: {
    BtnRadio,
    BtnCheckbox,
    BtnSub,
    GnbDropDown,
    DropDown,
    AgGridVue, // Add AG Grid Vue3 component
    // eslint-disable-next-line vue/no-unused-components
    AgCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    agColumnHeader: CustomHeader,    
    ChangeOrderPop
  },
  data() {
    return {
      symbolList: [],
      orderType: 'all',
      gridSearch: {
        ordStatType: '2',
        ordSortType: 'DESC'
      },
      grid: {
        columnDefs: [
          {
            headerName: '',
            headerComponentParams: {
              enableMenu: true,
              checkBoxId:'PannelComp4Header', // checkbox 이름이 안겹치도록 다르게 설정
              clicked: (chkVal) => { // customHeader.vue 에서 전달하는 리턴 클릭, 리턴값 (true, false)
                if (this.gridSearch.ordStatType != "2"){
                  document.getElementById("PannelComp4Header").checked = false; //그리드 헤더 전체 체크 해제
                  this.$alert.alert("미체결 주문탭만 이용할 수 있습니다.", '알림');
                  return;
                }
                if (chkVal){
                  this.selectList = [];
                  this.grid.rowData.forEach(data => {
                    data.checked = true;
                    this.selectList.push(data);
                  });
                }else{
                  this.selectList = [];
                  this.grid.rowData.forEach(data => {
                    data.checked = false;
                  });
                }
                this.gridApi.redrawRows();
              },
            },
            field: 'ordCheckBox',
            resizable: false,
            //flex: 0.55,
            width:35,
            cellRenderer: 'AgCheckbox',
            cellRendererParams: {
              clicked: (chkVal, params) => {
                document.getElementById("PannelComp4Header").checked = false; //그리드 헤더 전체 체크 해제
                if (chkVal){
                  if (this.gridSearch.ordStatType != "2"){
                    params.node.data.checked = false;
                    this.gridApi.redrawRows();
                    this.$alert.alert("미체결 주문탭만 이용할 수 있습니다.", '알림');
                    return;
                  }
                  this.selectList.push(params.node.data);
                }else{
                  this.selectList.forEach((node, index) => {
                    if (node.ordId == params.node.data.ordId){
                      this.selectList.splice(index, 1);
                      return;
                    }
                  })
                }
              },
            },
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '주문시간',
            field: 'ordTm',
            resizable: false,
            //flex: 1,
            width:72,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '종목코드',
            field: 'symCd',
            resizable: false,
            //flex: 1,
            width:72,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '종목명',
            field: 'symKorNm',
            resizable: false,
            //flex: 1.1,
            width:72,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '구분',
            field: 'ordSdNm',
            // maxWidth: 60,
            resizable: false,
            //flex: 1,
            width:72,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
            cellClassRules: buySellType,
          },
          {
            headerName: '유형',
            field: 'ordTpNm',
            resizable: false,
            //flex: 1,
            width:72,
            cellStyle: {
              textAlign: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '주문수량',
            field: 'ordQty',
            resizable: false,
            //flex: 0.85,
            width:72,
            type: 'rightAligned',
            headerClass: 'right-aligned-header',
            cellRenderer : gridUtils.numberComma,
          },
          {
            headerName: '주문가',
            field: 'ordPrc',
            resizable: false,
            //flex: 1.1,
            width:72,
            headerClass: 'right-aligned-header',
            type: 'rightAligned',
            valueFormatter: (d) =>{
              return d.value.toFixed(this.tikDotSz(d.data.symNo));
            },
          },
          {
            headerName: '체결수량',
            field: 'execQty',
            resizable: false,
            //flex: 0.85,
            width:72,
            type: 'rightAligned',
            headerClass: 'right-aligned-header',
            cellRenderer : gridUtils.numberComma,
          },
          {
            headerName: '체결가',
            field: 'execPrc',
            resizable: false,
            //flex: 1.1,
            width:72,
            headerClass: 'right-aligned-header',
            type: 'rightAligned',
            valueFormatter: (d) =>{
              return d.value.toFixed(this.tikDotSz(d.data.symNo));
            },
          },          
          {
            headerName: '주문NO',
            field: 'ordId',
            resizable: false,
            //flex: 1.1,
            width:70,
            type: 'rightAligned',
            headerClass: 'right-aligned-header',
          },
          {
            headerName: '원주문NO',
            field: 'orgHstOrdNo',
            resizable: false,
            //flex: 1.1,
            width:70,
            type: 'rightAligned',
            headerClass: 'right-aligned-header',
          },                    
        ],
        rowData: [],
        overlayNoRowsTemplate: '<span style="margin-top: 25px;">조회된 체결/미체결 목록이 없습니다.</span>',
        autoSizeStrategy: null,        
        custom : {
          ordTm: true,
          symCd: true,
          symKorNm: true,
          ordSdNm: true,
          ordTpNm: true,
          ordQty: true,
          ordPrc: true,
          execQty: true,
          execPrc: true,
          ordId: true,
          orgHstOrdNo: true,
        },            
      },
      //gridApi: null,
      //gridColumnApi: null,
      //isGridReady: false,
      symbol: {},
      cellCheckboxNum: 0,
      selectList:[],
      selectSymbol: null,
      selectOvcData: null,
    };
  },
  created() {
    // 
    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom()
    })
  },  

  mounted() {
    this.loadCustom()
  },

  methods: {
    tikDotSz(symNo){
      let pricescale = 0;
      this.symbolList.forEach(item => {
        if (symNo == item.symbolno){
          
          pricescale = item.pricescale;
          return;
        }
      })
      
      return stringCount(pricescale)
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      this.isGridReady = true
      this.setGridCustomVisible()
    },
    async search(symbol) {
      if (this.grid.rowData.length == 0){
        //this.gridApi.showLoadingOverlay()
      }

      if (this.account == undefined || this.account.accId == undefined){
        return;
      }

      let symbolCd = "";
      this.selectList = []; //셀렉트 목록 초기화
      document.getElementById("PannelComp4Header").checked = false; //그리드 헤더 전체 체크 해제
      if (symbol != null && symbol.name != undefined && symbol.name != '전체'){
        symbolCd = symbol.name;
      }
      
      let params = {accId: this.account.accId, symCd: symbolCd, ordStatType: this.gridSearch.ordStatType, ordSortType: this.gridSearch.ordSortType}
      const response = await this.$http.get('/api/order/ord-hst/list', { params: params })
      if ( response && response.data != undefined ) {

        document.getElementById("PannelComp4Header").checked = false; //그리드 헤더 전체 체크 해제
        this.grid.rowData = [];
        this.selectList = [];

        response.data.forEach((data) => {
          data.checkBoxId = "comp4_"+data.ordId;
        });
        this.grid.rowData = response.data;


        // if (response.data == null || response.data.length == 0){
        //   this.grid.rowData = [];
        // }else{
        //   response.data.forEach((data) => {
        //     data.checkBoxId = "comp4_"+data.ordId;
        //   });

        //   if (this.grid.rowData.length > 0){
        //     let chkCnt = 0;
        //     this.grid.rowData.forEach((data, index) => {
        //       chkCnt=0;
        //       response.data.forEach(item => {
        //         if (data.ordId == item.ordId){
        //           chkCnt++;
        //           return;
        //         }
        //       });

        //       //없는 종목 삭제
        //       if (chkCnt == 0){
        //         this.grid.rowData.splice(index, 1);
                
        //         //체크박스 설정 목록 삭제
        //         this.selectList.forEach((selData, selIndex) => {
        //           if (selData.ordId == data.ordId){
        //             this.selectList.splice(selIndex, 1);
        //             return;
        //           }
        //         });
        //       }
        //     });

            
        //     response.data.forEach(item => {
        //       chkCnt = 0;
        //       this.grid.rowData.forEach(data => {
        //         if (item.ordId == data.ordId){
        //           chkCnt++;
        //           return;
        //         }
        //       });

        //       //없는 종목 추가
        //       if (chkCnt == 0){
        //         this.grid.rowData.push(item);
        //         document.getElementById("PannelComp4Header").checked = false; //그리드 헤더 전체 체크 해제
        //       }
        //     });

        //     if (this.selectList.length == 0){
        //       document.getElementById("PannelComp4Header").checked = false; //그리드 헤더 전체 체크 해제
        //     }
        //   }else{
        //     this.grid.rowData = response.data;
        //   }
        // }

        
      }
    },
    setGridCustomVisible() {
      const grid  = this.grid
      const gridColumnApi = this.gridColumnApi

      Object.keys(grid.custom).forEach(function(key){
        gridColumnApi.setColumnVisible(key, grid.custom[key])
      })              
    },    
    loadCustom() {
      const custom = window.$store.getters['Layout/getOrdHstGridCustom']

      if ( custom !== undefined && custom !== null ) {
        this.grid.custom = custom
      }

      if ( this.isGridReady ) {
        this.setGridCustomVisible()
      }        
    },    
    setCustom() {
      window.$store.dispatch('Layout/saveCustom', { type: 'HS', custom: this.grid.custom })
      this.loadCustom()
    },    
    cancelSelect() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }
      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }
      if (this.selectList.length == 0){
        this.$alert.alert("선택한 주문이 없습니다.", '알림');
        return;
      }else if (this.gridSearch.ordStatType != "2"){
        this.$alert.alert("미체결 주문탭만 이용할 수 있습니다.", '알림');
        return;
      }

      this.$alert.confirm('선택한 미체결 주문을 취소하시겠습니까?', '취소 확인')
        .then((response) => {
          if (response) {
            const dataParam = {selectList: this.selectList, apiCode:"OMS0012"};
            //this.$emit("sendOrder", dataParam);
            const order = {
              usrKey: Utils.getUuidv4(),
              accId : this.account.accId,
              symCd : this.symbol.name,
              symNo : this.symbol.symbolno,
              trdDt : '',
              delList : this.selectList,
              accPass : this.getUsrAccPass,
            }
            this.sendOmsMessage('OMS0012', order)
          }
        })
    },
    correctionOrder() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }
      if (!this.getUsrAccPass){
        window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
        return;
      }
      if (this.selectList == null || this.selectList.length == 0){
        this.$alert.alert("선택한 주문이 없습니다.", '알림');
        return;
      }else if (this.selectList.length != 1){
        this.$alert.alert("개별정정은 1건씩만 처리 가능합니다.", '알림');
        return;
      }else if (this.selectList[0].ordTpCd == "2"){
        this.$alert.alert("시장가 주문은 주문가격을 수정할 수 없습니다.", '알림');
        return;
      }
      
      //symbol
      this.selectSymbol = null;
      this.symbolList.forEach(item =>{
        if (this.selectList[0].symCd == item.name){
          this.selectSymbol = item;
          return;
        }
      });

      //ovc
      // this.selectOvcData = null;
      // this.ovcData.forEach(item=>{
      //   if (this.selectList[0].symCd == item.symCd){
      //     this.selectOvcData = item;
      //   }
      // });

      // console.log("selectSymbol    ", this.selectSymbol);

      const paramData = {list:this.selectList, symbol:this.selectSymbol}

      this.$modal.show('changeOrderPop', paramData);
    },
    editOrder(uptOrd){ //정정주문 전송
      this.selectList = [];
      this.selectList.push(uptOrd)
      // const dataParam = {selectList: this.selectList, apiCode:"OMS0003"};
      //this.$emit("sendOrder", dataParam);

      let orgOrders = new Array()

      const orderItem = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.selectList[0].symCd,
        symNo : this.selectList[0].symNo,
        trdDt : this.selectList[0].trdDt,
        ordId : 0,
        orgOrdNo: this.selectList[0].ordId,
        ordSdCd : this.selectList[0].ordSdCd,
        ordTpCd : this.selectList[0].ordTpCd,
        ordPrc : this.selectList[0].editPrc,
        ordQty : this.selectList[0].ordQty,
        repReqQty : this.selectList[0].ordQty,
        cxlReqQty : 0,
        orgUsrKey : this.selectList[0].usrKey
      }

      orgOrders.push(orderItem)

      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          accPass : this.getUsrAccPass,
          orderList : orgOrders,
          selectMod : 'Y'
      }

      this.sendOmsMessage('OMS0003', order)
    },
    sendOmsMessage(apiCode, body) {
      if ( !window.$store.getters['SocketOms/getIsConnectOms'] ) {
        window.$alert.alert('OMS 서버 연결 종료.')
        return
      }

      //비밀번호 추가
      body.accPass = this.getUsrAccPass;

      window.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body})
    },  
    pendingChecked(value) {
      if (this.gridSearch.ordStatType != "2"){
        this.grid.rowData = [];
        this.gridSearch.ordStatType = "2";
        this.search(this.symbol);
      }
    },
    filledChecked(value) {
      if (this.gridSearch.ordStatType != "1"){
        this.grid.rowData = [];
        this.gridSearch.ordStatType = "1";
        this.search(this.symbol);
      }
    },
    allChecked(value) {

      if (this.gridSearch.ordStatType != "3"){
        this.grid.rowData = [];
        this.gridSearch.ordStatType = "3";
        this.search(this.symbol);
      }
    },
    descendingChecked(value) {
      if (this.gridSearch.ordSortType != "DESC"){
        this.grid.rowData = [];
        this.gridSearch.ordSortType = "DESC";
        this.search(this.symbol);
      }
    },
    ascendingChecked(value) {

      if (this.gridSearch.ordSortType != "ASC"){
        this.grid.rowData = [];
        this.gridSearch.ordSortType = "ASC";
        this.search(this.symbol);
      }
    },
    //종목셀렉트 선택
    symbolDropdown(value) {
      if (this.symbol.name != value.name){
        this.grid.rowData = [];
        this.symbol = value;
        this.search(this.symbol);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  width: 100%;
  /* height: 122px; */
  margin-top: 10px;
  @media (min-height: 500px) and (max-height: 1000px) {
    height: 92px;
  }
  @media (min-height: 1000px) and (max-height: 1024px) {
    height: 122px;
  }
  /* @media (max-height: 1100px) {
    height: 152px;
    
  } */
}

@import '@/assets/scss/agGridStyles.scss';
</style>
