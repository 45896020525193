<template>
  <modal name="layoutCreatePop" class="custom-modal" adaptive :minWidth="400" :minHeight="216" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">
    <ModalContent text="새로운 레이아웃 만들기" modalName="layoutCreatePop" footer>
      <template #body>
        <div class="container">
          <h3>새 레이아웃 이름을 넣으세요.</h3>
          <validation-observer ref="validator">
            <validation-provider rules="required|limit:40" name="레이아웃명" v-slot="{errors}">
              <InputComp placeholder="레이아웃명을 입력 해 주십시오." :error="errors[0] ? true: false" v-model="form.layNm" />
              <div class="error-message"><h3>{{errors[0]}}</h3></div>
            </validation-provider>
          </validation-observer>
        </div>          
      </template>
      <template #footer>
        <BtnSub text="취소" @click="close" />
        <BtnPrimary text="저장" :disabled="disabled" @click="save" />
      </template>
    </ModalContent>
  </modal>
</template>  

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputComp from '@/components/InputComp.vue'
import BtnSub from '@/components/BtnSub.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import Utils from '@/modules/utils'

export default {
  name: 'LayoutCreatePop',
  components: {
    ModalContent,
    InputComp,
    BtnSub,
    BtnPrimary,
  },
  data() {
    return {
      form: {
        layNm: '',
        layCont: ''
      },
      disabled: true
    }
  },

  mounted() { },

  computed: {
    // 레이아웃 수
    layCnt() {
      const loginUserInfo = window.$store.getters['Login/getLoginUserInfo']

      if ( loginUserInfo ) {
        return loginUserInfo.layCnt
      } else {
        return 2
      }
    },
  },  

  methods: {
    beforeOpen(e) {  
      Utils.initObject(this.form, false)
      this.disabled = true
    },    
    opened() {
      this.$lhttp
      .get('/common/layout/count')
      .then((response) => {
        if (response) {
          if ( response.data >= this.layCnt ) {
            this.$modal.show('upgradePop')
          } else {
            this.disabled = false
          }
        }
      })          
    },
    close() {
      this.$modal.hide('layoutCreatePop')
    },
    save() {
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {

          this.$alert.confirm('레이아웃을 저장 하시겠습니까?', '확인')
            .then((response) => {
              if (response) {
                this.form.layCont = JSON.stringify(window.$store.getters['Layout/getLayout'])

                this.$http
                  .post('/common/layout/save',
                      this.form)
                  .then(() => {
                      this.$alert.alert('레이아웃 저장이 완료되었습니다.', '안내').then(() => {
                      this.$emit('event:saveLayout')
                      this.close()
                      })
                  })
              }
            })      

        } else {
          this.$alert.alert('먼저 입력 항목을 확인 하십시오.', 'Warnning')
        }

      })
    }
  },
}
</script>    

<style lang="scss" scoped>
.container {
  /* height: 100%; */
  margin-bottom: 20px;
}
h3 {
  opacity: 0.6;
  margin-bottom: 10px;
}
</style>