<template>
  <div id="app">
    
    <router-view :reloadPanelComp="reloadPanelComp" :reloadNotice="reloadNotice"/>

    <!--<notifications group="notification" width="400" position="top right" /> -->
    <notifications group="notification" :duration="3000" :width="300" position="bottom right">
      <template slot="body" slot-scope="props">
        <div class="notification-container">
          <div class="notification-header">
            <h1>알림</h1>
            <BaseBtn naked @click="props.close" class="close-btn">
              <IconClose :width="24" :height="24" />
            </BaseBtn>
          </div>

          <div class="notification-body" v-html="props.item.text"></div>

          <div class="notification-footer">
            <BtnPrimary text="확인" @click="props.close" width="80" />
          </div>
        </div>
      </template>
    </notifications>

  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import BaseBtn from '@/components/BaseBtn.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import IconClose from '@/components/icons/IconClose.vue'
import EventBus from "@/modules/event-bus"
import Const from '@/const'

export default {
  name: 'App',
  components: {
    BaseBtn,
    BtnPrimary,
    IconClose
  },
  data() {
    return {
      socket: null, 
      isConnect: false,        
      loginCheckTimer: null, 
      reloadPanelComp: 1,
      reloadNotice: 1,
    }
  },
  created () {

    // 새로고침 처리
    this.refreshLogin()

    // 로그인 처리
    window.$store.watch(() => window.$store.getters['Login/isLogin'], isLogin => {
      if (isLogin) {
        if ( this.loginCheckTimer === null ) {
          this.loginCheckTimer = setInterval(this.refreshLogin, 1800000)
        }

        this.connect()
      } else {
        if ( this.loginCheckTimer !== null ) {
          clearInterval(this.loginCheckTimer)
          this.loginCheckTimer = null
        }

        this.disconnect()
      }
    })

  },
  mounted () {

  },
  methods: {
    refreshLogin() {
      const xDkToken = this.$sessionStorage.get('xDkToken')

      if ( xDkToken !== null && xDkToken !== '' ) {
        window.$store.dispatch('Login/refresh')
      }
    },
    connect() {
      const websocketPath = process.env.VUE_APP_MESSAGE_SOCKET_BASE_URL

      if(this.socket && this.socket.readyState == WebSocket.OPEN){       
          this.socket.close()
      }
      
      this.socket = new WebSocket(websocketPath)

      this.socket.onopen = () => {
        this.isConnect = true
        
        this.subscribeOnMessage()
        setInterval(() => {
          this.sendHeartBeat()
        }, 50000)
      }         

      this.socket.onmessage = (e) => {
        const returnData = JSON.parse(e.data)

        if (returnData.header.apiCode == Const.MSG_API_TYPE.BANK){
           this.reloadPanelComp = this.reloadPanelComp + 1

        //}else if (returnData.header.apiCode == 'MSG0001'){
        //   this.reloadNotice = this.reloadNotice + 1
        } else if ( returnData.header.apiCode == Const.MSG_API_TYPE.LOGOUT ) {

          window.$store.dispatch('Login/logout')

          this.$alert.alert('다른 브라우저에서 로그인하여 <span style="color:red;">로그아웃</span> 되었습니다.<br/><br/>로그인을 다시 시도하시거나 사용을 종료 해 주십시오.', '안내')
          .then(() => {
            this.$router.push('/login')
          })          

          return
        }

        // 알림 재조회
        EventBus.$emit('Alarm:event:refresh', {})

        //
        this.$notify({
          group : 'notification',
          text : '<span style="color: var(--main-red)">알림</span> '+ returnData.body.msg
        })

      }      
      
      this.socket.onerror = () => {
        this.isConnect = false
        console.error("[Error] socket Error")
      }

      this.socket.onclose = () => {
        this.isConnect = false
        console.error("[OnClose] socket close")
        setTimeout(() => {
            this.connect()
        }, 1000)
      }       

    },    
    disconnect() {
      if (this.isConnect) {
        this.socket.onclose = function () {}
        this.socket.close()
        this.isConnect = false 
      }
    },  
    subscribeOnMessage() {

      // 소켓 구독
      if ( this.isConnect ) {
        const reqData = {
          header: {
            apiCode : 'MSG9999',
            token : this.$sessionStorage.get('xDkToken'),
          },
        }  

        this.socket.send(JSON.stringify(reqData))
      }
      
    },
    sendHeartBeat() {
      if ( this.isConnect ) {
        this.socket.send(JSON.stringify({header: {apiCode : 'MSG0000'}}))
      }
    },        
  }  
}
</script>

<style lang="scss">
@import '@/assets/scss/_reset.scss';
@import '@/assets/scss/_variables.scss';

@font-face {
  font-family: 'Malgun';
  font-style: normal;
  font-weight: 300;
  src: url('@/assets/fonts/malgunsl.woff') format('woff'),
}

@font-face {
  font-family: 'Malgun';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/malgun.woff') format('woff'),
}

@font-face {
	font-family: 'Malgun';
	font-style: normal;
	font-weight: 600;
	src: url('@/assets/fonts/malgunbd.woff') format('woff'),
}

@import '@/assets/scss/styles.scss';

.notification-container {
  background-color: #212736;
}
.notification-header {
  /* height: 60px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
  .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
  }
  // @media (max-width: $mobile) {
  //   padding: 5px 20px;
  //   h1 {
  //     /* font-size: 14px; */
  //     display: none;
  //   }
  // }
}
.notification-body {
  padding: 0 20px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  // @media (max-width: $mobile) {
  //   text-align: left;
  // }
}
.notification-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
  // @media (max-width: $mobile) {
  //   padding: 5px 20px;
  //   button {
  //     display: none;
  //   }
  // }
}
</style>


