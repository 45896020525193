<template>
  <div class="base-dropdown" :id="id" :style="{ width: width + 'px', height: height + 'px', }" :class="{ full: full }" ref="dropdown">
    <div class="dropdown-title" @click="handleDropdown" :class="{ active: isDropdownVisible }" :style="{ height: height + 'px' }">
      <h3>
        {{ selectedName }}
              </h3>
      <img class="triangle" :class="{ open: isDropdownVisible }" src="@/assets/icons/triangle-down.svg" alt="" />
    </div>
    <transition name="fade">
      <DropDownList v-if="isDropdownVisible" v-bind:width="width" :popup="popup">
        <div
          class="dropdown-list-item"
          v-for="(account, index) in accountList"
          :key="index"
          @click="selectAccount(account)" >
          <div class="col">
            <h3>{{ account.accNm }}</h3>
          </div>
        </div>
        <div class="dropdown-division-line"></div>
        <div class="dropdown-list-item" @click="accountManage">
          <div class="col">
            <h3>계좌관리</h3>
          </div>
        </div>
      </DropDownList>
    </transition>

    <manageAccountPop @event:manage-account-complete="manageAccountComplete" />
  </div>
</template>

<script>
import DropDownList from '@/components/DropDownList.vue'
import ManageAccountPop from '@/pages/account/ManageAccountPop.vue'

export default {
  name: 'DropDown',
  props: {
    id: String,
    value: {
      type: Number,
      default: 0,
    },    
    width: [String, Number],
    height: [String, Number],
    full: Boolean,
    popup: Boolean,
    store: Boolean,
    updateAccountPass: Object,
  },
  components: {
    DropDownList,
    ManageAccountPop,
  },
  watch:{
    updateAccountPass:{
      handler(account){
        //비밀번호 초기화
        for (let i=0; i<this.accountList.length; i++){
          if (this.accountList[i].accNo == account.accNo){
            this.accountList[i].accPass = ""
          }
        }
      }
    }
  },
  data() {
    return {
      isDropdownVisible: false,
      accountList: []
    }
  },

  computed: {
    selectedName() {
      let rtn = ''  

      if ( this.value == 0 ) {
        if (this.accountList.length > 0 ) {
          rtn = this.accountList[0].accNm
        }
      } else {
        for (let i in this.accountList) {
          if ( this.value == this.accountList[i].accId ) {
            rtn = this.accountList[i].accNm
            break
          }  
        }
      }

      return rtn
    }
  },   

  mounted() {
    //홈 > 메인
    if (window.$store.getters['Login/isLogin']){
      this.selectAccountList()
    }else{
      //메인 새로고침시 로그인 확인되면
      window.$store.watch(() => window.$store.getters['Login/isLogin'], isLogin => {
        if (isLogin) {
          this.selectAccountList()
        }
      })
    }

    window.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeDropdown);
  },
  methods: {
    closeDropdown(element) {
      if (!this.$refs.dropdown.contains(element.target)) {
        this.isDropdownVisible = false;
      }
    },
    handleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    selectAccount(account) {
      this.isDropdownVisible = false
      this.$emit('input', account.accId)
      this.$emit('event:selectAccount', account)

      this.$sessionStorage.set('acc_id_store', account.accId)
    },    
    accountManage() {
      this.$modal.show('manageAccountPop')
      this.isDropdownVisible = false
    },
    selectAccountList(){
      this.$http
      .get('/api/account/list')
      .then((result) => {
        if ( result && result.data !== null ) {
          this.accountList = result.data

          if ( this.$sessionStorage.get('acc_pass_store') ) {
            const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))

            this.accountList.forEach(item => {
              item.accPass = accPassObj[item.accNo]
            })               
          }

          let accId = 0

          if ( this.store && this.$sessionStorage.get('acc_id_store') ) {
            accId = this.$sessionStorage.get('acc_id_store')
          } else {
            accId = this.value
          }

          if ( accId == 0 && this.accountList.length > 0 ) {

            this.$emit('input', this.accountList[0].accId)
            this.$emit('event:selectAccount', this.accountList[0])

          } else if ( accId != 0 && this.accountList.length > 0 ) {

            let isSelected = false

            for (let i in this.accountList) {
              if ( accId == this.accountList[i].accId ) {
                isSelected = true

                this.$emit('input', this.accountList[i].accId)
                this.$emit('event:selectAccount', this.accountList[i])
              }  
            }

            if ( !isSelected ) {
              this.$emit('input', this.accountList[0].accId)
              this.$emit('event:selectAccount', this.accountList[0])
            }

          }         
        }
      })
    },
    manageAccountComplete() {
      this.selectAccountList()
    }
  },
};
</script>

<style lang="scss" scoped>
.base-dropdown {
  position: relative;
  white-space: nowrap;
  width: 150px;
  height: 30px;
  cursor: pointer;
  &.full {
    flex: 1;
    width: 100%;
    .dropdown-list {
      width: 100%;
    }
  }
  .dropdown-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 0 10px;
    height: 100%;
    border: 1px solid var(--border-color);
    &:hover {
      border-color: var(--main-orange);
    }
    &.active {
      border-color: var(--main-orange);
    }
  }
  h3 {
    padding-bottom: 2px;
  }
  .triangle {
    margin-left: 3px;
    transition: transform 0.2s;
    &.open {
      transform: rotate(180deg);
    }
  }
  @media (max-width: $mobile) {
    /* width: 100%; */
    /* flex: 1; */
    height: 40px;
  }
}
</style>
