<template>
  <modal name="addFavoritePop" class="custom-modal" adaptive :minWidth="800" :minHeight="600" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">
    <ModalContent text="심볼 넣기" modalName="addFavoritePop">
      <template #body>  
        <div class="container">
          <InputCompSearch v-model="form.keyword" @event:enter="inputSearch" />
          <div class="table-container">
            <ag-grid-vue
              style="width: 100%; height: 100%"
              class="ag-theme-delkoit"
              alwaysShowHorizontalScroll="true"
              :columnDefs="grid.columnDefs"
              :rowHeight="30"
              :rowData="grid.rowData"
              :headerHeight="0"
            ></ag-grid-vue>
          </div>
        </div>
      </template>
    </ModalContent>
  </modal>        
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputCompSearch from '@/components/InputCompSearch.vue'
import { AgGridVue } from 'ag-grid-vue' // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import AgCheckbox from '@/components/grid/AgCheckbox.vue'
import AddDeleteBtn from '@/components/grid/addDeleteBtn.vue'

export default {
  name: 'AddFavoritePop',
  components: { 
    ModalContent, 
    InputCompSearch, 
    AgGridVue, // Add AG Grid Vue3 component
    // eslint-disable-next-line vue/no-unused-components
    AgCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    AddDeleteBtn,    
  },

  data() {
    return {
      grid: {
        columnDefs: [
        {
            headerName: '종목명',
            field: 'korNm',
            resizable: false,
            flex: 0.5,
            cellStyle: {
              textAlign: 'left'
            },
          },
          {
            headerName: '심볼코드',
            field: 'symCd',
            resizable: false,
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
          },   

          {
            headerName: '단축명',
            field: 'shtNm',
            resizable: false,
            flex: 1,
            cellStyle: {
              textAlign: 'center'
            },
            headerClass:'right-aligned-header-type2'
          },
          {
            flex: 0.5,
            cellRenderer: 'AddDeleteBtn',
            cellRendererParams: {
              enableMenu: true,
              clicked: (params) => { // AddDeleteBtn.vue 에서 전달하는 리턴 클릭
                  this.saveFavoriteDtl(params)
              }
            },
          }
        ],
        rowData: [],        
      },
      custom : {
        itrstNm: '관심종목',
        shtNm: true,
        curPr: true,
        ydiffPr: true,
        chGrate: true,
        totQ: true,
        seq:0,
        socketSymbol: [],
      },
      form:{
        seq:0,
        symNo:0,
        keyword: '',
      }
    }
  },

  mounted() {

  },
  methods: {
    beforeOpen(e) {
      this.custom = e.params.custom;
      this.form.seq = this.custom.seq;
      this.form.keyword = '';
    },    
    opened() {
      this.searchFavorite();
    },
    async searchFavorite(){
      const response = await window.$http.get('api/symbol/favorite/dtlAddList', { params: this.form })

      let symbolList = [];
      if ( response.data && response.data.length > 0 ) {
        this.grid.rowData = response.data
        
        this.grid.rowData.forEach((item, index) =>{
          if (item.orgSymNo != null && item.orgSymNo > 0){
            symbolList.push(item.symNo);
            this.grid.rowData[index].active = false;
          }else{
            this.grid.rowData[index].active = true;
          }
        })

        this.custom.socketSymbol = symbolList;

        //custom 저장필요
        this.setCustom();
      }
    },
    inputSearch(){
      this.searchFavorite();
    },
    async saveFavoriteDtl(params){
      if (this.form.seq == null || this.form.seq == 0){
        this.$alert.alert('선택한 관심종목이 없습니다.', '알림')
        return;
      }

      let apiUrl = 'api/symbol/favorite/dtlSave'
      this.form.symNo = params.symNo;

      //삭제
      if (params.orgSymNo != null && params.orgSymNo > 0){
        apiUrl = 'api/symbol/favorite/dtlDelete'
        this.form.symNo = params.orgSymNo;
      }      
      
      this.$http
        .post(apiUrl,
            this.form)
        .then((res) => {
          this.searchFavorite();
        })
    },
    setCustom() {
      window.$store.dispatch('Layout/saveCustom', { type: 'FV', custom: this.custom })
      this.loadCustom()
    },
    loadCustom() {
      this.custom = window.$store.getters['Layout/getFavoriteGridCustom']
      this.$emit('event:saveFavorite')
    }, 

  },
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(100% - 55px);
}
.table-container {
  margin-top: 10px;
  height: 100%;
}

@import '@/assets/scss/agGridStyles.scss';
</style>
