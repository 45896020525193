<template>
  <div class="container">
    <div class="current-level-container">
      <div class="current-level">
        <div class="label"><h3>현재레벨</h3></div>
        <div class="content"><h3>{{ planNm }}</h3></div>
      </div>
      <BtnSub text="업그레이드" @click="goPlan" />
    </div>
    <div class="payment-table">
      <div class="payment-table-header">
        <h3>결재내역</h3>
        <DropDown id="searchTime" default="최근 1개월" :options="[{name: '최근 1개월'}, {name: '최근 3개월'}, {name: '최근 6개월'}, {name: '최근 1년'}]" width="180" />
      </div>
      <div class="payment-table-body">
        <div class="payment-table-row" style="height:200px;">
          <div style="margin: auto;"><h3>조회된 결제 내역이 없습니다.</h3></div>
        </div>
        <!--
        <div class="payment-table-row">
          <div class="date"><h3>2023-12-12</h3></div>
          <div class="type"><h3>프리미엄</h3></div>
          <div class="price"><h3>$29.99</h3></div>
        </div>
        <div class="payment-table-row">
          <div class="date"><h3>2023-12-12</h3></div>
          <div class="type"><h3>프리미엄</h3></div>
          <div class="price"><h3>$29.99</h3></div>
        </div>
        <div class="payment-table-row">
          <div class="date"><h3>2023-12-12</h3></div>
          <div class="type"><h3>프리미엄</h3></div>
          <div class="price"><h3>$29.99</h3></div>
        </div>
        -->
      </div>
    </div>

  </div>
</template>

<script>
import BtnSub from '@/components/BtnSub.vue'
import DropDown from '@/components/DropDown.vue'

export default {
  name: 'UserLevelPage',
  components: {
    BtnSub,
    DropDown,
  },
  data() {
    return {}
  },

  mounted() {

  },

  computed: {
    // 플랜명
    planNm() {
      const loginUserInfo = window.$store.getters['Login/getLoginUserInfo']

      if ( loginUserInfo ) {
        return loginUserInfo.planNm
      } else {
        return '기본무료'
      }
    },
  },  

  methods: {
    goPlan() {
      this.$router.push('/plan')
    } 
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  @media (max-width: $mobile) {
    padding: 0 20px;
    margin-top: 10px;
  }
}
.current-level-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(255,255,255,0.1);
  padding: 10px 20px;

  .current-level {
    display: flex;
    align-items: center;
    .label {
      opacity: 0.6;
      margin-right: 26px;
    }
  }
}

.payment-table {
  margin-top: 20px;
  .payment-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      opacity: 0.6;
    }
  }
  .payment-table-body {
    border: 1px solid rgba(255,255,255,0.2);
    margin-top: 10px;
    .payment-table-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      &:not(:last-child) {
        border-bottom: 1px solid var(--border-color-dark);
      }
    }
  }
}
</style>
