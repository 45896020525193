<template>
  <header class="gnb-container">
    <div class="overlay">
      <!--
      <div class="logo">
        <img src="@/assets/images/ci_w@2x.png" alt="" />
      </div>
      -->
    </div>
    <nav>
      <!-- 로고 -->
      <GnbDropDown width="200" style="z-index: 999;">
        <template #gnb-title>
          <div class="logo">
            <!-- <img src="@/assets/images/ci_w@2x.png" alt="" /> -->
            <img src="@/assets/images/tab_w_01_28.png" alt="" />
          </div>
        </template>
        <template #dropdown-list>
          <DropDownItemWithSub v-if="isLogin">
            <template v-slot:item-name>
              <AvatarComp width="16" height="16" fontSize="10" :name="profileNick" :color="profilebgColor" />
              <h3>{{userNickName}}</h3>
            </template>
            <template v-slot:sub-content>
              <div class="dropdown-list-item">
                <div class="col">
                  <router-link to="/profile"><h3>프로파일</h3></router-link>
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <router-link to="/profile/level"><h3>구독변경</h3></router-link>
                </div>
              </div>
            </template>
          </DropDownItemWithSub>
          <div class="dropdown-list-item">
            <div class="col" @click="goMain">
              <img src="@/assets/icons/home.svg" alt="" />
              <h3>홈으로</h3>
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <img src="@/assets/icons/help-circle.svg" alt="" />
              <router-link to="/main/help" @click.native="handleSidemenu"><h3>Help Center</h3></router-link> 
            </div>
          </div>
          <div class="dropdown-list-item">
            <div class="col">
              <img src="@/assets/icons/notice.svg" alt="" />
              <router-link to="/main/notice" @click.native="handleSidemenu"><h3>공지사항</h3></router-link>
            </div>
          </div>
          <div class="dropdown-division-line"></div>
          <DropDownItemWithSub lang>
            <template v-slot:item-name>
              <img src="@/assets/icons/language.svg" alt="" />
              <h3>언어</h3>
            </template>
            <template v-slot:sub-content>
              <div class="dropdown-list-item">
                <div class="col">
                  <LangOption active lang="한국어" />
                </div>
              </div>
              <div class="dropdown-list-item">
                <div class="col">
                  <LangOption lang="영어" />
                </div>
              </div>
            </template>
          </DropDownItemWithSub>
          <!--
          <div class="dropdown-list-item">
            <div class="col">
              <img src="@/assets/icons/keyboard.svg" alt="" />
              <h3>키보드</h3>
            </div>
          </div>
          -->
          <div class="dropdown-division-line" v-if="isLogin"></div>
          <div class="dropdown-list-item" v-if="isLogin" @click="logout">
            <div class="col">
              <img src="@/assets/icons/logout.svg" alt="" />
              <h3>로그아웃</h3>
            </div>
          </div>
        </template>
      </GnbDropDown>


      <!-- 메뉴 -->
      <template v-for="menu in menuList">

        <template v-if="menu.lnkTpCd === LINK_TYPE.LAYER">
          <GnbMenu :ref="`ref-${menu.lnkId}`" v-bind:key="menu.menuId" :text="menu.menuNm" :eventName="menu.lnkId" @click="selectLayerMenu" />
        </template>

        <template v-else-if="menu.lnkTpCd === LINK_TYPE.POP">
          <GnbMenu :ref="`ref-${menu.lnkId}`" v-bind:key="menu.menuId" :text="menu.menuNm" :eventName="menu.lnkId" @click="selectPopMenu" />
        </template>
        
        <template v-else-if="menu.lnkTpCd === LINK_TYPE.URL">
          <GnbMenu :ref="`ref-${menu.lnkId}`" v-bind:key="menu.menuId" :text="menu.menuNm" :eventName="menu.lnkPth" @click="selectUrlMenu" />
        </template>        

        <template v-else-if="menu.lnkTpCd === LINK_TYPE.NONE">
          <GnbDropDown v-bind:key="menu.menuId" chevron>
            <template #gnb-title>
              <img src="@/assets/icons/account.svg" alt="" />
              <h2>{{menu.menuNm}}</h2>
            </template>
            <template #dropdown-list>
              <template v-for="childMenu in menu.childMenuList">

                <div class="dropdown-list-item" 
                  v-bind:key="childMenu.menuId" 
                  v-if="childMenu.lnkTpCd === LINK_TYPE.LAYER"
                  @click="selectLayerMenu({ eventName : childMenu.lnkId })">
                  <div class="col">
                    <h3>{{childMenu.menuNm}}</h3>
                  </div>
                </div>

                <div class="dropdown-list-item" 
                  v-bind:key="childMenu.menuId" 
                  v-else-if="childMenu.lnkTpCd === LINK_TYPE.POP"
                  @click="selectPopMenu({ eventName : childMenu.lnkId })">
                  <div class="col">
                    <h3>{{childMenu.menuNm}}</h3>
                  </div>
                </div>

                <div class="dropdown-list-item" 
                  v-bind:key="childMenu.menuId" 
                  v-else-if="childMenu.lnkTpCd === LINK_TYPE.URL"
                  @click="selectUrlMenu({ eventName : childMenu.lnkPth })">
                  <div class="col">
                    <h3>{{childMenu.menuNm}}</h3>
                  </div>
                </div>                

              </template>
            </template>  
          </GnbDropDown>          
        </template>

      </template>


      <!-- 디폴트 화면 -->
      <GnbDropDown chevron width="160" v-if="isLogin">
        <template #gnb-title>
          <img src="@/assets/icons/monitor.svg" alt="" />
          <h2>{{ layoutName }}</h2>
        </template>
        <template #dropdown-list>
          <h4 class="dropdown-subtitle">거래화면 관리</h4>
          <div class="dropdown-list-item">
            <div class="col">
              <h3>현재화면 저장</h3>
            </div>
            <div class="col">
              <BtnSlide id="autoSave" v-model="isSave" @event:checkSlide="checkSaveSlide" />
            </div>
          </div>
          <div class="dropdown-list-item" @click="selectPopMenu({ eventName : 'layoutRenamePop' })">
            <div class="col">
              <h3>이름 바꾸기</h3>
            </div>
          </div>
          <div class="dropdown-list-item" @click="selectPopMenu({ eventName : 'layoutCopyPop' })">
            <div class="col">
              <h3>카피 만들기</h3>
            </div>
          </div>
          <div class="dropdown-division-line"></div>
          <div class="dropdown-list-item" @click="selectPopMenu({ eventName : 'layoutCreatePop' })">
            <div class="col">
              <h3>새화면 저장</h3>
            </div>
          </div>
          <div class="dropdown-division-line"></div>
          <h4 class="dropdown-subtitle">최근화면</h4>

          <template v-for="layout in recentLayoutList">

            <div class="dropdown-list-item" v-bind:key="layout.laySeq" @click="useLayout(layout.laySeq)">
              <div class="col">
                <h3>{{ layout.layNm }}</h3>
              </div>
            </div>

          </template>

          <div class="dropdown-division-line"></div>
          <div class="dropdown-list-item" @click="selectPopMenu({ eventName : 'layoutLoadPop' })">
            <div class="col">
              <h3>화면 불러오기...</h3>
            </div>
          </div>
        </template>
      </GnbDropDown>
    </nav>

    <layoutCreatePop @event:saveLayout="loadLayout" />
    <layoutCopyPop />
    <layoutRenamePop @event:renameLayout="renameLayout" />
    <layoutLoadPop @event:selectLayout="useLayout" />      
  </header>
</template>

<script>
import BtnSlide from '@/components/BtnSlide.vue'
import DropDownItemWithSub from '@/components/DropDownItemWithSub.vue'
import GnbDropDown from '@/components/GnbDropDown.vue'
import AvatarComp from '@/components/AvatarComp.vue'
import LangOption from '@/components/LangOption.vue'
import GnbMenu from '@/components/GnbMenu.vue'
import Const from '@/const'

import LayoutCreatePop from '@/pages/layout/LayoutCreatePop.vue'
import LayoutCopyPop from '@/pages/layout/LayoutCopyPop.vue'
import LayoutRenamePop from '@/pages/layout/LayoutRenamePop.vue'
import LayoutLoadPop from '@/pages/layout/LayoutLoadPop.vue'

import EventBus from '@/modules/event-bus'

export default {
  name: 'GnbComp',
  props: {
    hideAccount: {
      type: Boolean,
      default: false
    },
    hidePending: {
      type: Boolean,
      default: false
    },
    hideOrders: {
      type: Boolean,
      default: false
    },
  },
  components: {
    BtnSlide,
    DropDownItemWithSub,
    GnbDropDown,
    AvatarComp,
    LangOption,
    GnbMenu,
    LayoutCreatePop,
    LayoutCopyPop,
    LayoutRenamePop,
    LayoutLoadPop,    
  },

  data() {
    return {
      LINK_TYPE: Const.LINK_TYPE,
      menuList: [],
      layoutName : '디폴트 화면',
      layout : {},
      isSave : false,
      recentLayoutList : [],
      isCompleteLoadLayout: false,
    }
  },

  created () {
    // 
    if (this.isLogin) {
      this.setMenus(true)
      this.setRecentLayoutList()
    } else {
      this.setMenus(false)
    }

    // 로그인 처리
    window.$store.watch(() => window.$store.getters['Login/isLogin'], isLogin => {
      if (isLogin) {
        this.setMenus(true)
        this.setRecentLayoutList()
      } else {
        this.setMenus(false)
      }
    })

  },  

  watch: {
    layout: {
      handler(newLayout, oldLayout) {
        this.setLayout(newLayout)

        if ( this.isCompleteLoadLayout ) {
          window.$store.dispatch('Layout/save')
        }
      },
      deep: true,      
    }
  },   

  computed: {
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin']
    },    
    // 사용자 이름
    userNickName () {
      return window.$store.getters['Login/getUsrNick']
    },
    profileNick () {
      const usrNick = window.$store.getters['Login/getUsrNick']

      if ( usrNick !== null ) {
        return usrNick.substring(0, 1)
      } else {
        return ''
      }
    },
    profilebgColor () {
      const prfBgCol = window.$store.getters['Login/getPrfBgCol']

      if ( prfBgCol !== null ) {
        return prfBgCol
      } else {
        return ''
      }      
    }        
  },

  methods: {
    async setMenus(isLoadLayout) {
      const response = await window.$http.get('/common/menu/list', {params : {menuTpCd : 'MENU0101'}})
      
      if (response.data && response.data.length > 0) {
        this.menuList = response.data[0].childMenuList

        // 레이어 메뉴 설정
        /*
        for ( let i = this.menuList.length - 1; i >= 0; i-- ) {
          if ( this.menuList[i].lnkTpCd === Const.LINK_TYPE.LAYER ) {
            this.selectLayerMenu({ eventName: this.menuList[i].lnkId, clicked : true })

            if ( this.menuList[i].childMenuList ) {
              for ( let childMenu of this.menuList[i].childMenuList ) {
                if ( childMenu.lnkTpCd === Const.LINK_TYPE.LAYER ) {
                  this.selectLayerMenu({ eventName: childMenu.lnkId, clicked : true })
                }
              }
            }
          }
        } // end for
        */

        if ( this.$route.path !== '/main' ) {
          this.handleSidemenu()
        }

        // 레이아웃 조회
        if ( isLoadLayout ) {
          this.loadLayout()
        }
      }
      
    },
    setLayout(layout) {
      if ( this.$refs['ref-hideAccount'] !== undefined ) { this.$refs['ref-hideAccount'][0].setClicked(!layout.hideAccount) }
      if ( this.$refs['ref-hidePending'] !== undefined ) { this.$refs['ref-hidePending'][0].setClicked(!layout.hidePending) }
      if ( this.$refs['ref-hideOrders']  !== undefined ) { this.$refs['ref-hideOrders'][0].setClicked(!layout.hideOrders) }

      if ( layout.path !== this.$route.path ) {
        this.$router.push(layout.path)
      }
    },
    loadLayout() {
      window.$store
        .dispatch('Layout/load')
        .then(() => {
          this.layout     = window.$store.getters['Layout/getLayout']
          this.isSave     = window.$store.getters['Layout/isSave']
          this.layoutName = window.$store.getters['Layout/getLayoutName']

          EventBus.$emit('Layout:event:loadLayout', this.layout)

          this.isCompleteLoadLayout = true
        })         
    },
    useLayout(laySeq) {
      window.$store
        .dispatch('Layout/use', laySeq)
        .then(() => {
          this.layout     = window.$store.getters['Layout/getLayout']
          this.isSave     = window.$store.getters['Layout/isSave']
          this.layoutName = window.$store.getters['Layout/getLayoutName']

          this.setRecentLayoutList()

          EventBus.$emit('Layout:event:loadLayout', this.layout)
        })   
    },
    renameLayout() {
      this.layoutName = window.$store.getters['Layout/getLayoutName']

      this.setRecentLayoutList()
    },
    async setRecentLayoutList() {
      const response = await window.$http.get('/common/layout/recent/list')

      if ( response.data && response.data.length > 0 ) {
        this.recentLayoutList = response.data
      }
    },
    goMain() {
      this.$router.push('/')
    },
    handleSidemenu() {
      window.$store.commit('Layout/setPath', { path: this.$route.path })

      this.$emit('event:hideSideMenu', { isCurrent: false })
    },    
    selectLayerMenu(e) {
      this.$emit('event:hidePannel', e)
    },
    selectPopMenu(e) {
      this.$modal.show(e.eventName)
    },
    selectUrlMenu(e) {
      this.$router.push(e.eventName)
    },
    checkSaveSlide(e) {
      window.$store
        .dispatch('Layout/updateSave', e)  
        .then(() => {
        })    
    },
    logout() {
      this.$alert.confirm('로그아웃 하시겠습니까?', '확인')
        .then((response) => {
          if (response) {      
            window.$store.dispatch('Login/logout')
              .then((response) => {
                if(this.$route.path === '/main/favorite' 
                  || this.$route.path === '/main/notification'
                  || this.$route.path === '/main/demo'
                  || this.$route.path === '/main/trade') {
                  this.$router.push('/login')
                }
              })
          }
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.gnb-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  gap: 30px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--main-black);
  z-index: 5;
  white-space: nowrap;
  nav {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}
.logo {
  width: 37px;
  img {
    width: 100%;
  }
}
.landing {
  .overlay {
    display: flex;
  }
}

.overlay {
  display: none;
  align-items: center;
  /* justify-content: center; */
  padding-left: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

</style>
