<template>
  <modal name="marginStatusPop" class="custom-modal" adaptive :minWidth="800" :maxHeight="460" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">  
    <ModalContent text="예수금 현황" modalName="marginStatusPop">    
      <template #body>
        <div class="row">
          <div class="row-item">
            <h3>계좌번호</h3>
            <div class="row-item-content">
              <AccountDropDown id="accountDropdown" popup v-model="accId" @event:selectAccount="selectAccount" />
              <InputComp readonly width="120" v-model="account.accNo" />
            </div>
          </div>
          <div class="row-item">
            <h3>비밀번호</h3>
            <InputComp type="password" :width="65" v-model="account.accPass" />
          </div>
          <div class="row-item">
            <h3>원/달러</h3>
            <div class="radio-group">
              <BtnRadio id="margin_status_pop_radio_usd" name="margin_status_pop_radio_crc"  value="USD" text="달러" checked @click="selectCurrency" />
              <BtnRadio id="margin_status_pop_radio_krw" name="margin_status_pop_radio_crc"  value="KRW" text="원" @click="selectCurrency" />
            </div> 
          </div>                                                                                                                                                                                             
          <div class="row-item">
            <BtnPrimary text="조회" icon="search" :width="70" @click="search" />
          </div>
        </div>
        <div class="margin-table">
          <div class="flex-table-row">
            <div class="flex-table-col">
              <div class="th">예탁금</div>
              <div class="td">{{ deposit.balMn | currency(curCrc) }}</div>
            </div>
            <div class="flex-table-col">
              <div class="th">익일예탁금</div>
              <div class="td"></div>
            </div>
            <div class="flex-table-col">
              <div class="th">예상추가증거금</div>
              <div class="td"></div>
            </div>
          </div>
          <div class="flex-table-row">
            <div class="flex-table-col">
              <div class="th">선물청산손익</div>
              <div class="td">{{ deposit.fxPlMn | currency(curCrc) }}</div>
            </div>
            <div class="flex-table-col">
              <div class="th">평가총액</div>
              <div class="td"></div>
            </div>
            <div class="flex-table-col">
              <div class="th">주문가능금액</div>
              <div class="td"></div>
            </div>
          </div>
          <div class="flex-table-row">
            <div class="flex-table-col">
              <div class="th">선물평가손익</div>
              <div class="td"></div>
            </div>
            <div class="flex-table-col">
              <div class="th">위탁증거금</div>
              <div class="td"></div>
            </div>
            <div class="flex-table-col">
              <div class="th">인출가능금액</div>
              <div class="td"></div>
            </div>
          </div>
          <div class="flex-table-row">
            <div class="flex-table-col">
              <div class="th">수수료</div>
              <div class="td">{{ deposit.fxCms | currency(curCrc) }}</div>
            </div>
            <div class="flex-table-col">
              <div class="th">유지증거금</div>
              <div class="td">{{ deposit.rmsSetMn | currency(curCrc) }}</div>
            </div>
            <div class="flex-table-col">
              <div class="th">원화환산환율</div>
              <div class="td">{{ deposit.usdExR | commas }}</div>
            </div>
          </div>
        </div>
      </template>        
    </ModalContent>  
  </modal> 
</template>

<script>
import ModalContent from '@/components/ModalContent.vue'
import InputComp from '@/components/InputComp.vue'
import BtnRadio from '@/components/BtnRadio.vue'
import BtnPrimary from '@/components/BtnPrimary.vue'
import AccountDropDown from '@/components/AccountDropDown.vue'
import Utils from '@/modules/utils'

export default {
  name: 'ModalMarginStatus',
  components: {
    ModalContent,
    InputComp,
    BtnRadio,
    BtnPrimary,
    AccountDropDown
  },

  data() {
    return {
      accId: 0,
      crc: 'USD',
      curCrc: 'USD',
      account: {
        accNo: '',
        accPass: ''
      },  
      deposit: {
        balMn: '',
        fxPlMn: '',
        fxCms: '',
        rmsSetMn: '',
        usdExR: ''
      }    
    }
  },

  mounted() {},

  methods: {
    beforeOpen(e) {  
      Utils.initObject(this.deposit, false)
    },    
    opened() {
    },  
    selectAccount(account) {
      this.account = account
    },     
    selectCurrency(value) {
      this.crc = value
    },    
    async search() {
      // 조회 조건
      const params = {
        accId: this.account.accId,
        accPass: this.account.accPass,
        crc: this.crc
      }

      const response = await this.$lhttp.get('/api/account/deposit', { params: params })

      this.curCrc = this.crc

      if ( response && response.data != null ) {
        this.deposit = response.data

      } else {
        this.$alert.alert('조회된 예수금 현황이 없습니다.', 'Warnning')
      }        

    }
  },
  filters: {
    currency(value, currency) {
      if ( value ) {
        return new Intl.NumberFormat('ja-JP', { style: 'currency', currency }).format(value)
      } else {
        return ''
      }
    },
    commas(value) {
      if ( value ) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return ''
      }      
    }
  },
};
</script>

<style lang="scss" scoped>
.margin-table {
  font-size: 12px;
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  @media (max-width: $mobile) {
    border-left: none;
    border-right: none;
  }
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  
  /* border-bottom: none; */
  /* border-top: 1px solid rgba(255, 255, 255, 0.2); */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
  .flex-table-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
		@media (max-width: $mobile) {
			display: block;
		}
    .flex-table-col {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: -1px;
      margin-bottom: -1px;
      /* flex: 0 100%; */
      line-height: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      /* border: 1px solid rgba(255, 255, 255, 0.1); */
      /* border-right: none; */
      .th {
        background-color: rgba(255, 255, 255, 0.05);
        /* background-color: red; */
				color: rgba(255,255,255,0.6);
        flex: 0 1 120px;
        padding-left: 10px;
        @media (max-width: $mobile) {
          padding-left: 20px;
        }
      }
      .td {
        flex: 1;
        text-align: right;
        padding-right: 10px;
        @media (max-width: $mobile) {
          padding-right: 20px;
        }
      }
      @media (max-width: $mobile) {
        line-height: 40px;
      }
    }
  }
}
</style>
