<template>
  <button :style="{ width: width + 'px', height: height + 'px' }" :class="{ white: white, red: red, blue: blue, round: round }" @click="handleClick">
    <IconSearch />
  </button>
</template>

<script>
// import BaseBtn from '@/components/BaseBtn.vue';
import IconSearch from '@/components/icons/IconSearch.vue';
export default {
  name: 'BtnIconLine',
  components: {
    // BaseBtn,
    IconSearch,
  },
  props: {
    width: [String, Number],
    height: [String, Number],
    red: Boolean,
    blue: Boolean,
    white: Boolean,
    round: Boolean,
    icon: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  position: relative;
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--main-white);
  cursor: pointer;
  &.red {
    border-color: var(--main-red);
    color: var(--main-red);
  }
  &.blue {
    border-color: var(--main-blue);
    color: var(--main-blue);
  }
  &.white {
    border-color: rgba(255, 255, 255, 0.5);
    color: var(--main-white);
  }
  &.round {
    border-radius: 50vh;
  }
  svg {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
