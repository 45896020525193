<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20">
    <path
      d="M7.552 13.414a.256.256 0 0 1-.183-.076L1.235 7.2A4.24 4.24 0 0 1 0 4.223a4.144 4.144 0 0 1 1.307-3.059A4.336 4.336 0 0 1 4.242 0a4.12 4.12 0 0 1 2.821 1.116l.489.449.488-.449A4.121 4.121 0 0 1 10.862 0 4.336 4.336 0 0 1 13.8 1.164a4.145 4.145 0 0 1 1.3 3.059A4.24 4.24 0 0 1 13.869 7.2l-6.133 6.138a.259.259 0 0 1-.184.076zM4.234 1.26a3.041 3.041 0 0 0-2.059.816 2.907 2.907 0 0 0-.916 2.146 2.938 2.938 0 0 0 .867 2.091l5.427 5.427 5.427-5.426a2.942 2.942 0 0 0 .865-2.091 2.908 2.908 0 0 0-.916-2.146 3.041 3.041 0 0 0-2.059-.816 2.891 2.891 0 0 0-1.979.783L7.553 3.272 6.214 2.044a2.891 2.891 0 0 0-1.979-.783z"
      transform="translate(2.517 4.1)"
      :style="{ fill: fill }"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconFavorite',
  props: {
    width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
}
</style>
