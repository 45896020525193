<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16">
    <path
      d="M11 2a1 1 0 1 1 1-1 1 1 0 0 1-1 1zM6 2a1 1 0 1 1 1-1 1 1 0 0 1-1 1zM1 2a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"
      transform="translate(2 7)"
      :style="{ fill: fill }"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconEllipsis',
  props: {
    width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
    fill: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: white;
}
</style>
