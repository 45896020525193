<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16">
    <path
      d="M7 14a7 7 0 1 1 7-7 7.008 7.008 0 0 1-7 7zM7 1a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6zm0 10.2a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6zM7.5 9h-1v-.907a1.452 1.452 0 0 1 .9-1.348 1.563 1.563 0 0 0 1.025-1.313 1.425 1.425 0 1 0-2.85 0h-1a2.425 2.425 0 1 1 4.85 0A2.535 2.535 0 0 1 7.778 7.67a.454.454 0 0 0-.278.423V9z"
      transform="translate(.994 1)" :style="{ fill: fill }"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconHelpCenter',
  props: {
		width: {
      type: [String, Number],
      default: 16,
    },
    height: {
      type: [String, Number],
      default: 16,
    },
		fill: String
	},
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
	fill: white;
}
</style>
