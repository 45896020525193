// eslint-disable-next-line
/* eslint-disable */

// default configuration setting
// 기본 구성 데이터 처리


import { parseFullSymbol } from './helper.js'

const lastBarsCache = new Map()

const configurationData = {
	supported_resolutions: ['1', '3','5','15','30','60','120','1D', '1W', '1M', '3M'],
	exchanges: [{
    "value" : "",
    "name"  : "All Exchanges",
    "desc"  : ""
  },
 /* {
    "value" : "energe",
    "name"  : "energe",
    "desc"  : "energe"
  },
  {
    "value" : "stock",
    "name"  : "stock",
    "desc"  : "stock"
  },
  {
    "value" : "gem",
    "name"  : "gem",
    "desc"  : "gem"
  },*/
	],
	// SymbolType
	symbols_types: [{
    "name": "All Type",
    "value": ""
},
/*{
    "name": "크루드오일",
    "value": "oil"
},
{
    "name": "S&P",
    "value": "snp"
},
{
    "name": "골드",
    "value": "gold"
},
{
    "name": "나스닥",
    "value": "Nasdaq"
},*/
		// ...
	],
  supports_time : true,
	theme: 'Dark',
	overrides : {
		"mainSeriesProperties.candleStyle.upColor": "#FC0E0E",
		"mainSeriesProperties.candleStyle.downColor": "#0E26FC",        
		"mainSeriesProperties.candleStyle.borderDownColor": "#0E26FC",
		"mainSeriesProperties.candleStyle.borderUpColor": "#FC0E0E",
		"mainSeriesProperties.candleStyle.wickUpColor" : "#FC0E0E",
		"mainSeriesProperties.candleStyle.wickDownColor" : "#0E26FC",
	},
	
}


/**
 * 다음 캔들 차트 시간
 * @param {long} barTime 
 * @param {int} resolution 
 * @returns 
 */
function getNextBarTime(barTime, resolution){
  if(resolution === '1D'){
    let nextTime = 24 * 60 * 60 * 1000;
    return barTime + nextTime;
  }else{
    if(resolution === '1'){
      return barTime;
    }
    let barDate = new Date(barTime);
    let nextTime = ((Math.floor(barDate.getUTCMinutes() / Number(resolution)) + 1) * Number(resolution)) - barDate.getUTCMinutes();
    barDate.setMinutes(barDate.getUTCMinutes() + nextTime);    
    return barDate.getTime();
  }
}


/**
 * 체결시간 전달
 * @param {yyyyMMdd} korDate 
 * @param {HHmmss} korTm 
 * @returns 
 */
function getTradeDateStr(korDate, korTm){

  let year = korDate.substr(0,4)
  let month = korDate.substr(4,2)
  let day = korDate.substr(6,2)          

  let hh = korTm.substr(0,2)
  let mm = korTm.substr(2,2)
  let ss = korTm.substr(4,2)
  let tradeDateStr = year +'-'+ month + '-' + day + 'T' + hh + ':' + mm + ':' + ss

  return tradeDateStr;

}

function getTradeBarTime(korDate, korTm){

  let year = korDate.substr(0,4)
  let month = korDate.substr(4,2)
  let day = korDate.substr(6,2)          

  let hh = korTm.substr(0,2)
  let mm = korTm.substr(2,2)
  //let ss = korTm.substr(4,2)
  let tradeDateStr = year +'-'+ month + '-' + day + 'T' + hh + ':' + mm + ':' + '00'

  return new Date(tradeDateStr).getTime();
}

let connetTime; //eslint-disable-line no-unused-vars

function heartbeat(){
  
  socketInterval = setInterval(() => {
    sendHeartBeat()
  }, 50000)

}

const sendHeartBeat = () => {
  const reqData = {
    header: {
      apiCode : 'DSA0000',
      token : '',
    },
    body : {
    }
  }
  //console.log('sendHeartBeat call')
  socket.send(JSON.stringify(reqData))
  connetTime = new Date() //eslint-disable-line no-unused-vars

}

/**
 * 소켓 통신 관련 메소드
 */

const websocketPath = process.env.VUE_APP_CHART_SOCKET_BASE_URL
let socket = null
let isConnectSocket = false
let socketInterval = null
let socketTimeout = null
let connetTimeout = null
let callbackTimeout = null

const channelToSubscription = new Map();
const channelToResoultion = new Map(); //eslint-disable-line no-unused-vars
let symbolData; //eslint-disable-line no-unused-vars


const connect = () => {
  if(socket && socket.readyState == WebSocket.OPEN){       
    socket.close()
  }

  socket = new WebSocket(websocketPath)

  socket.onopen = () => {
    isConnectSocket = true;
    //console.log('socket connect')
    connetTime = new Date();
    heartbeat()
    
    for(const channelString of channelToSubscription.keys()){
      const subscriptionItem = channelToSubscription.get(channelString);
     // console.log(subscriptionItem)
      if(subscriptionItem != undefined){
        symbolData = subscriptionItem.symbolInfo;
       // console.log(symbolData);
      }
    }
  }
  
  socket.onmessage = (e) => {
  
    const returnData = JSON.parse(e.data) 
    //console.log(returnData.body, '-----', new Date())

    const res = []; //eslint-disable-line no-unused-vars
    
    if(returnData.body != null){
  
      if(returnData.body.symbol != null){
        const channelString = `0~${returnData.body.symbol}`;    
        const subscriptionItem = channelToSubscription.get(channelString);
  
        if(subscriptionItem == undefined || subscriptionItem.lastDailyBar == undefined){
          return
        }        
        
        if(returnData.body.apiType == 'OVC' || returnData.body.apiType == 'FC0'){
          const lastDailyBar = subscriptionItem.lastDailyBar;          

          if(lastDailyBar.time == undefined){
            //window.location.reload();
          }     
          
          let tradeDateStr = getTradeDateStr(returnData.body.korDate, returnData.body.korTm)
          let tradeDateTime = getTradeBarTime(returnData.body.korDate, returnData.body.korTm)
          const nextBarTime = getNextBarTime(tradeDateTime, subscriptionItem.resolution)
          
          
          let tradeDate = new Date(tradeDateStr).getTime()    //eslint-disable-line no-unused-vars    
          let tradePrice = Number(parseFloat(returnData.body.curPr).toFixed(5))
          
          let volume = returnData.body.trDq;
          //console.log(new Date(tradeDate), new Date(nextBarTime))
  
          let bar;
          if(tradeDateTime > lastDailyBar.time){          
            bar = {
              time: nextBarTime,
              open: tradePrice,
              high: tradePrice,
              low: tradePrice,
              close: tradePrice,
              volume: volume
            };
          // console.log('[socket] Generate new bar', bar); 
          
          }else{
            let updateVolume = (lastDailyBar.volume + volume)
  
            bar = {
              ...lastDailyBar,
              high: Math.max(lastDailyBar.high, tradePrice),
              low: Math.min(lastDailyBar.low, tradePrice),
              close: tradePrice,
              volume: updateVolume
            };
            
            //console.log('[socket] Update the latest bar by price', bar);
          }
          subscriptionItem.lastDailyBar = bar;        
          subscriptionItem.handlers.forEach(handler => handler.callback(bar));
  
        }
  
      }
     
      
      
    }
  
  }
  
  socket.onerror = function(e){ //eslint-disable-line no-unused-vars
    console.error("[Error] socket Error");
  }
  
  socket.onclose = function(e){ //eslint-disable-line no-unused-vars
    console.error("[OnClose] socket close")
    // 종료시 재 연결
    connetTimeout = setTimeout(function() {
      connect()
    }, 1000);
  }
  
  socketTimeout = setTimeout(function(){
    for(const channelString of channelToSubscription.keys()){
      const subscriptionItem = channelToSubscription.get(channelString);
      if(subscriptionItem != undefined){
        sendMessage('DSA0003', subscriptionItem.symbolInfo.name);
      }
    }

  }, 1000)

  
  
  
  
}


/**
 * 소콋 서버로 데이터 전송
 * @param {} apiCode 
 * @param {*} subscriptionItem 
 */
const sendMessage = (apiCode, symbol) => {     
  
  let apiTypeData;
  let apiType;
  if(symbol.toUpperCase().indexOf('101') >= 0){
    apiTypeData = new Array('FC0')
    apiType = 'FC0'
  }else{
    apiTypeData = new Array('OVC');
    apiType = 'OVC'
  }
   
  let symbolData = [];
  symbolData.push(symbol)

  const reqData = {
    header: {
      apiCode : apiCode,
      token : '',
    },
    body : {
      apiType : apiType,
      symbol : symbol,
      apiTypeData: apiTypeData,
      symbolData : symbolData
      
    }
  }    
 
  socket.send(JSON.stringify(reqData))
}


const subscribeOnStream = (
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscriberUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) => {

	const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
	const channelString = `0~${parsedSymbol.fromSymbol}`;
  
	const handler = {
		id: subscriberUID,
		callback: onRealtimeCallback,
	};
	let subscriptionItem = channelToSubscription.get(channelString);  
  

	if (subscriptionItem) {
		// already subscribed to the channel, use the existing subscription    
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
    symbolInfo,
		subscriberUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
  channelToSubscription.set(channelString, subscriptionItem);
	//console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);  
  sendMessage('DSA0003', subscriptionItem.symbolInfo.name);
  

  //sendMessage('DSA0003', subscriptionItem.symbolInfo.name)

}

const unsubscribeFromStream = (subscriberUID) => {
  
	// find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		const handlerIndex = subscriptionItem.handlers
			.findIndex(handler => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			// remove from handlers
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers.length === 0) {
				// unsubscribe from the channel, if it was the last handler
				//console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
				sendMessage('DSA0004', subscriptionItem.symbolInfo.name)
				channelToSubscription.delete(channelString);        
				break;
			}
		}
	}
}


//
export default {
  onReady: (callback) => {
      //console.log('[onReady1]: Method call');
      connect()
      setTimeout(() => callback(configurationData))
  },
  getServerTime: async (callback) => {
    //console.log('[getServerTime]: Method call')
    const response = await window.$http.get('chart/datafeed/time')
    callback(response.data)

  },
  searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
     // console.log('[searchSymbols]: Method call')
     userInput  = '';
      const response = await window.$http.get('chart/datafeed/symbols')
      const symbols = response.data
    
      const newSymbols = symbols.filter(symbol => {
        symbol.symbol = symbol.name;
        const isExchangeValid = exchange === '' || symbol.exchange === exchange;
        const isFullSymbolContainsInput = symbol.full_name
          .toLowerCase()
          .indexOf(userInput.toLowerCase()) !== -1;
        return isExchangeValid && isFullSymbolContainsInput
      });   

      onResultReadyCallback(newSymbols);
      
  },
  resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
      //console.log('[resolveSymbol]: Method call', symbolName) 
      const response = await window.$http.get('chart/datafeed/symbols')
      const symbols = response.data
      

      const symbolItem = symbols.find(({
        name,
      }) => name === symbolName)
      if (!symbolItem) {
        
        onResolveErrorCallback('cannot resolve symbol');
        return;
      }
      //console.log('symbolItem : ', symbolItem)
      const symbolInfo = {
        base_name: symbolItem.base_name,
        ticker: symbolItem.ticker,
        name: symbolItem.name,
        description: symbolItem.description,
        type: symbolItem.type,
        session: symbolItem.session,			
        timezone:symbolItem.timezone,
        exchange: symbolItem.exchange,
        list_exchange: symbolItem.list_exchange,
        minmov: symbolItem.minmov,
        pricescale: symbolItem.pricescale,
        has_intraday: true,
        intraday_multipliers: ['1','3','5','10','15','30','60'],			
        has_empty_bars: false,
        has_weekly_and_monthly: false,
        supported_resolutions: symbolItem.supported_resolutions,        
        //has_no_volume: false,
        visible_plots_set: 'ohlcv',
        data_status: 'streaming',
        delay: 0,
        full_name : symbolItem.full_name,
        symbol : symbolItem.name,
        
        
      };      
      onSymbolResolvedCallback(symbolInfo);

  },
  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {

    //console.log('[getBars]: Method call', periodParams);
    const { from, to, firstDataRequest, countBack } = periodParams;	
		
		const parsedSymbol = parseFullSymbol(symbolInfo.full_name);    
		const urlParameters = {
			symbol: symbolInfo.name,
      resolution : resolution,
			from: from,					
			to: to,      
			limit: countBack,
		};
    
    //console.log(urlParameters)

		try {
      
      const response = await window.$http.get('/chart/datafeed/history', {params:urlParameters})
      
      const barData = response.data
      
      if(!barData){
        onHistoryCallback([], {
					noData: true					
				});
				return;
      }

      let bars = [];

      barData.forEach(bar => {        	

        if (bar.time >= from && bar.time < to) {               
          bars = [...bars, {
            time: bar.time  * 1000,
            low: bar.low,
            high: bar.high,
            open: bar.open,
            close: bar.close,
            volume: bar.volume
          }];
        }
        
      });
      

      //if (firstDataRequest) {
      if ( bars.length > 0 && !lastBarsCache.has(symbolInfo.full_name) ) {
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }    
      //}
      
      onHistoryCallback(bars, {
				noData: false,
			});
      			
		} catch (error) {			
			onErrorCallback(error);
		}
    
  },
  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
      //console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
      subscribeOnStream(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback,
        lastBarsCache.get(symbolInfo.full_name),
      );
  },
  unsubscribeBars: (subscriberUID) => {
     // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
      unsubscribeFromStream(subscriberUID);
  },
  disconnect: () => {
    if (isConnectSocket) {
      socket.onclose = function () {}
      socket.close()
      isConnectSocket = false 
    }
    clearInterval(socketInterval)
    clearTimeout(socketTimeout)
    clearTimeout(connetTimeout)
    clearTimeout(callbackTimeout)
  }
};