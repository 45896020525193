<template>
  <modal name="upgradePop" class="custom-modal" adaptive :minWidth="600" :minHeight="822" :clickToClose="false"
    @before-open="beforeOpen"
    @opened="opened">
    <div class="upgradeModal-inner">
      <div class="container">
        <BaseBtn naked class="upgrade-close" @click="close">
          <img src="@/assets/icons/close-large.svg" alt="" />
        </BaseBtn>
        <div class="image-container">
          <img src="@/assets/images/upgrade-sample-image.png" alt="" />
        </div>
        <div class="text-container" v-if="planId !== Const.PLAN_TYPE.PREMIUM">
          <h2>더 많은 차트 레이아웃 세이브로 트레이딩을 심플하게 하십시오.</h2>
          <p>
            플랜에 저장할 수 있는 차트 레이아웃의 수가 {{layCnt}}로 제한되어 있습니다.
            <br />
            하지만 플랜을 업그레이드하여 더 많은 레이아웃을 저장할 수 있으므로 시장 분석을 다시 보고 싶을 때 유용합니다.
          </p>
        </div>
        <div class="text-container" v-else>
          <h2>더 많은 차트 레이아웃 세이브로 트레이딩을 심플하게 하십시오.</h2>
          <p>
            플랜을 업그레이드하여 더 많은 레이아웃을 저장할 수 있으므로 시장 분석을 다시 보고 싶을 때 유용합니다.
          </p>
        </div>        
        <div class="swiper-container">
          <UpgradeSwiper />
        </div>
        <div class="footer">
          <BtnPrimary text="지금 업그레이드" width="200" height="50" fontSize="16" fontWeight="bold" class="upgrade-btn" @click="goPlan" />
        </div>
      </div>
    </div>    
  </modal>  
</template>

<script>
import BtnPrimary from '@/components/BtnPrimary.vue'
import UpgradeSwiper from '@/components/UpgradeSwiper.vue'
import BaseBtn from '@/components/BaseBtn.vue'
import Const from '@/const'

export default {
  name: 'UpgradePop',
  components: {
    BtnPrimary,
    UpgradeSwiper,
    BaseBtn,
  },

  data() {
    return {
      Const: Const
    }
  },

  computed: {
    // 레이아웃 수
    layCnt() {
      const loginUserInfo = window.$store.getters['Login/getLoginUserInfo']

      if ( loginUserInfo ) {
        return loginUserInfo.layCnt
      } else {
        return '1'
      }
    },
    // 플랜ID
    planId() {
      const loginUserInfo = window.$store.getters['Login/getLoginUserInfo']

      if ( loginUserInfo ) {
        return loginUserInfo.planId
      } else {
        return 'P00'
      }
    },    
  },   

  methods: {
    beforeOpen(e) {  
    },    
    opened() {
    },  
    close() {
      this.$modal.hide('upgradePop')
    },   
    goPlan() {
      this.$router.push('/plan')
    } 
  }  
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .image-container {
    margin: 0 -20px;
    flex: 0 0 auto;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4em;
    margin-top: 20px;
    @media (max-width: $mobile) {
      font-size: 18px;
    }
  }
  p {
    font-size: 16px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.8);
    margin: 20px 0;
    @media (max-width: $mobile) {
      font-size: 13px;
    }
  }
  .swiper-container {
    /* margin-right: -20px; */
    @media (max-width: $mobile) {
      /* margin-left: -20px; */
      /* margin-right: -40px; */
    }
  }
  .footer {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
    padding: 0 20px;
  }
  .upgrade-btn {
    @media (max-width: $mobile) {
      flex: 1;
      width: 100%;
    }
  }
  .upgrade-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .text-container {
    padding: 0 20px;
    @media (max-width: $mobile) {
      br {
        display: none;
      }
    }
  }
}
</style>