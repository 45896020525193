<template>
  <BaseBtn :style="{ width: width + 'px', height: height + 'px' }" :class="{white:white, red:red, blue:blue, round:round, green:green}" @click="handleClick">
    <img :src="require(`@/assets/icons/${icon}.svg`)" alt="" v-if="icon" />
	<h3 :style="{fontSize: fontSize + 'px'}" v-if="text">{{ text }}</h3>
  </BaseBtn>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue';

export default {
  name: 'BtnLine',
  props: {
    text: {
      type: String,
    },
		width: [String, Number],
    height: [String, Number],
		red: Boolean,
		blue: Boolean,
		green: Boolean,
		white: Boolean,
		round: Boolean,
		icon: String,
		fontSize: [String, Number],
		full: Boolean,		
  },
	components: { 
		BaseBtn,
	},
  data() {
    return {};
  },
  mounted() {},
  methods: {
		handleClick(e) {
      this.$emit('click',e);
    }
	},
  
};
</script>

<style lang="scss" scoped>
	button {
		border: 1px solid transparent;
    background-color: transparent;
    color: var(--main-white);
		&.full {
			flex: 1;
			width: 100%;
		}
		&.red {
			border-color: var(--main-red);
			color: var(--main-red);
		}
		&.blue {
			border-color: var(--main-blue);
			color: var(--main-blue);
		}
		&.white {
			border-color: rgba(255,255,255,0.5);
			color: var(--main-white);
		}
		&.green {
			border-color: var(--sub-green);
			color: var(--sub-green);
		}
		&.round {
			border-radius: 50vh;
			width: 50px;
			height: 24px;
			h3 {
				font-size: 11px;
			}
		}
	}
</style>
