<template>
  <div class="login-page">
    <div class="left">
      <div class="hero-title">
        <h1 class="title">Plan&Timing</h1>
        <h2 class="subtitle">The Art of Trading</h2>
      </div>
    </div>
    <div class="right">
      <div class="header">
        <BaseBtn naked @click="goBack">
          <IconChevronDown left width="24" height="24" />
        </BaseBtn>
        <h4 class="header-title">
          <b>TAP</b>
          <span>TradingAsPlan</span>
        </h4>
        <BaseBtn naked @click="close">
          <IconClose width="24" height="24" />
        </BaseBtn>
      </div>
      <div class="body">
        <div class="body-inner">
          <h3 class="body-title">회원가입</h3>
          <div class="login-ui">
            <div class="sns-login-container">
              <a href="javascript:;" @click="loginGooglePop">
                <img src="@/assets/images/google-logo.png" alt="" />
                google
              </a>
              <a href="javascript:;" @click="loginKakaoPop">
                <img src="@/assets/images/kakao-logo.png" alt="" />
                kakao
              </a>
            </div>
            <div class="login-division">
              <span></span>
              <p>또는</p>
              <span></span>
            </div>
            <div class="email-login-container">
              <router-link to="/signup-email" class="email-login">이메일</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn.vue'
import IconClose from '@/components/icons/IconClose.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import Popup from '@/modules/Popup'
import Const from '@/const'

export default {
  components: { BaseBtn, IconClose, IconChevronDown },
  name: 'SignupPage',

  data() {
    return {}
  },

  mounted() {
    //
    window.addEventListener('message', this.receiveMessage)

  },

  beforeDestroy() {
    //
    window.removeEventListener('message', this.receiveMessage)
  },  

  methods: {
    goBack () {
      this.$router.go(-1)
    },    
    close () {
      this.$router.push('/')
    },
    loginGooglePop () {
      const url = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' + process.env.VUE_APP_GOOGLE_CLIENT_ID +
                  '&redirect_uri=' + window.location.protocol +'//'+ window.location.host + process.env.VUE_APP_GOOGLE_REDIRECT_URL +
                  '&response_type=code' +
                  '&scope=email'

      Popup.openWinPop( url, 'loginExternalPop', 500, 600 )
    },
    loginKakaoPop () {
      const url = 'https://kauth.kakao.com/oauth/authorize?client_id=' + process.env.VUE_APP_KAKAO_JS_KEY +
                  '&redirect_uri=' + window.location.protocol +'//'+ window.location.host + process.env.VUE_APP_KAKAO_REDIRECT_URL +
                  '&response_type=code&' +
                  'scope=account_email'


      Popup.openWinPop( url, 'loginExternalPop', 500, 600 )
    },      
    receiveMessage(message) {
      if ( message.origin === process.env.VUE_APP_WEB_BASE_URL ) {
        const {type, payload} = message.data

        if (type === 'LOGIN_GOOGLE') {
          if ( payload !== null ) {
            this.signupGoogle(payload)
          }
        } else if (type === 'LOGIN_KAKAO') {
          if ( payload !== null ) {
            this.signupKakao(payload)
          }
        }
      }      
    }, 
    signupGoogle(data) {
      this.$lhttp
        .post('/auth/signup/google',
          data)
        .then((response) => {
          // 로그인 성공
          if ( response.data.code == Const.SIGN_UP_RESPONSE_TYPE.SUCCESS ) {

            this.$router.push('/signup-final/'+ response.data.body.id +'/'+ response.data.body.key +'')

          // 로그인 실패  
          } else { 

            if ( response.data.code == Const.SIGN_UP_RESPONSE_TYPE.DUP_EMAIL ) {
              this.$alert.alert(response.data.message, 'Warnning')
                .then(() => {
                  this.$router.push('/login')
                })
            } else {
              this.$alert.alert(response.data.message, 'Warnning')
            }

          }

        })
    },
    signupKakao(data) {
      this.$lhttp
        .post('/auth/signup/kakao',
          data)
        .then((response) => {
          // 로그인 성공
          if ( response.data.code == Const.SIGN_UP_RESPONSE_TYPE.SUCCESS ) {

            this.$router.push('/signup-final/'+ response.data.body.id +'/'+ response.data.body.key +'')

          // 로그인 실패  
          } else { 

            if ( response.data.code == Const.SIGN_UP_RESPONSE_TYPE.DUP_EMAIL ) {
              this.$alert.alert(response.data.message, 'Warnning')
                .then(() => {
                  this.$router.push('/login')
                })
            } else {
              this.$alert.alert(response.data.message, 'Warnning')
            }

          }

        })
    },    
  },
};
</script>

<style lang="scss" scoped>
</style>
